export interface CandidateProcess {
  id: string
  egineering: string
  accompanyingEngineering: boolean
  engineeringInfos: string
}

export interface Caracteristics {
  id: string
  pictures: []
  picturesDestroy?: []
  habitatFormat: HABITAT_FORMAT[] | string[]
  welcomeFormat: INCLUSIVE_WELCOME[] | string[]
  fragility: VULNERABILITY_TYPE
  outside: 0
  autonomy: INHABITANT_AUTONOMY[] | string[]
  surface: number
  totalSlot: number
  availableSlot: number
  accessInfos: string
  aroundInfos: string
  estateType: BUILDING_PARC_TYPE
  rentType: LOAN_LEVEL
  rentDetail: LOAN_DETAIL
  accessTransportation: TRANSPORTATION_ACCESSIBILITY[] | string[]
  around: AROUND_HABITAT[] | string[]
  infrastructure: HABITAT_FEATURES[] | string[]
}

export interface Organization {
  id: string
  animator: ANIMATION_SCHEDULE
  night: NIGHTWATCH_TYPE
  helpService: string
  partner: string
}

export interface SocialProject {
  id: string
  document: string
  documentDestroy?: boolean
  houseRules: string
  houseEnvironment: string
  project: SOCIAL_PROJECT_TYPE
  objective: SOCIAL_PROJECT_GOAL
}

export enum HABITAT_FORMAT {
  DIFUSE_INDIVIDUAL = 'individuel',
  GROUPED_INDIVIDUAL = 'individuel_group',
  COLOCATION = 'sharing',
}

export enum INHABITANT_AUTONOMY {
  AUTONOMOUS = 'high',
  RELATIVELY_AUTONOMOUS = 'midle',
  DEPENDANCE = 'low',
}

export enum BUILDING_PARC_TYPE {
  SOCIAL = 'social',
  PRIVATE = 'individual',
}

export enum LOAN_LEVEL {
  OVER_750 = 'high',
  BETWEEN_350_AND_750 = 'midle',
}
export enum LOAN_DETAIL {
  LOAN_ONLY = 'alone',
  WITH_CHARGES = 'with_charges',
}
export enum INCLUSIVE_WELCOME {
  VULNERABLE_ONLY = 'fragility_uniq',
  MIXED = 'mix',
  REFLECTION = 'unknwon',
}
export enum VULNERABILITY_TYPE {
  OLD = 'old',
  DISABLED = 'invalidity',
  OTHER = 'other',
}

export enum TRANSPORTATION_ACCESSIBILITY {
  NONE = 'none',
  TRAIN_STATION = 'train_station',
  TRAMWAY = 'tram',
  BUS = 'bus',
  SUBWAY = 'subway',
}

export enum AROUND_HABITAT {
  BAKERY = 'bakery',
  SUPERMARKET = 'supermarket',
  PARCS = 'parc',
  MALL = 'mall',
  THEATER = 'theater',
  GROCERY_STORE = 'shops',
  SCHOOLS = 'school',
  HOSPITAL = 'hospital',
  INCLUSIVE_COMPANY = 'companies',
}

export enum HABITAT_FEATURES {
  GARDEN = 'garden',
  SWIMING_POOL = 'pool',
  TERRACE = 'terrasse',
  LAUNDRY_ROOM = 'laundry',
  KITCHEN = 'kitchen',
  LIVING_ROOM = 'living_room',
  DINING_ROOM = 'dinner_room',
  BIKE_ROOM = 'bike_room',
  PARKING = 'parking',
  INTERNET = 'web',
  GAMING_ROOM = 'game_room',
  PRIVATE_SPACE = 'private_room',
}

export enum SOCIAL_PROJECT_TYPE {
  STOP_ISOLATION = 'isolation',
  PERSISTANT_PLACE = 'security',
  AUTONOMY = 'autonomy',
  COLLECTIVITY = 'collective',
  AGING = 'aging',
  OTHER = 'other',
}

export enum SOCIAL_PROJECT_GOAL {
  AUTONOMY = 'gain_autonomy',
  ACTIVITY = 'work_activity',
  CULTURE = 'culture_or_sport',
  COMMUNITY = 'comunauty',
  OTHER = 'other',
}

export enum ANIMATION_SCHEDULE {
  DAYTIME = 'daytime',
  NIGHT = 'night',
  ALL_DAY = 'all_day',
  WEEK = 'week',
  NONE = 'none',
}

export enum NIGHTWATCH_TYPE {
  FULL = 'supervision',
  PARTIAL = 'duty',
  NONE = 'no_supervision',
}

export enum SOCIAL_ENGINIERING {
  RAH = 'handicap',
  PCH = 'pch',
  APA = 'apa',
  NONE = 'no_resources',
}
