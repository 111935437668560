import { Form } from 'types/form'
import {
  DIET,
  CAPACITY,
  ALLERGY,
  FOOD_INTOLERANCE,
  DISEASES,
  MODIFIED_TEXTURES,
  MEAL_INSTALLATION,
} from 'types/educative'

const FOOD_FORM: Form = [
  [
    {
      name: 'capacity',
      type: 'select',
      label: "Capacités à s'alimenter",
      placeholder: 'Sélectionner la capacité',
      options: [
        {
          label: "S'alimente seul",
          value: CAPACITY.ALONE,
        },
        {
          label:
            "S'alimente seul avec l’utilisation d’aides techniques (assiette, couverts adaptés)",
          value: CAPACITY.WITH_TECHNIC,
        },
        {
          label:
            "S'alimente avec une aide partielle quotidienne (lui couper la viande, le stimuler oralement)",
          value: CAPACITY.WITH_HELP,
        },
        {
          label: "N'est pas capable de s’alimenter seul",
          value: CAPACITY.UNABLE,
        },
        {
          label: 'Alimentation orale impossible (sonde gastrique, perfusions)',
          value: CAPACITY.IMPOSSIBLE,
        },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'tastes',
      type: 'textarea',
      label: 'Goûts de la personne (Aime)',
      placeholder: 'Saisissez les goûts de la personne',
      required: false,
    },
  ],
  [
    {
      name: 'doesNotLike',
      type: 'textarea',
      label: 'N’aime pas',
      placeholder: 'Saisissez les goûts de la personne',
      required: false,
    },
  ],
  [
    {
      name: 'diet',
      type: 'checkbox',
      label: 'Régime',
      vertical: true,
      required: true,
      options: [
        {
          label: 'Aucun régime particulier',
          value: DIET.NO_DIET,
        },
        {
          label: 'Diabétique',
          value: DIET.DIABETE,
        },
        { label: 'Sans sel', value: DIET.NO_SALT },
        {
          label: 'Sans résidu',
          value: DIET.NO_RESIDUE,
        },
        {
          label: 'Hypocalorique',
          value: DIET.HYPOCALORIES,
        },
        {
          label: 'Hypercalorique',
          value: DIET.HYPERCALORIES,
        },
      ],
    },
  ],
  [
    {
      name: 'otherDiet',
      type: 'text',
      label: 'Régime autre',
      placeholder: 'Saisissez le régime particulier',
      required: false,
    },
    {
      name: 'culturalDuty',
      type: 'text',
      label: 'Obligation(s) selon rites religieux ou culturels',
      placeholder: 'Saisissez les obligations',
      required: false,
    },
  ],
  [
    {
      name: 'allergy',
      type: 'select',
      label: 'Allergies',
      placeholder: 'Oui, Non, Inconnues',
      options: [
        {
          label: 'Oui',
          value: ALLERGY.YES,
        },
        {
          label: 'Non',
          value: ALLERGY.NO,
        },
        {
          label: 'Inconnues',
          value: ALLERGY.DONT_KNOW,
        },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'allergyPrecision',
      type: 'textarea',
      label: 'Si oui, précisez',
      placeholder: 'Précisez la liste des allergies',
      required: false,
    },
  ],
  [
    {
      name: 'foodIntolerance',
      type: 'select',
      label: 'Intolérances',
      placeholder: 'Oui, Non, Inconnues',
      options: [
        {
          label: 'Oui',
          value: FOOD_INTOLERANCE.YES,
        },
        {
          label: 'Non',
          value: FOOD_INTOLERANCE.NO,
        },
        {
          label: 'Inconnues',
          value: FOOD_INTOLERANCE.DONT_KNOW,
        },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'foodIntolerancePrecision',
      type: 'textarea',
      label: 'Si oui, précisez',
      placeholder: 'Précisez la liste des intolérances',
      required: false,
    },
  ],
  [
    {
      name: 'disease',
      type: 'select',
      label: 'Conduites alimentaires perturbées',
      placeholder: 'Selectionnez la/les conduites alimentaires',
      options: [
        {
          label: 'Aucunes',
          value: DISEASES.NONE,
        },
        {
          label: 'Anorexie',
          value: DISEASES.ANOREXIA,
        },
        {
          label: 'Boulimie',
          value: DISEASES.BULEMIA,
        },
        {
          label: 'Potomanie',
          value: DISEASES.POTOMANIA,
        },
        {
          label: "Ingestion d'objets",
          value: DISEASES.OBJECT_INGESTION,
        },
        {
          label: 'Autres',
          value: DISEASES.OTHER,
        },
      ],
      multi: true,
      required: true,
    },
  ],
  [
    {
      name: 'diseasePrecision',
      type: 'textarea',
      label: 'Si autres, précisez',
      placeholder: 'Précisez la conduite alimentaire',
      required: false,
    },
  ],
  [
    {
      name: 'falseSwallowing',
      type: 'radio',
      label: 'Antécédents de fausses déglutitions',
      placeholder: 'Oui, Non',
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        {
          label: 'Non',
          value: 'false',
        },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'falseSwalloingPrecision',
      type: 'textarea',
      label: 'Précisions éventuelles',
      placeholder: '',
      required: false,
    },
  ],
  [
    {
      name: 'modifiedTextures',
      type: 'select',
      label: 'Textures modifiées',
      placeholder: 'Selectionnez la texture',
      options: [
        {
          label: "« Manger entier » (la personne sait couper seule, pas d'adaptation)",
          value: MODIFIED_TEXTURES.EAT_WHOLE,
        },
        {
          label: '« Manger coupé » (le plat est présenté coupé en morceaux)',
          value: MODIFIED_TEXTURES.EAT_CUT,
        },
        {
          label: '« Manger haché » (la viande est hachée uniquement)',
          value: MODIFIED_TEXTURES.EAT_GRINDED,
        },
        {
          label: '« Manger mouliné » (= comme une purée)',
          value: MODIFIED_TEXTURES.EAT_MILL,
        },
        {
          label: '« Manger mixé » (= lisse comme un produit laitier)',
          value: MODIFIED_TEXTURES.EAT_MIXED,
        },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'liquidTextures',
      type: 'radio',
      label: 'La texture des liquides doit- elle être épaissie pour l’hydratation de la personne ?',
      placeholder: 'Oui, Non',
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        {
          label: 'Non',
          value: 'false',
        },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'mealInstallation',
      type: 'select',
      label: 'Installation au repas',
      placeholder: "Selectionnez l'installation au repas",
      options: [
        {
          label: 'Aucune installation spécifique nécessaire',
          value: MEAL_INSTALLATION.NONE,
        },
        {
          label: "Besoin d'être seul",
          value: MEAL_INSTALLATION.ALONE,
        },
        {
          label: "Besoin d'un plateau",
          value: MEAL_INSTALLATION.WITH_TRAY,
        },
        {
          label: 'Set de table individualisé',
          value: MEAL_INSTALLATION.INDIVIDUAL_SET,
        },
        {
          label: 'Couverts en plastique',
          value: MEAL_INSTALLATION.WITH_PLASTIC_CUTLERY,
        },
        {
          label: 'Autre',
          value: MEAL_INSTALLATION.OTHER,
        },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'mealPrecision',
      type: 'textarea',
      label: 'Si autre, précisez',
      placeholder: '',
      required: false,
    },
  ],
]

export default FOOD_FORM
