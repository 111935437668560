import { Form } from 'types/form'
import { countries } from 'constants/country'

const COVERAGE_FORM: Form = [
  [
    {
      name: 'healthInsuranceName',
      type: 'text',
      label: 'Nom de la caisse',
      placeholder: 'Saisissez le nom de la caisse (ex: CPAM Seine Maritime, CPAM Tourcoing, ...)',
      required: true,
    },
  ],
  [
    {
      name: 'street',
      type: 'text',
      label: 'Adresse',
      placeholder: "Saisissez l'adresse de la caisse",
      required: true,
    },
    {
      name: 'streetComplement',
      type: 'text',
      label: "Complément d'adresse",
      placeholder: "Saisissez le complément d'adresse de la caisse",
      required: false,
    },
  ],
  [
    {
      name: 'zipcode',
      type: 'text',
      label: 'Code postal',
      placeholder: 'Saisissez le code postal',
      required: true,
    },
    {
      name: 'city',
      type: 'text',
      label: 'Ville',
      placeholder: 'Saisissez la ville',
      required: true,
    },
  ],
  [
    {
      name: 'country',
      type: 'select',
      label: 'Pays',
      placeholder: 'Choisissez le pays',
      options: countries,
      required: true,
    },
    null,
  ],
  [
    {
      name: 'insuredLastName',
      type: 'text',
      label: "Nom de l'assuré",
      placeholder: "Saisissez le nom de l'assuré",
      required: true,
    },
    {
      name: 'insuredFirstName',
      type: 'text',
      label: "Prénom de l'assuré",
      placeholder: "Saisissez le prénom de l'assuré",
      required: true,
    },
  ],
  [
    {
      name: 'registrationNumber',
      type: 'text',
      label: 'Numéro de matricule',
      placeholder: 'Saisissez le numéro de matricule',
      required: true,
    },
  ],
  [
    {
      name: 'extraInsurance',
      type: 'select',
      label: 'Assurance complémentaire eventuelle',
      placeholder: "Saisissez l'assurance eventuelle",
      required: true,
      options: [
        { value: 'not', label: 'Aucune' },
        { value: 'cmu', label: 'CSS' },
        { value: 'other', label: 'Autre' },
      ],
    },
    {
      name: 'additionalInsuranceName',
      type: 'text',
      label: "Si autre précisez laquelle, si CSS précisez l'organisme complémentaire",
      placeholder: "Saisissez l'assurance eventuelle",
      required: false,
    },
  ],
]

export default COVERAGE_FORM
