import { Form } from 'types/form'
import { TUTOR_STATUS } from 'types/enums'
import { countries } from 'constants/country'

export const OPTIONS_TUTOR_STATUS = [
  { value: TUTOR_STATUS.KIN_TUTOR, label: 'Tutelle familiale' },
  { value: TUTOR_STATUS.PRO_TUTOR, label: 'Tutelle professionnelle' },
  { value: TUTOR_STATUS.KIN_CUR, label: 'Curatelle familiale' },
  { value: TUTOR_STATUS.PRO_CUR, label: 'Curatelle professionnelle' },
  { value: TUTOR_STATUS.KIN_HAB, label: 'Habilitation familiale' },
]

const LEGAL_GUARDIAN_FORM: Form = [
  [
    {
      name: 'firstName',
      type: 'text',
      label: 'Prénom',
      placeholder: 'Saisissez le prénom',
      required: true,
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Nom',
      placeholder: 'Saisissez le nom',
      required: true,
    },
  ],
  [
    {
      name: 'status',
      type: 'select',
      label: 'Mesure de protection juridique',
      placeholder: 'Choisissez la mesure de protection juridique',
      required: true,
      options: OPTIONS_TUTOR_STATUS,
    },
    {
      name: 'kinship',
      type: 'text',
      label: 'Si mesure de protection familiale, lien de parenté',
      placeholder: 'Saisissez votre lien de parenté',
      required: false,
    },
  ],
  [
    {
      name: 'street',
      type: 'text',
      label: 'Lieu de résidence',
      placeholder: 'Saisissez le lieu de résidence',
      required: true,
    },
    {
      name: 'streetComplement',
      type: 'text',
      label: "Complément d'adresse",
      placeholder: "Saisissez le complément d'adresse",
      required: false,
    },
  ],
  [
    {
      name: 'zipcode',
      type: 'text',
      label: 'Code postal',
      placeholder: 'Saisissez le code postal',
      required: true,
    },
    {
      name: 'city',
      type: 'text',
      label: 'Ville',
      placeholder: 'Saisissez la ville',
      required: true,
    },
  ],
  [
    {
      name: 'country',
      type: 'select',
      label: 'Pays',
      placeholder: 'Choisissez le pays',
      options: countries,
      required: true,
    },
    null,
  ],
  [
    {
      name: 'phoneNumber',
      type: 'phone',
      label: 'Téléphone',
      placeholder: 'Saisissez le numéro de téléphone',
      required: true,
    },
    {
      name: 'email',
      type: 'text',
      label: 'E-mail',
      placeholder: "Saisissez l'e-mail",
      required: true,
    },
  ],
]

export default LEGAL_GUARDIAN_FORM
