import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { find, findIndex } from 'lodash'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Button from 'ui/button'
import Input from 'ui/form/input'
import Text from 'ui/typography/text'

const Circle = styled.div`
  color: white;
  background-color: ${props => props.theme.green};
  width: 32px;
  height: 32px;
  border-radius: 24px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 5px;
  margin-right: 16px;
  letter-spacing: -1px;
`
const Profile = styled(Box)`
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.green};

    ${Circle} {
      background-color: ${props => props.theme.white};
      color: ${props => props.theme.greenDark};
    }
  }
`

interface Props {
  currentId: string
  list: Array<
    { id: string } & Partial<{ firstName: string; lastName: string; institutionName: string }>
  >
  onSelect: (id: string) => void
  onNew?: () => void
  userRole?: 'user_institution' | 'doctor'
}

export default function ProfilePicker({ currentId, list, onSelect, onNew, userRole }: Props) {
  const [opened, setOpen] = useState(false)
  const [filters, setFilters] = useState<string[]>([])
  const inputRef = useRef<HTMLInputElement>(null)
  const current = find(list, ['id', currentId])
  const currentIndex = findIndex(list, ['id', currentId])

  const formatName = (
    index: number,
    firstName?: string,
    lastName?: string,
    institutionName?: string,
  ) => {
    if (institutionName) return institutionName
    if (firstName && lastName) return `${firstName} ${lastName}`

    return `Dossier n°${index + 1}`
  }

  const formatInitials = (firstName?: string, lastName?: string, institutionName?: string) => {
    if (institutionName) return institutionName[0]
    if (firstName && lastName) return `${firstName[0]} ${lastName[0]}`

    return `?`
  }

  const filteredList =
    filters.length > 0
      ? list.filter(d =>
          filters.some(f =>
            [d.firstName, d.lastName, d.institutionName].some(name =>
              name?.match(new RegExp(f, 'i')),
            ),
          ),
        )
      : list

  if (!current) {
    return (
      <Box f="intial" bg="greenDark" padding="16px" ai="c">
        {!!onNew && userRole !== 'user_institution' && (
          <Button
            variant="secondary"
            onClick={() => {
              onNew()
              setOpen(false)
            }}
          >
            {
              // Temponary disabled feature for institution. Need to be fixed
              /*userRole === 'user_institution'
              ? '+ Ajoutez un établissement'
              : */ '+ Ajoutez un nouveau protégé'
            }
          </Button>
        )}
      </Box>
    )
  }

  return (
    <Box fd="c" style={{ position: 'relative' }} f="intial">
      <Box
        bg="greenDark"
        padding="16px"
        f="initial"
        onClick={() => setOpen(!opened)}
        ai="c"
        style={{ cursor: 'pointer' }}
      >
        <Icon
          name={userRole === 'user_institution' ? 'institution' : 'person'}
          color="white"
          size={16}
        />
        <Text fs={16} bold color="white" style={{ flex: 1, padding: '0 16px' }}>
          {formatName(currentIndex, current.firstName, current.lastName, current.institutionName)}
        </Text>
        <Icon name="caretFill" color="white" size={9} rotate={opened ? 180 : 0} />
      </Box>
      {opened && (
        <Box
          fd="c"
          bg="greenDark"
          padding="0 16px 16px 16px"
          style={{ position: 'absolute', top: '78px', maxHeight: '60vh', zIndex: 2 }}
          width="100%"
        >
          <Box ai="c" style={{ marginBottom: '16px', position: 'relative' }}>
            <Input
              placeholder="Rechercher"
              style={{ border: 0, flex: 1, paddingRight: '36px', width: '100%' }}
              ref={inputRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFilters(e.target.value.split(' ').filter(f => !!f))
              }
            />

            <Icon
              size={12}
              name={filters.length > 0 ? 'close' : 'glass'}
              color="black"
              cp="12px"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = ''
                }

                setFilters([])
              }}
              containerStyle={{ position: 'absolute', right: 0 }}
            />
          </Box>

          <Box fd="c" scroll>
            {filteredList.map((r, index) => (
              <Profile
                fd="r"
                ai="c"
                key={r.id}
                padding="8px"
                onClick={() => {
                  setOpen(false)
                  onSelect(r.id)
                }}
              >
                <Circle>{formatInitials(r.firstName, r.lastName, r.institutionName)}</Circle>
                <Text fs={12} color="white">
                  {formatName(index, r.firstName, r.lastName, r.institutionName)}
                </Text>
              </Profile>
            ))}
          </Box>

          {filteredList.length === 0 && (
            <Text bold fs={16} color="white" style={{ padding: '16px 0' }}>
              {`Aucun dossier ne contient "${filters.join(', ')}"`}
            </Text>
          )}

          {!!onNew && userRole !== 'user_institution' && (
            <Button variant="secondary" style={{ marginTop: '16px' }} onClick={onNew}>
              {
                // Temponary disabled feature for institution. Need to be fixed
                /*userRole === 'user_institution'
                ? '+ Ajoutez un établissement'
                : */ '+ Ajoutez un nouveau protégé'
              }
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}
