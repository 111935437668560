import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { get } from 'lodash'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  responseType: 'json',
  headers: { post: { 'Content-Type': 'application/json' } },
})

instance.interceptors.request.use(request => {
  return {
    ...request,
    data:
      get(request.headers, 'Content-Type') !== 'multipart/form-data'
        ? decamelizeKeys(request.data)
        : request.data,
    withCredentials: true,
  }
})

instance.interceptors.response.use(response => {
  return {
    ...response,
    data: camelizeKeys(response.data, (key, convert) => (/[\s-]/g.test(key) ? key : convert(key))),
  }
})

export default instance
