import React from 'react'
import { get } from 'lodash'

import { GenericForm, GenericStep } from '../../form'
import { Auth } from 'types/user'
import TMedical from 'types/medical'
import { getProgress, submit, submitVaccination } from 'api/form'
import PATIENT_FORM from './forms/patient'
import ALD_FORM from './forms/ald'
import DIAGNOSTIC_FORM from './forms/diagnostic'
import HANDICAP_FORM from './forms/handicap'
import LIFE_STYLE_FORM from './forms/lifeStyle'
import HISTORY_FORM from './forms/history'
import AUTHOR_FORM from './forms/author'
import DEFICIENCY_FORM from './forms/deficiency'
import TreatmentForm from './forms/treatment'
import VaccinationForm from './forms/vaccination'
import Conclusion from './forms/conclusion'

export default function Medical({ refreshProgress }: { refreshProgress: () => void }) {
  return (
    <GenericForm<TMedical>
      key="medical-form"
      title="Renseignements médicaux"
      path="medical"
      resourceLoader={(auth: Partial<Auth>) => getProgress(get(auth, 'selectedId', '0'), 'medical')}
      globalStep={1}
      afterSave={refreshProgress}
      steps={(auth: Partial<Auth>) => [
        {
          title: 'Patient',
          Component: ({ resource }) => (
            <GenericStep<TMedical>
              resource={resource}
              subForm="patient"
              fields={PATIENT_FORM}
              subTitle="Informations relatives au patient"
            />
          ),
          save: (data: { patient: TMedical['patient'] }) => {
            return submit<TMedical['patient'], { patient: TMedical['patient'] }>(
              get(auth, 'selectedId', '0'),
              'medical',
              'patient',
              data,
            )
          },
        },
        {
          title: 'Informations ALD',
          Component: ({ resource }) => (
            <GenericStep<TMedical>
              resource={resource}
              subForm="ald"
              fields={ALD_FORM}
              subTitle="Informations ALD"
            />
          ),
          save: (data: { ald: TMedical['ald'] }) => {
            return submit<TMedical['ald'], { ald: TMedical['ald'] }>(
              get(auth, 'selectedId', '0'),
              'medical',
              'ald',
              data,
            )
          },
        },
        {
          title: 'Diagnostic principal',
          Component: ({ resource }) => (
            <GenericStep<TMedical>
              resource={resource}
              subForm="diagnostic"
              fields={DIAGNOSTIC_FORM}
              subTitle="Informations relatives au diagnostic principal"
            />
          ),
          save: (data: { diagnostic: TMedical['diagnostic'] }) => {
            return submit<TMedical['diagnostic'], { diagnostic: TMedical['diagnostic'] }>(
              get(auth, 'selectedId', '0'),
              'medical',
              'diagnostic',
              data,
            )
          },
        },
        {
          title: 'Origine du handicap',
          Component: ({ resource }) => (
            <GenericStep<TMedical>
              resource={resource}
              subForm="handicap"
              fields={HANDICAP_FORM}
              subTitle="Informations relatives à l'origine de l'handicap"
            />
          ),
          save: (data: { handicap: TMedical['handicap'] }) => {
            return submit<TMedical['handicap'], { handicap: TMedical['handicap'] }>(
              get(auth, 'selectedId', '0'),
              'medical',
              'handicap',
              data,
            )
          },
        },
        {
          title: 'Traitement actuel',
          Component: ({ resource }) => <TreatmentForm resource={resource} />,
          save: (data: { treatment: TMedical['treatment'] }) => {
            return submit<TMedical['treatment'], { treatment: TMedical['treatment'] }>(
              get(auth, 'selectedId', '0'),
              'medical',
              'treatment',
              data,
              true,
            )
          },
        },
        {
          title: 'Déficiences',
          Component: ({ resource }) => (
            <GenericStep<TMedical>
              resource={resource}
              subForm="deficiency"
              fields={DEFICIENCY_FORM}
              subTitle="Informations relatives aux déficiences"
            />
          ),
          save: (data: { deficiency: TMedical['deficiency'] }) => {
            return submit<TMedical['deficiency'], { deficiency: TMedical['deficiency'] }>(
              get(auth, 'selectedId', '0'),
              'medical',
              'deficiency',
              data,
            )
          },
        },
        {
          title: 'Mode de vie',
          Component: ({ resource }) => (
            <GenericStep<TMedical>
              resource={resource}
              subForm="lifeStyle"
              fields={LIFE_STYLE_FORM}
              subTitle="Informations relatives au mode de vie"
            />
          ),
          save: (data: { lifeStyle: TMedical['lifeStyle'] }) => {
            return submit<TMedical['lifeStyle'], { lifeStyle: TMedical['lifeStyle'] }>(
              get(auth, 'selectedId', '0'),
              'medical',
              'lifeStyle',
              data,
            )
          },
        },
        {
          title: 'Vaccinations',
          Component: ({ resource }) => <VaccinationForm resource={resource} />,
          save: (data: { vaccination: TMedical['vaccination'] }) => {
            return submitVaccination(get(auth, 'selectedId', '0'), data.vaccination)
          },
        },
        {
          title: 'Antécédents',
          Component: ({ resource }) => (
            <GenericStep<TMedical>
              resource={resource}
              subForm="history"
              fields={HISTORY_FORM}
              subTitle="Informations relatives aux antécédents"
            />
          ),
          save: (data: { history: TMedical['history'] }) => {
            return submit<TMedical['history'], { history: TMedical['history'] }>(
              get(auth, 'selectedId', '0'),
              'medical',
              'history',
              data,
            )
          },
        },
        {
          title: 'Auteur des données',
          Component: ({ resource }) => (
            <GenericStep<TMedical>
              resource={resource}
              subForm="author"
              fields={AUTHOR_FORM}
              subTitle="Informations relatives à l'auteur des données"
            />
          ),
          save: (data: { author: TMedical['author'] }) => {
            return submit<TMedical['author'], { author: TMedical['author'] }>(
              get(auth, 'selectedId', '0'),
              'medical',
              'author',
              data,
            )
          },
        },
        {
          title: 'Complet',
          Component: ({ resource }) => (
            <Conclusion
              resource={resource}
              nextStep="/medical-validation/etape/1"
              firstStep="/medical/etape/1"
            />
          ),
        },
      ]}
    />
  )
}
