import React, { useState } from 'react'
import { fr } from 'date-fns/locale'
import { format } from 'date-fns'

import Modal from 'ui/modal'
import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'

import { useToasts } from 'hooks/useToaster'
import { ownerEngage } from 'api/admission'

export default function OwnerAcceptedEngagement({
  data,
  opened,
  onClose,
  onSuccess,
}: {
  data: {
    candidacyId: string
    institutionName: string
    engageDate: Date
  }
  opened: boolean
  onClose: () => void
  onSuccess: () => void
}) {
  const { addToast, addGenericErrorToast } = useToasts()
  const [loading, setLoader] = useState(false)

  return (
    <Modal onClose={onClose} opened={opened} title="Décision finale">
      <Box fd="c">
        <Box br={4} bg="greyLightN" padding="24px">
          <Icon name="message" color="green" size={22} containerStyle={{ marginRight: '24px' }} />

          <Box fd="c">
            <Text fs={14} color="green" spacingBottom="8px">
              DATE D’ÉNTRÉE PRÉVUE LE : {format(data.engageDate, 'PPP', { locale: fr })}
            </Text>
            <Text fs={16} color="black">
              {`Vous êtes sur le point d’accepter la date d’entrée proposée par l’établissement : ${data.institutionName}. Si vous acceptez cet établissement vous abandonnez votre candidature
              pour les autres établissements. Le support pourra vous aider dans le cas ou vous
              changez d’avis.`}
            </Text>
          </Box>
        </Box>
        <Box jc="fe" padding="16px 0 0 0">
          <Button variant="outline" onClick={onClose} style={{ marginRight: '16px' }}>
            Retour
          </Button>
          <Button
            onClick={() => {
              setLoader(true)

              ownerEngage(data.candidacyId)
                .then(() => {
                  setLoader(false)
                  addToast({
                    title: 'Date d’entrée programmée !',
                    type: 'success',
                    message: `Votre arrivée est prévue le ${format(data.engageDate, 'PPP', {
                      locale: fr,
                    })}.`,
                  })
                  onSuccess()
                  onClose()
                })
                .catch(() => {
                  addGenericErrorToast()
                  setLoader(false)
                })
            }}
          >
            {loading ? (
              <Loader size={12} />
            ) : (
              <>
                Accepter cette date
                <Icon name="arrow" color="black" size={20} containerStyle={{ marginLeft: '8px' }} />
              </>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
