import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import styled from 'styled-components'
import { get, includes, xor } from 'lodash'

import Modal from 'ui/modal'
import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Dropdown from 'ui/form/dropdown'
import Loader from 'ui/loader'
import Radio from 'ui/form/radio'
import Text from 'ui/typography/text'
import { URGENCY_LEVELS, ESTABLISHMENT_TYPES, USER_TYPES, EstablishmentType } from 'types/enums'
import { postAdmissionRecord } from 'api/admissionRecord'
import { Auth } from 'types/user'

const ErrorMessage = styled(Text).attrs({ fs: 16, color: 'pink' })<{
  hasError: boolean
}>`
  visibility: ${props => (props.hasError ? 'visible' : 'hidden')};
`

export default function CreateAdmissionRecord({
  opened,
  onClose,
  onSuccess,
}: {
  opened: boolean
  onSuccess: (id: string, admissionRecords: Auth['admissionRecords']) => void
  onClose: () => void
}) {
  const [loading, setLoader] = useState(false)
  const { control, register, handleSubmit, errors } = useForm()
  const [establishmentSearch, setEstablishmentSearch] = useState<EstablishmentType[]>([])

  return (
    <Modal onClose={onClose} opened={opened} title="Ajoutez un nouveau protégé">
      <Box
        as="form"
        onSubmit={handleSubmit(data => {
          setLoader(true)

          postAdmissionRecord({
            admissionRecord: { ...data, establishmentSearch: establishmentSearch },
          })
            .then(({ id, admissionRecords }) => {
              setLoader(false)
              onSuccess(id, admissionRecords)
              onClose()
            })
            .catch(() => setLoader(false))
        })}
        autoComplete="off"
        fd="c"
        f="initial"
        style={{ overflow: 'hidden', position: 'relative' }}
      >
        <Box scroll fd="c">
          <Box f="initial" gutter="negative">
            <Group style={{ padding: '15px' }}>
              <Label htmlFor="lastName" required style={{ paddingBottom: '16px' }}>
                Nom
              </Label>
              <Input
                id="lastName"
                name="concerned.lastName"
                placeholder="Saisissez votre nom"
                ref={register({ required: 'Ce champ est requis' })}
              />
              <ErrorMessage hasError={!!errors?.concerned?.lastName}>
                {get(errors, 'concerned.lastName.message')}
              </ErrorMessage>
            </Group>
            <Group style={{ padding: '15px' }}>
              <Label htmlFor="firstName" required style={{ paddingBottom: '16px' }}>
                Prémon
              </Label>
              <Input
                id="firstName"
                name="concerned.firstName"
                placeholder="Saisissez votre prénom"
                ref={register({ required: 'Ce champ est requis' })}
              />
              <ErrorMessage hasError={!!errors?.concerned?.firstName}>
                {get(errors, 'concerned.firstName.message')}
              </ErrorMessage>
            </Group>
          </Box>
          <Box f="initial" gutter="negative">
            <Group style={{ padding: '15px' }}>
              <Label htmlFor="concernedAge" required style={{ paddingBottom: '16px' }}>
                Âge
              </Label>
              <Input
                id="concernedAge"
                name="concernedAge"
                type="number"
                placeholder="Saisissez l’âge"
                ref={register({
                  required: 'Ce champ est requis',
                  min: {
                    value: 18,
                    message: 'Le protégé doit avoir au moins 18 ans',
                  },
                })}
              />
              <ErrorMessage hasError={!!errors?.concernedAge}>
                {get(errors, 'concernedAge.message')}
              </ErrorMessage>
            </Group>
            <Group style={{ padding: '15px' }}>
              <Label htmlFor="urgencyLevel" required style={{ paddingBottom: '16px' }}>
                Niveau d’urgence de la requête
              </Label>
              <Controller
                as={Dropdown}
                id="urgencyLevel"
                name="urgencyLevel"
                placeholder="Sélectionnez le niveau d’urgence"
                options={[
                  { value: URGENCY_LEVELS.HIGH, label: 'Fort' },
                  { value: URGENCY_LEVELS.MEDIUM, label: 'Moyen' },
                  { value: URGENCY_LEVELS.LOW, label: 'Faible' },
                ]}
                control={control}
                rules={{ required: 'Ce champ est requis' }}
              />
              <ErrorMessage hasError={!!errors?.urgencyLevel}>
                {get(errors, 'urgencyLevel.message')}
              </ErrorMessage>
            </Group>
          </Box>
          <Box f="initial" gutter="negative">
            <Group style={{ padding: '15px' }}>
              <Label htmlFor="userType" required style={{ paddingBottom: '16px' }}>
                Vous êtes :
              </Label>
              <Controller
                as={Dropdown}
                id="userType"
                name="userType"
                placeholder="Parent, tuteur/tutrice, usager"
                options={[
                  {
                    value: USER_TYPES.TUTOR,
                    label: 'Vous êtes le tuteur ou la tutrice de l’usager',
                  },
                  { value: USER_TYPES.OWNER, label: 'Vous êtes l’usager' },
                ]}
                control={control}
                rules={{ required: 'Ce champ est requis' }}
              />
              <ErrorMessage hasError={!!errors?.userType}>
                {get(errors, 'userType.message')}
              </ErrorMessage>
            </Group>
            <Group style={{ padding: '15px' }}>
              <Label htmlFor="alreadyInStructure" required style={{ paddingBottom: '16px' }}>
                Actuellement pris en charge dans une structure avec internat ?
              </Label>
              <Box f="0 1 auto">
                <Radio
                  id="alreadyInStructure-true"
                  name="alreadyInStructure"
                  value="true"
                  forwardRef={register({ required: 'Ce champ est requis' })}
                >
                  Oui
                </Radio>
                <Radio
                  id="alreadyInStructure-false"
                  name="alreadyInStructure"
                  value="false"
                  forwardRef={register({ required: 'Ce champ est requis' })}
                >
                  Non
                </Radio>
              </Box>
              <ErrorMessage hasError={!!errors?.alreadyInStructure}>
                {get(errors, 'alreadyInStructure.message')}
              </ErrorMessage>
            </Group>
          </Box>
          <Box padding="15px 0 0 0">
            <Label required>Vous pouvez sélectionner plusieurs modes d&apos;accueil :</Label>
          </Box>
          <Box f="initial" gutter="negative">
            <Group style={{ padding: '15px' }}>
              <Box fd="c" jc="c" ai="c" withBorder padding="27px">
                <Text fs={18}>
                  <b>MAS -</b> Maison d&apos;Accueil Spécialisé
                </Text>
                <Button
                  variant={
                    includes(establishmentSearch, ESTABLISHMENT_TYPES.MAS) ? 'success' : 'outline'
                  }
                  style={{ height: '43px', width: '250px' }}
                  onClick={() =>
                    setEstablishmentSearch(xor(establishmentSearch, [ESTABLISHMENT_TYPES.MAS]))
                  }
                >
                  {includes(establishmentSearch, ESTABLISHMENT_TYPES.MAS)
                    ? 'Selectionné'
                    : 'Selectionner'}
                </Button>
              </Box>
            </Group>
            <Group style={{ padding: '15px' }}>
              <Box fd="c" jc="c" ai="c" withBorder padding="27px">
                <Text fs={18}>
                  <b>FAM -</b> Foyer d&apos;accueil médicalisé
                </Text>
                <Button
                  variant={
                    includes(establishmentSearch, ESTABLISHMENT_TYPES.FAM) ? 'success' : 'outline'
                  }
                  style={{ height: '43px', width: '250px' }}
                  onClick={() =>
                    setEstablishmentSearch(xor(establishmentSearch, [ESTABLISHMENT_TYPES.FAM]))
                  }
                >
                  {includes(establishmentSearch, ESTABLISHMENT_TYPES.FAM)
                    ? 'Selectionné'
                    : 'Selectionner'}
                </Button>
              </Box>
            </Group>
          </Box>
          <Box f="initial" gutter="negative">
            <Group style={{ padding: '15px' }}>
              <Box fd="c" jc="c" ai="c" withBorder padding="27px">
                <Text fs={18}>
                  <b>FO ou FV -</b> Foyer occupationnel ou de vie
                </Text>
                <Button
                  variant={
                    includes(establishmentSearch, ESTABLISHMENT_TYPES.FO_FV) ? 'success' : 'outline'
                  }
                  style={{ height: '43px', width: '250px' }}
                  onClick={() =>
                    setEstablishmentSearch(xor(establishmentSearch, [ESTABLISHMENT_TYPES.FO_FV]))
                  }
                >
                  {includes(establishmentSearch, ESTABLISHMENT_TYPES.FO_FV)
                    ? 'Selectionné'
                    : 'Selectionner'}
                </Button>
              </Box>
              <ErrorMessage hasError={!!errors?.establishmentSearch}>
                {get(errors, 'establishmentSearch.message')}
              </ErrorMessage>
            </Group>
          </Box>
          <Input
            type="hidden"
            id="establishmentSearch"
            name="establishmentSearch"
            defaultValue={establishmentSearch}
            ref={register({ required: 'Ce champ est requis' })}
          />
        </Box>
        <Box jc="sb" f="0 1 auto" padding="16px 0 0 0">
          <Button variant="outline" style={{ backgroundColor: 'white' }} onClick={onClose}>
            <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
            Annuler la création du profil
          </Button>
          <Button type="submit" variant="success" disabled={loading}>
            {loading ? (
              <Loader />
            ) : (
              <>
                Créer le profil du protégé
                <Icon name="arrow" color="white" size={20} containerStyle={{ marginLeft: '8px' }} />
              </>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
