import React, { useEffect } from 'react'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'

type Props = {
  title: string
  type: 'error' | 'success'
  message: JSX.Element | string
  onClose: () => void
  closeAfter?: number
}

export default function Toast({ title, type, message, onClose, closeAfter = 5 }: Props) {
  const color = type === 'success' ? 'green' : 'pink'

  useEffect(() => {
    setTimeout(() => onClose(), closeAfter * 1000)
  }, [])

  return (
    <Box
      bg="white"
      padding="10px"
      ai="st"
      fd="r"
      style={{
        borderRadius: '4px',
        zIndex: 20,
        margin: '0 0 0 0',
        minHeight: '94px',
        boxShadow: '20px 20px 20px rgba(0, 0, 0, 0.05)',
      }}
      width="375px"
    >
      <Icon
        color="white"
        size={14}
        name={type === 'success' ? 'check' : 'alertTriangle'}
        br={20}
        cp="10px"
        bg={color}
      />

      <Box fd="c" padding="0 12px 0 12px">
        <Title fs={16} bold color={color}>
          {title}
        </Title>
        <Text fs={14} as="div" color={color}>
          {message}
        </Text>
      </Box>
      <Box fd="c" ai="s" f="initial">
        <Icon name="close" color={color} size={14} onClick={onClose} />
      </Box>
    </Box>
  )
}
