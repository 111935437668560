import React from 'react'
import styled from 'styled-components'

import Box from 'ui/box'
import Text from 'ui/typography/text'

const InfoBox = styled(Box).attrs({ f: 'initial', fd: 'c', br: 4 })`
  background-color: ${props => props.theme.greenLight2};
  padding: 24px;
`

const CardInfo: React.FC<{
  name: string
  description: string
  style?: React.CSSProperties
}> = ({ name, description, style }) => {
  return (
    <InfoBox style={style}>
      <Text fs={18} bold spacingBottom="8px">
        {name}
      </Text>
      <Text fs={16}>{description}</Text>
    </InfoBox>
  )
}

export default CardInfo
