import { ESTABLISHMENT_TYPES, INCLUSIVE_ESTABLISHMENT_TYPES } from 'types/enums'

export enum ACCOMMODATION_PUBLIC {
  POLY_DISABLED = 'poly_disabled',
  AUTISTIC = 'autistic',
  INTELLECTUAL_DISABILITY = 'intellectual_disability',
  INJURED_BRAIN = 'injured_brain',
  PSYCHIC_IMPAIRMENT = 'psychic_impairment',
  COMMUNICATION_DEFICIENCY = 'communication_deficiency',
  SEVERE_MENTAL_IMPAIRMENT = 'severe_mental_impairment',
  MOTOR_DISABLED = 'motor_disabled',
  VISUAL_IMPAIRMENT = 'visual_impairment',
  BEHAVIOR_DISORDER = 'behavior_disorder',
  HEARING_DISABILITY = 'hearing_disability',
  COGNITIVE_DISABILITY = 'cognitive_disability',
  OTHER = 'other',
}

export enum ACCOMMODATION_HANDICAP {
  INTELLECTUAL_DISABILITY = 'intellectual_disability',
  SEVERE_MENTAL_RETARDATION = 'severe_mental_retardation',
  MILD_MENTAL_RETARDATION = 'mild_mental_retardation',
  MODERATE_MENTAL_RETARDATION = 'moderate_mental_retardation',
  INTELLECTUAL_DISABILITIES = 'intellectual_disabilities ',
  POLYDISABILITY = 'polydisability',
  SEVERE_RETARDATION = 'severe_retardation',
  COMMUNICATION_IMPAIRMENT = 'communication_impairment',
  MODERATE_RETARDATION_WITH_DISORDERS = 'moderate_retardation_with_disorders',
  MILD_RETARDATION_WITH_DISORDERS = 'mild_retardation_with_disorders',
  PSYCHOLOGICAL_DIFFICULTIES = 'psychological_difficulties',
  PSYCHE_IMPAIRMENT = 'psyche_impairment',
  INTERMITTENT_CONSCIOUSNESS = 'intermittent_consciousness',
  CEREBRAL_PALSY = 'cerebral_palsy',
  BRAIN_DAMAGE_IMPAIRMENT = 'brain_damage_impairment',
  COGNITIVE_DISABILITY = 'cognitive_disability',
  MOTOR_IMPAIRMENT_DISORDERS = 'motor_impairment_disorders',
  MOTOR_IMPAIRMENT = 'motor_impairment',
  HEARING_IMPAIRMENT_DISORDERS = 'hearing_impairment_disorders',
  HEARING_IMPAIRMENT = 'hearing_impairment',
  VISUAL_IMPAIRMENT_DISORDERS = 'visual_impairment_disorders',
  VISUAL_IMPAIRMENT = 'visual_impairment',
  DEAF_BLINDNESS = 'deaf_blindness',
  AUTISM_DISORDERS = 'autism_disorders',
  PSYCHOPATHOLOGICAL_DISORDERS = 'psychopathological_disorders',
  SEVERE_PSYCHOPATHOLOGICAL_DISORDERS = 'severe_psychopathological_disorders',
}

export enum ACCOMMODATION_MODE {
  DAY_RECEPTION = 'day_reception',
  FULL_ACCOMMODATION = 'full_accommodation',
  HALF_ACCOMMODATION = 'half_accommodation',
  NO_ACCOMMODATION = 'no_accommodation',
  TEMPORARY_PLACEMENT = 'temporary_placement',
  HOST_FAMILY_SERVICE = 'host_family_service',
}

export enum SUPPORT_ACTIVITY {
  WITH_ANIMALS = 'with_animals',
  DAILY_ACTIVITY = 'daily_activity',
  MANUAL_ACTIVITIES = 'manual_activities',
  MUSIC_ACTIVITY = 'music_activity',
  SPORT_ACTIVITY = 'sport_activity',
  ART_ACTIVITY = 'art_activity',
  BALNEOTHERAPY = 'balneotherapy',
  GO_OUT = 'go_out',
  BODY_CARE = 'body_care',
}

export enum PROFESSIONAL_ACTIVITY {
  MAINSTREAM_EDUCATION = 'mainstream_education',
  INITIATION_WORKSHOPS = 'initiation_workshops',
  ORIENTATION_STAGE = 'orientation_stage',
  TRAIN_AUTONOMY = 'train_autonomy',
}

export enum PRE_PROFESSIONAL_ACTIVITY {
  INITIATION_WORKSHOPS = 'initiation_workshops',
  RP_STAGE = 'rp_stage',
  ESAT = 'esat',
}

export enum EQUIPMENT_ON_SITE {
  ADJUSTMENT_AND_ORTHOTICS = 'adjustment _and_orthotics',
  EMERGENCY_TROLLEY = 'emergency_trolley',
  VACUUM_PLUGS = 'vacuum_plugs',
  THERAPEUTIC_POOL = 'therapeutic_pool',
  SPORT_EQUIPMENT = 'sport_equipment',
  SNOEZELEN = 'snoezelen',
  RESUSCITATION_TROLLEY = 'resuscitation_trolley',
  ADAPTED_VEHICLES = 'adapted_vehicles',
  ADAPTED_KITCHEN = 'adapted_kitchen',
  OUTDOOR_AND_GREEN_SPACES = 'outdoor_and_green_spaces',
  BRM = 'brm',
  TEMPORO_SPATIAL_DISORIENTATION = 'temporo_spatial_disorientation',
  ROOM_VISUALLY_IMPAIRED = 'room_visually_impaired',
  FURNITURE_FOR_OBESITY = 'furniture _for_obesity',
  OXYGENE = 'oxygene',
  POOL = 'pool',
  REEDUCATION_PLATFORM = 'reeducation_platform',
  SENSORY_STIMULATION_ROOM = 'sensory_stimulation_room',
}

export enum CARE_STAFF {
  CAREGIVER = 'caregiver',
  AVEJISTE = 'avejiste',
  OCCUPATIONAL_THERAPIST = 'occupational_therapist',
  COORDINATING_NURSE = 'coordinating_nurse',
  DOCTOR = 'doctor',
  LOCOMOTION_INSTRUCTOR = 'locomotion_instructor',
  PHYSIOTHERAPIST = 'physiotherapist',
  REHABILITATION_DOCTOR = 'rehabilitation_doctor',
  PSYCHIATRIST = 'psychiatrist',
  NEUROPSYCHIATRIST = 'neuropsychiatrist',
  NEUROLOGIST = 'neurologist',
  NEUROPSYCHOLOGIST = 'neuropsychologist',
  SPEECH_THERAPIST = 'speech_therapist',
  ORTHO_PROSTHETIST = 'ortho-prosthetist',
  ORTHOPTIST = 'orthoptist',
  PEDIATRICIAN = 'pediatrician',
  PEDICURE_PODIATRIST = 'pedicure_podiatrist',
  PEDIATRIC_PSYCHIATRIST = 'pediatric_psychiatrist',
  PODO_ORTHOTIST = 'podo_orthotist',
  PSYCHOLOGIST = 'psychologist',
  NURSERY_NURSE = 'nursery_nurse',
  INDIVIDUALIZED_DIETETIC = 'individualized_dietetic',
}

export enum EDUCATIF_SOCIAL_STAFF {
  PSYCHOLOGICAL_HELP = 'psychological_help',
  SCHOOL_ASSISTANCE = 'school_assistance',
  ARTS_THERAPISTS = 'arts_therapists',
  SOCIOCULTURAL_ANIMATOR = 'sociocultural_animator',
  SOCIAL_WORKER = 'social_worker',
  LIFE_ASSISTANT = 'life_assistant',
  TEACHER = 'teacher',
  SOCIAL_AND_FAMILY_ECONOMY_ADVISOR = 'social_and_family_economy_advisor',
  CHILDHOOD_EDUCATOR = 'childhood_educator',
  SPORTS_TRAINER = 'sports_trainer',
  SPECIAL_EDUCATOR = 'special_educator',
  EDUCATOR_MONITOR = 'educator_monitor',
  WORKSHOP_INSTRUCTOR = 'workshop_instructor',
}

export enum CARE_COVERAGE {
  RESPIRATORY_ASSISTANCE_AND_TRACHEOSTOMY = 'respiratory_assistance_and_tracheostomy',
  GLASTROSTOMY = 'glastrostomy',
  MULTI_DAILY_INJECTIONS = 'multi-daily_injections',
  OXYGEN_THERAPY = 'oxygen_therapy',
  CAREGIVER_AT_NIGHT = 'caregiver_at_night',
  NURSING_DURING_THE_DAY = 'nursing_during_the_day',
  SPASTICITY = 'spasticity',
  TRACHEOSTOMY_CARE = 'tracheostomy_care',
  DIABETES = 'diabetes',
  CONTINUOUS_CARE = 'continuous_care',
  BLADDER_CATHETERIZATION = 'bladder_catheterization',
  ORAL_CARE = 'oral_care',
}

export enum SPECIALIZED_CARE {
  EQUIPMENT_ADAPTATION = 'equipment_adaptation',
  ADDICTIVE_BEHAVIORS = 'addictive_behaviors',
  MOTOR_IMPAIRMENT = 'motor_impairment',
  INTELLECTUAL_DISABILITY = 'intellectual_disability',
  HEARING_IMPAIRMENT = 'hearing_impairment',
  VISUAL_IMPAIRMENT = 'visual_impairment',
  DEGENERATIVE_DEMENTIA = 'degenerative_dementia',
  PSYCHOLOGICAL_DIFFICULTY_WITH_BEHAVIORAL_DISORDERS = 'psychological_difficulty_with_behavioral_disorders',
  COGNITIVE_DISABILITY = 'cognitive_disability',
  PSYCHIC_HANDICAP = 'psychic_handicap',
  RARE_HANDICAP = 'rare_handicap',
  POLYHANDICAP = 'polyhandicap',
  PREDOMINANTLY_COGNITIVE_DISABILITY = 'predominantly_cognitive_disability',
  FRENCH_SIGN_LANGUAGE = 'french_sign_language',
  SPOKEN_LANGUAGE_COMPLETED = 'spoken_language_completed',
  BRAILLE = 'braille',
  ALZHEIMER = 'alzheimer',
  EMERGING_SCHIZOPHRENIA = 'emerging_schizophrenia',
  DEAF_BLINDNESS = 'deaf_blindness',
  MOOD_DISORDERS = 'mood_disorders',
  EATING_DISORDERS = 'eating_disorders',
  AUTISM = 'autism',
  HYPERACTIVITY = 'hyperactivity',
  CHRONIC_NEURO_COGNITIVE_DISORDERS = 'chronic_neuro_cognitive_disorders',
  OBSESSIVE_COMPULSIVE_DISORDER = 'obsessive_compulsive_disorder',
  PSYCHOTIC_DISORDERS = 'psychotic_disorders',
  PSYCHIC_SEXUAL_DISORDERS = 'psychic_sexual_disorders',
  BRAIN_DAMAGE = 'brain_damage',
  LANGUAGE_AND_LEARNING_DISORDERS = 'language_and_learning_disorders',
}

export enum EQUIPMENT_SERVICE {
  COMPANION_ANIMALS = 'companion_animals',
  INTERNET = 'internet',
  NIGHT_WATCHMAN = 'night_watchman',
  PUBLIC_TRANSPORT = 'public_transport',
  HOME_SUPPORT = 'home_support',
  UNIVERSAL_ACCESSIBILITY = 'universal_accessibility',
  SINGLE_ROOMS = 'single_rooms',
  RESTORATION = 'restoration',
  EXIT_CONTROL = 'exit_control',
  HOME_ESTABLISHMENT_TRANSPORT = 'home_establishment_transport',
  HAIRDRESSING = 'hairdressing',
  SOCIO_ESTHETICIAN = 'socio-esthetician',
  INDIVIDUAL_TELEVISION = 'individual_television',
}

export type InstitutionRegistration = {
  user: {
    email: string
    password: string
    firstName: string
    lastName: string
  }
  info: InstitutionInfo
  contact: InstitutionContact
  webflowItemId?: string
}

export type InstitutionInfo = {
  id: string
  socialReason: string
  siretNumber: string
  institutionType: string
  institutionName: string
  country: string
  street: string
  region: string
  city: string
  zipcode: string
  website: string
  phoneNumber: string
  pictures: string[] | null[]
  educationalProject: string
  accommodationCapacity: number | null
  capacityAvailable: number | null
  accommodationPublic: ACCOMMODATION_PUBLIC[] | string[]
  accommodationMode: ACCOMMODATION_MODE[] | string[]
  supportActivity: SUPPORT_ACTIVITY[] | string[]
  professionalActivity: PROFESSIONAL_ACTIVITY[] | string[]
  preProfessionalActivity: PRE_PROFESSIONAL_ACTIVITY[] | string[]
  equipmentOnSite: EQUIPMENT_ON_SITE[] | string[]
  careStaff: CARE_STAFF[] | string[]
  educatifSocialStaff: EDUCATIF_SOCIAL_STAFF[] | string[]
  careCoverage: CARE_COVERAGE[] | string[]
  specializedCare: SPECIALIZED_CARE[] | string[]
  equipmentService: EQUIPMENT_SERVICE[] | string[]
  picturesDestroy?: []
  accommodationHandicap: string[]
  habitatType: 'medical' | 'inclusive'
  managerName?: string
  projectHolder?: string
  structurePlace?: INCLUSIVE_ESTABLISHMENT_TYPES
}

export type InstitutionContact = {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

export type InstitutionInfoFromWebflow = {
  contactEmail: string
  contactPhoneNumber: string
  id: number | string
  infoAccommodationCapacity?: number
  infoAccommodationMode?: []
  infoCity: string
  infoCountry: string
  infoInstitutionName: string
  infoInstitutionType: ESTABLISHMENT_TYPES[]
  infoLat: number | string
  infoLong: number | string
  infoPhoneNumber: string
  infoRegion: string
  infoSiretNumber: string
  infoSocialReason: string
  infoStreet: string
  infoWebsite: string
  infoZipcode: string
  webflowCollectionId: string
  webflowItemId: string
  managerName?: string
}

export type AddressFromAPI = string
