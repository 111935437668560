import React, { useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Link from 'ui/typography/link'
import Box from 'ui/box'

const Label = styled.label<{ state: 'empty' | 'fill'; hasError?: boolean; disabled?: boolean }>`
  position: relative;
  font-family: Nunito;
  font-size: 16px;
  line-height: 26px;
  color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};
  border: ${props =>
    props.state === 'empty'
      ? `1px dashed ${props.hasError ? props.theme.pink : props.theme.greyLightN2}`
      : `1px solid ${props.hasError ? props.theme.pink : props.theme.black}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  border-radius: 2px;
  box-sizing: border-box;
  min-height: 120px;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  input {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

const FileContainer = styled(Box).attrs({ width: '100%', ai: 'c', jc: 'sb' })`
  ${Text} {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 220px;
  }
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
`

export default function File({
  id,
  name,
  forwardRef,
  uploadedFile,
  getValue,
  resetFile,
  hasError,
  ...inputProps
}: {
  id: string
  name: string
  uploadedFile?: string
  forwardRef?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined
    | void
  getValue?: () => FileList
  resetFile?: () => void
  hasError?: boolean
} & Omit<React.ComponentProps<'input'>, 'ref'>) {
  const inputRef = (forwardRef as any) || React.createRef<HTMLInputElement>()
  const [filename, setFilename] = useState<string>(
    get(inputRef, 'current.value', uploadedFile || '')
      .split(/(\\|\/)/g)
      .pop(),
  )

  return (
    <Label state={filename ? 'fill' : 'empty'} hasError={hasError} disabled={inputProps.disabled}>
      <Input
        id={id}
        name={name}
        type="file"
        ref={inputRef}
        style={{ zIndex: filename ? -1 : 1 }}
        onChange={e => {
          if (inputProps.onChange) return inputProps.onChange(e)

          const controlledFilename = getValue ? get(getValue(), '[0].name', '') : ''
          setFilename(
            get(inputRef, 'current.value', controlledFilename)
              .split(/(\\|\/)/g)
              .pop(),
          )
        }}
        {...inputProps}
      />
      {!filename ? (
        <>
          <Icon name="upload" color="green" size={18} cp="0 0 11px 0" />
          <Text fs={16} color="greyDark2" as="div">
            Importez un fichier ou
          </Text>
          <Link fs={16} color="green" underline style={{ textAlign: 'center' }}>
            parcourir votre ordinateur
          </Link>
        </>
      ) : (
        <FileContainer>
          <Box ai="c">
            <Icon
              name="transfer"
              size={24}
              color="white"
              bg="green"
              cp="12px"
              br={4}
              containerStyle={{ marginRight: '16px' }}
            />
            <Text fs={16} color="black">
              {filename}
            </Text>
          </Box>
          <Icon
            name="close"
            color="pink"
            size={14}
            onClick={
              !inputProps.disabled
                ? (event: { preventDefault: () => void }) => {
                    event.preventDefault()

                    if (inputProps.disabled) return

                    if (inputRef && inputRef.current) {
                      inputRef.current.value = ''
                      setFilename('')
                    } else if (resetFile) {
                      resetFile()
                      setFilename('')
                    }
                  }
                : undefined
            }
          />
        </FileContainer>
      )}
    </Label>
  )
}
