import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const StyledLabel = styled.label<{ hasError?: boolean }>`
  font-family: Nunito;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};
  margin: 0 8px;
  border: 1px solid ${props => (props.hasError ? props.theme.pink : props.theme.greyLightN2)};
  cursor: pointer;
  transition: border 0.4s;
  box-sizing: border-box;
  width: 51px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledInput = styled.input<{ hasError?: boolean }>`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;

  &:checked {
    + ${StyledLabel} {
      border: 2px solid ${props => (props.hasError ? props.theme.pink : props.theme.black)};
    }
  }
`

export default function Rank({
  id,
  from,
  to,
  radioRef,
  hasError,
  ...radioProps
}: {
  id: string
  from: number
  to: number
  name: string
  hasError?: boolean
  radioRef?: () => any
}) {
  const radios = new Array(to - from + 1).fill(0).map((_v, i) => from + i)

  return (
    <Container>
      {radios.map(rank => (
        <React.Fragment key={`${id}-${rank}`}>
          <StyledInput
            id={`${id}-${rank}`}
            type="radio"
            value={rank}
            ref={radioRef ? radioRef() : undefined}
            hasError={hasError}
            {...radioProps}
          />
          <StyledLabel hasError={hasError} htmlFor={`${id}-${rank}`}>
            {rank}
          </StyledLabel>
        </React.Fragment>
      ))}
    </Container>
  )
}
