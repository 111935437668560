import React from 'react'

import StepLayout from './stepLayout'
import Card from 'ui/card/button'

import { USER_TYPES, UserType } from 'types/enums'

const StepUserType: React.FC<{
  onNext: (data: UserType) => void
}> = ({ onNext }) => (
  <StepLayout
    title="Nous voulons en savoir plus sur vous"
    subText="Inscrivez-vous et accédez à votre espace personnel."
  >
    <Card
      title="Responsable légal"
      text="Vous êtes représentant légal d’un ou de plusieurs usagers"
      icon="health"
      onClick={() => onNext(USER_TYPES.TUTOR)}
      style={{ marginBottom: '24px', maxWidth: '80%' }}
    />
    <Card
      title="Usager"
      text="Vous êtes l’usager"
      icon="person"
      onClick={() => onNext(USER_TYPES.OWNER)}
      style={{ maxWidth: '80%' }}
    />
  </StepLayout>
)

export default StepUserType
