import React, { useContext } from 'react'
import { get, camelCase } from 'lodash'
import { useHistory } from 'react-router-dom'

import Box from 'ui/box'
import Button from 'ui/button'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Loader from 'ui/loader'
import Icon from 'ui/icon'

import { list, alert } from 'assets/illustrations'
import { AdmissionRecordProgress } from 'types/admissionRecord'
import useResource from 'hooks/useResource'
import { AuthContext } from 'App'
import { getAdmissionFormProgress } from 'api/admissionRecord'

export default function Intro({ path }: { path: string }) {
  const history = useHistory()
  const { auth } = useContext(AuthContext)
  const [{ resource, loading }] = useResource<AdmissionRecordProgress, {}>(
    undefined,
    () => getAdmissionFormProgress(get(auth, 'selectedId', '0')),
    [],
  )

  const isDisabled = get(resource, `${camelCase(path)}.statusProgress`) === 'disabled'

  return loading ? (
    <Box ai="c" jc="c">
      <Loader />
    </Box>
  ) : (
    <Box fd="c" ai="c" bg={'greenLight2'} padding="32px">
      <Box jc="c" style={{ marginBottom: '32px' }}>
        <img src={isDisabled ? alert : list} alt="illustration" />
      </Box>
      {isDisabled ? (
        <>
          <Box fd="c">
            <Title bold spacingBottom="32px">
              Étape non disponible
            </Title>
            <Text>
              Vous devez d'abord remplir intégralement les 3 premières étapes pour accéder à la
              suite.
            </Text>
          </Box>
          <Button
            variant="outline"
            onClick={() => history.push('/administrative/etape/1')}
            style={{ marginTop: '20px', width: '100%' }}
          >
            <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
            Revenir au formulaire
          </Button>
        </>
      ) : (
        <>
          <Box fd="c">
            <Title bold spacingBottom="32px">
              Bravo, votre dossier va être validé par le support !
            </Title>
            <Box padding="0 0 24px 0" f="0 1 auto">
              <Box
                ai="c"
                jc="c"
                bg="white"
                br={50}
                width="42px"
                height="42px"
                withBorder
                style={{ marginRight: '16px' }}
              >
                <Text>1</Text>
              </Box>
              <Box fd="c">
                <Text bold>
                  Vous avez complété les trois premières étapes, mais vous pouvez quand même
                  modifier vos réponses.
                </Text>
                <Text>Souhaitez-vous relire les réponses que vous avez renseignées ?</Text>
              </Box>
            </Box>
            <Box padding="0 0 24px 0" f="0 1 auto">
              <Box
                ai="c"
                jc="c"
                bg="white"
                br={50}
                width="42px"
                height="42px"
                withBorder
                style={{ marginRight: '16px' }}
              >
                <Text>2</Text>
              </Box>
              <Box fd="c">
                <Text bold>
                  Vous pouvez déjà voir les documents à préparer pour le suite du formulaire.
                </Text>
                <Text>Consultez la liste.</Text>
              </Box>
            </Box>
          </Box>
          <Box ai="c" fd="r" jc="c">
            <Button
              variant="outline"
              onClick={() => history.push(`/${path}/etape/2`)}
              style={{ marginRight: '24px' }}
            >
              Relire les réponses
            </Button>
            <Button variant="primary" onClick={() => history.push(`/${path}/etape/3`)}>
              Voir les documents à préparer
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}
