export type ProgressState =
  | 'pending'
  | 'todo'
  | 'validating'
  | 'support_validating'
  | 'completed'
  | 'disabled'
  | 'wait_change'
export type ProgressVariant =
  | 'todo'
  | 'pending'
  | 'completed'
  | 'validating'
  | 'supportValidating'
  | 'disabled'
  | 'waitChange'

export type UserType = 'tutor' | 'owner'
export enum USER_TYPES {
  OWNER = 'owner',
  TUTOR = 'tutor',
}

export type EstablishmentType = 'mas' | 'fam' | 'fo_fv'
export enum ESTABLISHMENT_TYPES {
  MAS = 'mas',
  FAM = 'fam',
  FO_FV = 'fo_fv',
}

export type InclusiveEstablishmentStatus = 'actif' | 'soon' | 'in_future'
export enum INCLUSIVE_ESTABLISHMENT_STATUS {
  ACTIVE = 'actif',
  SOON = 'soon',
  INPROJECT = 'in_future',
}

export type InclusiveStructureType = 'associative' | 'public_structure' | 'private_structure'
export enum INCLUSIVE_STRUCTURE_TYPE {
  ASSOCIATIVE = 'associative',
  PUBLIC = 'public_structure',
  PRIVATE = 'private_structure',
}

export type InclusiveEstablishmentType = 'inclusive' | 'family' | 'sharing'
export enum INCLUSIVE_ESTABLISHMENT_TYPES {
  INC = 'inclusive',
  FAM = 'family',
  SHA = 'sharing',
}

export type UrgencyLevel = 'high' | 'medium' | 'low'
export enum URGENCY_LEVELS {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export type ActualSituation =
  | 'single'
  | 'married'
  | 'in_cohabitation'
  | 'PACS'
  | 'widower'
  | 'divorced'
  | 'separate'
  | 'other'
export enum ACTUAL_SITUATION {
  SINGLE = 'single',
  MARRIED = 'married',
  IN_COHABITATION = 'in_cohabitation',
  PACS = 'pacs',
  WIDOWER = 'widower',
  DIVORCED = 'divorced',
  SEPARATE = 'separate',
  OTHER = 'other',
}

export type Gender = 'male' | 'female'
export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}

export type HabitationSituation = 'alone_at_home' | 'help_at_home' | 'residency' | 'establishment'
export enum HABITATION_SITUATION {
  EHPAD = 'ehpad',
  ESMS = 'esms',
  HOSPITAL = 'hospital',
  ALONE_AT_HOME = 'alone_at_home',
  FAMILY_HOME = 'family_home',
  HELP_AT_HOME = 'help_at_home',
  RESIDENCY = 'residency',
  ESTABLISHMENT = 'establishment',
}

export type TutorStatus = 'kin_tutor' | 'pro_tutor'
export enum TUTOR_STATUS {
  KIN_TUTOR = 'kin_tutor',
  PRO_TUTOR = 'pro_tutor',
  KIN_CUR = 'kin_cur',
  PRO_CUR = 'pro_cur',
  KIN_HAB = 'kin_hab',
}

export type ProfesionnalStatus = 'employee' | 'freelance' | 'unemployed'
export enum PROFESIONNAL_STATUS {
  EMPLOYEE = 'employee',
  FREELANCE = 'freelance',
  UNEMPLOYED = 'unemployed',
}
