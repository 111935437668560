import React from 'react'
import { useHistory } from 'react-router-dom'

import Intro from './intro'
import SummaryForm from './summary'
import List from './list'

import Button from 'ui/button'
import Icon from 'ui/icon'
import { GenericForm } from 'components/pages/form'

export default function Validate() {
  const history = useHistory()
  const path = 'support-validation'

  const customControl = (
    <Button variant="outline" onClick={() => history.push(`/${path}/etape/1`)}>
      <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
      Précédent
    </Button>
  )

  return (
    <GenericForm<{}>
      title="Validation du support"
      path={path}
      resourceLoader={() => Promise.resolve({})}
      globalStep={4}
      steps={() => [
        {
          title: 'Souhaitez-vous relire vos réponses ?',
          Component: () => <Intro path={path} />,
        },
        {
          title: 'Récapitulatif de ce que vous avez complété',
          Component: () => <SummaryForm />,
          customControls: customControl,
        },
        {
          title: 'Liste des documents à préparer pour la suite du formualaire',
          Component: () => <List />,
          customControls: customControl,
        },
      ]}
    />
  )
}
