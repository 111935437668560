import { AxiosResponse } from 'axios'

import request from './request'
import { Candidacies, Candidacy } from 'types/candidacy'
import { RejectCandidacyPayload } from 'components/modals/candidacy/reject'
import { RequestAdditionalInformationPayload } from 'components/modals/candidacy/requestAdditionalInformation'

export function getCandidacies(state?: string, institutionId?: string) {
  return request
    .get<any, AxiosResponse<Candidacies>>(`/institution/${institutionId}/candidacies`, {
      params: { state: state },
    })
    .then(res => res.data)
}

export function getCandidacy(id: string) {
  return request
    .get<any, AxiosResponse<Candidacy>>(`/admission_record_institutions/${id}`)
    .then(res => res.data)
}

export function rejectCandidacy(id: string, payload: RejectCandidacyPayload) {
  return request.post<any, AxiosResponse<any>>(
    `/admission_record_institutions/${id}/reject_candidacy`,
    payload,
  )
}

export function requestAdditionalInformationCandidacy(
  id: string,
  payload: RequestAdditionalInformationPayload,
) {
  return request.post<any, AxiosResponse<any>>(
    `/admission_record_institutions/${id}/request_additional_information`,
    payload,
  )
}

export function acceptCandidacy(id: string, kitId: string) {
  return request.post<any, AxiosResponse<any>>(
    `/admission_record_institutions/${id}/accept_candidacy`,
    {
      admissionRecordInstitution: {
        institutionKitListId: kitId,
      },
    },
  )
}
