import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { find, get, map } from 'lodash'
import { fr } from 'date-fns/locale'
import { format } from 'date-fns'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Loader from 'ui/loader'
import Separator from 'ui/separator'
import { Controls } from 'components/pages/form'

import { getAdmission, ownerEngageRefusal } from 'api/admission'
import { InstitutionCandidacy } from 'types/candidacy'

import { useToasts } from 'hooks/useToaster'
import { AuthContext } from 'App'
import useResource from 'hooks/useResource'
import styled from 'styled-components'
import OwnerAcceptedEngagement from 'components/modals/admission/ownerAcceptedEngagement'

const CustomText = styled(Text)`
  span {
    color: ${props => props.theme.black};
    font-weight: bold;
  }
`

export default function Step1() {
  const { auth } = useContext(AuthContext)
  const { addToast, addGenericErrorToast } = useToasts()
  const history = useHistory()
  const [resourceState, dispatch] = useResource<InstitutionCandidacy[], {}>(
    undefined,
    () =>
      getAdmission(get(auth, 'selectedId', '0'), { current: true }).then(
        ({ candidacies }) => candidacies || [],
      ),
    [],
  )
  const { section, step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)
  const [modalData, setModalData] = useState<
    { candidacyId: string; institutionName: string; engageDate: Date } | undefined
  >()

  function mapResource(listOfInstitutionCandidacy?: InstitutionCandidacy[]) {
    return map(listOfInstitutionCandidacy, ic => (
      <Box key={`ic-${ic.id}`} fd="c" withBorder br={4} style={{ marginBottom: '16px' }}>
        <Box padding="24px" jc="sb" ai="c">
          {!ic.engageDateProposition && (
            <Icon
              color="orange"
              name="loading"
              size={24}
              isSpinner
              containerStyle={{ marginRight: '24px' }}
            />
          )}
          <Box fd="c">
            <Text fs={18} color="black" bold>
              {ic.name}
            </Text>
            <Text fs={16} color="black">
              {ic.address}
            </Text>
          </Box>
        </Box>
        {ic.candidacyStatus === 'wait_financial' ? (
          <>
            <Separator />
            <Box padding="24px">
              <Box ai="c">
                <Icon
                  name="check"
                  color="white"
                  bg="green"
                  cp="4px"
                  br={50}
                  size={12}
                  containerStyle={{ marginRight: '16px' }}
                />
                <Text fs={14} color="green">
                  {`DATE D’ENTRÉE PROPOSÉE : ${format(
                    new Date(ic.engageDateProposition as string),
                    'PPP',
                    {
                      locale: fr,
                    },
                  )}`}
                </Text>
              </Box>
              <Box jc="fe" ai="c">
                <Button variant="success" size="small">
                  Date acceptée
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          !!ic.engageDateProposition && (
            <>
              <Separator />
              <Box padding="24px">
                <Box fd="c">
                  <CustomText fs={14} color="green">
                    DATE D’ENTRÉE PROPOSÉE :
                    <span>
                      &nbsp;{format(new Date(ic.engageDateProposition), 'PPP', { locale: fr })}
                    </span>
                  </CustomText>
                  <Text fs={16} color="black" opacity={0.7} italic>
                    Si vous acceptez cet établissement vous abandonnez votre candidature pour les
                    autres établissements.
                  </Text>
                </Box>
                <Box jc="fe" ai="c">
                  <Button
                    variant="outline"
                    size="small"
                    style={{ marginRight: '8px' }}
                    onClick={() =>
                      ownerEngageRefusal(ic.candidacyId)
                        .then(() => dispatch({ name: 'REFRESH_DATA' }))
                        .then(() =>
                          addToast({
                            title: 'Succès !',
                            type: 'success',
                            message: 'Etablissement refusé.',
                          }),
                        )
                        .catch(() => addGenericErrorToast())
                    }
                  >
                    Refuser
                  </Button>
                  <Button
                    variant="success"
                    size="small"
                    onClick={() =>
                      setModalData({
                        candidacyId: ic.candidacyId,
                        institutionName: ic.name,
                        engageDate: new Date(ic.engageDateProposition as string),
                      })
                    }
                  >
                    Accepter
                  </Button>
                </Box>
              </Box>
            </>
          )
        )}
      </Box>
    ))
  }

  return (
    <Box
      fd="c"
      style={{
        minHeight: '100%',
      }}
    >
      <Box
        fd="c"
        padding="32px"
        style={{
          overflowY: 'scroll',
        }}
      >
        <Box fd="c" f="initial">
          <Title fs={22} bold spacingBottom="16px">
            Liste des établissements :
          </Title>
          <Text fs={16} spacingBottom="32px">
            Voici la liste des établissements qui doivent donner leur accord de prise en charge. Il
            peut s’écouler 1 semaine pour que l’établissement donne son accord de prise en charge.
          </Text>
        </Box>
        <Box fd="c" f="initial">
          {resourceState.loading ? (
            <Box ai="c" jc="c">
              <Loader />
            </Box>
          ) : (
            mapResource(resourceState.resource)
          )}
        </Box>
      </Box>
      <Controls
        prevButton={{
          callback: () => history.push(`/admission/${section}/etape/${parsedStep - 1}`),
          label: 'Retour',
        }}
        nextButton={{
          callback: () => history.push(`/admission/${section}/etape/${parsedStep + 1}`),
          label: 'Continuer',
          disabled: !find(
            resourceState.resource,
            item => item.candidacyStatus === 'wait_financial',
          ),
        }}
      />
      <OwnerAcceptedEngagement
        data={modalData || { candidacyId: '', institutionName: '', engageDate: new Date() }}
        onClose={() => setModalData(undefined)}
        onSuccess={() => {
          setModalData(undefined)
          dispatch({ name: 'REFRESH_DATA' })
        }}
        opened={!!modalData}
      />
    </Box>
  )
}
