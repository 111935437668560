import styled from 'styled-components'

import { Colors } from '../../theme'

export default styled.h1<{
  align?: 'center' | 'justify' | 'left' | 'right'
  color?: Colors
  fs?: 16 | 18 | 20 | 22 | 24 | 28 | 32 | 34 | 54
  spacingBottom?: string
  fw?: string
  bold?: boolean
}>`
  color: ${props => (props.color ? props.theme[props.color] : props.theme.black)};
  font-family: Nunito;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: ${props => props.fs || 24}px;
  line-height: ${props => (props.fs ? props.fs + 10 : 34)}px;
  margin: 0;
  margin-bottom: ${props => props.spacingBottom || 0};
  text-align: ${props => props.align || 'left'};
`
