import React, { useState, useContext } from 'react'
import { times, get, reject } from 'lodash'
import { ThemeContext } from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { transparentize } from 'polished'

import Box from 'ui/box'
import Button from 'ui/button'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Separator from 'ui/separator'

import { FormBody } from 'components/pages/form'

import Administrative from 'types/administrative'
import { PROFESIONNAL_STATUS, GENDER } from 'types/enums'
import { Form } from 'types/form'
import { countries } from 'constants/country'
import { deleteFamilyMember } from 'api/form'

export const PARENT_FORM: Form = [
  [
    {
      name: 'firstName',
      type: 'text',
      label: 'Prénom',
      placeholder: 'Saisissez le prénom',
      required: true,
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Nom',
      placeholder: 'Saisissez le nom',
      required: true,
    },
  ],
  [
    {
      name: 'dateOfBirth',
      type: 'date',
      label: 'Date de naissance',
      placeholder: 'JJ/MM/AAAA',
      required: true,
    },
    {
      name: 'placeOfBirth',
      type: 'text',
      label: 'Lieu de naissance',
      placeholder: 'Saisissez le lieu de naissance',
      required: true,
    },
  ],
  [
    {
      name: 'street',
      type: 'text',
      label: 'Adresse',
      placeholder: 'Saisissez l’adresse',
      required: true,
    },
    {
      name: 'streetComplement',
      type: 'text',
      label: "Complément d'adresse",
      placeholder: "Saisissez le complément d'adresse",
      required: false,
    },
  ],
  [
    {
      name: 'zipcode',
      type: 'text',
      label: 'Code postal',
      placeholder: 'Saisissez le code postal',
      required: true,
    },
    {
      name: 'city',
      type: 'text',
      label: 'Ville',
      placeholder: 'Saisissez la ville ic',
      required: true,
    },
  ],
  [
    {
      name: 'country',
      type: 'select',
      label: 'Pays',
      placeholder: 'Choisissez le pays',
      options: countries,
      required: true,
    },
    null,
  ],
  [
    {
      name: 'phoneNumber',
      type: 'phone',
      label: 'Téléphone',
      placeholder: 'Saisissez le numéro de téléphone',
      required: true,
    },
    {
      name: 'email',
      type: 'text',
      label: 'E-mail',
      placeholder: "Saisissez l'e-mail",
      required: true,
    },
  ],
  [
    {
      name: 'profesionnalStatus',
      type: 'select',
      label: 'Situation professionnelle',
      placeholder: 'Choisissez la situation professionnelle',
      required: true,
      options: [
        { value: PROFESIONNAL_STATUS.EMPLOYEE, label: 'Salarié' },
        { value: PROFESIONNAL_STATUS.FREELANCE, label: 'Indépendant' },
        { value: PROFESIONNAL_STATUS.UNEMPLOYED, label: 'Sans emploi' },
      ],
    },
    null,
  ],
]

export const PERSON_FORM: Form = [
  [
    {
      name: 'firstName',
      type: 'text',
      label: 'Prénom',
      placeholder: 'Saisissez le prénom',
      required: true,
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Nom',
      placeholder: 'Saisissez le nom',
      required: true,
    },
  ],
  [
    {
      name: 'gender',
      type: 'select',
      label: 'Sexe',
      placeholder: 'Homme, Femme',
      required: true,
      options: [
        { value: GENDER.MALE, label: 'Homme' },
        { value: GENDER.FEMALE, label: 'Femme' },
      ],
    },
    {
      name: 'dateOfBirth',
      type: 'date',
      label: 'Date de naissance',
      placeholder: 'JJ/MM/AAAA',
      required: true,
    },
  ],
]

const BoxCollapse: React.FC<{ label: string; required?: boolean; hasError?: boolean }> = ({
  label,
  required,
  hasError,
  children,
}) => {
  const theme = useContext(ThemeContext)
  const [collapse, setCollapse] = useState(false)

  return (
    <Box
      f="0 0 auto"
      fd="c"
      padding="24px"
      br={4}
      withBorder
      style={{
        marginBottom: '24px',
        borderColor: hasError ? theme.pink : transparentize(0.7, theme.black),
      }}
    >
      <Box jc="sb" ai="c">
        <Label fs={18} bold required={required}>
          {label}
        </Label>
        <Button variant="outline" onClick={() => setCollapse(!collapse)} size="small">
          {collapse ? 'Masquez' : 'Complétez'}
        </Button>
      </Box>
      <Box fd="c" padding="32px 0 0 0" style={collapse ? {} : { display: 'none' }}>
        {children}
      </Box>
    </Box>
  )
}

type State = {
  showFatherForm: boolean
  showMotherForm: boolean
  nberOfChildren: number
  nberOfSiblings: number
}

export default function FormFamily({
  resource,
  admissionRecordId,
}: {
  resource: Administrative
  admissionRecordId: string
}) {
  const [defaultValues, updateDefaultValues] = useState(resource)
  const { register, errors, getValues } = useFormContext()
  const [state, setState] = useState<State>({
    showFatherForm: !!get(defaultValues, 'family.membersAttributes.father'),
    showMotherForm: !!get(defaultValues, 'family.membersAttributes.mother'),
    nberOfChildren: get(defaultValues, 'family.membersAttributes.child.length', 0),
    nberOfSiblings: get(defaultValues, 'family.membersAttributes.sibling.length', 0),
  })

  return (
    <Box fd="c">
      <Input
        id="id"
        name="family.id"
        type="hidden"
        ref={register()}
        defaultValue={get(defaultValues, 'family.id')}
      />
      <BoxCollapse label="Père" hasError={get(errors, 'family.membersAttributes.father[0]')}>
        {state.showFatherForm ? (
          <>
            <Input
              id="kinship"
              name="family.membersAttributes.father[0].kinship"
              type="hidden"
              ref={register()}
              defaultValue="father"
            />
            <FormBody<Administrative>
              fields={PARENT_FORM}
              subForm="family.membersAttributes.father[0]"
              resource={defaultValues}
            />
            <Box padding="15px 0 0 0">
              <Button
                variant="danger"
                onClick={() => {
                  const familyMemberId = getValues(`family.membersAttributes.father[0].id`)
                  familyMemberId
                    ? deleteFamilyMember(admissionRecordId, familyMemberId).then(() => {
                        updateDefaultValues({
                          ...defaultValues,
                          family: {
                            ...defaultValues.family,
                            membersAttributes: {
                              ...defaultValues.family.membersAttributes,
                              father: [],
                            },
                          },
                        })
                        setState({ ...state, showFatherForm: false })
                      })
                    : setState({ ...state, showFatherForm: false })
                }}
                size="small"
              >
                Supprimez
              </Button>
            </Box>
          </>
        ) : (
          <Box padding="15px 0 0 0">
            <Button
              variant="outline"
              onClick={() => setState({ ...state, showFatherForm: true })}
              size="small"
            >
              Ajoutez un père
            </Button>
          </Box>
        )}
      </BoxCollapse>
      <BoxCollapse label="Mère" hasError={get(errors, 'family.membersAttributes.mother[0]')}>
        {state.showMotherForm ? (
          <>
            <Input
              id="kinship"
              name="family.membersAttributes.mother[0].kinship"
              type="hidden"
              ref={register()}
              defaultValue="mother"
            />
            <FormBody<Administrative>
              fields={PARENT_FORM}
              subForm="family.membersAttributes.mother[0]"
              resource={defaultValues}
            />
            <Box padding="15px 0 0 0">
              <Button
                variant="danger"
                onClick={() => {
                  const familyMemberId = getValues(`family.membersAttributes.mother[0].id`)
                  familyMemberId
                    ? deleteFamilyMember(admissionRecordId, familyMemberId).then(() => {
                        updateDefaultValues({
                          ...defaultValues,
                          family: {
                            ...defaultValues.family,
                            membersAttributes: {
                              ...defaultValues.family.membersAttributes,
                              mother: [],
                            },
                          },
                        })
                        setState({ ...state, showMotherForm: false })
                      })
                    : setState({ ...state, showMotherForm: false })
                }}
                size="small"
              >
                Supprimez
              </Button>
            </Box>
          </>
        ) : (
          <Box padding="15px 0 0 0">
            <Button
              variant="outline"
              onClick={() => setState({ ...state, showMotherForm: true })}
              size="small"
            >
              Ajoutez une mère
            </Button>
          </Box>
        )}
      </BoxCollapse>
      <BoxCollapse label="Fratrie" hasError={get(errors, 'family.membersAttributes.sibling')}>
        {times(state.nberOfSiblings, n => (
          <Box fd="c" key={`sibling-form-${n}`}>
            {n > 0 && <Separator opacity={0.1} spacingTop={17} spacingBottom={32} />}
            <Input
              id="kinship"
              name={`family.membersAttributes.sibling[${n}].kinship`}
              type="hidden"
              ref={register()}
              defaultValue="sibling"
            />
            <FormBody<Administrative>
              fields={PERSON_FORM}
              subForm={`family.membersAttributes.sibling[${n}]`}
              resource={defaultValues}
            />
          </Box>
        ))}
        <Box padding="15px 0 0 0">
          <Button
            variant="outline"
            onClick={() => setState({ ...state, nberOfSiblings: state.nberOfSiblings + 1 })}
            size="small"
          >
            Ajoutez un membre
          </Button>
          {state.nberOfSiblings > 0 && (
            <Button
              variant="danger"
              onClick={() => {
                const familyMemberId = getValues(
                  `family.membersAttributes.sibling[${state.nberOfSiblings - 1}].id`,
                )
                familyMemberId
                  ? deleteFamilyMember(admissionRecordId, familyMemberId).then(() => {
                      updateDefaultValues({
                        ...defaultValues,
                        family: {
                          ...defaultValues.family,
                          membersAttributes: {
                            ...defaultValues.family.membersAttributes,
                            sibling: reject(defaultValues.family.membersAttributes.sibling, {
                              id: familyMemberId,
                            }),
                          },
                        },
                      })
                      setState({ ...state, nberOfSiblings: state.nberOfSiblings - 1 })
                    })
                  : setState({ ...state, nberOfSiblings: state.nberOfSiblings - 1 })
              }}
              size="small"
              style={{ marginLeft: '8px' }}
            >
              Supprimez
            </Button>
          )}
        </Box>
      </BoxCollapse>
      <BoxCollapse label="Enfant(s)" hasError={get(errors, 'family.membersAttributes.child')}>
        {times(state.nberOfChildren, n => (
          <Box fd="c" key={`child-form-${n}`}>
            {n > 0 && <Separator opacity={0.1} spacingTop={17} spacingBottom={32} />}
            <Input
              id="kinship"
              name={`family.membersAttributes.child[${n}].kinship`}
              type="hidden"
              ref={register()}
              defaultValue="child"
            />
            <FormBody<Administrative>
              fields={PERSON_FORM}
              subForm={`family.membersAttributes.child[${n}]`}
              resource={defaultValues}
            />
          </Box>
        ))}
        <Box padding="15px 0 0 0">
          <Button
            variant="outline"
            onClick={() => setState({ ...state, nberOfChildren: state.nberOfChildren + 1 })}
            size="small"
          >
            Ajoutez un enfant
          </Button>
          {state.nberOfChildren > 0 && (
            <Button
              variant="danger"
              onClick={() => {
                const familyMemberId = getValues(
                  `family.membersAttributes.child[${state.nberOfChildren - 1}].id`,
                )
                familyMemberId
                  ? deleteFamilyMember(admissionRecordId, familyMemberId).then(() => {
                      updateDefaultValues({
                        ...defaultValues,
                        family: {
                          ...defaultValues.family,
                          membersAttributes: {
                            ...defaultValues.family.membersAttributes,
                            child: reject(defaultValues.family.membersAttributes.child, {
                              id: familyMemberId,
                            }),
                          },
                        },
                      })
                      setState({ ...state, nberOfChildren: state.nberOfChildren - 1 })
                    })
                  : setState({ ...state, nberOfChildren: state.nberOfChildren - 1 })
              }}
              size="small"
              style={{ marginLeft: '8px' }}
            >
              Supprimez
            </Button>
          )}
        </Box>
      </BoxCollapse>
    </Box>
  )
}
