import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { IntercomProvider } from 'react-use-intercom'

ReactDOM.render(
  <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID as string}>
    <App />
  </IntercomProvider>,
  document.getElementById('root'),
)
