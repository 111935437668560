import React from 'react'
import { get } from 'lodash'

import { GenericForm, GenericStep } from '../../form'
import Intro from 'components/pages/intro'
import { Auth } from 'types/user'
import TFinancial, { Benefit, PocketMoney } from 'types/financial'
import { getProgress, submit } from 'api/form'
import POCKET_MONEY_FORM from './forms/pocketMoney'
import BENEFIT_FORM from './forms/benefit'
import Conclusion from '../conclusion'

export default function Financial({ refreshProgress }: { refreshProgress: () => void }) {
  return (
    <GenericForm<TFinancial>
      key="financial-form"
      title="Renseignements financiers"
      path="financial"
      resourceLoader={(auth: Partial<Auth>) =>
        getProgress(get(auth, 'selectedId', '0'), 'financial')
      }
      globalStep={5}
      afterSave={refreshProgress}
      steps={(auth: Partial<Auth>) => [
        {
          title: 'Introduction',
          Component: ({ onNext }) => (
            <Intro
              onNext={onNext}
              sections={[
                {
                  title: 'Prestations sociales perçues',
                  text:
                    "Nous souhaitons en savoir plus sur les prestations sociales perçues dont bénéficie actuellement l'usager.",
                },
                {
                  title: 'Argent personnel',
                  text:
                    "Nous souhaitons savoir le montant mensuel personnel dédié à l'usager au delà des prestations sociales dont il bénéficie par ailleurs.",
                },
              ]}
            />
          ),
        },
        {
          title: 'Prestations sociales perçues',
          Component: ({ resource }) => (
            <GenericStep<TFinancial> resource={resource} subForm="benefit" fields={BENEFIT_FORM} />
          ),
          save: (data: { benefit: Benefit }) => {
            return submit<Benefit, { benefit: Benefit }>(
              get(auth, 'selectedId', '0'),
              'financial',
              'benefit',
              data,
            )
          },
        },
        {
          title: 'Argent personnel',
          Component: ({ resource }) => (
            <GenericStep<TFinancial>
              resource={resource}
              subForm="pocketMoney"
              fields={POCKET_MONEY_FORM}
            />
          ),
          save: (data: { pocketMoney: PocketMoney }) => {
            return submit<PocketMoney, { pocketMoney: PocketMoney }>(
              get(auth, 'selectedId', '0'),
              'financial',
              'pocketMoney',
              data,
            )
          },
        },
        {
          title: 'Complet',
          Component: ({ resource }) => (
            <Conclusion
              title="renseignements financiers"
              text="Vous pouvez poursuivre le formulaire d'admission dès maintenant en envoyant les
              documents nécessaires !"
              nextStepCta="Complétez les documents à joindre"
              nextStep="/document/etape/1"
              firstStep="/financial/etape/2"
              isCompleted={['validating', 'completed'].includes(resource.statusProgress)}
            />
          ),
        },
      ]}
    />
  )
}
