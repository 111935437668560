import React from 'react'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Link from 'ui/typography/link'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'

import { success } from 'assets/illustrations'
import { useHistory } from 'react-router-dom'

export default function Conclusion({
  title,
  text,
  nextStep,
  nextStepCta,
  firstStep,
  isCompleted,
}: {
  title: string
  text: string
  nextStep: string
  nextStepCta: string
  firstStep: string
  isCompleted: boolean
}) {
  const history = useHistory()

  return (
    <Box fd="c">
      <Box jc="c" style={{ marginBottom: '32px' }}>
        <img src={success} style={{ width: '300px' }} alt="success" />
      </Box>
      <Box fd="c" ai="c" padding="0 32px 32px 32px">
        <Title bold spacingBottom="32px" align="center">
          {isCompleted
            ? `Félicitations ! La section ${title} est complète !`
            : `Vous avez consulté toute la section : ${title}`}
        </Title>
        <Text fs={16} align="center" style={{ paddingBottom: '24px' }}>
          {isCompleted
            ? text
            : 'Des informations ne sont pas complètes. Vous pouvez reparcourir les étapes et compléter les informations manquantes.'}
        </Text>
        <Button
          variant="primary"
          onClick={() => history.push(nextStep)}
          style={{ marginBottom: '12px' }}
        >
          {nextStepCta}
          <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
        </Button>
        <Link underline onClick={() => history.push(firstStep)}>
          Vérifier mes réponses
        </Link>
      </Box>
    </Box>
  )
}
