import React from 'react'
import styled from 'styled-components'

const Container = styled.div``

const StyledLabel = styled.label<{ hasError?: boolean }>`
  position: relative;
  font-family: Nunito;
  font-size: 16px;
  line-height: 26px;
  color: ${props => (props.hasError ? props.theme.pink : props.theme.greyDark2)};
  cursor: pointer;
  transition: color 0.4s;
  margin-right: 32px;

  &:before {
    content: '';
    margin-right: 8px;
    padding: 12px;
    border: 1px solid ${props => (props.hasError ? props.theme.pink : props.theme.greyDark2)};
    background-color: ${props => props.theme.white};
    border-radius: 20px;
    box-sizing: border-box;
    line-height: 0;
    font-size: 0;
    vertical-align: middle;
    transition: border 0.4s;
  }
`

const StyledInput = styled.input<{ hasError?: boolean }>`
  cursor: pointer;
  opacity: 0;
  margin: 0;
  height: 0;
  width: 0;

  &:checked {
    + ${StyledLabel} {
      color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};

      &:before {
        border: 1px solid ${props => (props.hasError ? props.theme.pink : props.theme.black)};
      }

      &:after {
        position: absolute;
        top: 7px;
        left: 8px;
        content: '';
        height: 10px;
        width: 10px;
        border-radius: 20px;
        background-color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};
      }
    }
  }
`

export default function Radio({
  style,
  containerStyle,
  children,
  forwardRef,
  hasError,
  ...radioProps
}: {
  containerStyle?: React.ComponentProps<'input'>['style']
  hasError?: boolean
  forwardRef?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined
    | void
} & Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'ref'
>) {
  return (
    <Container style={containerStyle}>
      <StyledInput hasError={hasError} ref={forwardRef as any} type="radio" {...radioProps} />
      <StyledLabel hasError={hasError} htmlFor={radioProps.id} style={style}>
        {children}
      </StyledLabel>
    </Container>
  )
}
