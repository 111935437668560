import styled from 'styled-components'

export default styled.textarea<{ hasError?: boolean }>`
  min-height: 52px;
  font-family: Nunito;
  line-height: 26px;
  font-size: 16px;
  border-radius: 2px;

  border: 1px solid ${props => (props.hasError ? props.theme.pink : props.theme.black)};

  background-color: white;
  padding: 16px;

  color: ${props => props.theme.black};

  &::placeholder {
    color: ${props => props.theme.greyDark2};
  }

  &:focus {
    outline: none;
  }
`
