import React from 'react'
import { useHistory } from 'react-router-dom'

import Box from 'ui/box'
import Intro from 'components/pages/intro'

import Layout from '../layout'

import { house } from 'assets/illustrations'
import Step1 from './step1'
import Conclusion from '../conclusion'

export default function InstitutionChoiceStep() {
  const history = useHistory()

  return (
    <Layout
      key="administrative-form"
      title="Choix des lieux de vie"
      globalStep={2}
      steps={() => [
        {
          title: 'Introduction',
          Component: () => (
            <Box padding="32px">
              <Intro
                title="Les établissements ont donné leurs avis"
                image={house}
                onNext={() => history.push('/admission/choix/etape/2')}
                sections={[
                  {
                    title: 'Consultez les établissements qui ont accepté votre dossier',
                    text: 'La liste est enfin disponible !',
                  },
                  {
                    title: 'Acceptez ou refusez',
                    text:
                      'Pour chaque établissement, vous pouvez accepter ou refuser selon vos préférences.',
                  },
                ]}
              />
            </Box>
          ),
        },
        {
          title: 'Acceptez ou refusez les propositions',
          Component: () => <Step1 />,
        },
        {
          title: 'Complet',
          Component: () => (
            <Conclusion
              title="Vous avez choisi les lieux de vie !"
              text="Avant de pouvoir envoyer votre dossier au MDPH, nous vous demandons de terminer votre formulaire d’admission."
              onNext={() => history.push('/admission/mdph/etape/1')}
              buttonTitle="Envoyer votre dossier au MDPH"
            />
          ),
        },
      ]}
    />
  )
}
