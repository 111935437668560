import { ProgressState } from './enums'

// enums...
export enum DRESSING {
  ALONE = 'alone',
  WITH_SOLICITATION = 'with_solicitation',
  WITH_HELP = 'with_help',
  COLLABORATE = 'collaborate',
  UNABLE = 'unable',
}
export enum CONTINENCE {
  COMPLETE = 'complete',
  WITH_SOLICITATION = 'with_solicitation',
  OCCASIONAL = 'occasional',
  REGULAR = 'regular',
  UNCONTROLLABLE = 'uncontrollable',
  ENCOPRESIE = 'encopresie',
}
export enum SIT {
  ALONE = 'alone',
  WITH_TECHNIC = 'with_technic',
  WITH_HELP = 'with_help',
  NEED_HELP = 'need_help',
  UNABLE = 'unable',
}
export enum LIE_DOWN {
  ALONE = 'alone',
  WITH_TECHNIC = 'with_technic',
  WITH_HELP = 'with_help',
  NEED_HELP = 'need_help',
  UNABLE = 'unable',
}
export enum MOBILITY {
  ALONE = 'alone',
  WITH_DIFFICULTY = 'with_difficulty',
  WITH_HELP = 'with_help',
  WITH_WHEELCHAIR = 'with_wheelchair',
  WITH_WHEELCHAIR_AND_HELP = 'with_wheelchair_and_help',
}
export enum EXPRESSION {
  NO_DIFFICULTY = 'no_difficulty',
  FEW_DIFFICULTY = 'few_difficulty',
  WITH_DIFFICULTY = 'with_difficulty',
  WITH_GREAT_DIFFICULTY = 'with_great_difficulty',
  SPECIFIC = 'specific',
  UNABLE = 'unable',
}
export enum UNDERSTANDING {
  NO_DIFFICULTY = 'no_difficulty',
  FEW_DIFFICULTY = 'few_difficulty',
  WITH_DIFFICULTY = 'with_difficulty',
  SPECIFIC = 'specific',
  UNABLE = 'unable',
}
export enum SLEEP {
  NO_DIFFICULTY = 'no_difficulty',
  FEW_DIFFICULTY = 'few_difficulty',
  WITH_DIFFICULTY = 'with_difficulty',
}
export enum MOOD {
  JOVIAL = 'jovial',
  SAD = 'sad',
  INHIBITION = 'inhibition',
  FLUCTUATE = 'fluctuate',
}
export enum TIME_ORIENTATION {
  NO_DIFFICULTY = 'no_difficulty',
  WITH_VISUAL_HELP = 'with_visual_help',
  WITH_RITUAL_HELP = 'with_ritual_help',
  WITH_DIFFICULTY = 'with_difficulty',
  NOT_EVALUABLE = 'not_evaluable',
  UNABLE = 'unable',
}
export enum SPATIAL_ORIENTATION {
  NO_DIFFICULTY = 'no_difficulty',
  WITH_KNOWN_PLACE = 'with_known_place',
  WITH_VISUAL_HELP = 'with_visual_help',
  WITH_DIFFICULTY = 'with_difficulty',
  NOT_EVALUABLE = 'not_evaluable',
  UNABLE = 'unable',
}
export enum MEMORY {
  RECENT = 'recent',
  OLD = 'old',
  UNABLE = 'unable',
  GOOD = 'good',
}
export enum HOBBY {
  ALONE = 'alone',
  WITH_HELP = 'with_help',
  UNABLE = 'unable',
}
export enum SOCIAL_CONTEXT {
  EASY_ADAPTATION = 'easy_adaptation',
  DIFFICULTY_ADAPTATION = 'difficulty_adaptation',
  ONLY_IN_ESTABLISHMENT = 'only_in_establishment',
  UNABLE = 'unable',
}
export enum RELATIONAL_FACILITY {
  ALL_PERSONS = 'all_persons',
  ONLY_KNOWN_PERSON = 'only_known_person',
  WITH_DIFFICULTY = 'with_difficulty',
  UNABLE = 'unable',
}
export enum FOOD {
  ALONE = 'alone',
  WITH_TEHCNIC = 'with_technic',
  WITH_HELP = 'with_help',
  UNABLE = 'unable',
  IMPOSSIBLE = 'impossible',
}
export enum HYGIENE {
  ALONE = 'alone',
  WITH_SOLICITATION = 'with_solicitation',
  WITH_HELP = 'with_help',
  COLLABORATE = 'collaborate',
  UNABLE = 'unable',
}

export enum ADDICTION_KIND_OF {
  NONE = 'none',
  TABACCO = 'tabacco',
  ALCOHOL = 'alcohol',
  DRUGS = 'drugs',
}

export enum CAPACITY {
  ALONE = 'alone',
  WITH_TECHNIC = 'with_technic',
  WITH_HELP = 'with_help',
  UNABLE = 'unable',
  IMPOSSIBLE = 'impossible',
}

export enum MODIFIED_TEXTURES {
  EAT_WHOLE = 'eat_whole',
  EAT_CUT = 'eat_cut',
  EAT_GRINDED = 'eat_grinded',
  EAT_MILL = 'eat_mill',
  EAT_MIXED = 'eat_mixed',
}

export enum ALLERGY {
  YES = 'yes',
  NO = 'no',
  DONT_KNOW = 'dont_know',
}

export enum FOOD_INTOLERANCE {
  YES = 'yes',
  NO = 'no',
  DONT_KNOW = 'dont_know',
}

export enum MEAL_INSTALLATION {
  ALONE = 'alone',
  WITH_TRAY = 'with_tray',
  INDIVIDUAL_SET = 'individual_set',
  WITH_PLASTIC_CUTLERY = 'with_plastic_cutlery',
  OTHER = 'other',
  NONE = 'none',
}

export enum CURRENT_SITUATION {
  FAMILY = 'family',
  HOST_FAMILY = 'host_family',
  ESTABLISHMENT = 'establishment',
  ALTERNATIVE = 'alternative',
}

export enum DIET {
  NO_DIET = 'no_diet',
  DIABETE = 'diabete',
  NO_SALT = 'no_salt',
  NO_RESIDUE = 'no_residue',
  HYPOCALORIES = 'hypocalories',
  HYPERCALORIES = 'hypercalories',
}

export enum DISEASES {
  NONE = 'none',
  ANOREXIA = 'anorexia',
  BULEMIA = 'bulemia',
  POTOMANIA = 'potomania',
  OBJECT_INGESTION = 'object_ingestion',
  OTHER = 'other',
}

export enum RUNAWAY_KIND_OF {
  NONE = 'none',
  INVONLOTARY = 'invonlotary',
  DELIBERATED = 'deliberated',
  SPONTANEOUS_RETURN = 'spontaneous_return',
  DANGER = 'danger',
}

export enum SEXUALITY_BEHAVIOR_LIST {
  NONE = 'none',
  LANGUAGE = 'language',
  MASTURBATE = 'masturbate',
  ATTEMPT_TOUCHING = 'attempt_touching',
  ACCEPT_ANY_SEX = 'accept_any_sex',
  RAPE_OR_TENTATIVE = 'rape_or_tentative',
}

type Educative = {
  statusProgress: ProgressState
  percentProgress: number
  ability: {
    id: string
    food: FOOD
    hygiene: HYGIENE
    dressing: DRESSING
    continence: CONTINENCE
    sit: SIT
    lieDown: LIE_DOWN
    mobility: MOBILITY
    educativeId: string
    createdAt: string
    updatedAt: string
    progress: number
    expression: EXPRESSION
    understanding: UNDERSTANDING
    sleep: SLEEP
    mood: MOOD
    timeOrientation: TIME_ORIENTATION
    spatialOrientation: SPATIAL_ORIENTATION
    memory: MEMORY
    hobby: HOBBY
    socialContext: SOCIAL_CONTEXT
    relationalFacility: RELATIONAL_FACILITY
  }
  addiction: {
    id: string
    educativeId: string
    createdAt: string
    updatedAt: string
    progress: number
    kindOf: ADDICTION_KIND_OF[]
    precision: string
  }
  aggressiveness: {
    id: string
    isAgressive: boolean
    oneself: 1 | 2 | 3 | 4 | 5
    hetero: 1 | 2 | 3 | 4 | 5
    verbal: 1 | 2 | 3 | 4 | 5
    environmental: 1 | 2 | 3 | 4 | 5
    precision: string
    educativeId: string
    createdAt: string
    updatedAt: string
    progress: number
  }
  foodLine: {
    id: string
    tastes: string
    doesNotLike: string
    falseSwallowing: boolean
    educativeId: string
    createdAt: string
    updatedAt: string
    progress: number
    capacity: CAPACITY
    diet: DIET[]
    otherDiet: string
    culturalDuty: string
    allergy: ALLERGY
    allergyPrecision: string
    foodIntolerance: FOOD_INTOLERANCE
    foodIntolerancePrecision: string
    disease: DISEASES[]
    diseasePrecision: string
    falseSwalloingPrecision: string
    modifiedTextures: MODIFIED_TEXTURES
    liquidTextures: boolean
    mealInstallation: MEAL_INSTALLATION
  }
  runaway: {
    id: string
    educativeId: string
    createdAt: string
    updatedAt: string
    progress: number
    kindOf: RUNAWAY_KIND_OF[]
    precision: string
  }
  sexualityBehavior: {
    id: string
    precision: string
    educativeId: string
    createdAt: string
    updatedAt: string
    progress: number
    list: SEXUALITY_BEHAVIOR_LIST[]
  }
  situation: {
    id: string
    establishmentsAttributes: {
      id?: string
      name: string
      entrance: string
      exit?: string
      _destroy?: string
    }[]
    progress: number
  }
}

export default Educative
