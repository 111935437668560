import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useWebflowId = () => {
  const { search } = useLocation()

  return useMemo(() => search.replace('?webflow_item_id=', ''), [search])
}
export const useInstitutionId = () => {
  const { search } = useLocation()

  const param = search.includes('institution_id')
    ? search.replace('?institution_id=', '')
    : undefined

  return useMemo(() => param, [search])
}

export const useWebflowSlug = () => {
  const { search } = useLocation()

  const param = search.includes('webflow_slug') ? search.replace('?webflow_slug=', '') : undefined

  return useMemo(() => param, [search])
}
