import React from 'react'
import styled from 'styled-components'

import * as icons from '../../assets/icons'
import { Colors } from '../theme'

export const IconNames = {
  account: 'account',
  add: 'add',
  alertTriangle: 'alertTriangle',
  animation: 'animation',
  arrow: 'arrow',
  bell: 'bell',
  book: 'book',
  brick: 'brick',
  building: 'building',
  call: 'call',
  caret: 'caret',
  caretFill: 'caretFill',
  check: 'check',
  checked: 'checked',
  checkGreen: 'checkGreen',
  chat: 'chat',
  close: 'close',
  closeRed: 'closeRed',
  copy: 'copy',
  couple: 'couple',
  dashboard: 'dashboard',
  delete: 'delete',
  embedLink: 'embedLink',
  envelope: 'envelope',
  eu: 'eu',
  eye: 'eye',
  file: 'file',
  france: 'france',
  folder: 'folder',
  goal: 'goal',
  guard: 'guard',
  glass: 'glass',
  health: 'health',
  help: 'help',
  hospital: 'hospital',
  hourglass: 'hourglass',
  info: 'info',
  institution: 'institution',
  inprogress: 'inprogress',
  link: 'link',
  loading: 'loading',
  location: 'location',
  lock: 'lock',
  magicwand: 'magicwand',
  message: 'message',
  micro: 'micro',
  money: 'money',
  noData: 'noData',
  off: 'off',
  paper: 'paper',
  parents: 'parents',
  pencil: 'pencil',
  person: 'person',
  phone: 'phone',
  pin: 'pin',
  publish: 'publish',
  save: 'save',
  screen: 'screen',
  settings: 'settings',
  specifications: 'specifications',
  stacks: 'stacks',
  subscription: 'subscription',
  target: 'target',
  tocomplete: 'tocomplete',
  transfer: 'transfer',
  trash: 'trash',
  upload: 'upload',
  users: 'users',
  videocall: 'videocall',
  bakery: 'bakery',
  bike: 'bike',
  bus: 'bus',
  dining: 'dining',
  gaming: 'gaming',
  garden: 'garden',
  insertion: 'insertion',
  internet: 'internet',
  kitchen: 'kitchen',
  laundry: 'laundry',
  living: 'living',
  mall: 'mall',
  medical: 'medical',
  parc: 'parc',
  parking: 'parking',
  pool: 'pool',
  private: 'private',
  school: 'school',
  station: 'station',
  store: 'store',
  subway: 'subway',
  supermarket: 'supermarket',
  terrace: 'terrace',
  theater: 'theater',
  tram: 'tram',
}

interface ContainerProps {
  size: number
  color?: Colors
  rotate?: number
  bg?: Colors
  br?: number
  cp?: string
  onClick?: (e: MouseEvent) => void
  isSpinner?: boolean
}

export interface IconProps {
  name: keyof typeof IconNames
  size: number
  color?: Colors
  rotate?: number
  className?: string
  bg?: Colors
  br?: number
  cp?: string
  as?: 'div' | 'span'
  containerStyle?: React.CSSProperties
  onClick?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) &
    ((e: MouseEvent) => void)
  isSpinner?: boolean
}

export const IconContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
  transform: ${props => props.rotate && `rotate(${props.rotate}deg)`};
  background-color: ${props => (props.bg ? props.theme[props.bg] : 'transparent')};
  border-radius: ${props => props.br || 0}px;
  padding: ${props => props.cp || '0px'};

  cursor: ${props => props.onClick && 'pointer'};

  animation: ${props => props.isSpinner && 'rotate360 3s linear infinite'};

  & > svg path {
    fill: ${props => (props.color ? props.theme[props.color] : props.theme.black)};
  }

  @keyframes rotate360 {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const Icon = ({
  color,
  name,
  size,
  rotate,
  className,
  bg,
  br,
  cp,
  containerStyle,
  onClick,
  as,
  isSpinner,
}: IconProps) => {
  const SVGIcon = icons[name]

  return (
    <IconContainer
      className={className}
      color={color}
      size={size}
      rotate={rotate}
      bg={bg}
      br={br}
      cp={cp}
      style={containerStyle}
      onClick={onClick}
      as={as}
      isSpinner={isSpinner}
    >
      <SVGIcon width={size} height={size} />
    </IconContainer>
  )
}

export default Icon
