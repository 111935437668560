import React, { useEffect } from 'react'
import styled from 'styled-components'
import { map } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'

import Box, { BoxWithHeader } from 'ui/box'
import { BackButton } from 'ui/button'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Icon, { IconNames } from 'ui/icon'

import InfosBlock from './blocks/infos'
import AccommodationBlock from './blocks/accommodation'
import NotificationBlock from './blocks/notification'
import SocialNetworksBlock from './blocks/socialNetworks'

import { usePageName } from 'hooks/usePageName'

const HeadBlock = styled(Box).attrs({ br: 4, fd: 'c' })`
  background-color: ${props => props.theme.greyLightN};
  margin: 0 15px 32px 15px;
  padding: 24px;
  flex: 0 1 346px;
  flex-shrink: 0;
  cursor: pointer;
`

const listOfItems = [
  {
    title: 'Éditer la page établissement',
    text: 'Éditez les informations affichées sur le site et l’interface Sahanest pour tous',
    icon: 'building',
    path: 'infos',
  },
  {
    title: 'Actualiser les critères d’accueil',
    text: 'Mettez à jours les critères et les places disponibles dans votre établissement',
    icon: 'users',
    path: 'capacite-accueil',
  },
  {
    title: 'Notifications',
    text: 'Choisissez vos préférences de notifications.',
    icon: 'chat',
    path: 'notifications',
  },
  {
    title: 'Reseaux sociaux',
    text: 'Actualiser vos liens vers les réseaux sociaux',
    icon: 'chat',
    path: 'social-networks',
  },
] as { title: string; text: string; icon: keyof typeof IconNames; path: string }[]

export default function InstitutionEdit() {
  const history = useHistory()
  const { subStep } = useParams<{ subStep: string }>()
  const { setPageName } = usePageName()

  useEffect(() => {
    setPageName('Mon Établissement')
  }, [])

  let block

  switch (subStep) {
    case 'infos':
      block = <InfosBlock />
      break
    case 'capacite-accueil':
      block = <AccommodationBlock />
      break
    case 'notifications':
      block = <NotificationBlock />
      break
    case 'social-networks':
      block = <SocialNetworksBlock />
      break
  }

  return block ? (
    block
  ) : (
    <Box fd="c">
      <BoxWithHeader br={4} f="0 1 auto" style={{ display: 'block' }}>
        <Box>
          <BackButton onClick={history.goBack} />
          <Title fs={24} bold>
            Édition de la fiche établissement
          </Title>
        </Box>

        <Box fd="r" padding="32px 32px 0 32px" gutter="negative" style={{ flexWrap: 'wrap' }}>
          {map(listOfItems, ({ title, text, icon, path }, i) => (
            <HeadBlock
              key={`section-${path}-${i}`}
              onClick={() => history.push(`/mon-etablissement/edition/${path}`)}
            >
              <Icon name={icon} color="green" size={32} containerStyle={{ marginBottom: '16px' }} />
              <Box ai="c">
                <Title fs={18} color="green" bold spacingBottom="4px">
                  {title}
                </Title>
                <Icon
                  name="arrow"
                  color="green"
                  size={20}
                  containerStyle={{ margin: '0 0 4px 8px' }}
                />
              </Box>
              <Text>{text}</Text>
            </HeadBlock>
          ))}
        </Box>
      </BoxWithHeader>
    </Box>
  )
}
