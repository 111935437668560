import request from './request'
import { AxiosResponse } from 'axios'

export function sendCode(admissionRecordsId: string, phone: string) {
  return request.patch<any, AxiosResponse<any>>(
    `/admission/${admissionRecordsId}/medical/send_doctor_phone`,
    {
      medical: { phoneDoctor: phone },
    },
  )
}

export function sendSignature(admissionRecordsId: string, confirmationCode: string) {
  return request.post<any, AxiosResponse<any>>(
    `/admission/${admissionRecordsId}/medical/send_signature`,
    {
      medical: { confirmationCode },
    },
  )
}

export function resendCode(admissionRecordsId: string) {
  return request.post<any, AxiosResponse<any>>(
    `/admission/${admissionRecordsId}/medical/resend_sms`,
  )
}
