import React, { useContext, useEffect } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'

import { usePageName } from 'hooks/usePageName'
import useResource from 'hooks/useResource'
import { AdmissionRecordProgress } from 'types/admissionRecord'
import { getAdmissionFormProgress } from 'api/admissionRecord'
import { AuthContext } from 'App'

import { FormLayout, GetItemsAdmissionRecord } from 'components/pages/form'
import Administrative from 'components/pages/patient/administrative'
import Social from 'components/pages/patient/social'
import Educative from 'components/pages/patient/educative'
import Validate from 'components/pages/patient/validate'
import Financial from 'components/pages/patient/financial'
import Document from 'components/pages/patient/document'
import Medical from 'components/pages/patient/medical'

export default function Form({ refreshUser }: { refreshUser: () => void }) {
  const { formName } = useParams<{
    formName: string
  }>()
  const { setPageName } = usePageName()
  const history = useHistory()
  const { pathname } = useLocation()
  const { auth } = useContext(AuthContext)
  const [resourceState, dispatch] = useResource<AdmissionRecordProgress, {}>(
    undefined,
    () =>
      auth.selectedId
        ? getAdmissionFormProgress(auth.selectedId)
        : Promise.resolve({} as AdmissionRecordProgress),
    [auth?.selectedId],
  )

  useEffect(() => {
    setPageName('Mon formulaire')
  }, [])

  const renderContent = () => {
    switch (formName) {
      case 'administrative':
        return (
          <Administrative
            refreshUser={refreshUser}
            refreshProgress={() => dispatch({ name: 'REFRESH_DATA' })}
          />
        )
      case 'social':
        return <Social refreshProgress={() => dispatch({ name: 'REFRESH_DATA' })} />
      case 'educative':
        return <Educative refreshProgress={() => dispatch({ name: 'REFRESH_DATA' })} />
      case 'support-validation':
        return <Validate />
      case 'financial':
        return <Financial refreshProgress={() => dispatch({ name: 'REFRESH_DATA' })} />
      case 'document':
        return <Document refreshProgress={() => dispatch({ name: 'REFRESH_DATA' })} />
      case 'medical':
        return <Medical />
      default:
        return <Redirect from="*" to="/administrative/etape/1" />
    }
  }

  return (
    <FormLayout
      path={formName}
      resourceState={resourceState}
      progressBar={{
        title: 'Formulaire d’admission',
        items: GetItemsAdmissionRecord(resourceState, history, pathname),
      }}
    >
      {renderContent()}
    </FormLayout>
  )
}
