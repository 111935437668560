import { Form } from 'types/form'
import { RUNAWAY_KIND_OF } from 'types/educative'

const RUNAWAY_FORM: Form = [
  [
    {
      name: 'kindOf',
      type: 'checkbox',
      label: 'Types de fugues',
      placeholder: 'Sélectionner les types de fugues',
      options: [
        {
          label: 'Aucune',
          value: RUNAWAY_KIND_OF.NONE,
        },
        { label: 'Fugues involontaires (errances)', value: RUNAWAY_KIND_OF.INVONLOTARY },
        { label: 'Fugues délibérées', value: RUNAWAY_KIND_OF.DELIBERATED },
        { label: 'Retours spontanés', value: RUNAWAY_KIND_OF.SPONTANEOUS_RETURN },
        {
          label: 'Risques importants de mise en danger durant la fugue',
          value: RUNAWAY_KIND_OF.DANGER,
        },
      ],
      required: true,
      vertical: true,
    },
  ],
  [
    {
      name: 'precision',
      type: 'textarea',
      label: 'Précisions',
      placeholder: '',
      required: false,
    },
  ],
]

export default RUNAWAY_FORM
