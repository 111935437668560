import { INCLUSIVE_ESTABLISHMENT_TYPES } from 'types/enums'
import {
  HABITAT_FORMAT,
  INHABITANT_AUTONOMY,
  BUILDING_PARC_TYPE,
  LOAN_LEVEL,
  LOAN_DETAIL,
  INCLUSIVE_WELCOME,
  VULNERABILITY_TYPE,
  TRANSPORTATION_ACCESSIBILITY,
  AROUND_HABITAT,
  HABITAT_FEATURES,
  SOCIAL_PROJECT_TYPE,
  SOCIAL_PROJECT_GOAL,
  ANIMATION_SCHEDULE,
  NIGHTWATCH_TYPE,
  SOCIAL_ENGINIERING,
} from 'types/inclusiveInstitution'
import {
  ACCOMMODATION_HANDICAP,
  ACCOMMODATION_MODE,
  ACCOMMODATION_PUBLIC,
  CARE_COVERAGE,
  CARE_STAFF,
  EDUCATIF_SOCIAL_STAFF,
  EQUIPMENT_ON_SITE,
  EQUIPMENT_SERVICE,
  PRE_PROFESSIONAL_ACTIVITY,
  PROFESSIONAL_ACTIVITY,
  SPECIALIZED_CARE,
  SUPPORT_ACTIVITY,
} from 'types/institution'
import { IconNames } from 'ui/icon'

export const SUPPORT_ACTIVITY_OPTIONS = [
  { value: SUPPORT_ACTIVITY.WITH_ANIMALS, label: 'Activités avec des animaux' },
  { value: SUPPORT_ACTIVITY.DAILY_ACTIVITY, label: 'Activités de la vie quotidienne' },
  { value: SUPPORT_ACTIVITY.MANUAL_ACTIVITIES, label: 'Activités manuelles' },
  { value: SUPPORT_ACTIVITY.MUSIC_ACTIVITY, label: 'Activités musicales' },
  { value: SUPPORT_ACTIVITY.SPORT_ACTIVITY, label: 'Activités sportives' },
  { value: SUPPORT_ACTIVITY.ART_ACTIVITY, label: 'Activités artistiques' },
  { value: SUPPORT_ACTIVITY.BALNEOTHERAPY, label: 'Balnéothérapie' },
  { value: SUPPORT_ACTIVITY.GO_OUT, label: 'Sorties' },
  { value: SUPPORT_ACTIVITY.BODY_CARE, label: 'Bien être et Soins du corps' },
]

export const PROFESSIONAL_ACTIVITY_OPTIONS = [
  {
    value: PROFESSIONAL_ACTIVITY.MAINSTREAM_EDUCATION,
    label: 'Scolarité ou formation en milieu ordinaire',
  },
  { value: PROFESSIONAL_ACTIVITY.INITIATION_WORKSHOPS, label: "Ateliers d'initiation" },
  { value: PROFESSIONAL_ACTIVITY.ORIENTATION_STAGE, label: 'Stages de pré-orientation' },
  {
    value: PROFESSIONAL_ACTIVITY.TRAIN_AUTONOMY,
    label:
      "Préparation à l'autonomie (vie sociale, intendance, arts ménagers, techniques de travail, etc.)",
  },
]

export const PRE_PROFESSIONAL_ACTIVITY_OPTIONS = [
  { value: PRE_PROFESSIONAL_ACTIVITY.INITIATION_WORKSHOPS, label: "Ateliers d'initiation" },
  {
    value: PRE_PROFESSIONAL_ACTIVITY.RP_STAGE,
    label: 'Stages de mises en situation (Préparation à la sortie)',
  },
  { value: PRE_PROFESSIONAL_ACTIVITY.ESAT, label: "Stages d'évaluation (ESAT)" },
]

export const EQUIPMENT_ON_SITE_OPTIONS = [
  {
    value: EQUIPMENT_ON_SITE.ADJUSTMENT_AND_ORTHOTICS,
    label: "Atelier d'ajustement d'aides techniques et orthèses",
  },
  { value: EQUIPMENT_ON_SITE.EMERGENCY_TROLLEY, label: "Chariot d'urgence" },
  { value: EQUIPMENT_ON_SITE.VACUUM_PLUGS, label: 'Prises de vide' },
  {
    value: EQUIPMENT_ON_SITE.THERAPEUTIC_POOL,
    label: 'Bassin thérapeutique pour balnéothérapie (<20m2)',
  },
  { value: EQUIPMENT_ON_SITE.SPORT_EQUIPMENT, label: 'Equipements sportifs' },
  { value: EQUIPMENT_ON_SITE.SNOEZELEN, label: 'Snoezelen' },
  {
    value: EQUIPMENT_ON_SITE.RESUSCITATION_TROLLEY,
    label: "Chariot de réanimation avec matériel d'intubation, ventilation non invasive",
  },
  { value: EQUIPMENT_ON_SITE.ADAPTED_VEHICLES, label: 'Véhicules adaptés' },
  { value: EQUIPMENT_ON_SITE.ADAPTED_KITCHEN, label: 'Cuisine adaptée à des besoins spécifiques' },
  { value: EQUIPMENT_ON_SITE.OUTDOOR_AND_GREEN_SPACES, label: 'Espaces verts et extérieurs' },
  { value: EQUIPMENT_ON_SITE.BRM, label: 'Isolement des patients porteurs de BMR' },
  {
    value: EQUIPMENT_ON_SITE.TEMPORO_SPATIAL_DISORIENTATION,
    label: 'Locaux adaptés à la désorientation temporo-spatiales',
  },
  {
    value: EQUIPMENT_ON_SITE.ROOM_VISUALLY_IMPAIRED,
    label: 'Locaux adaptés pour les personnes mal voyantes',
  },
  { value: EQUIPMENT_ON_SITE.FURNITURE_FOR_OBESITY, label: "Mobilier adapté à l'obésité massive" },
  { value: EQUIPMENT_ON_SITE.OXYGENE, label: 'Oxygène (prise murale/bouteille)' },
  { value: EQUIPMENT_ON_SITE.POOL, label: 'Piscine pour balnéothérapie (>20m2)' },
  { value: EQUIPMENT_ON_SITE.REEDUCATION_PLATFORM, label: 'Plateau de rééducation' },
  {
    value: EQUIPMENT_ON_SITE.SENSORY_STIMULATION_ROOM,
    label: 'Salle de stimulation multi-sensorielle',
  },
]

export const CARE_STAFF_OPTIONS = [
  { value: CARE_STAFF.CAREGIVER, label: 'Aide soignante' },
  { value: CARE_STAFF.AVEJISTE, label: 'Avéjiste' },
  { value: CARE_STAFF.OCCUPATIONAL_THERAPIST, label: 'Ergothérapeute' },
  { value: CARE_STAFF.COORDINATING_NURSE, label: 'Infirmier coordonnateur' },
  { value: CARE_STAFF.DOCTOR, label: 'Médecin' },
  { value: CARE_STAFF.LOCOMOTION_INSTRUCTOR, label: 'Instructeur en locomotion' },
  { value: CARE_STAFF.PHYSIOTHERAPIST, label: 'Kinésithérapeute' },
  { value: CARE_STAFF.REHABILITATION_DOCTOR, label: 'Médecin de rééducation' },
  { value: CARE_STAFF.PSYCHIATRIST, label: 'Médecin psychiatre' },
  { value: CARE_STAFF.NEUROPSYCHIATRIST, label: 'Neuropsychiatre' },
  { value: CARE_STAFF.NEUROLOGIST, label: 'Neurologue' },
  { value: CARE_STAFF.NEUROPSYCHOLOGIST, label: 'Neuropsychologue' },
  { value: CARE_STAFF.SPEECH_THERAPIST, label: 'Orthophoniste' },
  { value: CARE_STAFF.ORTHO_PROSTHETIST, label: 'Ortho-prothésiste' },
  { value: CARE_STAFF.ORTHOPTIST, label: 'Orthopsiste' },
  { value: CARE_STAFF.PEDIATRICIAN, label: 'Pédiatre' },
  { value: CARE_STAFF.PEDICURE_PODIATRIST, label: 'Pédicure-podologue' },
  { value: CARE_STAFF.PEDIATRIC_PSYCHIATRIST, label: 'Pédo-psychiatre' },
  { value: CARE_STAFF.PODO_ORTHOTIST, label: 'Podo-orthésiste' },
  { value: CARE_STAFF.PSYCHOLOGIST, label: 'Psychologue' },
  { value: CARE_STAFF.NURSERY_NURSE, label: 'Puéricultrice' },
  { value: CARE_STAFF.INDIVIDUALIZED_DIETETIC, label: 'Suivi diététique individualisé' },
]

export const EDUCATIF_SOCIAL_STAFF_OPTIONS = [
  { value: EDUCATIF_SOCIAL_STAFF.PSYCHOLOGICAL_HELP, label: 'Aide médico-psychologique' },
  { value: EDUCATIF_SOCIAL_STAFF.SCHOOL_ASSISTANCE, label: 'Aide humaine en milieu scolaire' },
  { value: EDUCATIF_SOCIAL_STAFF.ARTS_THERAPISTS, label: 'Arts-thérapeutes' },
  { value: EDUCATIF_SOCIAL_STAFF.SOCIOCULTURAL_ANIMATOR, label: 'Animateur socio-culturel' },
  { value: EDUCATIF_SOCIAL_STAFF.SOCIAL_WORKER, label: 'Assistant de service social' },
  { value: EDUCATIF_SOCIAL_STAFF.LIFE_ASSISTANT, label: 'Auxiliaire de vie' },
  { value: EDUCATIF_SOCIAL_STAFF.TEACHER, label: 'Enseignant' },
  {
    value: EDUCATIF_SOCIAL_STAFF.SOCIAL_AND_FAMILY_ECONOMY_ADVISOR,
    label: 'Conseiller en économie sociale et familiale',
  },
  { value: EDUCATIF_SOCIAL_STAFF.CHILDHOOD_EDUCATOR, label: 'Éducateur en jeunes enfants' },
  { value: EDUCATIF_SOCIAL_STAFF.SPORTS_TRAINER, label: 'Éducateur sportif' },
  { value: EDUCATIF_SOCIAL_STAFF.SPECIAL_EDUCATOR, label: 'Éducateur spécialisé' },
  { value: EDUCATIF_SOCIAL_STAFF.EDUCATOR_MONITOR, label: 'Moniteur-éducateur' },
  { value: EDUCATIF_SOCIAL_STAFF.WORKSHOP_INSTRUCTOR, label: "Moniteur d'atelier" },
]

export const CARE_COVERAGE_OPTIONS = [
  {
    value: CARE_COVERAGE.RESPIRATORY_ASSISTANCE_AND_TRACHEOSTOMY,
    label: 'Assistance respiratoire, trachéotomie avec assistance respiratoire permanente',
  },
  { value: CARE_COVERAGE.GLASTROSTOMY, label: 'Glastrostomie' },
  { value: CARE_COVERAGE.MULTI_DAILY_INJECTIONS, label: 'Injections pluriquotidiennes' },
  { value: CARE_COVERAGE.OXYGEN_THERAPY, label: 'Oxygénothérapie' },
  { value: CARE_COVERAGE.CAREGIVER_AT_NIGHT, label: "Présence d'aide-soignante la nuit" },
  { value: CARE_COVERAGE.NURSING_DURING_THE_DAY, label: 'Présence infirmière le jour' },
  { value: CARE_COVERAGE.SPASTICITY, label: 'Prise en charge de la spasticité' },
  {
    value: CARE_COVERAGE.TRACHEOSTOMY_CARE,
    label: 'Soins de trachéotomie (jusquà 3 aspirations/24h)',
  },
  { value: CARE_COVERAGE.DIABETES, label: 'Surveillance et prise en charge du diabète' },
  { value: CARE_COVERAGE.CONTINUOUS_CARE, label: 'Soins permanents continus / Soins IDE de nuit' },
  { value: CARE_COVERAGE.BLADDER_CATHETERIZATION, label: 'Sondage vésical intermittent' },
  {
    value: CARE_COVERAGE.ORAL_CARE,
    label: 'Suivi bucco-dentaire avec organisation dela prise en charge',
  },
]

export const SPECIALIZED_CARE_OPTIONS = [
  { value: SPECIALIZED_CARE.EQUIPMENT_ADAPTATION, label: "Adaptation d'appareillage" },
  { value: SPECIALIZED_CARE.ADDICTIVE_BEHAVIORS, label: 'Conduites addictives' },
  { value: SPECIALIZED_CARE.MOTOR_IMPAIRMENT, label: 'Déficience à prédominance motrice' },
  { value: SPECIALIZED_CARE.INTELLECTUAL_DISABILITY, label: 'Déficience intellectuelle' },
  { value: SPECIALIZED_CARE.HEARING_IMPAIRMENT, label: 'Déficience auditive grave' },
  { value: SPECIALIZED_CARE.VISUAL_IMPAIRMENT, label: 'Déficience visuelle grave' },
  { value: SPECIALIZED_CARE.DEGENERATIVE_DEMENTIA, label: 'Démence dégénérative' },
  {
    value: SPECIALIZED_CARE.PSYCHOLOGICAL_DIFFICULTY_WITH_BEHAVIORAL_DISORDERS,
    label: 'Difficulté spychologique avec troubles du comportement',
  },
  { value: SPECIALIZED_CARE.COGNITIVE_DISABILITY, label: 'Handicap à prédominance cognitive' },
  { value: SPECIALIZED_CARE.PSYCHIC_HANDICAP, label: 'Handicap psychique' },
  { value: SPECIALIZED_CARE.RARE_HANDICAP, label: 'Handicap rare' },
  { value: SPECIALIZED_CARE.POLYHANDICAP, label: 'Polyhandicap' },
  {
    value: SPECIALIZED_CARE.PREDOMINANTLY_COGNITIVE_DISABILITY,
    label: 'Handicap à prédominance cognitive avec troubles du comportement',
  },
  {
    value: SPECIALIZED_CARE.FRENCH_SIGN_LANGUAGE,
    label: 'Maîtrise de la langue des signes française (LSF)',
  },
  {
    value: SPECIALIZED_CARE.SPOKEN_LANGUAGE_COMPLETED,
    label: 'Maîtrise de la langue parlé complété (LPC)',
  },
  { value: SPECIALIZED_CARE.BRAILLE, label: "Maîrise de la lecture et de l'écriture du braille" },
  { value: SPECIALIZED_CARE.ALZHEIMER, label: "Maladies d'Alzheimer et apparentées" },
  { value: SPECIALIZED_CARE.EMERGING_SCHIZOPHRENIA, label: 'Schizophrénie émergente' },
  { value: SPECIALIZED_CARE.DEAF_BLINDNESS, label: 'Surdi-cécité avec ou sans troubles associés' },
  {
    value: SPECIALIZED_CARE.MOOD_DISORDERS,
    label: "Troubles de l'humeur (épisodes maniaque, bipolaire, dépression, etc.)",
  },
  {
    value: SPECIALIZED_CARE.EATING_DISORDERS,
    label: 'Troubles de la conduite alimentaire (TCA) (anorexie, boulimie, etc.)',
  },
  { value: SPECIALIZED_CARE.AUTISM, label: "Troubles du spectre de l'autisme" },
  {
    value: SPECIALIZED_CARE.HYPERACTIVITY,
    label: 'Troubles hyperkinétiques, hyperactivité (TDAH)',
  },
  {
    value: SPECIALIZED_CARE.CHRONIC_NEURO_COGNITIVE_DISORDERS,
    label:
      'Troubles neuro-cognitifs chroniques (Alzheimer et apparentées) avec troubles du comportement',
  },
  {
    value: SPECIALIZED_CARE.OBSESSIVE_COMPULSIVE_DISORDER,
    label: 'Troubles obsessionnels compulsifs (TOC)',
  },
  {
    value: SPECIALIZED_CARE.PSYCHOTIC_DISORDERS,
    label: 'Troubles psychotiques (schizophrénie, délires, etc.)',
  },
  {
    value: SPECIALIZED_CARE.PSYCHIC_SEXUAL_DISORDERS,
    label: 'Troubles psychiques des auteurs de violences sexuelles',
  },
  {
    value: SPECIALIZED_CARE.BRAIN_DAMAGE,
    label: 'Troubles psychiques dus à une affection organique ou une lésion cérébrale (épilepsie)',
  },
  {
    value: SPECIALIZED_CARE.LANGUAGE_AND_LEARNING_DISORDERS,
    label: 'Troubles spécifiques du langage et des apprentissages (DYS)',
  },
]

export const EQUIPMENT_SERVICE_OPTIONS = [
  {
    value: EQUIPMENT_SERVICE.COMPANION_ANIMALS,
    label: "Acceptation d'animaux aidants ou de compagnie",
  },
  { value: EQUIPMENT_SERVICE.INTERNET, label: 'Accès internet' },
  { value: EQUIPMENT_SERVICE.NIGHT_WATCHMAN, label: 'Veilleur de nuit' },
  { value: EQUIPMENT_SERVICE.PUBLIC_TRANSPORT, label: 'Accès transports en commun' },
  {
    value: EQUIPMENT_SERVICE.HOME_SUPPORT,
    label: 'Accompagnement à domicile par une équipe éducative',
  },
  {
    value: EQUIPMENT_SERVICE.UNIVERSAL_ACCESSIBILITY,
    label: 'Accessibilité universelle (fauteuils roulants)',
  },
  { value: EQUIPMENT_SERVICE.SINGLE_ROOMS, label: 'Chambres individuelles' },
  { value: EQUIPMENT_SERVICE.RESTORATION, label: 'Restauration' },
  { value: EQUIPMENT_SERVICE.EXIT_CONTROL, label: 'Sécurisation par contrôle des sorties' },
  {
    value: EQUIPMENT_SERVICE.HOME_ESTABLISHMENT_TRANSPORT,
    label: 'Service de transport domicile-établissement',
  },
  { value: EQUIPMENT_SERVICE.HAIRDRESSING, label: 'Coiffure' },
  { value: EQUIPMENT_SERVICE.SOCIO_ESTHETICIAN, label: 'Socio-esthéticienne' },
  { value: EQUIPMENT_SERVICE.INDIVIDUAL_TELEVISION, label: 'Télévision individuelle' },
]

export const ACCOMMODATION_HANDICAP_OPTIONS = [
  { value: ACCOMMODATION_HANDICAP.INTELLECTUAL_DISABILITY, label: 'Déficience intellectuelle' },
  {
    value: ACCOMMODATION_HANDICAP.SEVERE_MENTAL_RETARDATION,
    label: 'Retard mental profond ou sévère',
  },
  { value: ACCOMMODATION_HANDICAP.MILD_MENTAL_RETARDATION, label: 'Retard mental léger' },
  { value: ACCOMMODATION_HANDICAP.MODERATE_MENTAL_RETARDATION, label: 'Retard mental modéré' },
  {
    value: ACCOMMODATION_HANDICAP.INTELLECTUAL_DISABILITIES,
    label: 'Déficiences intellectuelles (SAI) avec troubles associés',
  },
  { value: ACCOMMODATION_HANDICAP.POLYDISABILITY, label: 'Polyhandicap' },
  {
    value: ACCOMMODATION_HANDICAP.SEVERE_RETARDATION,
    label: 'Retard mental profond et sévère avec troubles associés',
  },
  {
    value: ACCOMMODATION_HANDICAP.COMMUNICATION_IMPAIRMENT,
    label: 'Déficience grave de la communication',
  },
  {
    value: ACCOMMODATION_HANDICAP.MODERATE_RETARDATION_WITH_DISORDERS,
    label: 'Retard mental moyen avec troubles associés',
  },
  {
    value: ACCOMMODATION_HANDICAP.MILD_RETARDATION_WITH_DISORDERS,
    label: 'Retard mental léger avec troubles associés',
  },
  {
    value: ACCOMMODATION_HANDICAP.PSYCHOLOGICAL_DIFFICULTIES,
    label: 'Difficultés psychologiques avec troubles du comportement',
  },
  { value: ACCOMMODATION_HANDICAP.PSYCHE_IMPAIRMENT, label: 'Déficience grave du psychisme' },
  {
    value: ACCOMMODATION_HANDICAP.INTERMITTENT_CONSCIOUSNESS,
    label: 'Déficience intermittente de la conscience y compris épilepsie',
  },
  { value: ACCOMMODATION_HANDICAP.CEREBRAL_PALSY, label: 'Cérébro-lésés' },
  {
    value: ACCOMMODATION_HANDICAP.BRAIN_DAMAGE_IMPAIRMENT,
    label: 'Déficience grave du psychisme suite à une lésion cérébrale',
  },
  { value: ACCOMMODATION_HANDICAP.COGNITIVE_DISABILITY, label: 'Handicap cognitif spécifique' },
  {
    value: ACCOMMODATION_HANDICAP.MOTOR_IMPAIRMENT_DISORDERS,
    label: 'Déficience motrice avec troubles associés',
  },
  {
    value: ACCOMMODATION_HANDICAP.MOTOR_IMPAIRMENT,
    label: 'Déficience motrice sans troubles associés',
  },
  {
    value: ACCOMMODATION_HANDICAP.HEARING_IMPAIRMENT_DISORDERS,
    label: 'Déficience auditive sans troubles associés',
  },
  {
    value: ACCOMMODATION_HANDICAP.HEARING_IMPAIRMENT,
    label: 'Déficience auditive avec troubles associés',
  },
  {
    value: ACCOMMODATION_HANDICAP.VISUAL_IMPAIRMENT_DISORDERS,
    label: 'Déficience visuelle avec troubles associés',
  },
  {
    value: ACCOMMODATION_HANDICAP.VISUAL_IMPAIRMENT,
    label: 'Déficience visuelle sans troubles associés',
  },
  {
    value: ACCOMMODATION_HANDICAP.DEAF_BLINDNESS,
    label: 'Surdi-cécité avec ou sans troubles associés',
  },
  { value: ACCOMMODATION_HANDICAP.AUTISM_DISORDERS, label: 'Troubles du spectre de l’autisme' },
  {
    value: ACCOMMODATION_HANDICAP.PSYCHOPATHOLOGICAL_DISORDERS,
    label: 'Troubles psychopathologiques',
  },
  {
    value: ACCOMMODATION_HANDICAP.SEVERE_PSYCHOPATHOLOGICAL_DISORDERS,
    label: 'Troubles psychopathologiques graves',
  },
]

export const ACCOMMODATION_MODE_OPTIONS = [
  { value: ACCOMMODATION_MODE.DAY_RECEPTION, label: 'Accueil de Jour' },
  { value: ACCOMMODATION_MODE.FULL_ACCOMMODATION, label: 'Hébergement Complet Internat' },
  { value: ACCOMMODATION_MODE.HALF_ACCOMMODATION, label: 'Semi-Internat' },
  { value: ACCOMMODATION_MODE.NO_ACCOMMODATION, label: 'Externat' },
  { value: ACCOMMODATION_MODE.TEMPORARY_PLACEMENT, label: 'Accueil temporaire' },
  { value: ACCOMMODATION_MODE.HOST_FAMILY_SERVICE, label: "Placement Famille d'Accueil" },
]

export const ACCOMMODATION_PUBLIC_OPTIONS = [
  { value: ACCOMMODATION_PUBLIC.POLY_DISABLED, label: 'Polyhandicapés' },
  { value: ACCOMMODATION_PUBLIC.AUTISTIC, label: 'TSA (troubles du spectre autistique)' },
  { value: ACCOMMODATION_PUBLIC.INTELLECTUAL_DISABILITY, label: 'Déficience intellectuelle' },
  { value: ACCOMMODATION_PUBLIC.INJURED_BRAIN, label: 'Personnes cérébro lésées' },
  {
    value: ACCOMMODATION_PUBLIC.PSYCHIC_IMPAIRMENT,
    label: 'Maladie psychique consécutive à lésion cérébrale',
  },
  {
    value: ACCOMMODATION_PUBLIC.COMMUNICATION_DEFICIENCY,
    label: 'Déficience grave de la communication',
  },
  {
    value: ACCOMMODATION_PUBLIC.SEVERE_MENTAL_IMPAIRMENT,
    label: 'Déficience intellectuelle sévère',
  },
  {
    value: ACCOMMODATION_PUBLIC.MOTOR_DISABLED,
    label: 'Handicap moteur sans troubles associés',
  },
  {
    value: ACCOMMODATION_PUBLIC.VISUAL_IMPAIRMENT,
    label: 'Personnes souffrant de déficiences visuelles',
  },
  {
    value: ACCOMMODATION_PUBLIC.BEHAVIOR_DISORDER,
    label: 'Troubles du Caractère et du Comportement',
  },
  {
    value: ACCOMMODATION_PUBLIC.HEARING_DISABILITY,
    label: 'Déficience auditive',
  },
  {
    value: ACCOMMODATION_PUBLIC.COGNITIVE_DISABILITY,
    label: 'Handicap cognitif spécifique',
  },
  {
    value: ACCOMMODATION_PUBLIC.OTHER,
    label: 'Autre',
  },
]

export const HABITAT_FORMAT_OPTIONS = [
  {
    value: HABITAT_FORMAT.DIFUSE_INDIVIDUAL,
    label: 'Appartements individuels difus avec espaces collectifs',
  },
  {
    value: HABITAT_FORMAT.GROUPED_INDIVIDUAL,
    label: 'Appartements individuels regroupés avec espace collectif',
  },
  {
    value: HABITAT_FORMAT.COLOCATION,
    label: 'Colocation/coliving (chambre & salle de bain privative, autres espaces mutualisés)',
  },
]

export const INHABITANT_AUTONOMY_OPTIONS = [
  {
    value: INHABITANT_AUTONOMY.AUTONOMOUS,
    label: 'Grande Autonomie (0/2h de PCH/jour, GIR 5 ou 6)',
  },
  {
    value: INHABITANT_AUTONOMY.RELATIVELY_AUTONOMOUS,
    label: 'Autonomie relative (4/12h de PCH/jour, GIR 3 ou 4)',
  },
  {
    value: INHABITANT_AUTONOMY.DEPENDANCE,
    label: 'Grand niveau de dépendance (12/24h de PCH/jour, GIR 1 ou 2)',
  },
]

export const BUILDING_PARC_TYPE_OPTIONS = [
  {
    value: BUILDING_PARC_TYPE.SOCIAL,
    label: 'Parc social',
  },
  {
    value: BUILDING_PARC_TYPE.PRIVATE,
    label: 'Parc privé',
  },
]

export const LOAN_LEVEL_OPTIONS = [
  {
    value: LOAN_LEVEL.OVER_750,
    label: '+ de 750€',
  },
  {
    value: LOAN_LEVEL.BETWEEN_350_AND_750,
    label: 'Entre 350€ et 750€',
  },
]

export const LOAN_DETAIL_OPTIONS = [
  {
    value: LOAN_DETAIL.LOAN_ONLY,
    label: 'Loyer seul',
  },
  {
    value: LOAN_DETAIL.WITH_CHARGES,
    label: 'Loyer Charges comprises',
  },
]

export const INCLUSIVE_WELCOME_OPTIONS = [
  {
    value: INCLUSIVE_WELCOME.VULNERABLE_ONLY,
    label: 'Uniquement des personnes fragiles',
  },
  {
    value: INCLUSIVE_WELCOME.MIXED,
    label: 'Mixité entre personnes fragiles ou non',
  },
  {
    value: INCLUSIVE_WELCOME.REFLECTION,
    label: 'En réflexion',
  },
]

export const VULNERABILITY_TYPE_OPTIONS = [
  {
    value: VULNERABILITY_TYPE.OLD,
    label: 'Grand âge',
  },
  {
    value: VULNERABILITY_TYPE.DISABLED,
    label: 'Handicap',
  },
  {
    value: VULNERABILITY_TYPE.OTHER,
    label: 'Autre',
  },
]

export const TRANSPORTATION_ACCESSIBILITY_OPTIONS: Array<{
  label: string
  icon?: keyof typeof IconNames
  value: TRANSPORTATION_ACCESSIBILITY
}> = [
  { label: 'Aucun', value: TRANSPORTATION_ACCESSIBILITY.NONE },
  { label: 'Gare', value: TRANSPORTATION_ACCESSIBILITY.TRAIN_STATION, icon: 'station' },
  { label: 'Tramway', value: TRANSPORTATION_ACCESSIBILITY.TRAMWAY, icon: 'tram' },
  { label: 'Bus', value: TRANSPORTATION_ACCESSIBILITY.BUS, icon: 'bus' },
  { label: 'Métro', value: TRANSPORTATION_ACCESSIBILITY.SUBWAY, icon: 'subway' },
]

export const AROUND_HABITAT_OPTIONS: Array<{
  label: string
  icon?: keyof typeof IconNames
  value: AROUND_HABITAT
}> = [
  { label: 'Boulangerie', value: AROUND_HABITAT.BAKERY, icon: 'bakery' },
  { label: 'Supermarché', value: AROUND_HABITAT.SUPERMARKET, icon: 'supermarket' },
  { label: 'Parc / espaces verts', value: AROUND_HABITAT.PARCS, icon: 'parc' },
  { label: 'Centre commercial', value: AROUND_HABITAT.MALL, icon: 'mall' },
  { label: 'Cinéma / théâtre', value: AROUND_HABITAT.THEATER, icon: 'theater' },
  { label: 'Commerces', value: AROUND_HABITAT.GROCERY_STORE, icon: 'store' },
  { label: 'Ecole / université', value: AROUND_HABITAT.SCHOOLS, icon: 'school' },
  { label: 'Santé', value: AROUND_HABITAT.HOSPITAL, icon: 'medical' },
  {
    label: "Entreprises d'insertion",
    value: AROUND_HABITAT.INCLUSIVE_COMPANY,
    icon: 'insertion',
  },
]

export const HABITAT_FEATURES_OPTIONS: Array<{
  label: string
  icon?: keyof typeof IconNames
  value: HABITAT_FEATURES
}> = [
  { label: 'Jardin', value: HABITAT_FEATURES.GARDEN, icon: 'garden' },
  { label: 'Piscine', value: HABITAT_FEATURES.SWIMING_POOL, icon: 'pool' },
  { label: 'Terrasse', value: HABITAT_FEATURES.TERRACE, icon: 'terrace' },
  { label: 'Buanderie', value: HABITAT_FEATURES.LAUNDRY_ROOM, icon: 'laundry' },
  { label: 'Cuisine équipée', value: HABITAT_FEATURES.KITCHEN, icon: 'kitchen' },
  { label: 'Salon', value: HABITAT_FEATURES.LIVING_ROOM, icon: 'living' },
  { label: 'Salle à manger', value: HABITAT_FEATURES.DINING_ROOM, icon: 'dining' },
  { label: 'Local vélo', value: HABITAT_FEATURES.BIKE_ROOM, icon: 'bike' },
  { label: 'Parking', value: HABITAT_FEATURES.PARKING, icon: 'parking' },
  { label: 'Internet', value: HABITAT_FEATURES.INTERNET, icon: 'internet' },
  { label: 'Salle de jeu / détente', value: HABITAT_FEATURES.GAMING_ROOM, icon: 'gaming' },
  { label: 'Espaces privatifs meublés', value: HABITAT_FEATURES.PRIVATE_SPACE, icon: 'private' },
]

export const SOCIAL_PROJECT_TYPE_OPTIONS = [
  { label: "Rompre avec l'isolement social", value: SOCIAL_PROJECT_TYPE.STOP_ISOLATION },
  {
    label: 'Un lieu de vie pérenne, accompagné et sécurisé',
    value: SOCIAL_PROJECT_TYPE.PERSISTANT_PLACE,
  },
  {
    label: 'Une solution temporaire pour renouer avec une certaine autonomie de vie',
    value: SOCIAL_PROJECT_TYPE.AUTONOMY,
  },
  {
    label: 'Un projet de vie collectif et partagé qui implique les familles et les proches',
    value: SOCIAL_PROJECT_TYPE.COLLECTIVITY,
  },
  { label: 'Une solution qui accompagne le vieillissement', value: SOCIAL_PROJECT_TYPE.AGING },
  { label: 'Autre', value: SOCIAL_PROJECT_TYPE.OTHER },
]

export const SOCIAL_PROJECT_GOAL_OPTIONS = [
  {
    label: 'Le retour progressif à l’autonomie sur des tâches du quotidien.',
    value: SOCIAL_PROJECT_GOAL.AUTONOMY,
  },
  {
    label: 'Le retour vers une activité professionnelle ou associative régulière',
    value: SOCIAL_PROJECT_GOAL.ACTIVITY,
  },
  {
    label: 'L’amélioration des relations avec le tissu social amical et familial',
    value: SOCIAL_PROJECT_GOAL.CULTURE,
  },
  {
    label:
      'L’organisation régulière d’activités en partenariats avec les associations locales et les collectivités.',
    value: SOCIAL_PROJECT_GOAL.COMMUNITY,
  },
  { label: 'Autre', value: SOCIAL_PROJECT_GOAL.OTHER },
]

export const ANIMATION_SCHEDULE_OPTIONS = [
  { label: 'Dans la journée', value: ANIMATION_SCHEDULE.DAYTIME },
  { label: 'Dans la soirée', value: ANIMATION_SCHEDULE.NIGHT },
  { label: '24 heures/24', value: ANIMATION_SCHEDULE.ALL_DAY },
  { label: '7 jours/7', value: ANIMATION_SCHEDULE.WEEK },
  { label: "Pas de présence d'animateur/coordinateur de maison.", value: ANIMATION_SCHEDULE.NONE },
]

export const NIGHTWATCH_TYPE_OPTIONS = [
  { label: '', value: '' },
  { label: 'Surveillance la nuit', value: NIGHTWATCH_TYPE.FULL },
  { label: 'Service d’astreinte la nuit', value: NIGHTWATCH_TYPE.PARTIAL },
  { label: 'Pas de surveillance la nuit', value: NIGHTWATCH_TYPE.NONE },
]

export const SOCIAL_ENGINIERING_OPTIONS = [
  { label: 'Reconnaissance adulte handicapé nécessaire', value: SOCIAL_ENGINIERING.RAH },
  {
    label: 'Éligibilité à la Prestation compensatoire du handicap (PCH)',
    value: SOCIAL_ENGINIERING.PCH,
  },
  { label: "Elligibilité à l'allocation personne agée (APA)", value: SOCIAL_ENGINIERING.APA },
  { label: 'Aucune ressources sociales nécessaires', value: SOCIAL_ENGINIERING.NONE },
]

export const STRUCTURE_PLACE_OPTIONS = [
  {
    label: 'Habitat inclusif & accompagné',
    value: INCLUSIVE_ESTABLISHMENT_TYPES.INC,
  },
  { label: 'Accueil Familial', value: INCLUSIVE_ESTABLISHMENT_TYPES.FAM },
  { label: 'Habitat partagé', value: INCLUSIVE_ESTABLISHMENT_TYPES.SHA },
]
