import React from 'react'

import Box from 'ui/box'
import Button from 'ui/button'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Icon from 'ui/icon'

import { success } from 'assets/illustrations'

const Completed: React.FC<{
  goBack: () => void
}> = ({ goBack }) => {
  return (
    <Box fd="c" bg="greyLightN" style={{ margin: '32px 32px 0 32px', overflowY: 'scroll' }}>
      <Box jc="c" padding="32px">
        <img src={success} style={{ width: '300px' }} alt="succés" />
      </Box>
      <Box fd="c" ai="c" padding="0 32px 32px 32px">
        <Title fs={20} bold spacingBottom="16px">
          La création du trousseau est confirmé !
        </Title>
        <Text spacingBottom="32px">Vous pouvez l’utiliser dès maintenant.</Text>
        <Button onClick={goBack}>
          Terminer <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
        </Button>
      </Box>
    </Box>
  )
}

export default Completed
