const colors = {
  black: '#27262A',
  brown: '#515151',
  blue: '#201858',
  blueLight: '#E8F0FB',
  green: '#2F7067',
  greenDark: '#225952',
  greenLight: '#4CBE71',
  greenLight2: '#eaf1f0',
  greyLight: '#EEEEEE', // remove when possible
  greyLight3: '#F5F5F5',
  greyLight4: '#7B7B7C', // remove when possible
  greyLightN: '#F9F9F9',
  greyLightN2: 'rgba(39, 38, 42, 0.15)',
  greyLightN3: '#ebebec',
  greyDark: '#e9e9ea',
  greyDark2: 'rgba(39, 38, 42, 0.5)',
  greyDark3: '#E0E0E0',
  grey: '#939294', //sahanest v2
  greyTransparent: 'rgba(78, 78, 78, 0.1)',
  greyBlue: '#5A7184',
  orange: '#F2A56D',
  pink: '#F26D6D',
  purple: '#342B75',
  purpleDark: '#201858',
  purpleDarkTransparent: 'rgba(32, 24, 88, 0.1)',
  purpleLight: '#8B87A7',
  redLight: '#FBB0B0',
  redLight2: '#FEF1F1',
  yellow: '#FBE8B0',
  yellowDark: '#D5A417',
  yellowDarker: '#D4AF42',
  white: '#FFFFFF',
  codeEditor: '#3d3f47',
}

const theme = {
  ...colors,
}

export type DefaultTheme = typeof theme
export type Colors = keyof typeof colors

export default theme
