import React from 'react'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'

import { success } from 'assets/illustrations'

export default function Validate({ patientName }: { patientName: string }) {
  return (
    <Box fd="c" ai="c" bg={'greenLight2'} padding="32px">
      <Box jc="c" style={{ marginBottom: '32px' }}>
        <img src={success} alt="succés" />
      </Box>
      <Box fd="c">
        <Title bold spacingBottom="32px">
          Le formulaire de {patientName} a été envoyé avec succès !
        </Title>
        <Text>
          Vous allez recevoir un e-mail de confirmation sur votre adresse renseignée à
          l’inscription.
        </Text>
      </Box>
    </Box>
  )
}
