import React from 'react'
import styled from 'styled-components'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Tooltip from 'ui/tooltip'

const DocName = styled(Text)<{
  required?: boolean
  missing?: boolean
}>`
  font-weight: bold;
  color: ${props => (props.missing ? props.theme.pink : 'black')};

  &::before {
    content: '${props => (props.required ? '* ' : '')}';
    color: ${props => props.theme.pink};
  }
`

const CardDoc: React.FC<{
  docName: string
  required?: boolean
  docInfos?: string
  filename?: string
  hasError?: boolean
  missing?: boolean
  style?: React.CSSProperties
}> = ({ docName, required, docInfos, filename, hasError, missing, style }) => {
  return (
    <Box bg="greenLight2" padding="14px 20px" br={4} style={{ ...style, zIndex: 3 }}>
      <Box ai="c" jc="sb">
        <Box fd="c">
          <Box ai="c">
            <DocName required={required} missing={missing}>
              {docName}
            </DocName>
            {docInfos && (
              <Tooltip text={docInfos}>
                <Icon name="info" size={12} cp="0 6px" />
              </Tooltip>
            )}
          </Box>
          {filename ? (
            <Text
              color="green"
              style={{
                maxWidth: '400px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {filename}
            </Text>
          ) : (
            <Text color="black">Aucun fichier ajouté</Text>
          )}
        </Box>
        <Icon
          name={hasError ? 'closeRed' : filename ? 'check' : 'upload'}
          color={hasError ? 'white' : 'green'}
          size={21}
          cp="0 0 0 20px"
        />
      </Box>
    </Box>
  )
}

export default CardDoc
