import React from 'react'
import styled from 'styled-components'

import Box from '../box'
import Title from '../typography/title'
import Icon from '../icon'
import Separator from 'ui/separator'

const Overlay = styled(Box).attrs({ jc: 'c', ai: 'c', fd: 'r' })`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
`
const Content = styled(Box)`
  max-width: 50vw;
  max-height: 90vh;
  background-color: white;
  border-radius: 4px;
  margin: 0 32px;
  display: block;
  overflow-y: scroll;
`

export default function Modal({
  children,
  onClose,
  opened,
  title,
}: {
  children?: JSX.Element[] | JSX.Element
  title: string
  onClose: () => void
  opened: boolean
}) {
  return opened ? (
    <Overlay>
      <Content>
        <Box fd="r" ai="c" padding="24px 32px" f="initial">
          <Title fs={24} bold style={{ flex: 1, paddingRight: '16px' }}>
            {title}
          </Title>
          <Icon name="close" color="black" size={16} onClick={onClose} />
        </Box>
        <Separator color="greyLightN3" />
        <Box fd="c" padding="32px" style={{ overflow: 'hidden' }}>
          {children}
        </Box>
      </Content>
    </Overlay>
  ) : (
    <></>
  )
}
