import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { useForm } from 'react-hook-form'

import { AuthContext } from 'App'
import Box, { BoxWithHeader } from 'ui/box'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import Input from 'ui/form/input'
import Radio from 'ui/form/radio'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Separator from 'ui/separator'
import Button from 'ui/button'
import Loader from 'ui/loader'
import { patch, deleteUser } from 'api/user'
import { PatchUser } from 'types/user'
import { useToasts } from 'hooks/useToaster'
import { usePageName } from 'hooks/usePageName'

const ErrorMessage = styled(Text).attrs({ fs: 16, color: 'pink' })<{
  hasError: boolean
}>`
  visibility: ${props => (props.hasError ? 'visible' : 'hidden')};
`

export default function Profile() {
  const {
    auth: { user },
    updateUser,
  } = useContext(AuthContext)
  const { addToast, addGenericErrorToast } = useToasts()
  const [loading, setLoading] = useState<boolean>(false)
  const { register, handleSubmit, watch, reset, errors } = useForm<PatchUser>({
    defaultValues: {
      ...user,
      acceptEmailNotif: `${get(user, 'acceptEmailNotif', false) || false}`,
    },
  })
  const { setPageName } = usePageName()
  const isRequired = !!watch('password') || !!watch('passwordConfirmation')

  useEffect(() => {
    setPageName('Mon profil')
  }, [])

  const onSubmit = (data: any) => {
    setLoading(true)
    patch(`${get(user, 'id')}`, data)
      .then(res => {
        setLoading(false)
        addToast({
          type: 'success',
          title: 'Succès !',
          message: 'Votre profil a été mis à jour avec succès !',
        })
        updateUser(res.user)
        reset({ ...res.user, acceptEmailNotif: `${get(user, 'acceptEmailNotif', false) || false}` })
      })
      .catch(() => {
        setLoading(false)
        addGenericErrorToast()
        reset()
      })
  }

  return (
    <BoxWithHeader f="1" br={4} style={{ position: 'relative' }}>
      <Box>
        <Title fs={24} bold>
          Informations personnelles
        </Title>
      </Box>

      {loading && (
        <Box
          fd="c"
          ai="c"
          jc="c"
          width="100%"
          height="100%"
          style={{ position: 'absolute', backgroundColor: 'rgba(255,255,255,0.8)', zIndex: 5 }}
        >
          <Loader />
        </Box>
      )}

      <Box
        fd="c"
        as="form"
        padding="32px 32px 0 32px"
        style={{ display: 'block' }}
        onSubmit={handleSubmit(onSubmit)}
        scroll
      >
        <Title fs={18} bold spacingBottom="20px">
          Profil
        </Title>
        <Box gutter="negative" f="initial">
          <Group style={{ padding: '15px' }}>
            <Box fd="c">
              <Label htmlFor="lastName" style={{ paddingBottom: '16px' }}>
                Nom
              </Label>
              <Input
                id="lastName"
                name="lastName"
                ref={register({ required: 'Ce champ est requis' })}
              />
            </Box>
            <ErrorMessage hasError={!!get(errors, `lastName`)}>
              {get(errors, `lastName.message`)}
            </ErrorMessage>
          </Group>
          <Group style={{ padding: '15px' }}>
            <Box fd="c">
              <Label htmlFor="firstName" style={{ paddingBottom: '16px' }}>
                Prénom
              </Label>
              <Input
                id="firstName"
                name="firstName"
                ref={register({ required: 'Ce champ est requis' })}
              />
            </Box>
            <ErrorMessage hasError={!!get(errors, `firstName`)}>
              {get(errors, `firstName.message`)}
            </ErrorMessage>
          </Group>
        </Box>
        <Box gutter="negative" f="initial">
          <Group style={{ padding: '15px' }}>
            <Box fd="c">
              <Label htmlFor="email" style={{ paddingBottom: '16px' }}>
                E-mail
              </Label>
              <Input id="email" name="email" ref={register({ required: 'Ce champ est requis' })} />
            </Box>
            <ErrorMessage hasError={!!get(errors, `email`)}>
              {get(errors, `email.message`)}
            </ErrorMessage>
          </Group>
          <Group style={{ padding: '15px' }}>
            <Box fd="c" f="1">
              <Label htmlFor="acceptEmailNotif" style={{ paddingBottom: '16px' }}>
                Autoriser les notifactions par emails
              </Label>
              <Box fd="r" ai="c" jc="fs">
                <Radio
                  id="acceptEmailNotif-yes"
                  name="acceptEmailNotif"
                  value="true"
                  containerStyle={{ marginBottom: '18px' }}
                  forwardRef={register({})}
                >
                  Oui
                </Radio>
                <Radio
                  id="acceptEmailNotif-no"
                  name="acceptEmailNotif"
                  value="false"
                  containerStyle={{ marginBottom: '18px' }}
                  forwardRef={register({})}
                >
                  Non
                </Radio>
              </Box>
            </Box>
            <ErrorMessage hasError={!!get(errors, `acceptEmailNotif`)}>
              {get(errors, `acceptEmailNotif.message`)}
            </ErrorMessage>
          </Group>
        </Box>
        <Box padding="20px 0 0 0" f="initial">
          <Button type="submit" variant="success" style={{ marginRight: '20px' }}>
            Mettre à jour les informations
          </Button>
        </Box>
        <Separator spacingTop={20} spacingBottom={20} />
        <Title fs={18} bold spacingBottom="20px">
          Sécurité
        </Title>
        <Box gutter="negative" f="initial">
          <Group style={{ padding: '15px' }}>
            <Box fd="c">
              <Label
                htmlFor="currentPassword"
                style={{ paddingBottom: '16px' }}
                required={isRequired}
              >
                Mot de passe actuel
              </Label>
              <Input
                id="currentPassword"
                name="currentPassword"
                type="password"
                ref={register({
                  required: isRequired ? 'Ce champ est requis' : false,
                })}
              />
            </Box>
            <ErrorMessage hasError={!!get(errors, `currentPassword`)}>
              {get(errors, `currentPassword.message`)}
            </ErrorMessage>
          </Group>
          <Group style={{ padding: '15px' }} />
        </Box>
        <Box gutter="negative" f="initial">
          <Group style={{ padding: '15px' }}>
            <Box fd="c" f="initial">
              <Label htmlFor="password" style={{ paddingBottom: '16px' }} required={isRequired}>
                Nouveau mot de passe
              </Label>
              <Input
                id="password"
                name="password"
                type="password"
                ref={register({
                  required: isRequired ? 'Ce champ est requis' : false,
                  minLength: {
                    value: 8,
                    message: 'Le mot de passe doit contenir au moins 8 caractères',
                  },
                })}
              />
            </Box>
            <ErrorMessage hasError={!!get(errors, `password`)}>
              {get(errors, `password.message`)}
            </ErrorMessage>
          </Group>
          <Group style={{ padding: '15px' }}>
            <Box fd="c" f="initial">
              <Label
                htmlFor="passwordConfirmation"
                style={{ paddingBottom: '16px' }}
                required={isRequired}
              >
                Confirmation
              </Label>
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                ref={register({
                  required: isRequired ? 'Ce champ est requis' : false,
                  validate: value =>
                    value === watch('password') || 'Les mots de passe ne correspondent pas',
                })}
              />
            </Box>
            <ErrorMessage hasError={!!get(errors, `passwordConfirmation`)}>
              {get(errors, `passwordConfirmation.message`)}
            </ErrorMessage>
          </Group>
        </Box>
        <Box padding="20px 0 0 0" f="initial">
          <Button type="submit" variant="success" style={{ marginRight: '20px' }}>
            Modifier le mot de passe
          </Button>
          <Button
            variant="danger"
            onClick={() =>
              deleteUser()
                .then(() =>
                  addToast({
                    type: 'success',
                    title: 'Succès !',
                    message: 'Votre demande de suppression a bien été prise en compte !',
                  }),
                )
                .catch(() => addGenericErrorToast())
            }
          >
            Supprimer mon compte
          </Button>
        </Box>
      </Box>
    </BoxWithHeader>
  )
}
