import React from 'react'
import styled from 'styled-components'

import Box from 'ui/box'
import Card, { CardProps } from '../card'
import Icon from 'ui/icon'
import { Colors } from 'ui/theme'

import { ProgressVariant } from 'types/enums'

const Container = styled(Box)<{
  progressBarColor: Colors
}>`
  margin-bottom: 8px;
  padding-left: 32px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 58px;
    left: 12px;
    height: calc(100% - 24px);
    width: 1px;
    background: ${props => props.theme[props.progressBarColor]};
  }

  &:last-child:before {
    content: none;
  }
`

const ProgressBarSquare = styled(Box).attrs({ jc: 'c', ai: 'c', width: '22px', height: '22px' })<{
  fillB: boolean
  borderColor: Colors
}>`
  background-color: ${props => (props.fillB ? props.theme[props.borderColor] : props.theme.white)};
  border: 1px solid ${props => props.theme[props.borderColor]};
  color: ${props => (props.fillB ? props.theme.white : props.theme[props.borderColor])};
  font-family: Nunito;
  font-weight: bold;
  border-radius: 4px;
  position: absolute;
  top: 30px;
  z-index: 2;
  left: 0;
`

const variants: {
  [key in ProgressVariant]: { color: Colors; fill: boolean }
} = {
  todo: {
    color: 'black',
    fill: false,
  },
  pending: {
    color: 'black',
    fill: false,
  },
  completed: {
    color: 'green',
    fill: true,
  },
  validating: {
    color: 'green',
    fill: true,
  },
  supportValidating: {
    color: 'black',
    fill: false,
  },
  waitChange: {
    color: 'black',
    fill: true,
  },
  disabled: {
    color: 'greyDark2',
    fill: false,
  },
}

const ProgressBarItem: React.FC<CardProps & { index: number }> = ({
  children,
  variant,
  index,
  ...otherProps
}) => (
  <Container progressBarColor={variants[variant].color}>
    <Card variant={variant} {...otherProps}>
      {children}
    </Card>
    <ProgressBarSquare fillB={variants[variant].fill} borderColor={variants[variant].color}>
      {variant === 'completed' ? <Icon name="check" color="white" size={16} /> : index + 1}
    </ProgressBarSquare>
  </Container>
)

export default ProgressBarItem
