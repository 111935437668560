import React from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import styled from 'styled-components'

const Wrapper = styled.div<{ hasError: boolean }>`
  border-radius: 2px;
  border: 1px solid ${props => (props.hasError ? props.theme.pink : props.theme.black)};

  .PhoneInputInput {
    font-family: Nunito;
    line-height: 26px;
    font-size: 16px;
    border-radius: 0 2px 2px 0;
    margin: 0;
    border: none;

    background-color: white;
    padding: 16px;

    color: ${props => props.theme.black};
  }

  .PhoneInputInput::placeholder {
    font-family: Nunito;
    line-height: 26px;
    font-size: 16px;
    color: ${props => props.theme.greyDark2};
  }

  .PhoneInputInput:focus {
    outline: none;
  }

  .PhoneInputCountry {
    padding: 0 15px 0 15px;
    margin: 0;
    border-radius: 2px 0 0 2px;
    background-color: ${props => props.theme.greyLightN3};
  }

  .PhoneInputCountryIcon {
    box-shadow: none;
  }
`

const InputPhone = ({ hasError, style, ...props }: any) => (
  <Wrapper hasError={hasError} style={style}>
    <PhoneInput
      defaultCountry="FR"
      countryOptionsOrder={['FR', 'BE', 'ES', 'GB', 'US', '|', '...']}
      {...props}
    />
  </Wrapper>
)

export default InputPhone
