import React, { useMemo, useContext } from 'react'
import { map, get, camelCase } from 'lodash'

import Carousel, { CarouselItem } from 'ui/carousel'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Box from 'ui/box'
import { Colors } from 'ui/theme'

import wt1 from './wt1.png'
import wt2 from './wt2.png'
import wt3 from './wt3.png'
import wt4 from './wt4.png'
import wtInstitution1 from './wt-institution1.png'
import wtInstitution2 from './wt-institution2.png'
import wtInstitution3 from './wt-institution3.png'

import { AuthContext } from 'App'
import User from 'types/user'

function getWelcomeTour(userRole: User['role'], name: string) {
  const wts = {
    userInstitution: [
      {
        title: 'Bienvenue sur votre espace !',
        text:
          'Dans votre nouvel espace vous pourrez gérer toutes les démarches administratives liées à l’admission dans votre lieu de vie. Voici un petit tour d’horizon des outils à votre disposition sur la plateforme.',
        image: wtInstitution1,
      },
      {
        title: 'Suivez l’admission de vos usagers',
        text:
          'Une fois le dossier consulté et les premières rencontres effectuées vous pouvez suivre la progression de l’admission de l’usager en détail.',
        image: wtInstitution2,
      },
      {
        title: 'Ayez une vue d’ensemble de votre établissement',
        text:
          'Consultez à tout moment les dossiez de vos résidents et conservez une fiche établissement à jour afin de continuer de recevoir des dossiers de qualité.',
        image: wtInstitution3,
      },
    ],
    default: [
      {
        title: `Bienvenue sur votre espace personnel, <b>${name} !</b>`,
        text:
          "Dans votre nouvel espace vous pouvez gérer toutes les démarches administratives et prise de rendez-vous nécéssaires pour visiter les lieux de vie. Voici un petit tour d'horizon des outils à votre disposition sur la plateforme.",
        image: wt1,
      },
      {
        title: '<b>Votre accueil : </b> suivez la progression du dossier',
        text:
          'Gardez un oeil sur les étapes clés liées à la création de votre formulaire d’admission. Avant de compléter une étape, vous pouvez consulter les sujets qui vont être abordés.',
        image: wt2,
      },
      {
        title: '<b>Votre dossier : </b> vous êtes guidé à chaque étape',
        text:
          "A tout instant, vous savez où vous en êtes dans la préparation du dossier d'admission. Vous êtes autonome pour remplir chaque étape du dossier. Notre équipe support reste évidemment disponible par téléphone ou e-mail en cas de besoin.",
        image: wt3,
      },
      {
        title: '<b>Etablissement : </b> vous avez le choix',
        text:
          'Notre équipe vous propose les meilleurs établissements succeptibles d’accueillir le protégé. Le choix final vous appartient.',
        image: wt4,
        bg: 'yellow',
      },
    ],
  } as { [key in string]: { title: string; text: string; image: string; bg?: string }[] }

  return wts[camelCase(userRole)] || wts['default']
}

export default function WelcomeTour({ onFinish, name }: { name: string; onFinish: () => void }) {
  const { auth } = useContext(AuthContext)
  const wt = useMemo(() => getWelcomeTour(get(auth.user, 'role', 'user'), name), [name])

  return (
    <Carousel withButton onFinish={onFinish}>
      {map(wt, ({ title, text, image, bg }, i) => (
        <CarouselItem src={image} bg={bg as Colors} key={`carousel-item-${i}`}>
          <Box fd="c">
            <Title fs={28} align="center" style={{ paddingBottom: '8px' }}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
            <Text fs={18} align="center">
              <span dangerouslySetInnerHTML={{ __html: text }} />
            </Text>
          </Box>
        </CarouselItem>
      ))}
    </Carousel>
  )
}
