import { filter, get, orderBy } from 'lodash'

import { Candidacy } from 'types/candidacy'
import { IconProps } from 'ui/icon'

export function getAdmissionTimeline(candidacy: Candidacy) {
  const timeLine: {
    title: string
    date?: string
    text: string
    icon?: IconProps['name']
    hidden?: boolean
    hasButtons?: boolean
  }[] = [
    {
      title: 'DOSSIER ACCEPTÉ !',
      date: get(candidacy, 'institutionAcceptAt'), // institution_accept
      text: `Vous avez accepté le dossier de ${candidacy.patientName}. L’usager à été informé de votre décision et doit maintenant donner la sienne.`,
    },
    {
      title: 'L’USAGER A ANNULÉ SA CANDIDATURE',
      date: get(candidacy, 'ownerRefusalAt'), // owner_refusal
      text: 'L’usager à annulé sa candidature.',
      icon: 'close',
    },
    {
      title: 'L’USAGER A CONFIRMÉ SA CANDIDATURE',
      date: get(candidacy, 'waitMdphAt'), // wait_mdph
      text: 'L’usager a confirmé sa candidature. Il doit transmettre son dosier à la MDPH.',
    },
    {
      title: 'EN ATTENTE DE L’ACCORD DE PRISE EN CHARGE',
      date: get(candidacy, 'mdphAcceptAt'), // mdph_accept
      text: 'L’usager est en attente de votre accord de prise en charge.',
      hasButtons: true,
      hidden:
        !!get(candidacy, 'institutionEngageAt') || !!get(candidacy, 'institutionEngageRefusalAt'),
    },
    {
      title: 'MDPH ACCEPTÉ',
      date: get(candidacy, 'mdphAcceptAt'), // mdph_accept
      text: 'MDPH accepté.',
    },
    {
      title: 'MDPH REFUSÉ',
      date: get(candidacy, 'mdphRefusalAt'), // mdph_refusal
      text: 'MDPH refusé.',
      icon: 'close',
    },

    {
      title: 'ACCORD DE PRISE EN CHARGE TRANSMIS',
      date: get(candidacy, 'institutionEngageAt'), // institution_engage
      text: `L’accueil de l’usager est prévu le ${
        candidacy.engageDateProposition
          ? new Date(candidacy.engageDateProposition).toLocaleDateString()
          : ''
      }. L’accord de prise en charge lui a été envoyé.`,
      hidden: !!get(candidacy, 'waitFinancialAt') || !!get(candidacy, 'ownerEngageRefusalAt'),
    },
    {
      title: 'REFUS DE LA PRISE EN CHARGE',
      date: get(candidacy, 'institutionEngageRefusalAt'), // institution_engage_refusal
      text: 'Vous avez refusé la prise en charge. L’usager a été prévenu.',
      icon: 'close',
    },
    {
      title: 'L’USAGER A REFUSÉ LA PRISE EN CHARGE',
      date: get(candidacy, 'ownerEngageRefusalAt'), // owner_engage_refusal
      text: 'L’usager a refusé la prise en charge',
      icon: 'close',
    },
    {
      title: 'EN ATTENTE DE DEMANDE DE FINANCEMENT',
      date: get(candidacy, 'waitFinancialAt'), // wait_financial
      text:
        'L’usager a transmis le dossier à la CPAM. La demande de financement est en train d’être étudiée.',
    },
    {
      title: 'L’USAGER A ACCEPTÉ LA DATE DE PRISE EN CHARGE',
      date: get(candidacy, 'waitFinancialAt'), //  wait_financial
      text: `L’accueil de l’usager est prévu le ${
        candidacy.engageDateProposition
          ? new Date(candidacy.engageDateProposition).toLocaleDateString()
          : ''
      }.`,
    },
  ]

  const filterTimeline = filter(timeLine, item => !!item.date && !item.hidden) as {
    title: string
    date: string
    text: string
    icon?: IconProps['name']
    hasButtons?: boolean
  }[]

  return orderBy(filterTimeline, [item => new Date(item.date)], ['desc'])
}
