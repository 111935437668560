import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { map, includes, get } from 'lodash'

type Option = {
  value: string
  label: string
}

const StyledSelect = styled(Select).attrs({
  classNamePrefix: 'ReactSelect',
  menuPlacement: 'auto',
})<{ hasError: boolean; disabled: boolean }>`
  .ReactSelect__control {
    font-family: Nunito;
    border-radius: 2px;
    border: 1px solid ${props => (props.hasError ? props.theme.pink : props.theme.black)};
    background-color: ${props => (props.disabled ? props.theme.greyLight3 : props.theme.white)};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    padding: 16px;
    box-shadow: none;
    min-height: 60px;

    color: ${props => props.theme.black};

    &:hover {
      border: 1px solid ${props => props.theme.black};
    }

    .ReactSelect__single-value {
      color: ${props => props.theme.black};
    }

    .ReactSelect__value-container {
      padding: 0;

      .ReactSelect__placeholder {
        color: ${props => props.theme.greyDark2};
        font-family: Nunito;
        line-height: 26px;
        font-size: 16px;
        padding: 0;
      }
      .ReactSelect__value {
        line-height: 26px;
        font-size: 16px;
        color: ${props => props.theme.black};
      }
    }

    .ReactSelect__indicator {
      color: ${props => props.theme.black};
      padding: 0 8px 0 0;
    }

    .ReactSelect__indicator-separator {
      display: none;
    }
  }

  .ReactSelect__menu {
    border: 1px solid ${props => props.theme.black};
    padding: 0;
    border-radius: 2px;
    font-family: Nunito;
    line-height: 26px;
    font-size: 16px;
    color: ${props => props.theme.black};

    .ReactSelect__menu-list {
      padding: 0;

      .ReactSelect__option {
        padding: 16px;
      }

      .ReactSelect__option--is-selected {
        color: ${props => props.theme.black};
        background-color: ${props => props.theme.greenLight2};
      }

      .ReactSelect__option--is-focused {
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
      }
    }
  }
`

export default function Dropdown({ ...inputProps }) {
  return (
    <StyledSelect
      {...inputProps}
      value={inputProps.options.filter((option: Option) =>
        inputProps.value instanceof Array
          ? includes(inputProps.value, option.value)
          : option.value === inputProps.value,
      )}
      onChange={(selectedOption: Option | Option[] | null) =>
        inputProps.onChange(
          selectedOption instanceof Array
            ? map(selectedOption, option => option.value)
            : get(selectedOption, 'value'),
        )
      }
      isDisabled={inputProps.disabled}
      menuPosition="fixed"
      minMenuHeight={200}
    />
  )
}
