import React, { useState } from 'react'

import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Button from 'ui/button'
import { askResetPassword } from 'api/user'

export default function AskResetPassword() {
  const [{ error, success }, setState] = useState({
    loading: false,
    error: false,
    success: false,
  })

  function handleSubmit(e: any) {
    e.preventDefault()

    setState({ loading: true, error: false, success: false })

    askResetPassword(new FormData(e.target))
      .then(() => setState({ loading: false, error: false, success: true }))
      .catch(() => setState({ loading: false, error: true, success: false }))

    return false
  }

  return (
    <>
      <Title bold fs={28} style={{ paddingBottom: '8%' }}>
        Mot de passe oublié ?
      </Title>
      {!success ? (
        <>
          <Text fs={16} style={{ paddingBottom: '15%' }}>
            Entrez l’adresse e-mail que vous avez utilisé lorsque vous avez rejoint, et laissez-vous
            guider par les instructions.
          </Text>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Group style={{ paddingBottom: '5%' }}>
              <Label htmlFor="email" required style={{ paddingBottom: '16px' }}>
                E-mail
              </Label>
              <Input
                id="email"
                name="user[email]"
                type="email"
                required
                placeholder="Saisissez votre e-mail"
              />
            </Group>
            <Text
              fs={16}
              color="pink"
              style={{ paddingBottom: '16px', visibility: error ? 'visible' : 'hidden' }}
            >
              Une erreur est survenue, veuillez réessayer.
            </Text>
            <Button type="submit" style={{ width: '100%', margin: '12% 0 10% 0' }}>
              Continuer
            </Button>
          </form>
        </>
      ) : (
        <>
          <Text fs={16} style={{ paddingBottom: '15%' }}>
            Un e-mail vient d'être envoyé avec les instructions.
            <br />
            S'il n'apparait pas, vérifiez vos spams.
          </Text>
          <Button
            onClick={() => setState({ loading: false, error: false, success: false })}
            style={{ width: '100%', margin: '12% 0 10% 0' }}
          >
            Je n'ai pas reçu l'e-mail
          </Button>
        </>
      )}
    </>
  )
}
