import React, { useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'

import Modal from 'ui/modal'
import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'
import { FormBody } from 'components/pages/form'

import { rejectCandidacy } from 'api/candidacy'
import { useToasts } from 'hooks/useToaster'
import { Form } from 'types/form'

export type RejectCandidacyPayload = {
  admissionRecordInstitution: {
    rejectReason: string
    rejectReasonMessage: string
  }
}

const FORM: Form = [
  [
    {
      name: 'rejectReason',
      type: 'select',
      label: 'Motif',
      placeholder: 'Sélectionnez un motif',
      required: true,
      options: [
        {
          value: 'hight_autonomy',
          label:
            'Le résident a un niveau d’autonomie trop élevé en comparaison de la population accueillie.',
        },
        {
          value: 'high_dependency',
          label:
            'Le résident a un niveau de dépendance trop élevé en comparaison de la population accueillie',
        },
        {
          value: 'behavior_trouble',
          label:
            'Les troubles du comportements sont trop importants au regard de ce que l’institution à l’habitude de prendre en charge',
        },
        {
          value: 'need_medical_attention',
          label:
            'Les besoins en soins médicaux sont trop importants par rapport à ce que l’institution est en mesure de prendre en charge',
        },
        {
          value: 'no_places',
          label:
            'Il n’y a pas actuellement de place au sein de l’établissement et la liste d’attente est complète.',
        },
        {
          value: 'departement_only',
          label:
            'Certains établissements ont développé des partenariats avec des départements français et ils accueillent uniquement des résidents venant de ces départements. (Orientation FAM et FO)',
        },
      ],
    },
    null,
  ],
  [
    {
      name: 'rejectReasonMessage',
      type: 'textarea',
      label: 'Contenu du message',
      placeholder: 'Précisez ici',
      required: true,
    },
  ],
]

export default function RejectCandidacy({
  candidacy,
  opened,
  onClose,
  onSuccess,
}: {
  candidacy: {
    id: string
    patientName: string
  }
  opened: boolean
  onClose: () => void
  onSuccess: () => void
}) {
  const { addToast, addGenericErrorToast } = useToasts()
  const [loading, setLoader] = useState(false)
  const methods = useForm<RejectCandidacyPayload>({
    defaultValues: {
      admissionRecordInstitution: {
        rejectReasonMessage: `Bonjour, Après étude du dossier de ${candidacy.patientName} nous ne pouvons pas donner suite à son accueil dans notre établissement. En effet, ...`,
      },
    },
  })

  const onSubmit = (data: RejectCandidacyPayload) => {
    setLoader(true)

    rejectCandidacy(candidacy.id, data)
      .then(() => setLoader(false))
      .then(() =>
        addToast({
          title: 'Succès !',
          type: 'success',
          message: 'Candidature rejetée !',
        }),
      )
      .then(onSuccess)
      .catch(() => {
        addGenericErrorToast()
        setLoader(false)
      })
  }

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      title={`Refuser le dossier de ${candidacy.patientName}`}
    >
      <FormContext {...methods}>
        <Box fd="c" as="form" autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          <Box ai="c" br={4} bg="redLight2" padding="32px 24px" style={{ marginBottom: '32px' }}>
            <Icon name="message" color="pink" size={22} containerStyle={{ marginRight: '24px' }} />
            <Text fs={16}>
              Il est important de préciser le motif du refus. Cela permettra à l’usager de mieux
              préparer ses prochaines demandes.
            </Text>
          </Box>
          <FormBody<RejectCandidacyPayload>
            fields={FORM}
            subForm="admissionRecordInstitution"
            hasId={false}
          />
          <Box jc="fe" padding="16px 0 0 0">
            <Button variant="outline" onClick={onClose} style={{ marginRight: '16px' }}>
              Annuler
            </Button>
            <Button variant="danger" type="submit">
              {loading ? (
                <Loader size={12} />
              ) : (
                <>
                  Envoyer le message
                  <Icon
                    name="arrow"
                    color="white"
                    size={20}
                    containerStyle={{ marginLeft: '8px' }}
                  />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </FormContext>
    </Modal>
  )
}
