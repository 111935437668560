import { AxiosResponse } from 'axios'
import { reduce } from 'lodash'

import request from './request'
import { Admission, AdmissionStatus } from 'types/admission'
import { InstitutionCandidacy } from 'types/candidacy'

export function getAdmissionStatus(admissionRecordId: string) {
  return request
    .get<any, AxiosResponse<any>>(`/admission_records/${admissionRecordId}/candidacies_progress`)
    .then(({ data }) => ({
      admissionStatus: data.candidaciesProgress as AdmissionStatus,
    }))
}

export function getAdmission(admissionRecordId: string, params?: { current: boolean }) {
  return request
    .get<any, AxiosResponse<any>>(
      `/admission_records/${admissionRecordId}/candidacies${
        params?.current ? '?current=true' : ''
      }`,
    )
    .then(
      ({ data }) =>
        ({
          ...data.admissionRecord,
          candidacies: reduce(
            data.admissionRecord.candidacies,
            (acc, item) => [
              ...acc,
              {
                ...item,
                ...item.establishment,
                candidacyId: item.id,
                candidacyStatus: item.ariState,
                engageDateProposition: item.engageDateProposition,
                kitListId: item.institutionKitListId,
              },
            ],
            [] as InstitutionCandidacy[],
          ),
        } as Admission),
    )
}

export function candidateForInstitutions(selectedInstitutions: string[]) {
  return request.post('/admission_record_institutions/ask_owner', {
    ids: selectedInstitutions,
  })
}

export function acceptInstitutionsDecisions(
  admissionRecordId: string,
  selectedInstitutions: string[],
) {
  return request.post('/admission_record_institutions/owner_select_institutions', {
    admissionRecordId,
    acceptIds: selectedInstitutions,
  })
}

export function addFileMDPH(admissionRecordId: string, files: FileList) {
  const f: FormData = new FormData()
  f.append('mdph', files[0])

  return request.post(`/admission_records/${admissionRecordId}/mdph`, f, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function selectInstitutionsForMDPH(
  admissionRecordId: string,
  selectedInstitutions: string[],
) {
  return request.post(`/admission_records/${admissionRecordId}/mdph_candidacies_accept`, {
    candidaciesIds: selectedInstitutions,
  })
}

export function institutionEngage(
  candidacyId: string,
  engageDateProposition: string,
  institutionKitListId: string,
) {
  return request.post(`/admission_record_institutions/${candidacyId}/institution_engage`, {
    engageDateProposition,
    institutionKitListId,
  })
}

export function institutionEngageRefusal(candidacyId: string, motif: string) {
  return request.post(`/admission_record_institutions/${candidacyId}/institution_engage_refusal`, {
    motif,
  })
}

export function ownerEngage(candidacyId: string) {
  return request.post(`/admission_record_institutions/${candidacyId}/owner_engage`)
}

export function ownerEngageRefusal(candidacyId: string) {
  return request.post(`/admission_record_institutions/${candidacyId}/owner_engage_refusal`)
}

export function addFinancialFile(ariId: string, files: FileList, name: string) {
  const f: FormData = new FormData()
  f.append(`admission_record_institution[${name}]`, files[0])

  return request.post(`/admission_record_institutions/${ariId}/${name}`, f, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
