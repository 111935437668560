import { useReducer, Reducer, useEffect } from 'react'

export type FetchState<Success, Error> =
  | Fetching
  | Fetched<Success>
  | FailFetch<Error>
  | RefreshData<Success>

export type Fetching = {
  loading: true
  resource: undefined
  error: undefined
  refreshData: boolean
}
export type Fetched<Success> = {
  loading: false
  resource: Success
  error: undefined
  refreshData: boolean
}
export type FailFetch<Error> = {
  loading: false
  resource: undefined
  error: Error
  refreshData: boolean
}
export type RefreshData<Success> = {
  loading: true
  resource?: Success
  error: undefined
  refreshData: boolean
}

type Actions<S, E> =
  | {
      name: 'LOADING'
    }
  | { name: 'DONE'; payload: S }
  | { name: 'ERROR'; payload: E }
  | { name: 'REFRESH_DATA' }

function reducer<Success, Error>(
  state: FetchState<Success, Error>,
  action: Actions<Success, Error>,
): FetchState<Success, Error> {
  switch (action.name) {
    case 'LOADING':
      return {
        loading: true,
        resource: undefined,
        error: undefined,
        refreshData: state.refreshData,
      }
    case 'DONE':
      return { loading: false, resource: action.payload, error: undefined, refreshData: false }
    case 'ERROR':
      return { loading: false, resource: undefined, error: action.payload, refreshData: false }
    case 'REFRESH_DATA':
      return { loading: true, resource: state.resource, error: undefined, refreshData: true }
    default:
      return state
  }
}

export default function useResource<S, E>(
  base: FetchState<S, E> = {
    loading: true,
    resource: undefined,
    error: undefined,
    refreshData: false,
  },
  effect: () => Promise<S>,
  deps: any[] | undefined,
  disabled?: boolean,
) {
  const [state, dispatch] = useReducer<Reducer<FetchState<S, E>, Actions<S, E>>>(reducer, base)

  useEffect(() => {
    !disabled &&
      effect()
        .then(r => dispatch({ name: 'DONE', payload: r }))
        .catch((e: E) => dispatch({ name: 'ERROR', payload: e }))
  }, deps)

  useEffect(() => {
    if (!state.refreshData) return
    !disabled &&
      effect()
        .then(r => dispatch({ name: 'DONE', payload: r }))
        .catch((e: E) => dispatch({ name: 'ERROR', payload: e }))
  }, [state.refreshData, disabled])

  return [state, dispatch] as [FetchState<S, E>, typeof dispatch]
}
