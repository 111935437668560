import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Link from 'ui/typography/link'
import Input from 'ui/form/input'

import { success } from 'assets/illustrations'

import { AuthContext } from 'App'
import { useToasts } from 'hooks/useToaster'
import { sendSignature, resendCode } from 'api/otp'

export default function Confirm({ onNext, phone }: { onNext: () => void; phone: string }) {
  const { auth } = useContext(AuthContext)
  const { addToast, addGenericErrorToast } = useToasts()
  const { register, watch, handleSubmit } = useFormContext<{ confirmationCode: string }>()

  const onSubmit = (data: { confirmationCode: string }) => {
    return sendSignature(get(auth, 'selectedId', '0'), data.confirmationCode)
      .then(() => {
        addToast({
          title: 'Succès !',
          type: 'success',
          message: 'Dossier signé',
        })

        return onNext()
      })
      .catch(() => addGenericErrorToast())
  }

  return (
    <Box fd="c" ai="c" bg={'greenLight2'} padding="32px">
      <Box jc="c" style={{ marginBottom: '32px' }}>
        <img src={success} alt="succés" />
      </Box>
      <Box fd="c" style={{ height: '100px' }}>
        <Title bold spacingBottom="32px">
          Renseignez le code reçu par SMS :
        </Title>
        <Box padding="0 0 24px 0" f="0 1 auto">
          <Box
            ai="c"
            jc="c"
            bg="white"
            br={50}
            width="42px"
            height="42px"
            withBorder
            style={{ marginRight: '16px' }}
          >
            <Text>1</Text>
          </Box>
          <Box fd="c">
            <Text bold>Insérez le code reçu afin de signer le formulaire</Text>
            <Text>
              Une fois cette étape passée, votre patient recevra un e-mail et il pourra continuer
              son processus d&apos;admission.
            </Text>
            <Link
              underline
              onClick={() => {
                resendCode(get(auth, 'selectedId', '0'))
                  .then(() =>
                    addToast({
                      title: 'Succès !',
                      type: 'success',
                      message: 'Le code de vérification a bien été renvoyé',
                    }),
                  )
                  .catch(e =>
                    e.response.status === 422
                      ? addToast({
                          title: 'Erreur !',
                          type: 'error',
                          message:
                            'Le nombre maximal de tentatives a été atteint, veuillez réessayer dans quelques minutes',
                        })
                      : addGenericErrorToast(),
                  )
              }}
            >
              Renvoyer le code de vérification {phone && `(${phone})`}
            </Link>

            <Input
              id="confirmationCode"
              name="confirmationCode"
              placeholder="Code de vérification"
              style={{ maxWidth: '70%' }}
              ref={register()}
            />
          </Box>
        </Box>
      </Box>
      <Button
        variant="primary"
        disabled={!watch('confirmationCode')}
        onClick={handleSubmit(onSubmit)}
        style={{ marginTop: '20px', width: '100%' }}
      >
        Signer le dossier d’admission
        <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
      </Button>
    </Box>
  )
}
