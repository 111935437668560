import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { get } from 'lodash'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import { LinkButton } from 'ui/button'
import Icon from 'ui/icon'
import File from 'ui/form/file'
import Loader from 'ui/loader'
import { Controls } from 'components/pages/form'

import { AuthContext } from 'App'
import useResource, { Fetched } from 'hooks/useResource'
import { useToasts } from 'hooks/useToaster'
import { addFinancialFile, getAdmission } from 'api/admission'
import { InstitutionCandidacy } from 'types/candidacy'

export default function StepCCAS() {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const { addToast, addGenericErrorToast } = useToasts()
  const [resourceState] = useResource<InstitutionCandidacy, {}>(
    undefined,
    () =>
      getAdmission(get(auth, 'selectedId', '0'), { current: true }).then(
        data => get(data.candidacies, 0) as InstitutionCandidacy,
      ),
    [],
  )
  const { register, control, handleSubmit, errors } = useForm<{ files?: FileList }>()
  const { section, step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)

  if (resourceState.loading || !!resourceState.error) {
    return (
      <Box ai="c" jc="c">
        <Loader />
      </Box>
    )
  }
  const { resource } = resourceState as Fetched<InstitutionCandidacy>

  return (
    <Box
      fd="c"
      style={{
        minHeight: '100%',
      }}
    >
      <Box
        fd="c"
        padding="32px"
        style={{
          overflowY: 'scroll',
        }}
      >
        <Box
          f="initial"
          ai="c"
          jc="sb"
          bg="greyLightN"
          br={4}
          padding="24px"
          style={{ marginBottom: '32px' }}
        >
          <Text fs={16}>
            Comment effectuer une demande de finacement CCAS ? Toutes les informations sont
            disponibles sur notre blog.
          </Text>
          <LinkButton
            style={{ marginLeft: '32px', flexShrink: 0 }}
            className="primary"
            href={'https://www.sahanest.fr/mdph#ccas'}
            target="_blank"
          >
            Consulter le blog
            <Icon name="arrow" size={14} rotate={-45} containerStyle={{ marginLeft: '8px' }} />
          </LinkButton>
        </Box>
        <Title fs={22} bold spacingBottom="8px">
          Envoyer la notification d’aide sociale
        </Title>
        <Text fs={16} opacity={0.6} spacingBottom="32px">
          Ajoutez la notification d’aide sociale (NAS) reçue par courrier. Vous pouvez la scanner
          puis l’ajouter ci-dessous. Même si l’avis est négatif nous avons besoin de votre document.
        </Text>
        {resourceState.loading ? (
          <Box ai="c" jc="c">
            <Loader />
          </Box>
        ) : (
          <form>
            <File
              name="files"
              id="files"
              forwardRef={register({
                required: 'Ce champ est requis',
              })}
              getValue={() => control.getValues('files') as FileList}
              resetFile={() => control.setValue('files', undefined)}
              uploadedFile={resource.financialCcas}
              disabled={!!resource.financialCcas}
              hasError={!!errors?.files}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: !!errors?.files ? 'visible' : 'hidden', marginTop: '8px' }}
            >
              Ce champ est requis
            </Text>
          </form>
        )}
      </Box>
      <Controls
        prevButton={{
          callback: () => history.push(`/admission/${section}/etape/${parsedStep - 1}`),
          label: 'Retour',
        }}
        nextButton={{
          callback: !!resource.financialCcas
            ? () => history.push(`/admission/${section}/etape/${parsedStep + 1}`)
            : handleSubmit((data: { files?: FileList }) =>
                addFinancialFile(resource.candidacyId, data.files as FileList, 'financial_ccas')
                  .then(() =>
                    addToast({
                      type: 'success',
                      title: 'Succès !',
                      message: 'Votre notification CCAS a bien été envoyé !',
                    }),
                  )
                  .then(() => history.push(`/admission/${section}/etape/${parsedStep + 1}`))
                  .catch(addGenericErrorToast),
              ),
          label: 'Continuer',
        }}
      />
    </Box>
  )
}
