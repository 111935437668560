import React from 'react'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Button from 'ui/button'
import Separator from 'ui/separator'

import { success } from 'assets/illustrations'
import Icon from 'ui/icon'

export default function Conclusion({
  title,
  text,
  buttonTitle,
  onNext,
}: {
  title: string
  text: string
  buttonTitle: string
  onNext: () => void
}) {
  return (
    <Box fd="c">
      <Box fd="c" padding="32px">
        <Box jc="c" style={{ marginBottom: '32px' }}>
          <img src={success} style={{ width: '300px' }} alt="success" />
        </Box>
        <Box fd="c" ai="c" padding="0 32px 32px 32px">
          <Title bold spacingBottom="32px" align="center">
            {title}
          </Title>
          <Text fs={16} align="center" style={{ paddingBottom: '24px' }}>
            {text}
          </Text>
        </Box>
      </Box>
      <Separator />
      <Box jc="c" ai="c" f="initial" padding="32px">
        <Button onClick={onNext}>
          {buttonTitle}
          <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
        </Button>
      </Box>
    </Box>
  )
}
