import React, { useEffect, useMemo, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import Box, { BoxWithHeader } from 'ui/box'
import Button from 'ui/button'
import Table from 'ui/table'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'

import { usePageName } from 'hooks/usePageName'
import useResource from 'hooks/useResource'
import { getCandidacies } from 'api/candidacy'
import { Candidacies } from 'types/candidacy'
import { AuthContext } from 'App'

export default function Candidacy() {
  const history = useHistory()
  const { setPageName } = usePageName()
  const { auth } = useContext(AuthContext)

  const [{ resource, loading }] = useResource<Candidacies, {}>(
    undefined,
    () => getCandidacies(undefined, auth.selectedInstitutionId),
    [auth.selectedInstitutionId],
  )
  const columns = useMemo(
    () => [
      {
        Header: 'Usager',
        accessor: 'patientName',
      },
      {
        Header: 'Handicap',
        accessor: 'handicap',
      },
      {
        Header: 'Envoyée le',
        accessor: 'askOwnerInstitution',
        width: 100,
        sortType: (rowA: Row, rowB: Row, columnId: string) => {
          const a = new Date(rowA.values[columnId]).getTime()
          const b = new Date(rowB.values[columnId]).getTime()

          return a === b ? 0 : a > b ? 1 : -1
        },
        Cell: ({ value }: { value: string }) => (
          <Text fs={16} color="black" opacity={0.5}>
            {value && new Date(value).toLocaleDateString()}
          </Text>
        ),
      },
      {
        Header: 'Contact',
        accessor: 'legalGuardianContact',
        width: 100,
        Cell: ({ value }: { value: string }) => (
          <Text fs={16} color="yellowDark">
            {value}
          </Text>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        width: 70,
        Cell: ({ value }: { value: string }) => (
          <Button
            variant="outline"
            size="small"
            onClick={() => history.push(`/candidatures/${value}`)}
          >
            Voir le dossier
          </Button>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  useEffect(() => {
    setPageName('Candidatures')
  }, [])

  return (
    <Box fd="c">
      <BoxWithHeader br={4} f="0 1 auto" style={{ display: 'block' }}>
        <Box>
          <Box ai="b">
            <Title fs={24} bold>
              Candidats
            </Title>

            {resource && (
              <Text opacity={0.5} style={{ marginLeft: '8px' }}>
                ({resource.candidacies.length})
              </Text>
            )}
          </Box>
        </Box>

        {loading ? (
          <Box padding="32px 32px 0 32px" ai="c" jc="c">
            <Loader />
          </Box>
        ) : (
          <Box fd="c" padding="32px 32px 0 32px">
            <Table
              columns={columns}
              data={resource ? resource.candidacies : []}
              noDataText="Pas de candidature en vue"
              noDataInfo="Elles apparaîtront ici lorsque des usagers auront envoyé une demande."
            />
          </Box>
        )}
      </BoxWithHeader>
    </Box>
  )
}
