import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Box from 'ui/box'
import Intro from 'components/pages/intro'

import Layout from '../layout'
import Step1 from './step1'
import Conclusion from '../conclusion'

export default function TakenCareStep() {
  const history = useHistory()
  const { section, step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)

  return (
    <Layout
      title="Accord de prise en charge"
      globalStep={4}
      steps={() => [
        {
          title: 'Introduction',
          Component: () => (
            <Box padding="32px">
              <Intro
                onNext={() => history.push(`/admission/${section}/etape/${parsedStep + 1}`)}
                sections={[
                  {
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                    text:
                      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas nemo natus debitis harum expedita repellendus accusamus dolorum. Recusandae, sequi numquam. Illum minima provident accusantium blanditiis voluptas numquam nam ipsum nihil!',
                  },
                  {
                    title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                    text:
                      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas nemo natus debitis harum expedita repellendus accusamus dolorum. Recusandae, sequi numquam. Illum minima provident accusantium blanditiis voluptas numquam nam ipsum nihil!',
                  },
                ]}
              />
            </Box>
          ),
        },
        {
          title: 'Établissement prêt à accueillir',
          Component: () => <Step1 />,
        },
        {
          title: 'Complet',
          Component: () => (
            <Conclusion
              title="Vous avez choisi votre établissement !"
              text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet unde earum ratione, sapiente pariatur, totam quam neque velit."
              buttonTitle="Aller à l'étape de demande de financement"
              onNext={() => history.push('/admission/financement/etape/1')}
            />
          ),
        },
      ]}
    />
  )
}
