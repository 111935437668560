import { Form } from 'types/form'
import { GENDER } from 'types/enums'
import { BLOOD_GROUP } from 'types/medical'

const PATIENT_FORM: Form = [
  [
    {
      name: 'firstName',
      type: 'text',
      label: 'Prénom du patient',
      placeholder: 'Saisissez le prénom du patient',
      required: true,
      disabled: true,
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Nom du patient',
      placeholder: 'Saisissez le nom du patient',
      required: true,
      disabled: true,
    },
  ],
  [
    {
      name: 'gender',
      type: 'select',
      label: 'Sexe',
      placeholder: 'Homme, Femme',
      required: true,
      disabled: true,
      options: [
        { value: GENDER.MALE, label: 'Homme' },
        { value: GENDER.FEMALE, label: 'Femme' },
      ],
    },
    {
      name: 'dateOfBirth',
      type: 'date',
      label: 'Date de naissance',
      placeholder: 'JJ/MM/AAAA',
      required: true,
      disabled: true,
    },
  ],
  [
    {
      name: 'contraception',
      type: 'radio',
      label: 'Contraception',
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        { label: 'Non', value: 'false' },
      ],
      required: true,
    },
    {
      name: 'size',
      type: 'number',
      label: 'Taille (cm)',
      placeholder: 'Saisissez la taille du patient',
      required: true,
    },
  ],
  [
    {
      name: 'weight',
      type: 'number',
      label: 'Poids (kg)',
      placeholder: 'Saisissez le poids du patient',
      required: true,
    },
    {
      name: 'ta',
      type: 'text',
      label: 'TA (mmHg)',
      placeholder: 'Saisissez la tension artérielle du patient',
      required: true,
    },
  ],
  [
    {
      name: 'fc',
      type: 'number',
      label: 'FC (bpm)',
      placeholder: 'Saisissez la frequence cardiaque du patient',
      required: true,
    },
    {
      name: 'bloodGroup',
      type: 'select',
      label: 'Groupe Sanguin',
      placeholder: 'Choisissez le groupe sanguin',
      options: [
        { label: 'A+', value: BLOOD_GROUP.A_POSITIVE },
        { label: 'A-', value: BLOOD_GROUP.AB_NEGATIVE },
        { label: 'B+', value: BLOOD_GROUP.B_POSITIVE },
        { label: 'B-', value: BLOOD_GROUP.B_NEGATIVE },
        { label: 'AB+', value: BLOOD_GROUP.AB_POSITIVE },
        { label: 'AB-', value: BLOOD_GROUP.AB_NEGATIVE },
        { label: 'O+', value: BLOOD_GROUP.O_POSITIVE },
        { label: 'O-', value: BLOOD_GROUP.O_NEGATIVE },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'certifyDoctor',
      type: 'checkbox',
      options: [
        {
          label:
            'Je certifie être le médecin du patient renseigné ci-avant et, à ce titre, être autorisé à divulguer des données de santé le concernant aux fins de sa prise en charge sanitaire ou sociale, et [et, le cas échéant, de la tenue d’un dossier le concernant, permettant de structurer les échanges entre les différents intervenants (responsables légaux, médecins et établissements) – voir question ci-dessous]',
          value: 'true',
        },
      ],
      required: true,
    },
  ],
]

export default PATIENT_FORM
