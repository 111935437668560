import { Form } from 'types/form'
import { ALLOWANCES } from 'types/financial'

const BENEFIT_FORM: Form = [
  [
    {
      name: 'allowances',
      type: 'select',
      label: 'Prestation(s) perçue(s)',
      placeholder: 'Sélectionner la/les prestation(s) perçue(s)',
      options: [
        {
          label: 'Aucune',
          value: ALLOWANCES.NONE,
        },
        { label: 'Allocation adulte handicapé (AAH)', value: ALLOWANCES.AAH },
        {
          label: 'Prestation complémentaire du handicap (PCH)',
          value: ALLOWANCES.PCH,
        },
        {
          label: "Notification d'Aide Sociale pour une personne FAM (NAS)",
          value: ALLOWANCES.NAS,
        },
        {
          label: 'Autres',
          value: ALLOWANCES.OTHER,
        },
      ],
      required: true,
      multi: true,
    },
  ],
  [
    {
      name: 'additionalAllowances',
      type: 'text',
      label: 'Si autre, précisez laquelle',
      placeholder: 'Saisissez la/les éventuels autres prestation(s) perçue(s)',
      required: false,
    },
  ],
]

export default BENEFIT_FORM
