import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'

import Box from 'ui/box'
import Navbar from 'ui/navbar'
import MenuItem from 'ui/menuItem'
import Link from 'ui/typography/link'
import Loader from 'ui/loader'
import { logout } from 'api/user'
import ProfilePicker from 'ui/profilePicker'
import { Colors } from 'ui/theme'
import { AuthContext } from 'App'
import { Auth } from 'types/user'
import { usePageName } from 'hooks/usePageName'

import logo from 'assets/logo-light.svg'
import useResource from 'hooks/useResource'
import { AdmissionStatus } from 'types/admission'
import { getAdmissionStatus } from 'api/admission'

export const Footer = ({ bg, textColor }: { bg?: Colors; textColor?: Colors }) => (
  <Box bg={bg || 'white'} height="70px" jc="fe" ai="c" padding="16px 32px">
    <Link
      color={textColor || 'black'}
      fs={14}
      style={{ marginRight: '16px' }}
      href="https://sahanest.fr/mentions-legales"
      target="_blank"
    >
      Mentions légales
    </Link>
    <Link
      color={textColor || 'black'}
      fs={14}
      style={{ marginRight: '16px' }}
      href="https://sahanest.fr/cgu"
      target="_blank"
    >
      Conditions générales d’utilisation de la plateforme
    </Link>
    <Link
      color={textColor || 'black'}
      fs={14}
      style={{ marginRight: '16px' }}
      href="https://sahanest.fr/cookies-policy"
      target="_blank"
    >
      Cookies Policy
    </Link>
    <Link
      color={textColor || 'black'}
      fs={14}
      style={{ marginRight: '16px' }}
      href="https://sahanest.fr/politique-donnees"
      target="_blank"
    >
      Politique de protection des données
    </Link>
  </Box>
)

export default function Layout({
  onDisconnect,
  onSwitch,
  toggleHelp,
  children,
  openCreateAdmissionModal,
}: {
  onDisconnect: () => void
  openCreateAdmissionModal: () => void
  toggleHelp: (open: boolean) => void
  onSwitch: (id: string, admissionRecords?: Auth['admissionRecords']) => void
  children: React.ReactNode
}) {
  const history = useHistory()
  const { auth, updateInstitutionId, updateAddEstablishment } = useContext(AuthContext)
  const userRole = get(auth.user, 'role')
  const { pageName } = usePageName()

  const institutionsList = useMemo(
    () =>
      auth?.institution?.map(({ info, id, habitatType }) => ({
        institutionName: info.institutionName,
        habitatType,
        id,
      })),
    [auth],
  )

  const getHabitatTypeById = (id: string) =>
    institutionsList?.find(inst => inst.id === id)?.habitatType || ''

  const currentHabitatType = useMemo(() => getHabitatTypeById(auth?.selectedInstitutionId || ''), [
    institutionsList,
    auth,
  ])

  const selectedId = useMemo(() => get(auth, 'selectedId', '0'), [auth])

  const [{ resource }] = useResource<{ admissionStatus: AdmissionStatus }, {}>(
    undefined,
    () => getAdmissionStatus(selectedId),
    [selectedId, currentHabitatType],
    currentHabitatType !== 'medical',
  )

  if (!auth) {
    return (
      <Box fd="r" ai="c" jc="c" height="100vh" width="100vh">
        <Loader />
      </Box>
    )
  }

  switch (userRole) {
    case 'doctor':
      return (
        <Box fd="r" height="100vh">
          <Box fd="c" ai="c" bg="green" padding="64px 0 42px 0" f="0 0 240px">
            <img src={logo} style={{ width: '123px' }} alt="logo" />
            <Box fd="c" width="100%" padding="50px 0 0 0" f="1 0 auto">
              <ProfilePicker
                currentId={auth.selectedId as string}
                list={auth.admissionRecords as []}
                onSelect={id => {
                  onSwitch(id)
                  history.push('/')
                }}
              />
            </Box>
            <Box fd="c" width="100%" padding="50px 0 0 0" f="1 0 auto"></Box>
            <Link icon="off" color="white" onClick={() => logout().then(() => onDisconnect())}>
              Se déconnecter
            </Link>
          </Box>
          <Box fd="c">
            <Navbar title={pageName} onClickHelp={() => toggleHelp(true)} />
            <Box style={{ overflowY: 'scroll', flex: '1' }} bg="greyLight3" fd="c">
              <Box padding="32px">{children}</Box>
              <Footer />
            </Box>
          </Box>
        </Box>
      )
    case 'user_institution':
      return (
        <Box fd="r" height="100vh">
          <Box fd="c" ai="c" bg="green" padding="64px 0 42px 0" f="0 0 240px">
            <img src={logo} style={{ width: '123px' }} alt="logo" />
            <Box fd="c" width="100%" padding="50px 0 0 0" f="1 0 auto">
              {institutionsList && auth.selectedInstitutionId && (
                <ProfilePicker
                  userRole="user_institution"
                  currentId={auth.selectedInstitutionId as string}
                  list={institutionsList}
                  onSelect={id => {
                    updateInstitutionId(id)
                    const type = getHabitatTypeById(id)
                    history.push(type === 'medical' ? '/' : '/mon-lieu/edition')
                  }}
                  onNew={() => {
                    updateAddEstablishment(true)
                    history.push('/institution/inscription')
                  }}
                />
              )}
              {currentHabitatType === 'medical' ? (
                <>
                  <MenuItem
                    icon="dashboard"
                    onClick={() => history.push('/candidatures')}
                    style={{ margin: '16px 0 0 0' }}
                    active={pageName === 'Candidatures'}
                  >
                    Candidatures
                  </MenuItem>
                  <MenuItem
                    icon="folder"
                    onClick={() => history.push('/admission')}
                    active={pageName === 'Suivi de l’admission'}
                  >
                    Suivi de l’admission
                  </MenuItem>
                  <MenuItem
                    icon="institution"
                    onClick={() => history.push('/mon-etablissement')}
                    active={pageName === 'Mon Établissement'}
                  >
                    Mon établissement
                  </MenuItem>
                </>
              ) : (
                <MenuItem
                  icon="institution"
                  onClick={() => history.push('/mon-lieu/edition')}
                  style={{ margin: '16px 0 0 0' }}
                  active={pageName === 'Mon Lieu'}
                >
                  Mon lieu de vie
                </MenuItem>
              )}
            </Box>
            <Box fd="c" width="100%" padding="50px 0 0 0" f="1 0 auto"></Box>
            <Link icon="off" color="white" onClick={() => logout().then(() => onDisconnect())}>
              Se déconnecter
            </Link>
          </Box>
          <Box fd="c">
            <Navbar
              yellowButtonLabel="Mon Profil"
              title={pageName}
              onClick={() => history.push('/mon-compte')}
              onClickHelp={() => toggleHelp(true)}
            />
            <Box style={{ overflowY: 'scroll', flex: '1' }} bg="greyLight3" fd="c">
              <Box style={{ padding: '32px' }}>{children}</Box>
              <Footer />
            </Box>
          </Box>
        </Box>
      )
    default:
      return (
        <Box fd="r" height="100vh">
          <Box fd="c" ai="c" bg="green" padding="64px 0 42px 0" f="0 0 240px">
            <img src={logo} style={{ width: '123px' }} alt="logo" />
            <Box fd="c" width="100%" padding="50px 0 0 0" f="1 0 auto">
              <ProfilePicker
                currentId={auth.selectedId as string}
                list={auth.admissionRecords as []}
                onSelect={id => {
                  onSwitch(id)
                  history.push('/')
                }}
                onNew={openCreateAdmissionModal}
              />
              <MenuItem
                icon="dashboard"
                onClick={() => history.push('/')}
                style={{ margin: '16px 0 0 0' }}
                active={pageName === 'Accueil'}
              >
                Accueil
              </MenuItem>
              <MenuItem
                icon="folder"
                onClick={() => history.push('/administrative/etape/1')}
                active={pageName === 'Mon formulaire'}
              >
                Mon formulaire
              </MenuItem>
              <MenuItem
                onClick={() => history.push('/admission/lieux-de-vie/etape/1')}
                active={pageName === 'Admission'}
                disabled={resource?.admissionStatus === 'disabled'}
                icon="institution"
              >
                Admission
              </MenuItem>
            </Box>
            <Link icon="off" color="white" onClick={() => logout().then(() => onDisconnect())}>
              Se déconnecter
            </Link>
          </Box>
          <Box fd="c">
            <Navbar
              yellowButtonLabel="Mon Profil"
              title={pageName}
              onClick={() => history.push('/mon-compte')}
              onClickHelp={() => toggleHelp(true)}
            />
            <Box style={{ overflowY: 'scroll', flex: '1' }} bg="greyLight3" fd="c">
              <div style={{ padding: '32px' }}>{children}</div>

              <Footer />
            </Box>
          </Box>
        </Box>
      )
  }
}
