import React from 'react'
import styled from 'styled-components'

import Box from '../../box'
import Button from '../../button'
import Icon, { IconProps } from '../../icon'
import Text from '../../typography/text'

interface Props {
  title: string
  text: string
  icon: IconProps['name']
  onClick?: () => void
  style?: React.CSSProperties
}

const Container = styled(Box).attrs({ ai: 'c' })``

const CardButton: React.FC<Props> = ({ title, text, icon, style, onClick }) => (
  <Button onClick={onClick} variant="card" type="button" style={{ ...style, padding: '32px' }}>
    <Container>
      <Icon
        name={icon}
        size={28}
        color="black"
        bg="greyLightN"
        br={4}
        cp="18px"
        containerStyle={{ marginRight: '24px' }}
      />
      <Box fd="c" padding="0 24px 0 0">
        <Text fs={18} bold>
          {title}
        </Text>
        <Text className="subText">{text}</Text>
      </Box>
      <Icon className="arrowIcon" name="arrow" size={20} color="green" />
    </Container>
  </Button>
)

export default CardButton
