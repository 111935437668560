import React, { useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'

import Modal from 'ui/modal'
import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'

import { FormBody } from 'components/pages/form'

import { requestAdditionalInformationCandidacy } from 'api/candidacy'
import { useToasts } from 'hooks/useToaster'
import { Form } from 'types/form'

export type RequestAdditionalInformationPayload = {
  admissionRecordInstitution: {
    supportQuestion: string
  }
}

const FORM: Form = [
  [
    {
      name: 'supportQuestion',
      type: 'textarea',
      label: 'Contenu du message',
      placeholder: 'Précisez ici',
      required: true,
    },
  ],
]

export default function RequestAdditionalInformation({
  candidacy,
  opened,
  onClose,
}: {
  candidacy: {
    id: string
    patientName: string
  }
  opened: boolean
  onClose: () => void
}) {
  const { addToast, addGenericErrorToast } = useToasts()
  const [loading, setLoader] = useState(false)
  const methods = useForm<RequestAdditionalInformationPayload>({
    defaultValues: {
      admissionRecordInstitution: {
        supportQuestion: `Bonjour, Après étude du dossier de ${candidacy.patientName} nous ne pouvons pas vous donner une date pour la visite de l’établissement. En effet, ...`,
      },
    },
  })

  const onSubmit = (data: RequestAdditionalInformationPayload) => {
    setLoader(true)

    requestAdditionalInformationCandidacy(candidacy.id, data)
      .then(() => setLoader(false))
      .then(() =>
        addToast({
          title: 'Succès !',
          type: 'success',
          message: 'Demande envoyée !',
        }),
      )
      .then(onClose)
      .catch(() => {
        addGenericErrorToast()
        setLoader(false)
      })
  }

  return (
    <Modal onClose={onClose} opened={opened} title="Demander des informations complémentaires">
      <FormContext {...methods}>
        <Box fd="c" as="form" autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          <Box ai="c" br={4} bg="greyLightN" padding="32px 24px" style={{ marginBottom: '32px' }}>
            <Icon name="message" color="green" size={22} containerStyle={{ marginRight: '24px' }} />
            <Text fs={16}>
              Essayez d’être le plus clair possible dans votre demande afin que l’usager puisse vous
              fournir les informations demandées.
            </Text>
          </Box>
          <FormBody<RequestAdditionalInformationPayload>
            fields={FORM}
            subForm="admissionRecordInstitution"
            hasId={false}
          />
          <Box jc="fe" padding="16px 0 0 0">
            <Button variant="outline" onClick={onClose} style={{ marginRight: '16px' }}>
              Annuler
            </Button>
            <Button type="submit">
              {loading ? (
                <Loader size={12} />
              ) : (
                <>
                  Envoyer le message
                  <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </FormContext>
    </Modal>
  )
}
