import React, { useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import CardDoc from 'ui/card/doc'

const Label = styled.label`
  position: relative;
  font-family: Nunito;
  font-size: 16px;
  line-height: 26px;
  color: ${props => props.theme.black};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  flex: 1;
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 3;
`

export default function InlineFile({
  id,
  label,
  name,
  forwardRef,
  uploadedFile,
  getValue,
  hasError,
  required,
  missing,
  ...inputProps
}: {
  id: string
  name: string
  label: string
  uploadedFile?: string
  forwardRef?:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined
    | void
  getValue?: () => FileList
  resetFile?: () => void
  hasError?: boolean
  missing?: boolean
} & Omit<React.ComponentProps<'input'>, 'ref'>) {
  const inputRef = (forwardRef as any) || React.createRef<HTMLInputElement>()
  const [filename, setFilename] = useState<string>(
    get(inputRef, 'current.value', uploadedFile || '')
      .split(/(\\|\/)/g)
      .pop(),
  )

  return (
    <Label>
      <Input
        id={id}
        name={name}
        type="file"
        ref={inputRef}
        {...inputProps}
        onChange={e => {
          const controlledFilename = getValue ? get(getValue(), '[0].name', '') : ''

          setFilename(
            get(inputRef, 'current.value', controlledFilename)
              .split(/(\\|\/)/g)
              .pop(),
          )

          inputProps.onChange && inputProps.onChange(e)
        }}
      />
      <CardDoc
        docName={label}
        filename={filename}
        hasError={hasError}
        missing={missing}
        required={required}
      />
    </Label>
  )
}
