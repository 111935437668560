import React from 'react'

import Layout from '../../edit/blocks/layout'
import { Form } from 'types/form'
import { SOCIAL_ENGINIERING_OPTIONS } from 'constants/form'

const CANDIDATE_FORM: Form = [
  [
    {
      name: 'engineering',
      type: 'checkbox',
      label: 'Ingénierie sociale nécessaire',
      required: false,
      vertical: true,
      options: SOCIAL_ENGINIERING_OPTIONS,
    },
  ],
  [
    {
      name: 'accompanyingEngineering',
      type: 'select',
      label: "Accompagnement dans l'ingénierie sociale proposée par le porteur de projet",
      placeholder: '',
      required: false,
      multi: false,
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        { label: 'Non', value: 'false' },
      ],
    },
  ],
  [
    {
      name: 'engineeringInfos',
      type: 'textarea',
      label: '',
      placeholder:
        "Décrivez comment vous structurez et accompagnez la réflexion et la validation d'une candidature dans votre lieu de vie...",
      required: false,
    },
  ],
]

export default function InclusiveCandidateProcess() {
  return (
    <Layout
      title="Parcours de candidature"
      subTitle="Expliquez le parcours de candidature afin d’intégrer votre lieu de vie"
      ctaText="Enregistrer et quitter"
      hideCTAIcon
      subText=""
      help={{
        icon: 'subscription',
        title: "Décrivez les grandes étapes d'une candidature chez vous",
        text:
          "Décrivez par quelles étapes devront passer les candidats pour pouvoir intégrer votre lieu de vie et s'intégrer au projet de vie sociale et partagée de vos habitants.",
      }}
      fields={CANDIDATE_FORM}
      subForm="inclusiveCandidateProcess"
    />
  )
}
