import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { useForm } from 'react-hook-form'

import { AuthContext } from 'App'
import Box, { BoxWithHeader } from 'ui/box'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import Input from 'ui/form/input'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Separator from 'ui/separator'

import { patch, deleteUser } from 'api/user'
import { useToasts } from 'hooks/useToaster'
import { usePageName } from 'hooks/usePageName'

const ErrorMessage = styled(Text).attrs({ fs: 16, color: 'pink' })<{
  hasError: boolean
}>`
  visibility: ${props => (props.hasError ? 'visible' : 'hidden')};
`

export default function Profile() {
  const {
    auth: { user },
    updateUser,
  } = useContext(AuthContext)
  const { addToast, addGenericErrorToast } = useToasts()
  const { register, handleSubmit, watch, reset, errors } = useForm<{
    email: string
    currentPassword: string
    password: string
    passwordConfirmation: string
  }>({
    defaultValues: {
      ...user,
    },
  })
  const { setPageName } = usePageName()
  const isRequired = !!watch('password') || !!watch('passwordConfirmation')

  useEffect(() => {
    setPageName('Mon profil')
  }, [])

  const onSubmit = (data: {
    currentPassword: string
    password: string
    passwordConfirmation: string
  }) => {
    patch(`${get(user, 'id')}`, data)
      .then(res => {
        addToast({
          type: 'success',
          title: 'Succès !',
          message: 'Votre profil a été mis à jour avec succès !',
        })
        updateUser(res.user)
        reset({ ...res.user })
      })
      .catch(() => {
        addGenericErrorToast()
        reset()
      })
  }

  return (
    <Box gutter="negative" style={{ maxHeight: '100%' }}>
      <BoxWithHeader
        as="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        br={4}
        style={{
          margin: '0 15px',
          alignSelf: 'flex-start',
        }}
      >
        <Box>
          <Title fs={24} bold>
            Édition des informations de votre compte
          </Title>
        </Box>

        <Box fd="c" padding="32px 32px 0 32px">
          <Title fs={18} bold spacingBottom="20px">
            Informations de connexion
          </Title>
          <Text fs={16} color="black" opacity={0.5} spacingBottom="15px">
            C’est les informations que vous avez fournis lors de votre inscription et qui vous
            permettent d’accéder à votre compte.
          </Text>
          <Box gutter="negative" f="initial">
            <Group style={{ padding: '15px' }}>
              <Box fd="c">
                <Label htmlFor="email" style={{ paddingBottom: '16px' }}>
                  E-mail
                </Label>
                <Input
                  id="email"
                  name="email"
                  ref={register({ required: 'Ce champ est requis' })}
                />
              </Box>
              <ErrorMessage hasError={!!get(errors, `email`)}>
                {get(errors, `email.message`)}
              </ErrorMessage>
            </Group>
            <Group style={{ padding: '15px' }} />
          </Box>
          <Box padding="20px 0 0 0" f="initial">
            <Button type="submit" variant="success" style={{ marginRight: '20px' }}>
              Mettre à jour les informations
            </Button>
          </Box>
          <Separator spacingTop={20} spacingBottom={20} />
          <Box gutter="negative" f="initial">
            <Group style={{ padding: '15px' }}>
              <Box fd="c">
                <Label
                  htmlFor="currentPassword"
                  style={{ paddingBottom: '16px' }}
                  required={isRequired}
                >
                  Mot de passe actuel
                </Label>
                <Input
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                  ref={register({
                    required: isRequired ? 'Ce champ est requis' : false,
                  })}
                />
              </Box>
              <ErrorMessage hasError={!!get(errors, `currentPassword`)}>
                {get(errors, `currentPassword.message`)}
              </ErrorMessage>
            </Group>
            <Group style={{ padding: '15px' }} />
          </Box>
          <Box gutter="negative" f="initial">
            <Group style={{ padding: '15px' }}>
              <Box fd="c" f="initial">
                <Label htmlFor="password" style={{ paddingBottom: '16px' }} required={isRequired}>
                  Nouveau mot de passe
                </Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  ref={register({
                    required: isRequired ? 'Ce champ est requis' : false,
                    minLength: {
                      value: 8,
                      message: 'Le mot de passe doit contenir au moins 8 caractères',
                    },
                  })}
                />
              </Box>
              <ErrorMessage hasError={!!get(errors, `password`)}>
                {get(errors, `password.message`)}
              </ErrorMessage>
            </Group>
            <Group style={{ padding: '15px' }}>
              <Box fd="c" f="initial">
                <Label
                  htmlFor="passwordConfirmation"
                  style={{ paddingBottom: '16px' }}
                  required={isRequired}
                >
                  Confirmation
                </Label>
                <Input
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  ref={register({
                    required: isRequired ? 'Ce champ est requis' : false,
                    validate: value =>
                      value === watch('password') || 'Les mots de passe ne correspondent pas',
                  })}
                />
              </Box>
              <ErrorMessage hasError={!!get(errors, `passwordConfirmation`)}>
                {get(errors, `passwordConfirmation.message`)}
              </ErrorMessage>
            </Group>
          </Box>
          <Box padding="20px 0 0 0" f="initial">
            <Button type="submit" variant="success" style={{ marginRight: '20px' }}>
              Modifier le mot de passe
            </Button>
            <Button
              variant="danger"
              onClick={() =>
                deleteUser()
                  .then(() =>
                    addToast({
                      type: 'success',
                      title: 'Succès !',
                      message: 'Votre demande de suppression a bien été prise en compte !',
                    }),
                  )
                  .catch(() => addGenericErrorToast())
              }
            >
              Supprimer mon compte
            </Button>
          </Box>
        </Box>
      </BoxWithHeader>
      <Box
        fd="c"
        bg="white"
        width="380px"
        br={4}
        withBorder
        padding="32px"
        style={{ margin: '0 15px', alignSelf: 'flex-start' }}
      >
        <Icon name="guard" color="green" size={32} containerStyle={{ marginBottom: '16px' }} />
        <Box ai="c">
          <Title fs={18} bold spacingBottom="16px">
            La sécurité est primordiale.
          </Title>
        </Box>
        <Text>
          Nous travaillons en permanence à renforcer la sécurité de notre communauté. C’est pourquoi
          nous examinons chaque compte pour nous assurer qu’il est aussi sécurisé que possible.
        </Text>
      </Box>
    </Box>
  )
}
