import { Form } from 'types/form'

const HISTORY_FORM: Form = [
  [
    {
      name: 'family',
      type: 'textarea',
      label: 'Antécédents familiaux',
      placeholder: 'Précisez les antécédents familiaux',
      required: false,
    },
  ],
  [
    {
      name: 'personal',
      type: 'textarea',
      label: 'Antécédents personnels',
      placeholder: 'Précisez les antécédents personnels',
      required: false,
    },
  ],
  [
    {
      name: 'medicalBackground',
      type: 'textarea',
      label: 'Antécédents médicaux',
      placeholder: 'Précisez les antécédents médicaux',
      required: false,
    },
  ],
  [
    {
      name: 'surgical',
      type: 'textarea',
      label: 'Antécédents chirurgicaux',
      placeholder: 'Précisez les antécédents chirurgicaux',
      required: false,
    },
  ],
  [
    {
      name: 'hospitalization',
      type: 'textarea',
      label: 'Hostpitalisations (Date/Lieu/Objet)',
      placeholder: 'Précisez les hostpitalisations',
      required: false,
    },
  ],
  [
    {
      name: 'futureSurgical',
      type: 'textarea',
      label: 'Interventions chirurgicales à prévoir',
      placeholder: 'Précisez les interventions chirurgicales à prévoir',
      required: false,
    },
  ],
  [
    {
      name: 'comments',
      type: 'textarea',
      label: 'Remarques',
      placeholder: 'Précisez les remarques',
      required: false,
    },
  ],
]

export default HISTORY_FORM
