import { AxiosResponse } from 'axios'

import { InstitutionRegistration, InstitutionInfo, InstitutionContact } from 'types/institution'
import User, { Auth, Notification } from 'types/user'
import request from './request'
import { get } from 'lodash'
import { decamelize } from 'humps'

export const getFormData = (data: any, formData: FormData, option?: { picturePrefix?: string }) => {
  for (const [keyform, formValue] of Object.entries(data)) {
    if (formValue instanceof Object) {
      for (const [key, value] of Object.entries(formValue)) {
        if (key === 'pictures') {
          for (const file of value) {
            if (file[0]) {
              formData.append(`${option?.picturePrefix || 'info'}[pictures][]`, file[0])
            }
          }
        } else if (key === 'picturesDestroy') {
          value?.id.map((id: number) => {
            formData.append(`${option?.picturePrefix}[pictures_destroy][id][]`, `${id}`)
          })
        } else if (key === 'document') {
          for (const file of value) {
            if (file) {
              formData.append(`social_project[document]`, file)
            }
          }
          /*} else if (key === 'documentDestroy') {
          //formData.append(`social_project[document_destroy][id]`, `${value.id}`)*/
        } else {
          value instanceof Object
            ? value.forEach((v: any) =>
                formData.append(`${decamelize(keyform)}[${decamelize(key)}][]`, v),
              )
            : formData.append(`${decamelize(keyform)}[${decamelize(key)}]`, `${value}` || '')
        }
      }
    } else {
      formData.append(`${decamelize(keyform)}`, `${formValue}`)
    }
  }

  return formData
}

export function create(form: InstitutionRegistration, webflowSlug?: string) {
  const formData = new FormData()

  getFormData(form, formData)

  return request
    .post<any, AxiosResponse<User>>(`/registration_institution`, formData, {
      params: { webflowSlug },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data)
}

export function update(
  subForm: 'info' | 'contact',
  data: any, //Partial<Auth['institution']>,
  selectedInstitutionId?: string,
) {
  const formData = new FormData()
  const subformId = get(data, `${subForm}.id`)

  getFormData(
    {
      ...data,
      info: {
        ...data?.info,
        progress: data?.info?.progress || '',
        structureType: data?.info?.structureType || '',
      },
    },
    formData,
  )

  return request
    .patch<any, AxiosResponse<InstitutionInfo | InstitutionContact>>(
      `/institution/${selectedInstitutionId}/${subForm}s/${subformId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .then(res => res.data)
}

export function getInstitutionWebflowAvailability(id?: string) {
  return request.get(`/webflow_institutions/availability/${id}`).then(res => res.data)
}

export function getInstitutionWebflowInfos(id?: string) {
  return request.get(`/webflow_institutions/${id}`).then(res => res.data)
}

export function updateNotification(form: any, selectedInstitutionId?: string) {
  const { notification } = form
  const formData = new FormData()

  formData.append('notification[admission_followup]', notification?.admissionFollowup.toString())
  formData.append('notification[admission_update]', notification?.admissionUpdate.toString())

  if (notification?.emails.length > 0) {
    notification?.emails.forEach((email: string) => {
      formData.append(`notification[emails][]`, email)
    })
  }

  return request
    .patch<any, AxiosResponse<Notification>>(
      `/institution/${selectedInstitutionId}/notifications/${form.notification.id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .then(res => res.data)
}

const commonHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export function updateAnimation(data: any, ressourceId?: string, selectedInstitutionId?: string) {
  const baseUrl = `/inclusive/organizations/`
  const formData = new FormData()
  const body = {
    organization: {
      ...data?.inclusiveOrganization,
      institutionId: selectedInstitutionId,
      night: data?.inclusiveOrganization.night || '', // for some reason, undefined is not accepted by backend
    },
  }
  getFormData(body, formData)

  if (!!ressourceId) {
    return request
      .patch<any, AxiosResponse<InstitutionInfo>>(
        `${baseUrl}${ressourceId}`,
        formData,
        commonHeaders,
      )
      .then(res => res.data)
  } else {
    return request
      .post<any, AxiosResponse<InstitutionInfo | InstitutionContact>>(
        baseUrl,
        formData,
        commonHeaders,
      )
      .then(res => res.data)
  }
}

export function updateCaracteristics(
  data: any,
  ressourceId?: string,
  selectedInstitutionId?: string,
) {
  const baseUrl = `/inclusive/caracteristics/`
  const formData = new FormData()
  const body = {
    caracteristic: {
      institutionId: selectedInstitutionId,
      ...data?.inclusiveCaracteristic,
      estateType: data?.inclusiveCaracteristic.estateType || '', // for some reason, undefined is not accepted by backend
      rentType: data?.inclusiveCaracteristic.rentType || '',
      rentDetail: data?.inclusiveCaracteristic.rentDetail || '',
    },
  }
  getFormData(body, formData, { picturePrefix: 'caracteristic' })

  if (!!ressourceId) {
    return request
      .patch<any, AxiosResponse<InstitutionInfo>>(
        `${baseUrl}${ressourceId}`,
        formData,
        commonHeaders,
      )
      .then(res => res.data)
  } else {
    return request
      .post<any, AxiosResponse<InstitutionInfo | InstitutionContact>>(
        baseUrl,
        formData,
        commonHeaders,
      )
      .then(res => res.data)
  }
}

export function updateSocialProject(
  data: any,
  ressourceId?: string,
  selectedInstitutionId?: string,
) {
  const baseUrl = `/inclusive/social_projects/`

  const formData = new FormData()

  const body = {
    socialProject: {
      ...data?.inclusiveSocialProject,
      institutionId: selectedInstitutionId,
    },
  }
  getFormData(body, formData)

  if (!!ressourceId) {
    return request
      .patch<any, AxiosResponse<InstitutionInfo>>(
        `${baseUrl}${ressourceId}`,
        formData,
        commonHeaders,
      )
      .then(res => res.data)
  } else {
    return request
      .post<any, AxiosResponse<InstitutionInfo | InstitutionContact>>(
        baseUrl,
        formData,
        commonHeaders,
      )
      .then(res => res.data)
  }
}

export function updateCandidate(data: any, ressourceId?: string, selectedInstitutionId?: string) {
  const baseUrl = `/inclusive/candidate_processes/`
  const formData = new FormData()
  const body = {
    candidateProcess: {
      ...data?.inclusiveCandidateProcess,
      accompanyingEngineering: data?.inclusiveCandidateProcess.accompanying_engineering === 'true',
      institutionId: selectedInstitutionId,
    },
  }
  getFormData(body, formData)

  if (!!ressourceId) {
    return request
      .patch<any, AxiosResponse<InstitutionInfo>>(
        `${baseUrl}${ressourceId}`,
        formData,
        commonHeaders,
      )
      .then(res => res.data)
  } else {
    return request
      .post<any, AxiosResponse<InstitutionInfo | InstitutionContact>>(
        baseUrl,
        formData,
        commonHeaders,
      )
      .then(res => res.data)
  }
}

export function createNewInstitution(form: InstitutionRegistration) {
  return request.post(`/institutions`, form).then(res => res.data)
}

export function searchInstitutions(info?: InstitutionInfo) {
  return request
    .get(`/webflow_institutions/search_webflow_institutions`, {
      params: {
        city: info?.city,
        street: info?.street,
        socialReason: info?.socialReason,
        phoneNumber: info?.phoneNumber,
      },
    })
    .then(res => res.data)
}

export function similarWebflowInstitutions(webflowSlug?: string) {
  return request
    .get(`/webflow_institutions/similar_wf_institutions/${webflowSlug}`)
    .then(res => res.data)
}
