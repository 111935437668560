import React from 'react'
import { map } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'

import { help } from 'assets/illustrations'

interface Props {
  sections: {
    title: string
    text: string
  }[]
  onNext?: () => void
  image?: string
  title?: string
  buttonLabel?: string
}

export default function Intro({ onNext, sections, image, title, buttonLabel }: Props) {
  return (
    <Box fd="c" ai="c" bg={'greenLight2'} padding="32px">
      <Box jc="c" style={{ marginBottom: '32px' }}>
        <img src={image || help} alt="illustration" style={{ width: '300px' }} />
      </Box>
      <Box fd="c">
        <Title bold spacingBottom="32px">
          {title || 'Voici un bref aperçu de cette section :'}
        </Title>
        {map(sections, (section, i) => (
          <Box key={`section-${i}`} padding="0 0 24px 0" f="0 1 auto">
            <Box
              ai="c"
              jc="c"
              bg="white"
              br={50}
              width="42px"
              height="42px"
              withBorder
              style={{ marginRight: '16px' }}
            >
              <Text>{i + 1}</Text>
            </Box>
            <Box fd="c">
              <Text bold>{section.title}</Text>
              <Text>{section.text}</Text>
            </Box>
          </Box>
        ))}
      </Box>
      {onNext && (
        <Button variant="primary" onClick={onNext} style={{ marginTop: '20px', width: '100%' }}>
          {buttonLabel || 'Commencer l’étape'}
          <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
        </Button>
      )}
    </Box>
  )
}
