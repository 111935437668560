import React from 'react'
import { find, map, get, filter } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Icon from 'ui/icon'
import Separator from 'ui/separator'

import { TYPE_OPTIONS } from './step1'
import { State } from '.'
import { SECTIONS } from './step2'

const Step3: React.FC<{
  data: State['data']
  goStep: (stepNumber: number) => void
}> = ({ goStep, data }) => {
  return (
    <Box fd="c" padding="32px" style={{ overflowY: 'scroll' }}>
      <Title fs={22} bold spacingBottom="32px">
        Récapitulons ensemble
      </Title>
      <Box jc="sb">
        <Box ai="c">
          <Icon
            name="check"
            color="white"
            bg="green"
            size={10}
            cp="3px"
            br={50}
            containerStyle={{ marginRight: '8px', display: 'inline-flex' }}
          />
          <Text>Vous avez défini le type de pension et le nom</Text>
        </Box>
        <Button variant="transparent" onClick={() => goStep(0)}>
          <Text color="green">Modifier</Text>
        </Button>
      </Box>
      <Text color="black" opacity={0.5}>
        {find(TYPE_OPTIONS, option => data.type === option.value)?.label} - {data.name}
      </Text>
      <Separator spacingTop={16} spacingBottom={24} />
      <Box jc="sb">
        <Box ai="c">
          <Icon
            name="check"
            color="white"
            bg="green"
            size={10}
            cp="3px"
            br={50}
            containerStyle={{ marginRight: '8px', display: 'inline-flex' }}
          />
          <Text>Vous avez ajouté ces catégories</Text>
        </Box>
        <Button variant="transparent" onClick={() => goStep(1)}>
          <Text color="green">Modifier</Text>
        </Button>
      </Box>
      <Box fd="c">
        {map(
          filter(SECTIONS, ({ name }) => get(data, name, []).length > 0),
          ({ name, panelLabel }) => (
            <Box fd="c" key={name}>
              <Text color="black" opacity={0.5} bold style={{ margin: '24px 0 8px 0' }}>
                {panelLabel}
              </Text>
              {map(data[name], (item, i) => {
                return (
                  <Box jc="sb" style={{ marginTop: '8px' }} key={`${panelLabel}-item-${i}`}>
                    <Text color="black" opacity={0.5}>
                      {item.name}
                    </Text>
                    <Text color="black" opacity={0.5}>
                      {item.qte}
                    </Text>
                  </Box>
                )
              })}
            </Box>
          ),
        )}
      </Box>
    </Box>
  )
}

export default Step3
