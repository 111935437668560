import React, { useReducer, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm, FormContext } from 'react-hook-form'
import styled from 'styled-components'
import { get } from 'lodash'

import Box, { BoxWithHeader } from 'ui/box'
import Title from 'ui/typography/title'
import Button from 'ui/button'
import Separator from 'ui/separator'
import Icon from 'ui/icon'
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Completed from './completed'

import Kit from 'types/kit'
import { submitKitList, getKitList } from 'api/kit'

import { useToasts } from 'hooks/useToaster'
import useResource from 'hooks/useResource'
import { usePageName } from 'hooks/usePageName'
import { AuthContext } from 'App'

const Progress = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${props => props.theme.greyLightN};

  div {
    height: 10px;
    background-color: ${props => props.theme.green};
    opacity: 0.5;
    transition: width 0.5s ease;
  }
`

export type State = {
  currentStep: number
  kitSaved: boolean
  data: Kit
}

type Actions =
  | {
      name: 'INITIALIZE'
      data?: State['data']
    }
  | {
      name: 'NEXT_STEP'
      data: State['data']
    }
  | { name: 'PREVIOUS_STEP' }
  | { name: 'CHANGE_STEP'; stepNumber: number }
  | { name: 'SUCCESS' }

const reducer = (state: State, action: Actions) => {
  switch (action.name) {
    case 'INITIALIZE':
      return {
        ...state,
        data: { ...state.data, ...action.data },
      }
    case 'NEXT_STEP':
      return {
        ...state,
        currentStep: state.currentStep + 1,
        data: { ...state.data, ...action.data },
      }
    case 'PREVIOUS_STEP':
      return {
        ...state,
        currentStep: state.currentStep - 1,
      }
    case 'CHANGE_STEP':
      return {
        ...state,
        currentStep: action.stepNumber,
      }
    case 'SUCCESS':
      return {
        ...state,
        kitSaved: true,
      }
  }
}

export default function KitForm() {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const { kitId } = useParams<{ kitId?: string }>()
  const { addToast, addGenericErrorToast } = useToasts()
  const methods = useForm()
  const { setPageName } = usePageName()
  const [{ resource }] = useResource<Kit, {}>(
    undefined,
    () => (kitId ? getKitList(kitId) : Promise.resolve({})),
    [],
  )

  const [state, dispatch] = useReducer(reducer, {
    currentStep: 0,
    kitSaved: false,
    data: {
      id: kitId,
      ...resource,
    },
  })

  useEffect(() => {
    setPageName('Mon Établissement')
  }, [])

  useEffect(() => {
    methods.reset({ ...state.data })
  }, [state.data])

  useEffect(() => {
    dispatch({
      name: 'INITIALIZE',
      data: resource,
    })
  }, [resource])

  const onSubmit = (data: State['data']) => {
    switch (state.currentStep) {
      case 1:
        return dispatch({
          name: 'NEXT_STEP',
          data: {
            shoesAndClothes: get(data, 'shoesAndClothes', []),
            administrativeDocuments: get(data, 'administrativeDocuments', []),
            toiletKit: get(data, 'toiletKit', []),
            medical: get(data, 'medical', []),
          },
        })
      case 2:
        return submitKitList(state.data, auth.selectedInstitutionId)
          .then(() => {
            addToast({
              title: 'Succès !',
              type: 'success',
              message: 'Trousseau sauvegardé avec succès !',
            })
            dispatch({ name: 'SUCCESS' })
          })
          .catch(() => addGenericErrorToast())
      default:
        return dispatch({ name: 'NEXT_STEP', data: data })
    }
  }

  const renderContent = () => {
    switch (state.currentStep) {
      case 0:
        return <Step1 />
      case 1:
        return <Step2 />
      case 2:
        return (
          <Step3
            goStep={(stepNumber: number) => dispatch({ name: 'CHANGE_STEP', stepNumber })}
            data={state.data}
          />
        )
    }
  }

  return (
    <Box gutter="negative" jc="c" style={{ maxHeight: '100%' }}>
      <BoxWithHeader width="736px" br={4} style={{ alignSelf: 'flex-start', maxHeight: '100%' }}>
        <Box>
          <Title fs={24} bold>
            Création d’un trousseau d’accueil
          </Title>
          <Box jc="fe">
            {!state.kitSaved && (
              <Button variant="outline" onClick={() => history.goBack()}>
                Quitter
              </Button>
            )}
          </Box>
        </Box>

        <Box fd="c" as={FormContext} {...methods}>
          {state.kitSaved ? (
            <Completed goBack={() => history.goBack()} />
          ) : (
            <>
              <Progress>
                <div style={{ width: `${((state.currentStep + 1) / 3) * 100}%` }}></div>
              </Progress>
              {renderContent()}
              <Box fd="c">
                <Separator spacingBottom={24} />
                <Box ai="c" jc={state.currentStep !== 0 ? 'sb' : 'fe'} f="initial" padding="0 32px">
                  {state.currentStep !== 0 && (
                    <Button variant="outline" onClick={() => dispatch({ name: 'PREVIOUS_STEP' })}>
                      <Icon
                        name="arrow"
                        rotate={180}
                        size={20}
                        containerStyle={{ marginRight: '8px' }}
                      />
                      Retour
                    </Button>
                  )}
                  <Button type="submit" onClick={methods.handleSubmit(onSubmit)}>
                    {state.currentStep === 2 ? 'Terminer' : 'Suivant'}
                    <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </BoxWithHeader>
    </Box>
  )
}
