import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { get } from 'lodash'

import { renderInput } from 'components/pages/helper'
import Box from 'ui/box'
import Button from 'ui/button'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Separator from 'ui/separator'

import { Radio } from 'types/form'
import Educative, {
  HYGIENE,
  DRESSING,
  CONTINENCE,
  SIT,
  LIE_DOWN,
  MOBILITY,
  EXPRESSION,
  UNDERSTANDING,
  SLEEP,
  MOOD,
  TIME_ORIENTATION,
  SPATIAL_ORIENTATION,
  MEMORY,
  HOBBY,
  SOCIAL_CONTEXT,
  RELATIONAL_FACILITY,
} from 'types/educative'

const ErrorMessage = styled(Text).attrs({ fs: 16, color: 'pink' })`
  flex: 1;
`

const Progress = styled.div`
  width: 100px;
  height: 8px;
  border-radius: 1px;
  background-color: ${props => props.theme.greyDark};
  margin-right: 8px;

  div {
    height: 8px;
    background-color: ${props => props.theme.green};
    transition: ease width 0.3s;
  }
`

const QuestionGroup = styled(Box)`
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.purpleDarkTransparent};
  margin-bottom: 24px;
`

export const abilityForm: { title: string; questions: Radio[] }[] = [
  {
    title: 'Autonomie / Dépendance physique',
    questions: [
      {
        name: 'hygiene',
        label: 'Hygiène',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          { value: HYGIENE.ALONE, label: 'Se lave seul sans aucune aide' },
          {
            value: HYGIENE.WITH_SOLICITATION,
            label: ' Se lave seul sur sollicitation ou guidance orale',
          },
          {
            value: HYGIENE.WITH_HELP,
            label: 'Nécessite une aide pour laver certaines parties du corps',
          },
          {
            value: HYGIENE.COLLABORATE,
            label: "N'est pas capable de se laver seul mais collabore lors de la toilette",
          },
          {
            value: HYGIENE.UNABLE,
            label: "N'est pas capable de se laver seul et reste passif",
          },
        ],
      },
      {
        name: 'dressing',
        label: 'Habillage / Déshabillage',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          { value: DRESSING.ALONE, label: "S'habille et se déshabille sans aucune aide" },
          {
            value: DRESSING.WITH_SOLICITATION,
            label: "S'habille et se déshabille seul sur sollicitation ou guidance orale",
          },
          {
            value: DRESSING.WITH_HELP,
            label:
              'Nécessite une aide partielle pour s’habiller et se déshabiller (fermer les boutons, nouer les lacets, etc.)',
          },
          {
            value: DRESSING.COLLABORATE,
            label: 'N’est pas capable de s’habiller ni de se déshabiller seul mais collabore',
          },
          {
            value: DRESSING.UNABLE,
            label: 'N’est pas capable de s’habiller ni de se déshabiller seul et reste passif',
          },
        ],
      },
      {
        name: 'continence',
        label: 'Continence',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          { value: CONTINENCE.COMPLETE, label: 'Continence complète' },
          {
            value: CONTINENCE.WITH_SOLICITATION,
            label: 'Continence sur sollicitation',
          },
          {
            value: CONTINENCE.OCCASIONAL,
            label: 'Incontinence occasionnelle',
          },
          {
            value: CONTINENCE.REGULAR,
            label: 'Incontinence fréquente nocturne',
          },
          {
            value: CONTINENCE.UNCONTROLLABLE,
            label: 'Aucun contrôle des sphincters',
          },
          {
            value: CONTINENCE.ENCOPRESIE,
            label: 'Encoprésie',
          },
        ],
      },
      {
        name: 'sit',
        label: "S'asseoir et se relever",
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          { value: SIT.ALONE, label: 'Se lève et s’assoit seul' },
          {
            value: SIT.WITH_TECHNIC,
            label: 'Se lève et s’assoit seul dans un siège adapté (accoudoirs, etc.)',
          },
          {
            value: SIT.WITH_HELP,
            label: 'Se lève et s’assoit avec l’aide d’une personne (le résident collabore)',
          },
          {
            value: SIT.NEED_HELP,
            label: 'Est levé et assis avec l’aide d’une personne (le résident reste passif)',
          },
          {
            value: SIT.UNABLE,
            label: 'Ne peut être levé',
          },
        ],
      },
      {
        name: 'lieDown',
        label: 'Se lever et se coucher',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          { value: LIE_DOWN.ALONE, label: 'Se lève et se couche seul' },
          {
            value: LIE_DOWN.WITH_TECHNIC,
            label: 'Se lève et se couche seul dans un lit médicalisé adapté',
          },
          {
            value: LIE_DOWN.WITH_HELP,
            label: 'Se lève et se couche avec l’aide d’une personne (le résident collabore)',
          },
          {
            value: LIE_DOWN.NEED_HELP,
            label: 'Est levé est couché avec l’aide d’une personne (le résident reste passif)',
          },
          {
            value: LIE_DOWN.UNABLE,
            label: 'Ne peut être levé',
          },
        ],
      },
      {
        name: 'mobility',
        label: 'Mobilité',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          { value: MOBILITY.ALONE, label: 'Se déplace seul sans appui' },
          {
            value: MOBILITY.WITH_DIFFICULTY,
            label:
              'Se déplace seul mais avec difficulté (nécessité d’un appui : canne, tribune, etc.)',
          },
          {
            value: MOBILITY.WITH_HELP,
            label: 'Se déplace avec l’aide d’une personne',
          },
          {
            value: MOBILITY.WITH_WHEELCHAIR,
            label: 'Se déplace seul, en fauteuil roulant',
          },
          {
            value: MOBILITY.WITH_WHEELCHAIR_AND_HELP,
            label: 'Se déplace en fauteuil roulant avec l’aide d’une personne',
          },
        ],
      },
    ],
  },
  {
    title: 'Autonomie / Dépendance psycho-cognitive',
    questions: [
      {
        name: 'expression',
        label: 'Expression',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          { value: EXPRESSION.NO_DIFFICULTY, label: 'S’exprime oralement sans difficulté' },
          {
            value: EXPRESSION.FEW_DIFFICULTY,
            label: 'S’exprime oralement avec quelques difficultés',
          },
          {
            value: EXPRESSION.WITH_DIFFICULTY,
            label:
              'S’exprime oralement avec difficultés (difficultés importantes pour l’interlocuteur de comprendre ce que dit le résident)',
          },
          {
            value: EXPRESSION.WITH_GREAT_DIFFICULTY,
            label:
              'S’exprime difficilement (impossibilité pour l’interlocuteur de comprendre ce que dit le résident)',
          },
          {
            value: EXPRESSION.SPECIFIC,
            label:
              "S'exprime à travers un mode de communication spécifique (PECS, pictogrammes, langage des signes,...)",
          },
          {
            value: EXPRESSION.UNABLE,
            label: 'Pas d’accès au langage',
          },
        ],
      },
      {
        name: 'understanding',
        label: 'Compréhension',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          {
            value: UNDERSTANDING.NO_DIFFICULTY,
            label: 'Dispose de bonnes capacités de compréhension',
          },
          {
            value: UNDERSTANDING.FEW_DIFFICULTY,
            label: 'Ne comprend que des phrases simples',
          },
          {
            value: UNDERSTANDING.WITH_DIFFICULTY,
            label: 'Grandes difficultés de compréhension même pour des consignes simples',
          },
          {
            value: UNDERSTANDING.SPECIFIC,
            label:
              "Ne comprend qu’au travers d'un mode de communication spécifique (PECS, pictogrammes, langage des signes, ...)",
          },
        ],
      },
      {
        name: 'sleep',
        label: 'Sommeil',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          {
            value: SLEEP.NO_DIFFICULTY,
            label: 'Ne présente aucun trouble du sommeil',
          },
          {
            value: SLEEP.FEW_DIFFICULTY,
            label: 'Troubles du sommeil ponctuels et circonstanciés',
          },
          {
            value: SLEEP.WITH_DIFFICULTY,
            label: 'Présente des troubles du sommeil manifestes et réguliers',
          },
        ],
      },
      {
        name: 'mood',
        label: 'Humeur',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          {
            value: MOOD.JOVIAL,
            label: 'Humeur stable - Jovial',
          },
          {
            value: MOOD.SAD,
            label: 'Humeur stable - Déprimé et triste',
          },
          {
            value: MOOD.INHIBITION,
            label: 'Humeur stable - Repli et inhibition',
          },
          {
            value: MOOD.FLUCTUATE,
            label: 'Humeur fluctuante',
          },
        ],
      },
      {
        name: 'timeOrientation',
        label: 'Orientation temporelle',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          {
            value: TIME_ORIENTATION.NO_DIFFICULTY,
            label: 'Se repère correctement dans le temps (jour, mois, année)',
          },
          {
            value: TIME_ORIENTATION.WITH_VISUAL_HELP,
            label: 'Est capable de se repérer dans le temps à l’aide de supports visuels',
          },
          {
            value: TIME_ORIENTATION.WITH_RITUAL_HELP,
            label: 'Est capable de se repérer dans le temps à l’aide de supports rituels',
          },
          {
            value: TIME_ORIENTATION.WITH_DIFFICULTY,
            label: 'Se repère difficilement dans le temps même avec des aides',
          },
          {
            value: TIME_ORIENTATION.NOT_EVALUABLE,
            label: 'Difficile à évaluer',
          },
          {
            value: TIME_ORIENTATION.UNABLE,
            label: 'Est incapable de se repérer dans le temps',
          },
        ],
      },
      {
        name: 'spatialOrientation',
        label: 'Orientation spatiale',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          {
            value: SPATIAL_ORIENTATION.NO_DIFFICULTY,
            label: 'Se repère correctement dans l’espace',
          },
          {
            value: SPATIAL_ORIENTATION.WITH_KNOWN_PLACE,
            label: 'Se repère correctement dans un lieu qu’il connaît',
          },
          {
            value: SPATIAL_ORIENTATION.WITH_VISUAL_HELP,
            label: 'Est capable de se repérer à l’aide de supports visuels',
          },
          {
            value: SPATIAL_ORIENTATION.WITH_DIFFICULTY,
            label: 'Se repère difficilement dans l’espace même avec des aides',
          },
          {
            value: SPATIAL_ORIENTATION.NOT_EVALUABLE,
            label: 'Difficile à évaluer',
          },
          {
            value: SPATIAL_ORIENTATION.UNABLE,
            label: 'Est incapable de se repérer dans l’espace',
          },
        ],
      },
      {
        name: 'memory',
        label: "Mémoire d'évocation",
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          {
            value: MEMORY.RECENT,
            label:
              'Évoque uniquement des faits récents (menu du dernier repas, emploi du temps de la veille et du jour, prénoms des personnes qui l’entourent, etc.)',
          },
          {
            value: MEMORY.OLD,
            label: 'Évoque uniquement des faits anciens',
          },
          {
            value: MEMORY.UNABLE,
            label: 'Aucune évocation cohérente (propos qui ne correspondent pas à la réalité)',
          },
          {
            value: MEMORY.GOOD,
            label: 'Aucun problème de mémoire',
          },
        ],
      },
    ],
  },
  {
    title: 'Autonomie / Dépendance sociale et relationnelle',
    questions: [
      {
        name: 'hobby',
        label: 'Loisir et temps libre',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          { value: HOBBY.ALONE, label: "Est capable de s'occuper seul" },
          {
            value: HOBBY.WITH_HELP,
            label: "Est capable de s'occuper sur sollicitation",
          },
          {
            value: HOBBY.UNABLE,
            label: "Est incapable de s'occuper",
          },
        ],
      },
      {
        name: 'socialContext',
        label: 'Adaptation au contexte social',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          {
            value: SOCIAL_CONTEXT.EASY_ADAPTATION,
            label: "Repère les différents types de situations sociales et s'adapte correctement",
          },
          {
            value: SOCIAL_CONTEXT.DIFFICULTY_ADAPTATION,
            label: "S'adapte avec quelques difficultés aux différents contextes sociaux",
          },
          {
            value: SOCIAL_CONTEXT.ONLY_IN_ESTABLISHMENT,
            label: "S'adapte uniquement aux situations sociales de l'établissement",
          },
          {
            value: SOCIAL_CONTEXT.UNABLE,
            label: 'Vit dans son monde sans tenir compte du contexte social',
          },
        ],
      },
      {
        name: 'relationalFacility',
        label: 'Aisance relationnelle',
        type: 'radio',
        required: true,
        vertical: true,
        options: [
          {
            value: RELATIONAL_FACILITY.ALL_PERSONS,
            label: "Entre facilement en relation avec d'autres personnes",
          },
          {
            value: RELATIONAL_FACILITY.ONLY_KNOWN_PERSON,
            label: 'Entre en relation uniquement avec des personnes connues',
          },
          {
            value: RELATIONAL_FACILITY.WITH_DIFFICULTY,
            label: 'Présente des difficultés relationnelles importantes',
          },
          {
            value: RELATIONAL_FACILITY.UNABLE,
            label: "Evite tout contact avec d'autres personnes",
          },
        ],
      },
    ],
  },
]

export default function FormAbility({ resource }: { resource: Educative }) {
  const defaultValues = resource['ability'] || {}
  const { register, control, errors, watch, setValue } = useFormContext()
  const formObj = watch()
  const [panelState, setPanel] = useState<{ [key: number]: boolean }>({
    0: false,
    1: false,
    2: false,
  })

  useEffect(() => {
    setValue('ability', { ...defaultValues })
  }, [defaultValues])

  const isMissingAnswer = defaultValues.progress < 100 && defaultValues.updatedAt

  return (
    <>
      <Input id="id" name="ability.id" type="hidden" ref={register()} />
      <Title bold fs={22} spacingBottom="24px">
        Dites nous en un peu plus sur la situation actuelle.
      </Title>
      {abilityForm.map(({ title, questions }, groupIndex) => {
        const groupOpen = panelState[groupIndex]
        const progress = questions
          .map(({ name }: { name: string }) => `ability.${name}`)
          .reduce((acc, name) => (formObj[name] ? acc + 1 : acc), 0)

        return (
          <QuestionGroup key={title} fd="c" f="initial">
            <Box fd="r" jc="sb" width="100%">
              <Box fd="c" jc="fs">
                <Title fs={18} bold>
                  {title}
                </Title>
                <Box fd="r" jc="fs" ai="c">
                  <Progress>
                    <div style={{ width: `${Math.round((progress / questions.length) * 100)}%` }} />
                  </Progress>
                  <Text
                    fs={14}
                    color={isMissingAnswer && progress < questions.length ? 'pink' : 'greyDark2'}
                  >
                    {`${progress}/${questions.length} `} complétés
                  </Text>
                </Box>
              </Box>
              <Box ai="c" jc="fe">
                <Button
                  variant="outline"
                  onClick={() => setPanel({ ...panelState, [groupIndex]: !groupOpen })}
                  size="small"
                >
                  {groupOpen ? 'Replier' : 'Déplier'}
                </Button>
              </Box>
            </Box>
            <Box fd="c" style={{ display: groupOpen ? 'flex' : 'none' }}>
              {questions.map((q, index) => {
                const { name, label } = q

                return (
                  <React.Fragment key={`ability-input-${name}`}>
                    <Group style={{ padding: '15px 0' }}>
                      <Title
                        color={isMissingAnswer && !watch(`ability.${name}`) ? 'pink' : 'black'}
                        fs={18}
                        bold
                        style={{ paddingBottom: '16px' }}
                      >
                        {label}
                      </Title>
                      {renderInput(
                        'ability',
                        { ...q, hasError: get(errors, `ability.${name}`) },
                        control,
                        register,
                      )}
                      <ErrorMessage>{get(errors, `ability.${name}.message`)}</ErrorMessage>
                    </Group>
                    {index !== questions.length - 1 && (
                      <Separator opacity={0.1} spacingBottom={17} />
                    )}
                  </React.Fragment>
                )
              })}
            </Box>
          </QuestionGroup>
        )
      })}
    </>
  )
}
