import { Form } from 'types/form'
import { VISION } from 'types/medical'

const DEFICIENCY_FORM: Form = [
  [
    {
      name: 'report',
      type: 'file',
      label: 'Comptes-rendus et feuilles de traitement',
      placeholder: '',
      required: true,
    },
    null,
  ],
  [
    {
      name: 'intellectual',
      type: 'textarea',
      label: 'Déficience intellectuelle, troubles du psychisme ou du comportement',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'epilepsyType',
      type: 'text',
      label: 'Déficience liée à l’épilepsie - Type',
      placeholder: 'Précisez',
      required: false,
    },
    {
      name: 'epilepsyFrequency',
      type: 'text',
      label: 'Déficience liée à l’épilepsie - Fréquence',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'audition',
      type: 'text',
      label: "Déficience de l'audition - Troubles associés",
      placeholder: 'Précisez',
      required: false,
    },
    {
      name: 'language',
      type: 'text',
      label: 'Déficience du langage et de la parole',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'vision',
      type: 'select',
      label: 'Déficience de la vision',
      placeholder: 'Choisissez',
      options: [
        {
          value: VISION.POSSIBLE_MEDICAL_IMPROVEMENT,
          label: 'Amélioration possible (médicalement)',
        },
        {
          value: VISION.POSSIBLE_CHIRURGICAL_IMPROVEMENT,
          label: 'Amélioration possible (chirurgicalement)',
        },
        { value: VISION.IMPOSSIBLE_IMPROVEMENT, label: 'Amélioration impossible' },
      ],
      required: false,
    },
    {
      name: 'otherConfusion',
      type: 'text',
      label: 'Autres troubles',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'digestive',
      type: 'text',
      label:
        'Déficience de la sphère digestive - Trouble de la déglutition, type d’alimentation, troubles du transit',
      placeholder: 'Précisez',
      required: false,
    },
    {
      name: 'urinary',
      type: 'text',
      label: 'Déficience de la fonction urinaire - Troubles de l’élimination',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'enzymatic',
      type: 'text',
      label: 'Déficience métabolique, enzymatique',
      placeholder: 'Précisez',
      required: false,
    },
    {
      name: 'immune',
      type: 'text',
      label: 'Déficience du système immunitaire et hématopoïétique',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'locomotive',
      type: 'text',
      label: "Déficience de l'appareil locomoteur - Précisions (2 champs avant)",
      placeholder: 'Précisez',
      required: false,
    },
    {
      name: 'cardioRespiratory',
      type: 'text',
      label: 'Déficience de la fonction cardio-respiratoire - Signes fonctionnels',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'decompensationCardiac',
      type: 'text',
      label: 'Signes de décompensation cardiaque',
      placeholder: 'Précisez',
      required: false,
    },
    {
      name: 'respiratory',
      type: 'text',
      label: 'Assistance respiratoire éventuelle',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'dialysis',
      type: 'radio',
      label: 'Dialyse',
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        { label: 'Non', value: 'false' },
      ],
      required: false,
    },
  ],
  [
    {
      name: 'dialysisFrequency',
      type: 'text',
      label: 'Si oui, fréquence hebdomadaire',
      placeholder: 'Précisez',
      required: false,
    },
    null,
  ],
  [
    {
      name: 'transplant',
      type: 'radio',
      label: 'Greffe',
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        { label: 'Non', value: 'false' },
      ],
      required: false,
    },
  ],
  [
    {
      name: 'transplantDate',
      type: 'date',
      label: 'Si oui, date',
      placeholder: '',
      required: false,
    },
    {
      name: 'transplantTolerance',
      type: 'text',
      label: 'Tolérance',
      placeholder: 'Précisez',
      required: false,
    },
  ],
]

export default DEFICIENCY_FORM
