import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'

import Box from '../../box'
import { CardProps } from './card'
import ProgressBarItem from './item'

const Container = styled(Box).attrs({ fd: 'c' })``

export default function ProgressBarVertical({
  items,
}: {
  items: Array<CardProps & { children: React.ReactNode }>
}) {
  return (
    <Container>
      {map(items, (item, key) => (
        <ProgressBarItem key={`progressBarItem-${key}`} index={key} {...item}>
          {item.children}
        </ProgressBarItem>
      ))}
    </Container>
  )
}
