import { Form } from 'types/form'

const DIAGNOSTIC_FORM: Form = [
  [
    {
      name: 'principalReport',
      type: 'textarea',
      label: 'Diagnostic principal',
      placeholder: 'Précisez',
      required: true,
    },
  ],
]

export default DIAGNOSTIC_FORM
