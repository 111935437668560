import React, { useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'

import Modal from 'ui/modal'
import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'
import { FormBody } from 'components/pages/form'

import { useToasts } from 'hooks/useToaster'
import { institutionEngageRefusal } from 'api/admission'

export default function InstitutionRefusedEngagement({
  data,
  opened,
  onClose,
  onSuccess,
}: {
  data: {
    candidacyId: string
    patientName: string
  }
  opened: boolean
  onClose: () => void
  onSuccess: () => void
}) {
  const { addToast, addGenericErrorToast } = useToasts()
  const methods = useForm<{ data: { motif: string } }>()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = ({ data: { motif } }: { data: { motif: string } }) => {
    setLoading(true)

    institutionEngageRefusal(data.candidacyId, motif)
      .then(() => setLoading(false))
      .then(() =>
        addToast({
          type: 'success',
          title: 'Succès !',
          message: 'L’accord de prise en charge a été refusé.',
        }),
      )
      .then(onSuccess)
      .catch(() => {
        addGenericErrorToast()
        setLoading(false)
      })
  }

  return (
    <Modal onClose={onClose} opened={opened} title={`Ne pas accueillir ${data.patientName}`}>
      <FormContext {...methods}>
        <Box fd="c" as="form" autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          <Box bg="redLight2" padding="32px" ai="c" br={4} style={{ marginBottom: '16px' }}>
            <Icon name="message" color="pink" size={22} containerStyle={{ marginRight: '32px' }} />
            <Text fs={16}>
              Il est important de préciser pour quelle raison vous ne souhaitez pas donner votre
              accord de prise en charge à l’usager
            </Text>
          </Box>

          <Box fd="c">
            <FormBody<{ data: { motif: string } }>
              fields={[
                [
                  {
                    name: 'motif',
                    type: 'textarea',
                    label: 'Motif',
                    placeholder: 'Sélectionnez un motif',
                    required: true,
                  },
                ],
              ]}
              subForm="data"
              hasId={false}
            />
          </Box>

          <Box jc="fe" padding="32px 0 0 0">
            <Button variant="outline" onClick={onClose} style={{ marginRight: '16px' }}>
              Annuler
            </Button>
            <Button type="submit" variant="danger">
              {loading ? (
                <Loader size={12} />
              ) : (
                <>
                  Refuser l’accueil
                  <Icon
                    name="arrow"
                    color="white"
                    size={20}
                    containerStyle={{ marginLeft: '8px' }}
                  />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </FormContext>
    </Modal>
  )
}
