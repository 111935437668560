import React from 'react'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Group from 'ui/form/group'
import StepLayout from './stepLayout'
import { State } from '..'
import { InstitutionInfo } from 'types/institution'
import Button from 'ui/button'

interface Props {
  onNext: (type: State['habitatType']) => void
  onPrevious: () => void
}

const StepEstablishmentType = ({ onNext, onPrevious }: Props) => {
  const establishmentTypes: Array<{
    icon: React.ReactNode
    title: string
    text: string
    value: InstitutionInfo['habitatType']
  }> = [
    {
      icon: <Icon name="france" size={40} />,
      title: 'Un habitat inclusif et accompagné en France',
      text: "Vous êtes porteur d'un ou plusieurs projets en fonctionnement ou en devenir",
      value: 'inclusive',
    },
    {
      icon: <Icon name="eu" size={40} />,
      title: 'Un hébergement médicalisé en dehors de France',
      text:
        "Vous êtes en charge d'un ou plusieurs établissements médico sociaux en dehors de France",
      value: 'medical',
    },
  ]

  return (
    <StepLayout
      spacingBottom="8px"
      title="Quel type de lieu de vie représentez-vous ?"
      subText="Inscrivez-vous et accédez à votre espace personnel"
    >
      <Box as="form" autoComplete="off" fd="c" style={{ marginTop: '64px' }}>
        <Box fd="c" ai="s" f="0 1 auto">
          {establishmentTypes.map(type => (
            <Group
              key={`type-${type.value}`}
              style={{ paddingBottom: '2%', width: '80%', cursor: 'pointer' }}
              onClick={() => onNext(type.value)}
            >
              <Box
                fd="r"
                jc="sb"
                ai="c"
                padding="27px"
                style={{
                  width: '100%',
                  backgroundColor: '#FFF',
                  borderRadius: '4px',
                  boxShadow: '0px 2px 0px #D5E2E1',
                }}
              >
                <div style={{ padding: '10px', backgroundColor: '#F5F5F5' }}>{type.icon}</div>
                <Box fd="c" style={{ marginLeft: '24px', flex: '2' }}>
                  <Text fs={18} bold>
                    {type.title}
                  </Text>
                  <Text fs={16}>{type.text}</Text>
                </Box>
                <Icon color="green" name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
              </Box>
            </Group>
          ))}
        </Box>
        <Box f="0 1 auto">
          <Button variant="outline" style={{ backgroundColor: 'white' }} onClick={onPrevious}>
            <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
            Étape précédente
          </Button>
        </Box>
      </Box>
    </StepLayout>
  )
}

export default StepEstablishmentType
