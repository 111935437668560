import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { map } from 'lodash'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Button from 'ui/button'
import Icon from 'ui/icon'
import { Controls } from 'components/pages/form'

import { candidateForInstitutions } from 'api/admission'
import { InstitutionCandidacy } from 'types/candidacy'

import { InstitutionStepContext } from '.'
import { useToasts } from 'hooks/useToaster'

function SimplifiedInstitutionCard({
  data,
  onSelectInstitution,
}: {
  data: InstitutionCandidacy
  onSelectInstitution: (ic: InstitutionCandidacy) => void
}) {
  return (
    <Box fd="c" withBorder br={4} style={{ marginBottom: '16px' }}>
      <Box padding="24px" jc="sb">
        <Box fd="c">
          <Text fs={18} color="black" bold>
            {data.name}
          </Text>
          <Text fs={16} color="black">
            {data.address}
          </Text>
        </Box>
        <Box jc="fe">
          <Button
            variant="outline-danger"
            size="small"
            style={{ marginRight: '8px' }}
            onClick={() => onSelectInstitution(data)}
          >
            Supprimer ce lieu
          </Button>
          <Button variant="success" size="small">
            <>
              Sélectionné
              <Icon name="check" size={15} color="white" containerStyle={{ paddingLeft: '6px' }} />
            </>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default function Step2() {
  const { addToast, addGenericErrorToast } = useToasts()
  const { selectedInstitutions, setSelectedInstitutions } = useContext(InstitutionStepContext)
  const history = useHistory()
  const { section, step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)

  return (
    <Box
      fd="c"
      style={{
        minHeight: '100%',
      }}
    >
      <Box
        fd="c"
        padding="32px"
        style={{
          overflowY: 'scroll',
        }}
      >
        <Box fd="c" f="initial">
          <Title fs={22} bold spacingBottom="16px">
            {selectedInstitutions.length === 1
              ? "Vous allez envoyer votre dossier à l'établissements."
              : `Vous allez envoyer votre dossier aux ${selectedInstitutions.length} établissements.`}
          </Title>
          <Text fs={16} spacingBottom="32px">
            {selectedInstitutions.length === 1
              ? "L'établissement va étudier votre dossier, vous recevrez sa décision par e-mail sous 15 jours ouvrés."
              : 'Les établissements vont étudier votre dossier, vous recevrez leurs décisions par e-mail sous 15 jours ouvrés.'}
          </Text>
        </Box>
        <Box fd="c" f="initial">
          {map(selectedInstitutions, (institution, i) => (
            <SimplifiedInstitutionCard
              key={`institution-${i}`}
              data={institution}
              onSelectInstitution={setSelectedInstitutions}
            />
          ))}
        </Box>
      </Box>
      <Controls
        prevButton={{
          callback: () => history.push(`/admission/${section}/etape/${parsedStep - 1}`),
          label: 'Retour',
        }}
        nextButton={{
          callback: () => {
            candidateForInstitutions(map(selectedInstitutions, si => si.candidacyId))
              .then(() =>
                addToast({
                  type: 'success',
                  title: 'Succès !',
                  message: 'Votre dossier a bien été envoyé !',
                }),
              )
              .then(() => history.push(`/admission/${section}/etape/${parsedStep + 1}`))
              .catch(addGenericErrorToast)
          },
          disabled: selectedInstitutions.length === 0,
          label: 'Postuler',
        }}
      />
    </Box>
  )
}
