import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Separator from 'ui/separator'
import { Colors } from 'ui/theme'

import { ProgressVariant } from 'types/enums'

export interface CardProps {
  title: string
  variant: ProgressVariant
  collapseChildren?: React.ReactNode
  defaultOpen?: boolean
  onClick?: () => void
}

const Wrapper = styled(Box).attrs({ fd: 'c' })<{
  bc: Colors
  brC: Colors
}>`
  background-color: ${props => get(props.theme, props.bc, props.bc)};
  border: ${props => `1px solid ${props.theme[props.brC]}`};
  border-radius: 4px;
  display: block;
`

const variants = {
  todo: {
    backgroundColor: 'white',
    borderColor: 'greyLightN3',
    textColor: 'black',
  },
  default: {
    backgroundColor: 'white',
    borderColor: 'black',
    textColor: 'black',
  },
  disabled: {
    backgroundColor: 'greyLightN',
    borderColor: 'greyLightN',
    textColor: 'black',
  },
} as {
  [index in 'todo' | 'default' | 'disabled']: {
    backgroundColor: Colors
    borderColor: Colors
    textColor: Colors
  }
}

const Card: React.FC<CardProps> = ({
  children,
  collapseChildren,
  title,
  variant,
  defaultOpen,
  onClick,
}) => {
  const [collapse, setCollapse] = useState(defaultOpen)
  const disabled = variant === 'disabled'
  const variantStyle =
    variant in variants ? variants[variant as 'disabled' | 'todo'] : variants['default']

  useEffect(() => {
    defaultOpen && setCollapse(defaultOpen)
  }, [defaultOpen])

  return (
    <Wrapper bc={variantStyle.backgroundColor} brC={variantStyle.borderColor}>
      <Box
        ai="c"
        onClick={() =>
          !disabled ? (collapseChildren ? setCollapse(!collapse) : onClick && onClick()) : null
        }
        style={{
          padding: '16px',
          cursor: disabled ? 'initial' : 'pointer',
          opacity: disabled ? '0.5' : '1',
        }}
      >
        <Box fd="c" jc="sa" style={{ minHeight: '50px' }}>
          <Text color={variantStyle.textColor} bold>
            {title}
          </Text>
          {children}
        </Box>
        {collapseChildren && !disabled && (
          <Icon
            name="caretFill"
            color={variantStyle.textColor}
            rotate={collapse ? 180 : 0}
            size={9}
          />
        )}
      </Box>
      {collapseChildren && collapse && (
        <Box fd="c" style={{ margin: '16px', marginBottom: '8px', marginTop: '0' }}>
          <Separator spacingBottom={16} />
          <Box f="1 0 auto">{collapseChildren}</Box>
        </Box>
      )}
    </Wrapper>
  )
}

export default Card
