import React from 'react'
import styled, { css } from 'styled-components'

import Icon, { IconProps } from '../icon'

const STYLE_LINK = css`
  font-family: Nunito;
  font-weight: bold;
  line-height: 26px;
  font-size: 16px;
  border-radius: 120px 0px 0px 120px;
  box-sizing: border-box;
  height: 58px;
  color: ${props => props.theme.white};

  display: flex;
  align-items: center;

  padding: 16px 0 16px 20px;

  cursor: pointer;

  .menuIcon {
    opacity: 0.4;
    padding-right: 14px;
  }
`

const Container = styled.a<{ active?: boolean }>`
  ${STYLE_LINK};

  &:hover {
    background-color: ${props => props.theme.greenDark};
    opacity: 0.75;
  }

  ${props =>
    props.active
      ? css`
          background-color: ${props => props.theme.greenDark};

          .menuIcon {
            opacity: 1;
          }
        `
      : ''}
`

const DisabledContainer = styled.div`
  ${STYLE_LINK};
  cursor: default;
  opacity: 0.5;

  .menuIcon {
    opacity: 1;
  }
`

export default function MenuItem({
  icon,
  children,
  disabled,
  ...linksProps
}: {
  icon: IconProps['name']
  children: any
  active?: boolean
  disabled?: boolean
} & Omit<React.ComponentProps<'a'>, 'ref'>) {
  return disabled ? (
    <DisabledContainer>
      <Icon className="menuIcon" name={icon} color="white" size={20} />
      {children}
    </DisabledContainer>
  ) : (
    <Container {...linksProps}>
      <Icon className="menuIcon" name={icon} color="white" size={20} />
      {children}
    </Container>
  )
}
