import React, { forwardRef } from 'react'
import styled from 'styled-components'

import Box from 'ui/box'
import Icon from 'ui/icon'

const StyledInputContainer = styled(Box)<{
  hasError?: boolean
  disabled?: boolean
  isHidden?: boolean
  width?: string
  hasBorder?: boolean
}>`
  background-color: white;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};
  border-color: ${props => props.disabled && props.theme.greyDark};
  display: ${props => props.isHidden && 'none'};
  width: ${({ width }) => width || 'auto'};
  border: ${({ hasBorder }) => hasBorder === false && `none`};
`

const StyledInput = styled.input`
  font-family: Nunito;
  line-height: 26px;
  font-size: 16px;
  width: 100%;
  background-color: white;
  padding: 16px;
  border: none;

  color: ${props => props.theme.black};

  &::placeholder {
    font-family: Nunito;
    line-height: 26px;
    font-size: 16px;
    color: ${props => props.theme.greyDark2};
  }

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: ${props => props.theme.black};
  }

  &:disabled {
    cursor: not-allowed;
  }
`

interface InputType {
  hasError?: boolean
  id?: string
  name?: string
  placeholder?: string
  type?: string
  style?: any
  disabled?: boolean
  defaultValue?: any
  required?: boolean
  onChange?: any
  onBlur?: any
  hasBorder?: boolean
  width?: string
}

const Input = forwardRef(
  (
    {
      hasError,
      id,
      name,
      placeholder,
      type,
      style,
      disabled,
      defaultValue,
      required,
      onChange,
      onBlur,
      hasBorder,
      width,
    }: InputType,
    ref: any,
  ) => {
    return (
      <StyledInputContainer
        hasError={hasError}
        disabled={disabled}
        isHidden={type === 'hidden'}
        hasBorder={hasBorder}
        width={width}
      >
        <StyledInput
          id={id}
          name={name}
          placeholder={placeholder}
          type={type !== 'hidden' ? type : undefined}
          style={style}
          defaultValue={defaultValue}
          required={required}
          onChange={onChange}
          ref={ref}
          onBlur={onBlur}
        />
        {disabled && (
          <Box ai="c" padding={'0 18px 0 18px'}>
            <Icon name="lock" color="black" size={20} />
          </Box>
        )}
      </StyledInputContainer>
    )
  },
)

export default Input
