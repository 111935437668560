import React, { useContext } from 'react'
import { AuthContext } from 'App'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import { Footer } from 'components/pages/layout'

import SignInBlock from './blocks/signin'
import AskResetPasswordBlock from './blocks/askResetPassword'
import ResetPasswordBlock from './blocks/resetPassword'
import ResendEmail from './blocks/resendEmail'

import { Auth } from 'types/user'

import Logo from 'components/logo'
import cover from 'assets/images/cover.png'
import { useLocation } from 'react-router-dom'

export default function SignIn({
  onConnected,
  state = 'signin',
}: {
  onConnected?: (auth: Auth) => void
  state: 'signin' | 'ask-reset' | 'reset' | 'emailNotConfirmed'
}) {
  let leftBlock
  const { auth } = useContext(AuthContext)
  const params: { state: { email: string } } = useLocation()

  switch (state) {
    case 'reset':
      leftBlock = <ResetPasswordBlock />
      break
    case 'ask-reset':
      leftBlock = <AskResetPasswordBlock />
      break
    case 'signin':
      if (onConnected) {
        leftBlock = <SignInBlock onConnected={onConnected} />
      }
      break
    case 'emailNotConfirmed':
      leftBlock = <ResendEmail auth={auth} email={params?.state?.email} />
      break
  }

  return (
    <Box fd="r" style={{ width: '100%', minHeight: '100vh' }}>
      <Box style={{ flex: '2' }} bg="greyLightN">
        <Box style={{ padding: '6% 17%' }} fd="c">
          <Logo paddingBottom="17%" darkLogo />
          {leftBlock}
        </Box>
      </Box>
      <Box
        style={{
          flex: '3',
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(196, 196, 196, 0) 100%), url(${cover})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        fd="c"
      >
        <Box style={{ padding: '20% 0 0 15%' }} fd="c">
          <Title
            fs={32}
            style={{ paddingRight: '30px', lineHeight: '140%', textDecoration: 'underline' }}
            color="white"
          >
            Accompagner
          </Title>
          <Title
            fs={32}
            style={{ paddingRight: '30px', lineHeight: '140%', textDecoration: 'underline' }}
            color="white"
          >
            Guider
          </Title>
          <Title
            fs={32}
            style={{ paddingRight: '30px', lineHeight: '140%', textDecoration: 'underline' }}
            color="white"
          >
            Écouter
          </Title>
          <Text fs={16} color="white" style={{ paddingTop: '24px' }}>
            Créez votre compte en moins de 3 minutes. <br />
            Une aide et un gain de temps précieux.
          </Text>
        </Box>
        <Footer bg="greyLightN" />
      </Box>
    </Box>
  )
}
