import React from 'react'

import Layout from '../../edit/blocks/layout'
import { Form } from 'types/form'
import { INCLUSIVE_ESTABLISHMENT_STATUS, INCLUSIVE_STRUCTURE_TYPE } from 'types/enums'
import { countries } from 'constants/country'
import { STRUCTURE_PLACE_OPTIONS } from 'constants/form'

const INFOS_FORM: Form = [
  [
    {
      title: 'Informations sur le lieu de vie',
      name: 'structurePlace',
      type: 'select',
      label: 'Type de lieu de vie',
      placeholder: 'Saisissez le type de lieu de vie',
      required: true,
      multi: false,
      options: STRUCTURE_PLACE_OPTIONS,
    },
    {
      name: 'managerName',
      type: 'text',
      label: 'Nom du gestionnaire du lieu de vie',
      placeholder: 'Saisissez le nom gestionnaire du lieu de vie',
      required: true,
    },
  ],
  [
    {
      name: 'institutionName',
      type: 'text',
      label: 'Nom du lieu de vie',
      placeholder: 'Saisissez le nom du lieu de vie',
      required: true,
    },
    {
      name: 'progress',
      type: 'select',
      label: 'État d’avancement',
      placeholder: 'Sélectionnez l’état d’avancement',
      required: false,
      multi: false,
      options: [
        {
          label: 'Actif',
          value: INCLUSIVE_ESTABLISHMENT_STATUS.ACTIVE,
        },
        { label: 'Ouverture prochaine', value: INCLUSIVE_ESTABLISHMENT_STATUS.SOON },
        { label: 'En projet', value: INCLUSIVE_ESTABLISHMENT_STATUS.INPROJECT },
      ],
    },
  ],
  [
    {
      name: 'street',
      type: 'text',
      label: 'Adresse postale',
      placeholder: "Saissez l'adresse postale",
      required: false,
      disabled: false,
    },
  ],
  [
    {
      name: 'region',
      type: 'text',
      label: 'Region',
      placeholder: 'Saisissez la région',
      required: false,
      disabled: false,
    },
    {
      name: 'zipcode',
      type: 'text',
      label: 'Code postal',
      placeholder: 'Saisissez le code postal',
      required: true,
      disabled: false,
    },
  ],
  [
    {
      name: 'city',
      type: 'text',
      label: 'Ville',
      placeholder: 'Saisissez la ville',
      required: true,
      disabled: false,
    },
    {
      name: 'country',
      type: 'select',
      label: 'Pays',
      placeholder: 'Saisissez le pays',
      required: true,
      disabled: false,
      options: countries,
    },
  ],
  [
    {
      name: 'website',
      type: 'text',
      label: 'Site web du lieu de vie',
      placeholder: "Saisissez l'URL du site web",
      required: false,
    },
  ],
  [
    {
      title: 'Information de contact',
      name: 'projectHolder',
      type: 'text',
      label: 'Nom du porteur du projet d’habitat',
      placeholder: "Saisissez le nom du porteur de projet d'habitat",
      required: true,
    },
    {
      name: 'structureType',
      type: 'select',
      label: 'Type de structure',
      placeholder: 'Sélectionnez le type de structure',
      required: false,
      multi: false,
      options: [
        {
          label: 'Associative',
          value: INCLUSIVE_STRUCTURE_TYPE.ASSOCIATIVE,
        },
        { label: 'Publique', value: INCLUSIVE_STRUCTURE_TYPE.PUBLIC },
        { label: 'Privée', value: INCLUSIVE_STRUCTURE_TYPE.PRIVATE },
      ],
    },
  ],
  [
    {
      name: 'mail',
      type: 'text',
      label: 'Adresse email',
      placeholder: "Saisissez l'adresse email",
      required: true,
    },
    {
      name: 'phoneNumber',
      type: 'phone',
      label: 'Numéro de téléphone',
      placeholder: 'Numéro de téléphone',
      required: false,
    },
  ],
]

export default function InclusiveInfos() {
  return (
    <Layout
      title="Informations du lieu de vie"
      subTitle="Éditez les informations concernant votre lieu de vie"
      ctaText="Enregistrer et quitter"
      hideCTAIcon
      subText=""
      help={{
        icon: 'building',
        title: 'A quoi serviront ces informations ?',
        text:
          "Sahanest veut pouvoir s'assurer que votre lieu de vie ou votre projet est bien réel.  D'autre part, certaines de ces informations permettront aux candidats de localiser votre initiative et d'avoir un point de contact pour créer une première rencontre.",
      }}
      fields={INFOS_FORM}
      subForm="info"
    />
  )
}
