export const nationalities = [
  { value: 'FR', label: 'Française' },
  { value: 'CH', label: 'Suisse' },
  { value: 'BE', label: 'Belge' },
  { value: 'DE', label: 'Allemande' },
  { value: 'IT', label: 'Italienne' },
  { value: 'AF', label: 'Afghane' },
  { value: 'AL', label: 'Albanaise' },
  { value: 'DZ', label: 'Algerienne' },
  { value: 'US', label: 'Americaine' },
  { value: 'AD', label: 'Andorrane' },
  { value: 'AO', label: 'Angolaise' },
  { value: 'AG', label: 'Antiguaise et barbudienne' },
  { value: 'AR', label: 'Argentine' },
  { value: 'AM', label: 'Armenienne' },
  { value: 'AU', label: 'Australienne' },
  { value: 'AT', label: 'Autrichienne' },
  { value: 'AZ', label: 'Azerbaïdjanaise' },
  { value: 'BS', label: 'Bahamienne' },
  { value: 'BH', label: 'Bahreinienne' },
  { value: 'BD', label: 'Bangladaise' },
  { value: 'BB', label: 'Barbadienne' },
  { value: 'BZ', label: 'Belizienne' },
  { value: 'BJ', label: 'Beninoise' },
  { value: 'BT', label: 'Bhoutanaise' },
  { value: 'BY', label: 'Bielorusse' },
  { value: 'MM', label: 'Birmane' },
  { value: 'GW', label: 'Bissau-Guinéenne' },
  { value: 'BO', label: 'Bolivienne' },
  { value: 'BA', label: 'Bosnienne' },
  { value: 'BW', label: 'Botswanaise' },
  { value: 'BR', label: 'Bresilienne' },
  { value: 'UK', label: 'Britannique' },
  { value: 'BN', label: 'Bruneienne' },
  { value: 'BG', label: 'Bulgare' },
  { value: 'BF', label: 'Burkinabe' },
  { value: 'BI', label: 'Burundaise' },
  { value: 'KH', label: 'Cambodgienne' },
  { value: 'CM', label: 'Camerounaise' },
  { value: 'CA', label: 'Canadienne' },
  { value: 'CV', label: 'Cap-verdienne' },
  { value: 'CF', label: 'Centrafricaine' },
  { value: 'CL', label: 'Chilienne' },
  { value: 'CN', label: 'Chinoise' },
  { value: 'CY', label: 'Chypriote' },
  { value: 'CO', label: 'Colombienne' },
  { value: 'KM', label: 'Comorienne' },
  { value: 'CG', label: 'Congolaise' },
  { value: 'CR', label: 'Costaricaine' },
  { value: 'HR', label: 'Croate' },
  { value: 'CU', label: 'Cubaine' },
  { value: 'CW', label: 'Curaçaoane' },
  { value: 'DK', label: 'Danoise' },
  { value: 'DJ', label: 'Djiboutienne' },
  { value: 'DO', label: 'Dominicaine' },
  { value: 'DM', label: 'Dominiquaise' },
  { value: 'EG', label: 'Egyptienne' },
  { value: 'AE', label: 'Emirienne' },
  { value: 'GQ', label: 'Equato-guineenne' },
  { value: 'EC', label: 'Equatorienne' },
  { value: 'ER', label: 'Erythreenne' },
  { value: 'ES', label: 'Espagnole' },
  { value: 'TL', label: 'Est-timoraise' },
  { value: 'EE', label: 'Estonienne' },
  { value: 'ET', label: 'Ethiopienne' },
  { value: 'FJ', label: 'Fidjienne' },
  { value: 'FI', label: 'Finlandaise' },
  { value: 'GA', label: 'Gabonaise' },
  { value: 'GM', label: 'Gambienne' },
  { value: 'GE', label: 'Georgienne' },
  { value: 'GH', label: 'Ghaneenne' },
  { value: 'GD', label: 'Grenadienne' },
  { value: 'GT', label: 'Guatemalteque' },
  { value: 'GN', label: 'Guineenne' },
  { value: 'GY', label: 'Guyanienne' },
  { value: 'HT', label: 'Haïtienne' },
  { value: 'GR', label: 'Hellenique' },
  { value: 'HN', label: 'Hondurienne' },
  { value: 'HU', label: 'Hongroise' },
  { value: 'IN', label: 'Indienne' },
  { value: 'ID', label: 'Indonesienne' },
  { value: 'IQ', label: 'Irakienne' },
  { value: 'IE', label: 'Irlandaise' },
  { value: 'IS', label: 'Islandaise' },
  { value: 'IL', label: 'Israélienne' },
  { value: 'CI', label: 'Ivoirienne' },
  { value: 'JM', label: 'Jamaïcaine' },
  { value: 'JP', label: 'Japonaise' },
  { value: 'JO', label: 'Jordanienne' },
  { value: 'KZ', label: 'Kazakhstanaise' },
  { value: 'KE', label: 'Kenyane' },
  { value: 'KG', label: 'Kirghize' },
  { value: 'KI', label: 'Kiribatienne' },
  { value: 'KN', label: 'Kittitienne-et-nevicienne' },
  { value: 'XK', label: 'Kossovienne' },
  { value: 'KW', label: 'Koweitienne' },
  { value: 'LA', label: 'Laotienne' },
  { value: 'LS', label: 'Lesothane' },
  { value: 'LV', label: 'Lettone' },
  { value: 'LB', label: 'Libanaise' },
  { value: 'LR', label: 'Liberienne' },
  { value: 'LY', label: 'Libyenne' },
  { value: 'LI', label: 'Liechtensteinoise' },
  { value: 'LT', label: 'Lituanienne' },
  { value: 'LU', label: 'Luxembourgeoise' },
  { value: 'MK', label: 'Macedonienne' },
  { value: 'MY', label: 'Malaisienne' },
  { value: 'MW', label: 'Malawienne' },
  { value: 'MV', label: 'Maldivienne' },
  { value: 'MG', label: 'Malgache' },
  { value: 'ML', label: 'Malienne' },
  { value: 'MT', label: 'Maltaise' },
  { value: 'MA', label: 'Marocaine' },
  { value: 'MH', label: 'Marshallaise' },
  { value: 'MU', label: 'Mauricienne' },
  { value: 'MR', label: 'Mauritanienne' },
  { value: 'MX', label: 'Mexicaine' },
  { value: 'FM', label: 'Micronesienne' },
  { value: 'MD', label: 'Moldave' },
  { value: 'MC', label: 'Monegasque' },
  { value: 'MN', label: 'Mongole' },
  { value: 'ME', label: 'Montenegrine' },
  { value: 'MZ', label: 'Mozambicaine' },
  { value: 'NA', label: 'Namibienne' },
  { value: 'NR', label: 'Nauruane' },
  { value: 'NL', label: 'Neerlandaise' },
  { value: 'NZ', label: 'Neo-zelandaise' },
  { value: 'NP', label: 'Nepalaise' },
  { value: 'NI', label: 'Nicaraguayenne' },
  { value: 'NG', label: 'Nigeriane' },
  { value: 'NE', label: 'Nigerienne' },
  { value: 'KP', label: 'Nord-coréenne' },
  { value: 'NO', label: 'Norvegienne' },
  { value: 'OM', label: 'Omanaise' },
  { value: 'UG', label: 'Ougandaise' },
  { value: 'UZ', label: 'Ouzbeke' },
  { value: 'PK', label: 'Pakistanaise' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestinienne' },
  { value: 'PA', label: 'Panameenne' },
  { value: 'PG', label: 'Papouane-neoguineenne' },
  { value: 'PY', label: 'Paraguayenne' },
  { value: 'PE', label: 'Peruvienne' },
  { value: 'PH', label: 'Philippine' },
  { value: 'PL', label: 'Polonaise' },
  { value: 'PR', label: 'Portoricaine' },
  { value: 'PT', label: 'Portugaise' },
  { value: 'QA', label: 'Qatarienne' },
  { value: 'RO', label: 'Roumaine' },
  { value: 'RU', label: 'Russe' },
  { value: 'RW', label: 'Rwandaise' },
  { value: 'LC', label: 'Saint-Lucienne' },
  { value: 'SM', label: 'Saint-Marinaise' },
  { value: 'SX', label: 'Saint-Martinoise' },
  { value: 'VC', label: 'Saint-Vincentaise-et-Grenadine' },
  { value: 'SB', label: 'Salomonaise' },
  { value: 'SV', label: 'Salvadorienne' },
  { value: 'WS', label: 'Samoane' },
  { value: 'ST', label: 'Santomeenne' },
  { value: 'SA', label: 'Saoudienne' },
  { value: 'SN', label: 'Senegalaise' },
  { value: 'SS', label: 'Sud-Soudanaise' },
  { value: 'RS', label: 'Serbe' },
  { value: 'SC', label: 'Seychelloise' },
  { value: 'SL', label: 'Sierra-leonaise' },
  { value: 'SG', label: 'Singapourienne' },
  { value: 'SK', label: 'Slovaque' },
  { value: 'SI', label: 'Slovene' },
  { value: 'SO', label: 'Somalienne' },
  { value: 'SD', label: 'Soudanaise' },
  { value: 'LK', label: 'Sri-lankaise' },
  { value: 'ZA', label: 'Sud-africaine' },
  { value: 'KR', label: 'Sud-coréenne' },
  { value: 'SE', label: 'Suedoise' },
  { value: 'SR', label: 'Surinamaise' },
  { value: 'SZ', label: 'Swazie' },
  { value: 'SY', label: 'Syrienne' },
  { value: 'TJ', label: 'Tadjike' },
  { value: 'TW', label: 'Taiwanaise' },
  { value: 'TZ', label: 'Tanzanienne' },
  { value: 'TD', label: 'Tchadienne' },
  { value: 'CZ', label: 'Tcheque' },
  { value: 'TH', label: 'Thaïlandaise' },
  { value: 'TG', label: 'Togolaise' },
  { value: 'TO', label: 'Tonguienne' },
  { value: 'TT', label: 'Trinidadienne' },
  { value: 'TN', label: 'Tunisienne' },
  { value: 'TM', label: 'Turkmene' },
  { value: 'TR', label: 'Turque' },
  { value: 'TV', label: 'Tuvaluane' },
  { value: 'UA', label: 'Ukrainienne' },
  { value: 'UY', label: 'Uruguayenne' },
  { value: 'VA', label: 'Vaticane' },
  { value: 'VU', label: 'Vanuatuane' },
  { value: 'VE', label: 'Venezuelienne' },
  { value: 'VN', label: 'Vietnamienne' },
  { value: 'YE', label: 'Yemenite' },
  { value: 'ZM', label: 'Zambienne' },
  { value: 'ZW', label: 'Zimbabweenne' },
]
