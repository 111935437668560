import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import Icon from 'ui/icon'
import { Colors } from 'ui/theme'

type StepVariant = 'todo' | 'pending' | 'completed'

const variantsColors: { pending: Colors; completed: Colors } = {
  pending: 'white',
  completed: 'white',
}

const Item = styled(Text)`
  margin-bottom: 32px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
  span {
    font-size: 12px;
    font-style: italic;
  }
`

const Marker = styled(Box).attrs({ height: '48px', width: '48px', ai: 'c', jc: 'c' })<{
  variant: StepVariant
}>`
  border-radius: 120px;
  box-sizing: border-box;
  border: 2px solid ${props => props.theme[get(variantsColors, props.variant, 'white') as Colors]};
  background-color: ${props =>
    props.variant === 'todo' ? 'transparent' : props.theme[variantsColors[props.variant]]};
  margin-right: 24px;
`

export const Step: React.FC<{ variant: StepVariant; index: string; isOptional?: boolean }> = ({
  variant,
  index,
  children,
  isOptional,
}) => (
  <Item color="white" as="div">
    <Marker variant={variant}>
      {variant === 'completed' ? (
        <Icon name="check" color="green" size={24} />
      ) : (
        <Text bold color={variant === 'todo' ? 'white' : 'green'}>
          {index}
        </Text>
      )}
    </Marker>

    <Text color="white" opacity={variant === 'todo' ? 0.3 : 1}>
      {children} {isOptional && <span>(faculatif)</span>}
    </Text>
  </Item>
)

const Stepper = styled.div`
  display: flex;
  flex-direction: column;
`

export default Stepper
