import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Box from 'ui/box'
import CardDescription from 'ui/card/description'
import Icon from 'ui/icon'
import { LinkButton } from 'ui/button'
import Navbar from 'ui/navbar'
import Text from 'ui/typography/text'
import { ellipseLeft, ellipseRight } from 'assets/illustrations'
import { useWebflowId } from 'hooks/useParamId'

export const descriptionsList = [
  {
    title: 'Gérer',
    description: 'Gérez en ligne de vos résidents ainsi qu’une visualisation des visites.',
  },
  {
    title: 'Faciliter',
    description: 'Faciliter vos transferts de dossiers lors des déplacements de résidents.',
  },
  {
    title: 'Simplifier',
    description: 'Simplifiez l’accueil de vos futurs résidents avec vos trousseaux personnalisés.',
  },
  {
    title: 'Digitaliser',
    description: 'Digitalisation complète et de qualité de vos dossiers d’admissions.',
  },
] as { title: string; description: string }[]

const BackgroundContainer = styled(Box)`
  position: absolute;
  top: 148px;
  overflow: hidden;
  background: ${props => props.theme.greyLight3};

  .imageRight {
    position: relative;
    top: 268px;
  }
`

const GreenText = styled.b`
  font-weight: 700;
  color: ${props => props.theme.green};
`

export default function PresInscription() {
  const history = useHistory()
  const webflowId = useWebflowId()

  return (
    <Box fd="c" bg="greyLight3" height="100vh">
      <Navbar
        yellowButtonLabel="Déjà un compte ?"
        onClick={() => history.push('connexion')}
        hasLogo
      />

      <Box>
        <Box ai="c" fd="c" style={{ zIndex: 20 }}>
          <Text align="center" bold fs={32} style={{ marginTop: 86 }}>
            Avec Sahanest,
            <GreenText> digitalisez et simplifiez</GreenText>
          </Text>
          <Text align="center" bold fs={32} style={{ marginBottom: 86 }}>
            la gestion des admissions dans votre lieu d&quot;accueil
          </Text>

          <Box height="430px">
            <CardDescription
              title={'Visibilité de votre établissement'}
              description={
                'Mettez en avant votre établissement grâce à une page dédiée & accompagnée d’une description riche et rassurante.'
              }
              isSmallCard={false}
              style={{ height: '430px' }}
            />

            <Box style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              {descriptionsList.map(({ title, description }, index) => (
                <CardDescription
                  key={index}
                  title={title}
                  description={description}
                  isSmallCard
                  style={{ margin: '0 0 10px 10px' }}
                />
              ))}
            </Box>
          </Box>
          <LinkButton
            className="primary"
            href={
              webflowId
                ? `/institution/inscription?webflow_item_id=${webflowId}`
                : '/institution/inscription'
            }
            style={{ margin: '126px 0 0 618px' }}
          >
            Créer un compte pour mon établissement
            <Box style={{ marginLeft: '10px' }}>
              <Icon name="arrow" size={16} />
            </Box>
          </LinkButton>
        </Box>

        <BackgroundContainer width="100%" jc="sb">
          <img src={ellipseLeft} alt="illustration" />
          <img src={ellipseRight} alt="illustration" className="imageRight" />
        </BackgroundContainer>
      </Box>
    </Box>
  )
}
