import React, { useEffect, useState, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import Input from 'ui/form/input'
import InputPhone from 'ui/form/inputPhone'
import Dropdown from 'ui/form/dropdown'
import StepLayout from './stepLayout'
import { countries } from 'constants/country'
import { State } from '..'
import { ESTABLISHMENT_TYPES } from 'types/enums'
import { getCitiesByZipCode } from 'api/laposte'
import { formatLaposteCityList } from '../helpers'

interface Props {
  onNext: (data: State['data']['info']) => void
  onPrevious: () => void
  defaultValues?: Partial<State['data']['info']>
}

const establishmentTypes = [
  {
    label: "Maison d'accueil spécialisée",
    value: ESTABLISHMENT_TYPES.MAS,
  },
  { label: "Foyer d'accueil médicalisé", value: ESTABLISHMENT_TYPES.FAM },
  { label: 'Foyer occupationnel ou de vie', value: ESTABLISHMENT_TYPES.FO_FV },
]

const StepEstablishment = ({ onNext, onPrevious, defaultValues }: Props) => {
  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: { ...defaultValues, country: 'France' },
  })
  const [cities, setCities] = useState<string[]>([])

  // Create options List for City Dropdown (when cities state is Changing)
  const citiesList = useMemo(() => formatLaposteCityList(cities), [cities])

  // Launch Fetch or Clear Cities State when zipcode Field is Changing
  useEffect(() => {
    watch('country') === 'France' && watch('zipcode')?.length === 5
      ? getCitiesByZipCode(watch('zipcode')).then(setCities)
      : setCities([])
  }, [watch('zipcode')])

  return (
    <StepLayout
      spacingBottom="8px"
      title="Informations de l’établissement"
      subText="Ces informations seront visibiles sur la fiche de votre établissement."
    >
      <Box as="form" onSubmit={handleSubmit(onNext)} autoComplete="off" fd="c">
        <Box ai="s" f="0 1 auto">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="socialReason" required style={{ paddingBottom: '16px' }}>
              Raison sociale
            </Label>
            <Input
              id="socialReason"
              name="socialReason"
              placeholder="Saisissez la raison sociale"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.socialReason ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="siretNumber" required style={{ paddingBottom: '16px' }}>
              Numéro de SIRET / BCE
            </Label>
            <Input
              id="siretNumber"
              name="siretNumber"
              placeholder="Saisissez votre numéro de SIRET / BCE"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.siretNumber ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <Box ai="s" f="0 1 auto">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="institutionType" required style={{ paddingBottom: '16px' }}>
              Type d&apos;établissement
            </Label>
            <Controller
              as={Dropdown}
              id={'institutionType'}
              name={`institutionType`}
              placeholder="Saisissez le type d'établissement"
              options={establishmentTypes}
              control={control}
              isMulti={true}
              rules={{ required: true }}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.institutionType ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="institutionName" required style={{ paddingBottom: '16px' }}>
              Nom de l&apos;établissement
            </Label>
            <Input
              id="institutionName"
              name="institutionName"
              placeholder="Saisissez le nom de l'établissement"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.institutionName ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <Box ai="s" f="0 1 auto">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="country" required style={{ paddingBottom: '16px' }}>
              Pays
            </Label>
            <Controller
              as={Dropdown}
              id={'country'}
              name={`country`}
              placeholder="Saisissez le pays"
              options={countries}
              control={control}
              rules={{ required: true }}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.country ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="street" required style={{ paddingBottom: '16px' }}>
              Adresse postale
            </Label>
            <Input
              id="street"
              name="street"
              placeholder="Saissez l'adresse postale"
              ref={register({ required: true })}
            />
            <Text fs={16} color="pink" style={{ visibility: errors.street ? 'visible' : 'hidden' }}>
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <Box ai="s" f="0 1 auto">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="region" style={{ paddingBottom: '16px' }}>
              Région
            </Label>
            <Input id="region" name="region" placeholder="Saisissez la région" ref={register({})} />
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="zipcode" required style={{ paddingBottom: '16px' }}>
              Code postal
            </Label>
            <Input
              id="zipcode"
              name="zipcode"
              type="number"
              placeholder="Saisissez le code postal"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.zipcode ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="city" required style={{ paddingBottom: '16px' }}>
              Ville
            </Label>
            {watch('country') === 'France' ? (
              <Controller
                as={Dropdown}
                id={'city'}
                name={`city`}
                placeholder="Sélectionnez la ville"
                options={citiesList}
                disabled={!citiesList.length}
                control={control}
                rules={{ required: true }}
              />
            ) : (
              <Input
                id="city"
                name="city"
                placeholder="Saisissez la ville"
                ref={register({ required: true })}
              />
            )}
            <Text fs={16} color="pink" style={{ visibility: errors.city ? 'visible' : 'hidden' }}>
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <Box ai="s" f="0 1 auto">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="website" style={{ paddingBottom: '16px' }}>
              Site internet
            </Label>
            <Input
              id="website"
              name="website"
              placeholder="Saisissez le site internet"
              ref={register({})}
            />
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="phoneNumber" required style={{ paddingBottom: '16px' }}>
              Numéro de téléphone
            </Label>
            <Controller
              as={InputPhone}
              id="phoneNumber"
              name="phoneNumber"
              control={control}
              placeholder="Numéro de téléphone de l'établissement"
              rules={{ required: true }}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.phoneNumber ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <Box jc="sb" f="0 1 auto">
          <Button variant="outline" style={{ backgroundColor: 'white' }} onClick={onPrevious}>
            <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
            Étape précédente
          </Button>
          <Button type="submit">
            Étape suivante
            <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
          </Button>
        </Box>
      </Box>
    </StepLayout>
  )
}

export default StepEstablishment
