import React from 'react'
import styled from 'styled-components'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'

const DescriptionHeader = styled.div<{ isSmallCard: boolean }>`
  flex-direction: ${props => (props.isSmallCard ? 'row' : 'column')};
  display: flex;
  align-items: center;
  padding: ${props => (props.isSmallCard ? '24px' : 'O 24px')};

  .icon {
    margin: ${props => (props.isSmallCard ? ' 0 18px 0 0 ' : '0 0 24px 0')};
  }
`
const Divider = styled(Box)`
  max-height: 1px;
  background: ${props => props.theme.greyTransparent};
`

const CardDescription: React.FC<{
  title: string
  description: string
  style?: React.CSSProperties
  isSmallCard: boolean
}> = ({ title, description, style, isSmallCard }) => {
  return (
    <Box jc="c" fd="c" bg="white" withBorder width="310px" height="210px" style={style}>
      <DescriptionHeader isSmallCard={isSmallCard}>
        <Icon name="magicwand" size={16} color="white" className="icon" />
        <Text fs={24} bold color="green" align={isSmallCard ? 'left' : 'center'}>
          {title}
        </Text>
      </DescriptionHeader>
      {isSmallCard && <Divider />}
      <Text
        opacity={0.6}
        align={isSmallCard ? 'left' : 'center'}
        fs={16}
        style={{ padding: isSmallCard ? '20px' : '24px 35px' }}
      >
        {description}
      </Text>
    </Box>
  )
}

export default CardDescription
