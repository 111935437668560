export enum PENSION_TYPE {
  FULL_PENSION = 'full_pension',
  HALF_PENSION = 'half_pension',
  OTHER = 'other',
}

export type ItemKit = { name: string; qte: string }

export type KitPayload = {
  id: string
  name: string
  shoesAndClothes?: { [key: string]: string }
  administrativeDocuments?: { [key: string]: string }
  toiletKit?: { [key: string]: string }
  medical?: { [key: string]: string }
  createdAt: string
  typeList: PENSION_TYPE
}

type Kit = {
  id?: string
  name?: string
  type?: PENSION_TYPE
  shoesAndClothes?: ItemKit[]
  administrativeDocuments?: ItemKit[]
  toiletKit?: ItemKit[]
  medical?: ItemKit[]
}

export default Kit
