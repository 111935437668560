import React from 'react'

import { GenericForm } from '../../form'
import Intro from 'components/pages/intro'

import { time } from 'assets/illustrations'

export default function Medical() {
  return (
    <GenericForm<{}>
      key="medical-form"
      title="Renseignements médicaux"
      path="medical"
      resourceLoader={() => Promise.resolve({})}
      globalStep={7}
      steps={() => [
        {
          title: 'Introduction',
          Component: () => (
            <Intro
              image={time}
              sections={[
                {
                  title: 'Le médecin complète cette section',
                  text:
                    "Cette section est remplie et signée par votre médecin renseigné précédemment. Suivant sa disponibilité, cela peut prendre plusieurs jours. N'hésitez pas à le relancer, lui et son équipe.",
                },
                {
                  title:
                    'Vous recevrez un e-mail lorsque le médecin aura rempli et signé cette section',
                  text: 'Vous pourrez alors passer à la section suivante',
                },
              ]}
            />
          ),
        },
      ]}
    />
  )
}
