import { filter, get, map, uniqueId } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import Box from 'ui/box'
import File from 'ui/form/file'
import Input from 'ui/form/input'
import Group from 'ui/form/group'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'

import TDocument from 'types/document'
import { deleteFile } from 'api/form'
import { useToasts } from 'hooks/useToaster'

const FileContainer = styled(Box).attrs({
  jc: 'sb',
  ai: 'c',
  withBorder: true,
  br: 4,
  padding: '24px',
})`
  margin-bottom: 8px;

  ${Text} {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 220px;
  }
`

export default function FreeDocumentForm({
  resource,
  refreshResource,
  admissionRecordId,
}: {
  resource: TDocument
  refreshResource: () => void
  admissionRecordId: string
}) {
  const { addToast, addGenericErrorToast } = useToasts()
  const { register, unregister, setValue, getValues, reset } = useFormContext()
  const [oldFiles, setOldFiles] = useState(get(resource.free, 'files', []))
  const [files, setFiles] = useState<{ id: string; file: File }[]>([])

  useEffect(() => {
    register({ name: 'frees.files' })

    return () => {
      unregister('frees.files')
    }
  }, [register, unregister])

  useEffect(() => {
    setValue('frees.id', resource['free']['id'])

    // DO NOT RESET THE FORM WHEN DELETING AN ALREADY UPLOADED FILE
    if (oldFiles.length < get(resource.free, 'files', []).length) {
      setFiles([])
      reset()
    }

    setOldFiles(get(resource.free, 'files', []))
  }, [resource])

  return (
    <Box fd="c" style={{ marginBottom: '32px' }}>
      <Input id="id" name="frees.id" type="hidden" ref={register()} />
      <Box f="initial" style={{ marginBottom: '32px' }}>
        <Group>
          <File
            id="fakeInput"
            name="fakeInput"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files && e.target.files[0]) {
                setValue('frees.files', [...(getValues('frees.files') || []), e.target.files[0]])
                setFiles([{ id: uniqueId(), file: e.target.files[0] }, ...files])
              }
            }}
          />
        </Group>
      </Box>
      <Box fd="c" f="initial">
        <Text fs={16} bold spacingBottom="16px">
          Documents libres
        </Text>
        {files.length + oldFiles.length > 0 ? (
          map([...files, ...oldFiles], item => (
            <FileContainer key={`file-${item.id}`}>
              <Box ai="c">
                <Icon
                  name="transfer"
                  size={24}
                  color="white"
                  bg="green"
                  cp="12px"
                  br={4}
                  containerStyle={{ marginRight: '16px' }}
                />

                <Box fd="c">
                  <Text fs={16} color="black">
                    {get(item, 'file.name') || get(item, 'fileName')}
                  </Text>
                  <Text fs={12} opacity={0.5}>
                    {get(item, 'createdAt')
                      ? `Ajouté le ${new Date(get(item, 'createdAt')).toLocaleDateString()}`
                      : 'Pas encore sauvegardé'}
                  </Text>
                </Box>
              </Box>
              <Icon
                name="close"
                color="pink"
                size={14}
                onClick={() => {
                  get(item, 'fileId')
                    ? deleteFile(admissionRecordId, item.id)
                        .then(refreshResource)
                        .then(() =>
                          addToast({
                            title: 'Succès !',
                            type: 'success',
                            message: 'Document supprimé',
                          }),
                        )
                        .catch(addGenericErrorToast)
                    : setFiles(filter(files, f => f.id !== item.id))
                }}
              />
            </FileContainer>
          ))
        ) : (
          <Text opacity={0.7}>Aucun</Text>
        )}
      </Box>
    </Box>
  )
}
