import React from 'react'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'

const StepLayout: React.FC<{
  title: string
  subText: string
  isOptional?: boolean
  spacingBottom?: string
}> = ({ title, subText, spacingBottom, isOptional, children }) => (
  <Box padding="8% 12% 64px 12%" fd="c">
    <Box fd="c">
      <Box fd="r" ai="c" style={{ marginBottom: spacingBottom || '8%', flex: 0 }}>
        <Title fs={28} bold>
          {title}
        </Title>
        {isOptional && (
          <Text italic color="grey" style={{ margin: '10px 0 0 4px' }}>
            (facultatif)
          </Text>
        )}
      </Box>
      <Text opacity={0.6} spacingBottom={spacingBottom || '8%'}>
        {subText}
      </Text>
      <Box fd="c">{children}</Box>
    </Box>
  </Box>
)

export default StepLayout
