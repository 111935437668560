import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'
import { get, find, map, uniqueId } from 'lodash'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import File from 'ui/form/file'
import Radio from 'ui/form/radio'
import Input from 'ui/form/input'
import Dropdown from 'ui/form/dropdown'
import Rank from 'ui/form/rank'
import Textarea from 'ui/form/textarea'
import Toggle from 'ui/form/toggle'
import Checkbox from 'ui/form/checkbox'
import InputPhone from 'ui/form/inputPhone'
import SelectMultiple from 'ui/form/selectMultiple'

import { Inputs } from 'types/form'
import Picture from 'ui/form/picture'

export const formatedAddress = (
  street: string,
  zipcode: string,
  country: string,
  region?: string,
) => {
  return [street, region, zipcode, country].filter(e => e).join(', ')
}

export const isValidEmail = (email: string) =>
  new RegExp(/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(email)

const Label = styled(Text).attrs({ fs: 16, opacity: 0.5 })`
  padding-right: 12px;
`
const Value = styled(Text).attrs({ fs: 16, bold: true })``

export const renderSerialize = (input: Inputs, value?: any) => {
  const { label } = input
  if (!label) return

  let returnComponent

  switch (input.type) {
    case 'select':
    case 'radio':
      returnComponent = (
        <Value>
          {value instanceof Array
            ? map(value, (v, i) => (
                <Fragment key={uniqueId(`${input.name}-value-${i}-`)}>
                  {get(find(input.options, ['value', `${v}`]), 'label', '-')}
                  <br />
                </Fragment>
              ))
            : get(find(input.options, ['value', `${value}`]), 'label', '-')}
        </Value>
      )
      break
    case 'checkbox':
      returnComponent = (
        <Value>
          {value.length > 0
            ? input.vertical
              ? map(value as Array<string>, (v, i) => (
                  <Fragment key={uniqueId(`${input.name}-value-${i}-`)}>
                    {get(
                      find(
                        input.options.map(o => ({ ...o, value: `${o.value}` })),
                        ['value', v],
                      ),
                      'label',
                    )}
                    <br />
                  </Fragment>
                ))
              : map(value as Array<string>, (v, i) => (
                  <Fragment key={uniqueId(`${input.name}-value-${i}-`)}>
                    {get(
                      find(
                        input.options.map(o => ({ ...o, value: `${o.value}` })),
                        ['value', v],
                      ),
                      'label',
                    )}
                  </Fragment>
                )).join(', ')
            : '-'}
        </Value>
      )
      break
    case 'date':
      returnComponent = (
        <Value>{value === '-' ? value : new Date(value).toLocaleDateString()}</Value>
      )
      break
    default:
      returnComponent = <Value>{value || '-'}</Value>
  }

  return (
    <Box fd="r" key={uniqueId(`-${input.name}-`)}>
      <Label>{label} :</Label>
      {returnComponent}
    </Box>
  )
}

export const renderInput = (
  formName: string,
  inputInfos: Inputs,
  control: ReturnType<typeof useForm>['control'],
  register: ReturnType<typeof useForm>['register'],
  watch?: ReturnType<typeof useForm>['watch'],
  defaultValues?: any,
) => {
  const { name, type, placeholder, rules, disabled, hasError, index } = inputInfos

  const computedRules = {
    ...rules,
    ...(inputInfos.required
      ? {
          required: 'Ce champ est requis',
        }
      : {}),
  }

  switch (inputInfos.type) {
    case 'radio':
      return (
        <Box fd={inputInfos.vertical ? 'c' : 'r'} ai="s" jc="fs">
          {inputInfos.options.map(({ value, label }, i) => (
            <Radio
              key={`${name}-${i}`}
              id={`${name}-${i}`}
              name={`${formName}.${name}`}
              value={`${value}`}
              containerStyle={{ marginBottom: '18px' }}
              forwardRef={register({ ...computedRules })}
              hasError={hasError}
              disabled={disabled}
            >
              {label}
            </Radio>
          ))}
        </Box>
      )
    case 'checkbox':
      return (
        <Box
          fd={inputInfos.vertical ? 'c' : 'r'}
          ai="s"
          jc="fs"
          style={inputInfos.multiColumn ? { flexWrap: 'wrap' } : {}}
        >
          {inputInfos.options.map(({ value, label, icon }, i) => (
            <Checkbox
              key={`${name}-${i}`}
              id={`${name}-${i}`}
              name={`${formName}.${name}`}
              value={`${value}`}
              containerStyle={{
                ...(inputInfos.options.length === i + 1 ? {} : { marginBottom: '18px' }),
                ...(inputInfos.multiColumn ? { minWidth: '30%', maxWidth: '30%' } : {}),
              }}
              hasError={hasError}
              forwardRef={register({ ...computedRules })}
              disabled={disabled}
              icon={icon}
            >
              {label}
            </Checkbox>
          ))}
        </Box>
      )
    case 'file':
      return (
        <>
          <File
            id={name}
            name={`${formName}.${name}`}
            forwardRef={register({ ...computedRules })}
            getValue={() => {
              control.setValue(`${formName}.${name}Destroy`, 'false')

              return control.getValues(`${formName}.${name}`) as FileList
            }}
            resetFile={() => {
              control.setValue(`${formName}.${name}`, null)
              control.setValue(`${formName}.${name}Destroy`, 'true')
            }}
            uploadedFile={
              get(defaultValues, `${formName}.${name}`) || get(defaultValues, `${name}`)
            }
            hasError={hasError}
            disabled={disabled}
          />
          <Input
            id={`${formName}.${name}Destroy`}
            name={`${formName}.${name}Destroy`}
            type="hidden"
            defaultValue="false"
            ref={register()}
          />
        </>
      )
    case 'picture':
      return (
        <Picture
          id={`${formName}.${name}`}
          name={`${formName}.${name}`}
          register={register()}
          value={watch && watch(`${formName}.${name}`)}
          uploadedFile={
            typeof index === 'number' &&
            defaultValues?.pictures &&
            defaultValues?.pictures.length > 0 &&
            defaultValues?.pictures[index]
          }
          resetFile={(id?: string) => {
            if (id) {
              const pictureToDestroy = (watch && watch(`${formName}.picturesDestroy`)?.id) || []
              pictureToDestroy.push(id)
              register({ name: `${formName}.picturesDestroy` })
              control.setValue(`${formName}.picturesDestroy`, { id: pictureToDestroy })
            }
            control.setValue(`${formName}.${name}`, null)
          }}
        />
      )
    case 'select':
      return (
        <Controller
          as={Dropdown}
          id={name}
          name={`${formName}.${name}`}
          placeholder={placeholder}
          options={inputInfos.options}
          isMulti={inputInfos.multi || false}
          control={control}
          rules={{ ...computedRules }}
          hasError={hasError}
          disabled={disabled}
        />
      )
    case 'toggle':
      return (
        <Controller
          as={Toggle}
          id={name}
          name={`${formName}.${name}`}
          rules={{ ...computedRules }}
          control={control}
          placeholder={placeholder}
          onChange={([value]) => value.toString()}
        />
      )
    case 'selectMultiple':
      return (
        <Controller
          as={SelectMultiple}
          id={name}
          name={`${formName}.${name}`}
          options={inputInfos.options}
          control={control}
          rules={{ ...computedRules }}
          label={inputInfos.label}
        />
      )
    case 'ranking':
      return (
        <Rank
          from={inputInfos.from}
          to={inputInfos.to}
          name={`${formName}.${name}`}
          id={name}
          radioRef={() => register({ ...computedRules })}
          hasError={hasError}
        />
      )
    case 'textarea':
      return (
        <Textarea
          id={name}
          name={`${formName}.${name}`}
          placeholder={placeholder}
          ref={register({ ...computedRules })}
          hasError={hasError}
          disabled={disabled}
        />
      )
    case 'phone':
      return (
        <Controller
          as={InputPhone}
          id={name}
          name={`${formName}.${name}`}
          placeholder={placeholder}
          control={control}
          rules={{ ...computedRules }}
          hasError={hasError}
          disabled={disabled}
        />
      )
    default:
      return (
        <Input
          id={name}
          name={`${formName}.${name}`}
          placeholder={placeholder}
          type={type}
          ref={register({ ...computedRules })}
          hasError={hasError}
          disabled={disabled}
        />
      )
  }
}

export const isMissingAnswer = (
  defaultValues: any,
  resource: any,
  subForm: string,
  name: string,
  required?: boolean,
) => {
  if (resource && (resource[`${subForm}`]?.progress || 0) < 100) {
    if (name === 'report' && !resource[`${subForm}`].report) {
      return true
    } else if (
      name === 'additionalInsuranceName' &&
      !resource[`${subForm}`].additionalInsuranceName &&
      resource[`${subForm}`].extraInsurance === 'other'
    ) {
      return true
    } else if (
      name === 'additionalInsuranceName' &&
      !resource[`${subForm}`].additionalInsuranceName &&
      resource[`${subForm}`].extraInsurance === 'other'
    ) {
      return true
    } else if (
      name === 'foodIntolerancePrecision' &&
      !resource[`${subForm}`].foodIntolerancePrecision &&
      resource[`${subForm}`].foodIntolerance === 'yes'
    ) {
      return true
    } else if (
      name === 'allergyPrecision' &&
      !resource[`${subForm}`].allergyPrecision &&
      resource[`${subForm}`].allergy === 'yes'
    ) {
      return true
    } else if (
      required &&
      !!defaultValues &&
      (defaultValues[`${name}`] === null ||
        defaultValues[`${name}`] === '' ||
        (Array.isArray(defaultValues[`${name}`]) && defaultValues[`${name}`]?.length === 0) ||
        defaultValues[`${name}`] === undefined) &&
      resource &&
      !!resource[`${subForm}`]?.updatedAt
    ) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
