import request from './request'
import { AxiosResponse } from 'axios'
import Kit, { ItemKit, KitPayload } from 'types/kit'
import { reduce, map } from 'lodash'

function formatKitCategory(kitCategory?: ItemKit[]) {
  return reduce(
    kitCategory,
    (acc, item) => ({ ...acc, [item.name]: item.qte }),
    {} as { [key: string]: string },
  )
}

function parseKitCategory(kitCategory?: { [key: string]: string }) {
  return map(kitCategory, (qte, name) => ({ name, qte }))
}

export function getKitLists(selectedInstitutionId: string | undefined) {
  return request
    .get<any, AxiosResponse<{ kitLists: KitPayload[] }>>(
      `/institution/${selectedInstitutionId}/kit_lists`,
    )
    .then(res => res.data.kitLists)
}

export function getKitList(KitListId: string): Promise<Kit> {
  return request.get<any, AxiosResponse<any>>(`/institution/kit_lists/${KitListId}`).then(res => ({
    id: res.data.id,
    name: res.data.name,
    type: res.data.typeList,
    shoesAndClothes: parseKitCategory(res.data.shoesAndClothes),
    administrativeDocuments: parseKitCategory(res.data.administrativeDocuments),
    toiletKit: parseKitCategory(res.data.toiletKit),
    medical: parseKitCategory(res.data.medical),
  }))
}

export function submitKitList(data: Kit, selectedInstitutionId: string | undefined) {
  const requestType = data.id ? 'patch' : 'post'

  return request[requestType]<any, AxiosResponse<any>>(
    `/institution/${selectedInstitutionId}/kit_lists${
      requestType === 'patch' ? `/${data.id}` : ''
    }`,
    {
      kitList: {
        name: data.name,
        typeList: data.type,
        shoesAndClothes: formatKitCategory(data.shoesAndClothes),
        administrativeDocuments: formatKitCategory(data.administrativeDocuments),
        toiletKit: formatKitCategory(data.toiletKit),
        medical: formatKitCategory(data.medical),
      },
    },
  )
}

export function deleteKitList(KitListId: string, selectedInstitutionId: string | undefined) {
  return request.delete<any, AxiosResponse<any>>(
    `/institution/${selectedInstitutionId}/kit_lists/${KitListId}`,
  )
}
