import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { get, reduce, isNil, forEach, map } from 'lodash'

import { Educative, Medical, Owner } from 'types/document'
import Box from 'ui/box'
import Input from 'ui/form/input'
import Group from 'ui/form/group'
import Label from 'ui/form/label'
import File from 'ui/form/file/inline'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import { Radio } from 'types/form'
import { renderInput } from 'components/pages/helper'
import { ProgressState } from 'types/enums'

const defaultRules = {
  required: 'Ce champ est requis',
}

const ErrorMessage = styled(Text).attrs({ fs: 16, color: 'pink' })`
  flex: 1;
`

const StyledLabel = styled(Label)<{ missing: boolean }>`
  color: ${props => (props.missing ? props.theme.pink : props.theme.black)};
  font-weight: ${props => (props.missing ? 'bold' : 'regular')};
  padding-bottom: '16px';
`

export default function FormDoc({
  title,
  form,
  formPath,
  formStatus,
  resource,
}: {
  title: string
  form: ({ name: string; label: string; required?: boolean } | Radio)[]
  formPath: 'educative' | 'medical' | 'owner'
  formStatus: ProgressState
  resource: Partial<Educative & Medical & Owner>
}) {
  const { register, setValue, control, getValues, setError, errors } = useFormContext()

  useEffect(() => {
    setValue(formPath, {
      ...(resource || {}),
      ...reduce(
        resource,
        (acc, value, key) => ({ ...acc, [key]: !isNil(value) ? `${value}` : undefined }),
        {},
      ),
      ...reduce(
        form,
        (acc, field) => ((field as Radio).type ? acc : { ...acc, [field.name]: '' }),
        {},
      ),
    })

    forEach(get(resource, 'errorReport', {}), (value, key) =>
      setError(`${formPath}.${key}`, 'comment', value),
    )
  }, [resource])

  return (
    <Box fd="c" jc="fs">
      <Input id="form-id" name={`${formPath}.id`} type="hidden" ref={register()} />
      <Title bold fs={24} spacingBottom={'24px'}>
        {title}
      </Title>
      <Box fd="c" f="0 1 auto">
        {map(form, input => {
          const { name, label, required } = input

          const isMissingAnswer =
            (formStatus === 'pending' &&
              name === 'socialSecurityCard' &&
              !resource.socialSecurityCard) ||
            (formStatus === 'pending' &&
              name === 'homeWithoutHelp' &&
              resource.homeWithoutHelp === null)

          if ((input as Radio).type === 'radio') {
            return (
              <Group key={`${formPath}.${name}`} f="initial" padding="0 0 20px 0">
                <StyledLabel htmlFor={name} required={required} missing={isMissingAnswer}>
                  {label}
                </StyledLabel>
                {renderInput(
                  formPath,
                  { ...input, hasError: !!get(errors, `${formPath}.${name}`, false) } as Radio,
                  control,
                  register,
                )}
              </Group>
            )
          }

          return (
            <Box fd="c" key={`${formPath}.${name}`} style={{ paddingBottom: '16px' }}>
              <File
                id={`${formPath}.${name}`}
                name={`${formPath}.${name}`}
                label={label}
                forwardRef={register(required ? defaultRules : {})}
                uploadedFile={get(resource, name, undefined)}
                getValue={() => getValues(`${formPath}.${name}`) as FileList}
                resetFile={() => setValue(`${formPath}.${name}`, null)}
                hasError={!!get(errors, `${formPath}.${name}`)}
                missing={isMissingAnswer}
                required={required}
              />
              <ErrorMessage>{get(errors, `${formPath}.${name}`)}</ErrorMessage>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
