/* eslint-disable react/jsx-key */
import React from 'react'
import { useTable, useSortBy, useFlexLayout, Column, HeaderGroup, usePagination } from 'react-table'
import styled from 'styled-components'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'

const Th = styled(Box).attrs({ ai: 'c' })`
  font-family: Nunito;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: ${props => props.theme.black};
  opacity: 0.7;
  padding: 16px 0;
  text-align: left;
  border-bottom: 1px solid ${props => props.theme.greyLight};
`

const Td = styled(Box).attrs({ ai: 'c' })`
  font-family: Nunito;
  font-style: normal;
  font-size: 16px;
  line-height: 130%;
  padding: 16px 0;
  border-bottom: 1px solid ${props => props.theme.greyLight};
`

export default function Table({
  columns,
  data,
  noDataText,
  noDataInfo,
}: {
  columns: Array<Column>
  data: Array<any>
  noDataText: string
  noDataInfo?: string
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useFlexLayout,
    usePagination,
  )

  return data.length > 0 ? (
    <Box fd="c">
      <Box fd="c" {...getTableProps()}>
        {headerGroups.map((headerGroup: HeaderGroup<object>) => (
          <div {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.Header}
                {!column.disableSortBy && (
                  <Box fd="c" jc="c">
                    {(!column.isSorted || !column.isSortedDesc) && (
                      <Icon
                        name="caretFill"
                        size={8}
                        rotate={180}
                        containerStyle={{ marginLeft: '8px' }}
                      />
                    )}
                    {(!column.isSorted || column.isSortedDesc) && (
                      <Icon name="caretFill" size={8} containerStyle={{ marginLeft: '8px' }} />
                    )}
                  </Box>
                )}
              </Th>
            ))}
          </div>
        ))}
        <div {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)

            return (
              <div {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                ))}
              </div>
            )
          })}
        </div>
      </Box>
      {pageOptions.length > 1 && (
        <Box padding="16px 0" jc="fe" ai="c">
          <Text opacity={0.7} style={{ marginRight: '8px' }}>
            Page{' '}
            <strong>
              {pageIndex + 1} sur {pageOptions.length}
            </strong>
          </Text>
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            variant="outline"
            size="small"
            style={{ marginRight: '8px' }}
          >
            <Icon name="caretFill" size={8} rotate={90} />
            <Icon name="caretFill" size={8} rotate={90} />
          </Button>
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            variant="outline"
            size="small"
            style={{ marginRight: '8px' }}
          >
            <Icon name="caretFill" size={8} rotate={90} />{' '}
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            variant="outline"
            size="small"
            style={{ marginRight: '8px' }}
          >
            <Icon name="caretFill" size={8} rotate={-90} />
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            variant="outline"
            size="small"
          >
            <Icon name="caretFill" size={8} rotate={-90} />
            <Icon name="caretFill" size={8} rotate={-90} />
          </Button>
        </Box>
      )}
    </Box>
  ) : (
    <Box fd="c" bg="greyLightN" padding="64px 32px" ai="c" br={4}>
      <Icon name="noData" size={40} containerStyle={{ marginBottom: '16px' }} />
      <Text fs={18} bold spacingBottom="8px">
        {noDataText}
      </Text>
      {noDataInfo && <Text fs={16}>{noDataInfo}</Text>}
    </Box>
  )
}
