import { Form } from 'types/form'
import { ESTABLISHMENT_TYPES } from 'types/enums'

const COVERAGE_FORM: Form = [
  [
    {
      name: 'mdphNotif',
      type: 'file',
      label: 'Notification MDPH',
      placeholder: '',
      required: false,
    },
    {
      name: 'socialHelpNotif',
      type: 'file',
      label: "Notification d'aide sociale",
      placeholder: '',
      required: false,
    },
  ],
  [
    {
      name: 'beginningMdph',
      type: 'date',
      label: 'Début de validité de la notification d’orientation MDPH',
      placeholder: 'À débuter le jj/mm/aaaa',
      required: true,
    },
    {
      name: 'endingMdph',
      type: 'date',
      label: 'Fin de validité de la notification d’orientation MDPH',
      placeholder: 'Se termine le jj/mm/aaaa',
      required: true,
    },
  ],
  [
    {
      name: 'haveInvalidityCard',
      type: 'radio',
      label: "Titulaire d'une carte d'invalidité",
      placeholder: '',
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        { label: 'Non', value: 'false' },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'orientationNature',
      type: 'select',
      label: "Nature de l'orientation",
      placeholder: "Choisissez la nature de l'orientation",
      required: true,
      options: [
        {
          label: "MAS - Maison d'Accueil Spécialisée",
          value: ESTABLISHMENT_TYPES.MAS,
        },
        { label: "FAM - Foyer d'Accueil Médicalisé", value: ESTABLISHMENT_TYPES.FAM },
        { label: 'FO / FV - Foyer Occupationnel / Foyer de Vie', value: ESTABLISHMENT_TYPES.FO_FV },
      ],
    },
  ],
]

export default COVERAGE_FORM
