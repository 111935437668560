import React, { useEffect, useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { map, find } from 'lodash'

import Box, { BoxWithHeader } from 'ui/box'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Icon from 'ui/icon'
import Button, { LinkButton } from 'ui/button'
import Table from 'ui/table'
import Loader from 'ui/loader'

import { ACCOMMODATION_PUBLIC_OPTIONS } from 'constants/form'

import { usePageName } from 'hooks/usePageName'
import useResource from 'hooks/useResource'
import { AuthContext } from 'App'
import { Candidacies } from 'types/candidacy'
import { getCandidacies } from 'api/candidacy'
import { getInstitutionWebflowInfos } from 'api/institution'

export default function Institution() {
  const { auth } = useContext(AuthContext)
  const [institutionSlug, setInstitutionSlug] = useState<string>('')
  const history = useHistory()
  const { setPageName } = usePageName()

  const selectedInstitution = useMemo(
    () => auth?.institution?.filter(({ id }) => id === auth.selectedInstitutionId)[0],
    [auth],
  )

  const institutionId = useMemo(() => auth.selectedInstitutionId, [auth.selectedInstitutionId])

  const [{ resource, loading }] = useResource<Candidacies, {}>(
    undefined,
    () => getCandidacies('residents', institutionId),
    [],
  )
  const columns = useMemo(
    () => [
      {
        Header: 'résidents',
        accessor: 'patientName',
      },
      {
        Header: 'Handicap',
        accessor: 'handicap',
      },
      {
        Header: 'MODE ACCUEIL',
        accessor: 'mode',
      },
      {
        Header: '',
        accessor: 'admissionRecordId',
        width: 70,
        Cell: ({ value }: { value: string }) => (
          <Button
            variant="outline"
            size="small"
            onClick={() =>
              history.push(`/mon-etablissement/resident/${value}/administrative/etape/1`)
            }
          >
            Voir le dossier
          </Button>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  useEffect(() => {
    setPageName('Mon Établissement')
  }, [])

  const webflowId = useMemo(() => selectedInstitution?.webflowItemId, [selectedInstitution])

  useEffect(() => {
    const getItem = async () => {
      const response = await getInstitutionWebflowInfos(webflowId)
      setInstitutionSlug(response?.webflowSlug)
    }

    webflowId && getItem()
  }, [])

  return (
    <Box fd="c">
      {selectedInstitution && !selectedInstitution.kitListsPresent && (
        <Box
          ai="c"
          jc="sb"
          bg="yellow"
          br={4}
          height="82px"
          padding="22px 32px"
          style={{ marginBottom: '32px' }}
        >
          <Box ai="c">
            <Icon name="stacks" size={20} containerStyle={{ marginRight: '16px' }} />
            <Text fs={20}>Créez votre premier trousseau pour accueillir les usagers</Text>
          </Box>
          <Button
            variant="outline"
            size="small"
            onClick={() => history.push('/mon-etablissement/trousseaux/edition')}
          >
            Créer mon premier trousseau
          </Button>
        </Box>
      )}
      <BoxWithHeader br={4} f="0 1 auto" style={{ marginBottom: '32px' }}>
        <Box>
          <Title fs={24} bold>
            Fiche établissement
          </Title>
          <Box jc="fe">
            <Button
              variant="outline"
              onClick={() => history.push('/mon-etablissement/trousseaux')}
              style={{ marginRight: '16px' }}
            >
              Voir le(s) trousseau(x)
            </Button>
            <Button
              variant="outline"
              onClick={() => history.push('/mon-etablissement/edition')}
              style={{ marginRight: '16px' }}
            >
              <Icon
                name="settings"
                size={20}
                rotate={180}
                containerStyle={{ marginRight: '8px' }}
              />
              Éditer la fiche établissement
            </Button>

            <LinkButton
              className="success"
              href={
                institutionSlug
                  ? `https://www.sahanest.fr/annuaire/${institutionSlug}`
                  : `https://www.sahanest.fr/annuaire/`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="eye" size={20} containerStyle={{ marginRight: '8px' }} color="white" />
              Voir la fiche établissement
            </LinkButton>
          </Box>
        </Box>

        <Box fd="r" padding="32px 32px 0 32px">
          <Box bg="greyLightN" br={4} padding="24px 32px">
            <Box fd="c">
              <Text fs={14} color="black" opacity={0.7} bold>
                RAISON SOCIALE
              </Text>
              <Text fs={16} color="black">
                {selectedInstitution?.info?.socialReason}
              </Text>
            </Box>
            <Box fd="c">
              <Text fs={14} color="black" opacity={0.7} bold>
                NB PLACES TOTALE
              </Text>
              <Text fs={16} color="black">
                {selectedInstitution?.info?.accommodationCapacity}
              </Text>
            </Box>
            <Box fd="c">
              <Text fs={14} color="black" opacity={0.7} bold>
                NB PLACES DISPO.
              </Text>
              <Text fs={16} color="black">
                {selectedInstitution?.info?.capacityAvailable}
              </Text>
            </Box>
            <Box fd="c">
              <Text fs={14} color="black" opacity={0.7} bold>
                PUBLIC ACCUEILLI
              </Text>
              <Text fs={16} color="black">
                {map(
                  selectedInstitution?.info?.accommodationPublic,
                  value => find(ACCOMMODATION_PUBLIC_OPTIONS, apo => apo.value === value)?.label,
                ).join(', ')}
              </Text>
            </Box>
          </Box>
        </Box>
      </BoxWithHeader>
      <BoxWithHeader br={4} f="0 1 auto">
        <Box jc="sb">
          <Title fs={24} bold>
            Liste des résidents
          </Title>
        </Box>

        <Box fd="c" padding="32px 32px 0 32px">
          {loading ? (
            <Box padding="32px 32px 0 32px" ai="c" jc="c">
              <Loader />
            </Box>
          ) : (
            <Table
              columns={columns}
              data={resource ? resource.candidacies : []}
              noDataText="Aucun résidents"
            />
          )}
        </Box>
      </BoxWithHeader>
    </Box>
  )
}
