import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Link from 'ui/typography/link'
import Text from 'ui/typography/text'

import { ESTABLISHMENT_TYPES } from 'types/enums'

interface Props {
  institutionName: string
  address: string
  phoneNumber: string
  siretNumber: string
  webSite: string
  institutionType: ESTABLISHMENT_TYPES[]
  onClick: () => void
  isSelected?: boolean
}

const EstablishmentCard = ({
  institutionName,
  address,
  phoneNumber,
  siretNumber,
  webSite,
  institutionType,
  onClick,
  isSelected,
}: Props) => {
  const INSTITUTION_TYPE = {
    [ESTABLISHMENT_TYPES.MAS]: "MAS - Maison d'Accueil Spécialisée",
    [ESTABLISHMENT_TYPES.FAM]: "FAM - Foyer d'Accueil Médicalisé",
    [ESTABLISHMENT_TYPES.FO_FV]: 'FO / FV - Foyer Occupationnel / Foyer de Vie',
  }

  const types = institutionType?.reduce((acc: string, type: string) => {
    return `${acc}, ${INSTITUTION_TYPE[type as keyof typeof INSTITUTION_TYPE]} `
  }, '')

  return (
    <Box padding="24px" bg="white" br={4} style={{ margin: '0 0 8px 0' }} ai="c" jc="sb">
      <Box fd="c" width="80%">
        <Text fs={18} bold>
          {_.capitalize(institutionName)}
        </Text>
        <Text fs={14} color="greyBlue" style={{ margin: '8px 0 0 0' }}>
          {address} {types}
        </Text>

        <Box style={{ margin: '24px 0 0 0' }}>
          <Box ai="c" width="33%">
            <Icon name="call" color="green" size={12} containerStyle={{ marginRight: '14px' }} />
            <Box fd="c">
              <Text fs={14} bold>
                Téléphone
              </Text>
              <Text
                fs={14}
                color="greyBlue"
                style={{ minHeight: '24px', overflowWrap: 'break-word' }}
              >
                {phoneNumber}
              </Text>
            </Box>
          </Box>
          <Box ai="c" width="33%">
            <Icon
              name="location"
              color="green"
              size={12}
              containerStyle={{ marginRight: '14px' }}
            />
            <Box fd="c">
              <Text fs={14} bold>
                Siret
              </Text>
              <Text fs={14} color="greyBlue" style={{ minHeight: '24px' }}>
                {siretNumber}
              </Text>
            </Box>
          </Box>
          {webSite && (
            <Box ai="c" width="33%">
              <Icon
                name="screen"
                color="green"
                size={12}
                containerStyle={{ marginRight: '14px' }}
              />
              <Box fd="c">
                <Text fs={14} bold>
                  Site web
                </Text>
                <Text
                  fs={14}
                  color="greyBlue"
                  style={{ minHeight: '24px', overflowWrap: 'break-word' }}
                >
                  <Link color={'green'} fs={14} href={webSite} target="_blank" underline>
                    {webSite}
                  </Link>
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box ai="c" jc="c">
        <CheckContainer isSelected={isSelected} onClick={() => onClick()}>
          {isSelected && <Icon name="check" color="white" size={18} />}
        </CheckContainer>
      </Box>
    </Box>
  )
}

export default EstablishmentCard

const CheckContainer = styled.div<{ isSelected?: boolean }>`
  width: 24px;
  height: 24px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isSelected, theme }) => (isSelected ? theme.green : theme.greyLight4)};
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ isSelected, theme }) => isSelected && theme.green};
  display: flex;
  align-items: center;
  justify-content: center;
`
