import React from 'react'
import { useHistory } from 'react-router-dom'

import Box from 'ui/box'
import Intro from 'components/pages/intro'

import Layout from '../layout'
import Step1 from './step1'

export default function KitStep() {
  const history = useHistory()

  return (
    <Layout
      title="Trousseau & Accueil"
      globalStep={6}
      steps={() => [
        {
          title: 'Introduction',
          Component: () => (
            <Box padding="32px">
              <Intro
                onNext={() => history.push(`/admission/trousseau/etape/2`)}
                sections={[
                  {
                    title: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                    text:
                      '  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas nemo natus debitis harum expedita repellendus accusamus dolorum. Recusandae, sequi numquam. Illum minima provident accusantium blanditiis voluptas numquam nam ipsum nihil!',
                  },
                  {
                    title: ' Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                    text:
                      '  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas nemo natus debitis harum expedita repellendus accusamus dolorum. Recusandae, sequi numquam. Illum minima provident accusantium blanditiis voluptas numquam nam ipsum nihil!',
                  },
                ]}
              />
            </Box>
          ),
        },
        {
          title: 'Récapitulatif',
          Component: () => <Step1 />,
        },
      ]}
    />
  )
}
