import React from 'react'

import Layout from './layout'
import { Form } from 'types/form'

import {
  ACCOMMODATION_HANDICAP_OPTIONS,
  ACCOMMODATION_MODE_OPTIONS,
  ACCOMMODATION_PUBLIC_OPTIONS,
} from 'constants/form'

const ACCOMMODATION_FORM: Form = [
  [
    {
      name: 'accommodationCapacity',
      type: 'number',
      label: 'Nombre de places totale',
      placeholder: 'Saisissez le nombre de places',
      required: false,
    },
    {
      name: 'capacityAvailable',
      type: 'number',
      label: 'Nombre de places disponibles',
      placeholder: 'Saisissez le nombre de places disponibles',
      required: false,
    },
  ],
  [
    {
      name: 'accommodationMode',
      type: 'selectMultiple',
      label: "Mode d'accueil",
      options: ACCOMMODATION_MODE_OPTIONS,
      required: false,
    },
  ],
  [
    {
      name: 'accommodationPublic',
      type: 'selectMultiple',
      label: 'Public accueilli',
      options: ACCOMMODATION_PUBLIC_OPTIONS,
      required: false,
    },
  ],
  [
    {
      name: 'accommodationHandicap',
      type: 'selectMultiple',
      label: 'Handicaps pris en charge',
      options: ACCOMMODATION_HANDICAP_OPTIONS,
      required: false,
    },
  ],
]

export default function Accommodation() {
  return (
    <Layout
      title="Actualiser les critères d’accueil"
      subText="Si votre établissement à évolué il est important de garder ces données à jour."
      help={{
        icon: 'users',
        title: 'En quoi ces informations sont importantes ?',
        text:
          'Sahanest souhaite proposer aux usagers des établissements adaptés à leurs besoins. Et c’est justement ces informations qui peremttent aux usagers de choisir le bon lieu de vie.',
      }}
      fields={ACCOMMODATION_FORM}
      subForm="info"
    />
  )
}
