import React, { useState, ChangeEvent } from 'react'
import styled from 'styled-components'

import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Box from 'ui/box'
import Input from 'ui/form/input'
import { isValidEmail } from 'components/pages/helper'

const Container = styled(Box).attrs({ fd: 'c', ai: 's', jc: 'fs' })<{ error: string }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, error }) => (error ? theme.pink : theme.black)};
  box-sizing: content-box;
`

const Tag = styled(Box).attrs({ ai: 'c', jc: 'c' })`
  padding: 5px;
  margin-top: 8px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.green};
  background: ${({ theme }) => theme.greyLightN3};
`

const InputTag = ({
  id,
  name,
  placeholder,
  onChange,
  value,
}: {
  id: string
  name: string
  placeholder: string
  onChange: any
  value?: string[]
}) => {
  const [error, setError] = useState<string>('')

  const removeTag = (indexToRemove: number) => {
    const tags = value && [...value.filter((_, index) => index !== indexToRemove)]
    onChange(tags)
  }

  const addTag = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      if (isValidEmail(event.target.value)) {
        const newTags = value ? [...value, event.target.value] : [event.target.value]
        onChange(newTags)

        event.target.value = ''
        setError('')
      } else {
        setError(`Veuillez indiquer un email valide`)
      }
    } else {
      setError('')
    }
  }

  return (
    <Box fd="c">
      <Container error={error}>
        <Box fd="c" ai="s" jc="fs" style={{ width: '100%', paddingLeft: '15px' }}>
          {value?.map((tag, index) => (
            <Tag key={index}>
              <Text style={{ marginRight: '10px' }}>{tag}</Text>
              <Box onClick={() => removeTag(index)}>
                <Icon name="close" size={16} color="black" />
              </Box>
            </Tag>
          ))}
        </Box>
        <Input
          width={'95%'}
          hasBorder={false}
          id={id}
          name={name}
          placeholder={placeholder}
          style={{ border: 'none' }}
          type="email"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            event.target.value === '' && setError('')
          }
          onBlur={(event: ChangeEvent<HTMLInputElement>) => addTag(event)}
        />
      </Container>
      {error && (
        <Text fs={16} color="pink" style={{ marginTop: 5 }}>
          {error}
        </Text>
      )}
    </Box>
  )
}

export default InputTag
