import { get, forEach } from 'lodash'
import { decamelize } from 'humps'
import { AxiosResponse } from 'axios'

import request from './request'

import { State } from 'components/pages/patient/signup'
import { PayloadSignUpDoctor } from 'components/pages/doctor/signup'
import { Auth, PatchUser, ResetPasswordPayload } from 'types/user'

export function signIn(f: FormData) {
  return request
    .post(`${process.env.REACT_APP_API_ENDPOINT}/oauth/token`, f, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data)
}

export function checkIfConnected() {
  return request.get<any, AxiosResponse<Auth>>(`${process.env.REACT_APP_API_ENDPOINT}/me`)
}

export function askResetPassword(f: FormData) {
  return request.post(`${process.env.REACT_APP_API_ENDPOINT}/users/password`, f, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function resetPassword(data: ResetPasswordPayload) {
  const f: FormData = new FormData()
  forEach(data, (value, key) => value && f.append(`user[${decamelize(key)}]`, `${value}`))

  return request.patch(`${process.env.REACT_APP_API_ENDPOINT}/users/password`, f, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function deleteUser() {
  return request.post('/deletion_request')
}

export function patch(id: string, user: Partial<PatchUser>) {
  const f: FormData = new FormData()

  forEach(user, (value, key) => value && f.append(`user[${decamelize(key)}]`, `${value}`))

  return request
    .patch<any, AxiosResponse<Auth>>(`/registrations/${id}`, f, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data)
}

export function signUp(data: State['data']) {
  return request
    .post<any, AxiosResponse<Auth>>('/registrations', {
      user: {
        ...data.userInfos,
        admissionRecordsAttributes: [
          {
            webflowSlug: data.webflowSlug || null,
            otherSuggestedInstitutions: data.otherSuggestedInstitutionId || null,
            userType: data.userType,
            establishmentSearch: data.establishmentSearch,
            concernedAge: get(data.concernedInfos, 'concernedAge'),
            urgencyLevel: get(data.concernedInfos, 'urgencyLevel'),
            alreadyInStructure: get(data.concernedInfos, 'alreadyInStructure') === 'true',
          },
        ],
      },
    })
    .then(({ data }) => data)
}

export function signUpDoctor(data: PayloadSignUpDoctor, invitationToken: string) {
  return request
    .patch<any, AxiosResponse<Auth>>('/users/invitation', {
      ...data,
      invitationToken,
    })
    .then(({ data }) => data)
}

export function logout() {
  return request.delete(`${process.env.REACT_APP_API_ENDPOINT}/oauth/token`)
}

export function resendEmailConfirmation(email?: string) {
  return request
    .post(`${process.env.REACT_APP_API}/resend_confirmation`, {
      email: email,
    })
    .then(({ data }) => data)
}
