import { get, isNil } from 'lodash'

import { Form, Inputs } from 'types/form'
import AdmissionRecord from 'types/admissionRecord'

export function flattenFields(fields: Form): Inputs[] {
  return fields
    .flat()
    .filter(f => f !== null && f.type !== 'info' && f.type !== 'hidden') as Inputs[]
}

export function firstNotUndefined(
  possibilities: string[],
  resource: Partial<AdmissionRecord>,
  fallback: string | string[],
) {
  const key = possibilities.find(p => !isNil(get(resource, p)))

  return key ? get(resource, key) : fallback
}
