import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { useTransition, animated } from 'react-spring'

import Toast from 'ui/toast'

type TToast = {
  id: number
  type: 'error' | 'success'
  title: string
  message: JSX.Element | string
  closeAfter?: number
}

type ContextState = {
  addToast: (toast: Omit<TToast, 'id'>) => void
  addGenericErrorToast: () => void
  removeToast: (id: number) => void
}

type Props = {
  children: React.ReactNode
  maxToast: number
}

let id = 1

const ToastsContext = createContext({} as ContextState)

function ToastsProvider({ children, maxToast = 5 }: Props) {
  const [toasts, setToasts] = useState<Array<TToast>>([])

  const removeToast = useCallback(
    id => {
      setToasts(toasts => toasts.filter(t => t.id !== id))
    },
    [setToasts],
  )

  const addToast = useCallback(
    (toast: Omit<TToast, 'id'>) => {
      setToasts(toasts => [{ ...toast, id: id++ }, ...toasts].slice(0, maxToast))
    },
    [maxToast],
  )

  const addGenericErrorToast = useCallback(() => {
    setToasts(toasts =>
      [
        {
          id: id++,
          title: 'Erreur !',
          type: 'error' as 'error' | 'success',
          message: 'Un problème est survenu, veuillez réessayer !',
        },
        ...toasts,
      ].slice(0, maxToast),
    )
  }, [maxToast])

  const value = useMemo(() => ({ addToast, removeToast, addGenericErrorToast }), [
    addToast,
    removeToast,
    addGenericErrorToast,
  ])
  const transitions = useTransition(toasts, item => item.id, {
    from: { opacity: 0, marginTop: 0 },
    enter: { opacity: 1, marginTop: 20 },
    leave: { opacity: 0, height: 0, marginTop: 0 },
  })

  return (
    <ToastsContext.Provider value={value}>
      <div style={{ position: 'absolute', top: 30, right: 30, zIndex: 20 }}>
        {transitions.map(({ item: { id, type, title, message, closeAfter }, props, key }) => (
          <animated.div key={key} style={props}>
            <Toast
              key={id}
              title={title}
              type={type}
              message={message}
              onClose={() => removeToast(id)}
              closeAfter={closeAfter}
            />
          </animated.div>
        ))}
      </div>
      {children}
    </ToastsContext.Provider>
  )
}

function useToasts() {
  const context = useContext(ToastsContext)

  if (context === undefined) {
    throw new Error(`useToasts must be used within a ToastsProvider`)
  }

  return context
}

export { ToastsProvider, useToasts }
