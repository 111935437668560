import { Form } from 'types/form'

const POCKET_MONEY_FORM: Form = [
  [
    {
      name: 'monthlyFreeMoney',
      type: 'text',
      label:
        "A titre indicatif, quel montant d'argent de poche mensuel souhaiteriez-vous verser ? Sinon, mettre 0.",
      placeholder: "Saisissez le montant de l'argent de poche mensuel",
      required: true,
    },
  ],
  [
    {
      name: 'monthlyClothingCosts',
      type: 'text',
      label:
        'A titre indicatif, quel montant de frais de vêture mensuel souhaiteriez-vous verser ? Sinon, mettre 0.',
      placeholder: 'Saisissez le montant mensuel des frais de vêture',
      required: true,
    },
  ],
]

export default POCKET_MONEY_FORM
