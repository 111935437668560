import { ProgressState, GENDER } from './enums'

export enum BLOOD_GROUP {
  A_POSITIVE = 'a_positive',
  A_NEGATIVE = 'a_negative',
  B_POSITIVE = 'b_positive',
  B_NEGATIVE = 'b_negative',
  AB_POSITIVE = 'ab_positive',
  AB_NEGATIVE = 'ab_negative',
  O_POSITIVE = 'o_positive',
  O_NEGATIVE = 'o_negative',
}

export enum ALLERGY {
  YES = 'yes',
  NO = 'no',
  DONT_KNOW = 'dont_know',
}

export enum DOCTOR_KIND {
  REGULAR = 'regular',
  COORDINATOR = 'coordinator',
}

export enum VISION {
  POSSIBLE_MEDICAL_IMPROVEMENT = 'possible_medical_improvement',
  POSSIBLE_CHIRURGICAL_IMPROVEMENT = 'possible_chirurgical_improvement',
  IMPOSSIBLE_IMPROVEMENT = 'impossible_improvement',
}

type Medical = {
  statusProgress: ProgressState
  percentProgress: number
  phoneDoctor: string
  signature: boolean
  signatureDate: Date
  patient: {
    id?: string
    firstName: string
    lastName: string
    gender: GENDER
    dateOfBirth: Date
    contraception: 'true' | 'false'
    size: number
    weight: number
    ta: string
    fc: number
    bloodGroup: BLOOD_GROUP
  }
  ald: {
    id?: string
    isPresent: 'true' | 'false'
    diagnostic: string
    pharmacist: string
    biologic: string
    specialist: string
    paraMedical: string
    firstExtension: Date
    treatmentProtocol: any // TODO type file ??
  }
  diagnostic: {
    id?: string
    principalReport: string
  }
  handicap: {
    id?: string
    principalReport: string
  }
  treatment: {
    id?: string
    medicinesAttributes: {
      id?: string
      ald: string
      name: string
      dose: string
      posology: string
      _destroy?: string
    }[]
  }
  deficiency: {
    id?: string
    report: any // TODO type file ??
    epilepsyType: string
    epilepsyFrequency: string
    audition: string
    language: string
    vision: VISION
    otherConfusion: string
    digestive: string
    urinary: string
    dialysis: string
    dialysisFrequency: string
    transplant: string
    transplantDate: Date
    transplantTolerance: string
    enzymatic: string
    immune: string
    locomotive: string
    cardioRespiratory: string
    decompensationCardiac: string
    respiratory: string
    intellectual: string
  }
  lifeStyle: {
    id?: string
    allergy: ALLERGY
    allergyPrecision: string
    tabacco: string
    alcoholAndDrug: string
  }
  vaccination: {
    id?: string
    vaccinesAttributes: {
      id?: string
      kind: string
      recallDate: Date
      _destroy?: string
    }[]
  }
  history: {
    id?: string
    family: string
    personal: string
    medicalBackground: string
    surgical: string
    hospitalization: string
    futureSurgical: string
    comments: string
  }
  author: {
    id?: string
    doctor: string
    kind: DOCTOR_KIND
    signedOn: Date
    signature: any // TODO file type ?
  }
}

export default Medical
