import { Form } from 'types/form'
import { ALLERGY } from 'types/medical'

const LIFE_STYLE_FORM: Form = [
  [
    {
      name: 'allergy',
      type: 'select',
      label: 'Allergies et intolérances',
      placeholder: 'Oui, Non, Inconnues',
      options: [
        {
          label: 'Oui',
          value: ALLERGY.YES,
        },
        {
          label: 'Non',
          value: ALLERGY.NO,
        },
        {
          label: 'Inconnues',
          value: ALLERGY.DONT_KNOW,
        },
      ],
      required: false,
    },
  ],
  [
    {
      name: 'allergyPrecision',
      type: 'textarea',
      label: 'Si oui, précisez',
      placeholder: 'Précisez la liste des allergies',
      required: false,
    },
  ],
  [
    {
      name: 'tabacco',
      type: 'textarea',
      label: 'Tabac',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'alcoholAndDrug',
      type: 'textarea',
      label: 'Alcool et drogues',
      placeholder: 'Précisez',
      required: false,
    },
  ],
]

export default LIFE_STYLE_FORM
