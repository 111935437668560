import styled from 'styled-components'
import { transparentize } from 'polished'
import { get } from 'lodash'

import { Colors } from '../theme'

const ALIGN = {
  c: 'center;',
  e: 'flex-end;',
  s: 'flex-start;',
  b: 'baseline',
  st: 'stretch',
}

const DIRECTIONS = {
  c: 'column;',
  cr: 'column-reverse;',
  r: 'row;',
  rr: 'row-reverse;',
}

const JUSTIFY = {
  sa: 'space-around;',
  sb: 'space-between;',
  c: 'center;',
  fe: 'flex-end;',
  se: 'space-evenly',
  fs: 'flex-start;',
}

const GUTTER = {
  negative: '0 -15px',
}

type BoxAlign = keyof typeof ALIGN
type BoxDirection = keyof typeof DIRECTIONS
type BoxJustify = keyof typeof JUSTIFY
type BoxGutter = keyof typeof GUTTER

interface BoxProps {
  ai?: BoxAlign
  bg?: string | Colors
  fd?: BoxDirection
  gutter?: BoxGutter
  jc?: BoxJustify
  f?: string
  height?: string
  scroll?: boolean
  width?: string
  padding?: string
  br?: number
  withBorder?: boolean
}

const Box = styled.div<BoxProps>`
  align-items: ${props => props.ai && ALIGN[props.ai]};
  background-color: ${props => (props.bg ? get(props.theme, props.bg, props.bg) : 'transparent')};
  display: flex;
  flex: ${props => props.f || (props.width || props.height ? '0 1 auto' : '1')};
  flex-direction: ${props => props.fd && DIRECTIONS[props.fd]};
  height: ${props => props.height};
  justify-content: ${props => props.jc && JUSTIFY[props.jc]};
  margin: ${props => props.gutter && GUTTER[props.gutter]};
  -webkit-overflow-scrolling: ${props => (props.scroll ? 'touch' : null)};
  overflow-x: ${props => (props.scroll ? 'hidden' : null)};
  overflow-y: ${props => (props.scroll ? 'auto' : null)};
  width: ${props => props.width};
  padding: ${props => props.padding};
  box-sizing: border-box;
  border-radius: ${props => props.br || 0}px;
  border: ${props => props.withBorder && `1px solid ${props.theme.greyLightN3}`};
`

export const BoxWithHeader = styled(Box).attrs({
  fd: 'c',
  withBorder: true,
  bg: 'white',
  padding: '0 0 32px 0',
})`
  > ${Box} {
    &:first-child {
      height: 88px;
      flex: 0 0 auto;
      padding: 0 32px;
      align-items: center;
      border-bottom: 1px solid ${props => transparentize(0.9, props.theme.purpleDark)};
    }
  }
`

export default Box
