import { camelCase } from 'lodash'
import { AdmissionStatus } from 'types/admission'
import { ProgressVariant } from 'types/enums'
import User from 'types/user'

type Item = {
  path: string
  label: string
  subSteps?: [string, string][]
  childrenLabel?: {
    [key in ProgressVariant]?: string
  }
}

const ORDER_PATIENT: Item[] = [
  {
    path: 'administrative',
    label: 'Administratifs et familiaux',
    subSteps: [
      ['intro', 'Introduction'],
      ['concerned', 'Personne concernée'],
      ['legalGuardian', 'Responsable légal'],
      ['family', 'Famille'],
      ['medicalTeam', 'Équipe médicale'],
    ],
    childrenLabel: {
      disabled: 'Vérouillé',
      todo: 'À compléter',
    },
  },
  {
    path: 'social',
    label: 'Renseignements sociaux',
    subSteps: [
      ['intro', 'Introduction'],
      ['coverage', 'Couverture sociale'],
      ['otherCoverage', 'Autre couverture'],
    ],
    childrenLabel: {
      disabled: 'Vérouillé',
      todo: 'À compléter',
    },
  },
  {
    path: 'educative',
    label: 'Renseignements éducatifs',
    subSteps: [
      ['intro', 'Introduction'],
      ['situation', 'Situation actuelle'],
      ['ability', 'Évaluation des capacités'],
      ['aggressiveness', 'Aggressivité'],
      ['sexualityBehavior', 'Sexualité'],
      ['foodLine', 'Conduites alimentaires'],
      ['runaway', 'Fugues'],
      ['addiction', 'Conduites Addictives'],
    ],
    childrenLabel: {
      disabled: 'Vérouillé',
      todo: 'À compléter',
    },
  },
  {
    path: 'supportValidation',
    label: 'Validation du support',
    childrenLabel: {
      disabled: 'Vérouillé',
      todo: 'Valider l’envoi',
      pending: 'Valider l’envoi',
      validating: 'Valider l’envoi',
      supportValidating: 'Dossier envoyé au support',
      waitChange: 'Dossier bloqué',
    },
  },
  {
    path: 'financial',
    label: 'Renseignements financiers',
    subSteps: [
      ['intro', 'Introduction'],
      ['benefit', 'Prestations perçues'],
      ['pocketMoney', 'Argent personnel'],
    ],
    childrenLabel: {
      disabled: 'Vérouillé',
      todo: 'À compléter',
    },
  },
  {
    path: 'document',
    label: 'Documents à joindre',
    subSteps: [
      ['intro', 'Introduction'],
      ['owner', 'Documents administratifs'],
      ['medical', 'Documents médicaux'],
      ['educative', 'Documents socio-éducatifs'],
      ['free', 'Documents libres'],
    ],
    childrenLabel: {
      disabled: 'Vérouillé',
      todo: 'À compléter',
    },
  },
  {
    path: 'medical',
    label: 'Renseignements médicaux',
    subSteps: [['intro', 'Introduction']],
    childrenLabel: {
      disabled: 'Vérouillé',
      todo: 'En attente du médecin',
      pending: 'En attente du médecin',
      supportValidating: 'En attente du médecin',
    },
  },
  {
    path: 'form',
    label: 'Envoi du formulaire',
    childrenLabel: {
      disabled: 'Vérouillé',
      todo: 'Valider l’envoi',
      pending: 'Valider l’envoi',
      validating: 'Valider l’envoi',
      supportValidating: 'Dossier envoyé au support',
    },
  },
]

const ORDER_DOCTOR: Item[] = [
  {
    path: 'medical',
    label: 'Renseignements médicaux',
    subSteps: [
      ['patient', 'Patient'],
      ['ald', 'Informations ALD'],
      ['diagnostic', 'Diagnostic principal'],
      ['handicap', 'Origine du handicap'],
      ['treatment', 'Traitement actuel'],
      ['deficiency', 'Déficiences'],
      ['lifeStyle', 'Mode de vie'],
      ['vaccination', 'Vaccinations'],
      ['history', 'Antécédents'],
      ['author', 'Auteur des données'],
    ],
    childrenLabel: {
      disabled: 'Vérouillé',
      todo: 'À compléter',
    },
  },
  {
    path: 'medicalValidation',
    label: 'Envoi du formulaire',
    childrenLabel: {
      disabled: 'L’étape 1 doit être complétée',
      todo: 'Validez les renseignements médicaux',
      pending: 'Validez les renseignements médicaux',
      validating: 'Validez les renseignements médicaux',
      supportValidating: 'Dossier envoyé au support',
    },
  },
]

const ORDER_INSTITUTION: Item[] = [
  {
    path: 'administrative',
    label: 'Administratifs et familiaux',
    subSteps: [
      ['concerned', 'Personne concernée'],
      ['legalGuardian', 'Responsable légal'],
      ['family', 'Famille'],
      ['medicalTeam', 'Équipe médicale'],
    ],
  },
  {
    path: 'social',
    label: 'Renseignements sociaux',
    subSteps: [
      ['coverage', 'Couverture sociale'],
      ['otherCoverage', 'Autre couverture'],
    ],
  },
  {
    path: 'educative',
    label: 'Renseignements éducatifs',
    subSteps: [
      ['situation', 'Situation actuelle'],
      ['ability', 'Évaluation des capacités'],
      ['aggressiveness', 'Aggressivité'],
      ['sexualityBehavior', 'Sexualité'],
      ['foodLine', 'Conduites alimentaires'],
      ['runaway', 'Fugues'],
      ['addiction', 'Conduites Addictives'],
    ],
  },
  {
    path: 'financial',
    label: 'Renseignements financiers',
    subSteps: [
      ['benefit', 'Prestations perçues'],
      ['pocketMoney', 'Argent personnel'],
    ],
  },
  {
    path: 'document',
    label: 'Documents à joindre',
    subSteps: [
      ['owner', 'Documents administratifs'],
      ['medical', 'Documents médicaux'],
      ['educative', 'Documents socio-éducatifs'],
      ['free', 'Documents libres'],
    ],
  },
  {
    path: 'medical',
    label: 'Renseignements médicaux',
    subSteps: [
      ['patient', 'Patient'],
      ['ald', 'Informations ALD'],
      ['diagnostic', 'Diagnostic principal'],
      ['handicap', 'Origine du handicap'],
      ['treatment', 'Traitement actuel'],
      ['deficiency', 'Déficiences'],
      ['lifeStyle', 'Mode de vie'],
      ['vaccination', 'Vaccinations'],
      ['history', 'Antécédents'],
      ['author', 'Auteur des données'],
    ],
  },
]

export const ORDER_ADMISSION = [
  {
    label: 'Lieux de vie compatibles',
    path: 'lieux-de-vie',
    childrenLabel: {
      disabled: 'Vérouillé',
      pending: 'Démarche à effectuer',
    },
  },
  {
    label: 'Choix des lieux de vie',
    path: 'choix',
    childrenLabel: {
      disabled: 'Vérouillé',
      pending: 'Démarche à effectuer',
    },
  },
  {
    label: 'Dossier MDPH et notification',
    path: 'mdph',
    childrenLabel: {
      disabled: 'Vérouillé',
      pending: 'Démarche à effectuer',
    },
  },
  {
    label: 'Accord de pris en charge',
    path: 'accord',
    childrenLabel: {
      disabled: 'Vérouillé',
      pending: 'Démarche à effectuer',
    },
  },
  {
    label: 'Demande financement',
    path: 'financement',
    childrenLabel: {
      disabled: 'Vérouillé',
      pending: 'Démarche à effectuer',
    },
  },
  {
    label: 'Trousseau & Accueil',
    path: 'trousseau',
    childrenLabel: {
      disabled: 'Vérouillé',
      pending: 'Démarche à effectuer',
    },
  },
]

export function getOrder(userRole?: User['role']) {
  const order: { [key in string]: Item[] } = {
    doctor: ORDER_DOCTOR,
    userInstitution: ORDER_INSTITUTION,
    default: ORDER_PATIENT,
  }

  return order[camelCase(userRole)] || order['default']
}

export function computeAdmissionProgressVariant(as?: AdmissionStatus) {
  const status: { [key in string]: ProgressVariant[] } = {
    toDo: ['pending', 'disabled', 'disabled', 'disabled', 'disabled', 'disabled'],
    pending: ['completed', 'disabled', 'disabled', 'disabled', 'disabled', 'disabled'],
    establishmentChoice: ['completed', 'pending', 'disabled', 'disabled', 'disabled', 'disabled'],
    waitMdph: ['completed', 'completed', 'pending', 'disabled', 'disabled', 'disabled'],
    assumptionAgreement: ['completed', 'completed', 'completed', 'pending', 'disabled', 'disabled'],
    waitFinancial: ['completed', 'completed', 'completed', 'completed', 'pending', 'disabled'],
    resident: ['completed', 'completed', 'completed', 'completed', 'completed', 'pending'],
    default: ['disabled', 'disabled', 'disabled', 'disabled', 'disabled', 'disabled'],
  }

  return status[camelCase(as)] || status['default']
}
