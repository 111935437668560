import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { get, some, map } from 'lodash'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'
import Separator from 'ui/separator'
import CardInfo from 'ui/card/info'
import Button, { LinkButton } from 'ui/button'
import Icon from 'ui/icon'

import { Controls } from 'components/pages/form'
import { ACCOMMODATION_PUBLIC_OPTIONS } from 'constants/form'

import { AuthContext } from 'App'
import { getAdmission } from 'api/admission'
import { InstitutionCandidacy } from 'types/candidacy'
import useResource from 'hooks/useResource'

import { InstitutionStepContext } from '.'

function InstitutionCard({
  data,
  selectedInstitutions,
  onSelectInstitution,
}: {
  data: InstitutionCandidacy
  selectedInstitutions: InstitutionCandidacy[]
  onSelectInstitution: (ic: InstitutionCandidacy) => void
}) {
  return (
    <Box fd="c" withBorder br={4} style={{ marginBottom: '16px' }}>
      <Box padding="24px" jc="sb">
        <Box fd="c">
          <Text fs={18} color="black" bold>
            {data.name}
          </Text>
          <Text fs={16} color="black">
            {data.address}
          </Text>
        </Box>
        <Box jc="fe">
          {data.website && (
            <LinkButton
              className="outline small"
              style={{ marginRight: '8px' }}
              href={data.website.match(/\/\//) ? data.website : `//${data.website}`}
              target="_blank"
            >
              Voir le site web
            </LinkButton>
          )}
          <Button
            variant={some(selectedInstitutions, data) ? 'success' : 'outline'}
            size="small"
            onClick={() => onSelectInstitution(data)}
            disabled={selectedInstitutions.length >= 3 && !some(selectedInstitutions, data)}
          >
            {some(selectedInstitutions, data) ? (
              <>
                Sélectionné
                <Icon
                  name="check"
                  size={15}
                  color="white"
                  containerStyle={{ paddingLeft: '6px' }}
                />
              </>
            ) : (
              'Postuler'
            )}
          </Button>
        </Box>
      </Box>
      <Separator color="greyLightN3" />
      <Box padding="24px">
        <Box fd="c">
          <Text fs={14} color="black" bold>
            PUBLIC ACCUEILLI
          </Text>
          <Text fs={14} color="black">
            {data.type
              .map(value =>
                ACCOMMODATION_PUBLIC_OPTIONS.filter(apo => apo.value === value).map(
                  apo => apo.label,
                ),
              )
              .join(', ')}
          </Text>
        </Box>
        <Box fd="c">
          <Text fs={14} color="black" bold>
            TÉLÉPHONE
          </Text>
          <Text fs={14} color="black">
            {data.phone}
          </Text>
        </Box>
        <Box fd="c">
          <Text fs={14} color="black" bold>
            MAIL
          </Text>
          <Text fs={14} color="black">
            {data.mail}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default function Step1() {
  const { auth } = useContext(AuthContext)
  const { selectedInstitutions, setSelectedInstitutions } = useContext(InstitutionStepContext)
  const history = useHistory()
  const [resourceState] = useResource<InstitutionCandidacy[], {}>(
    undefined,
    () => getAdmission(get(auth, 'selectedId', '0')).then(({ candidacies }) => candidacies || []),
    [],
  )
  const { section, step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)

  return (
    <Box
      fd="c"
      style={{
        minHeight: '100%',
      }}
    >
      <Box
        fd="c"
        padding="32px"
        style={{
          overflowY: 'scroll',
        }}
      >
        <Box f="initial" ai="b">
          <Title fs={22} bold spacingBottom="32px">
            Lieux de vie compatibles
          </Title>
          {resourceState.resource && (
            <Text opacity={0.5} style={{ marginLeft: '8px' }}>
              ({resourceState.resource.length})
            </Text>
          )}
        </Box>
        <CardInfo
          name="Comment les avons-nous sélectionnés ?"
          description="Nous avons sélectionné ces lieux de vie selon des critères géographiques, le public accueilli ainsi que la qualité de vie des habitants. Vous pouvez postuler dans 3 établissements."
          style={{ marginBottom: '32px' }}
        />
        <Box fd="c" f="initial">
          {resourceState.loading ? (
            <Box ai="c" jc="c">
              <Loader />
            </Box>
          ) : (
            map(resourceState.resource, (institution, i) => (
              <InstitutionCard
                key={`institution-${i}`}
                data={institution}
                selectedInstitutions={selectedInstitutions}
                onSelectInstitution={setSelectedInstitutions}
              />
            ))
          )}
        </Box>
      </Box>
      <Controls
        prevButton={{
          callback: () => history.push(`/admission/${section}/etape/${parsedStep - 1}`),
          label: 'Retour',
        }}
        nextButton={{
          callback: () => history.push(`/admission/${section}/etape/${parsedStep + 1}`),
          disabled: selectedInstitutions.length === 0,
          label: 'Continuer',
        }}
      />
    </Box>
  )
}
