import { AxiosResponse } from 'axios'
import { forEach, forOwn, get, reduce } from 'lodash'
import { decamelize } from 'humps'

import TMedical from 'types/medical'

import request from './request'

const SPECIAL_CASE = {
  family: 'families',
  ability: 'abilities',
  aggressiveness: 'aggressivenesses',
  history: 'histories',
  deficiency: 'deficiencies',
}

function formToFormData<F extends {}>(form: string, inputData: F) {
  return reduce(
    inputData,
    (acc, data: any, key) => {
      const value = data instanceof FileList ? data[0] : data
      value instanceof Array
        ? value.forEach(v => acc.append(`${decamelize(form)}[${decamelize(key)}][]`, v))
        : acc.append(`${decamelize(form)}[${decamelize(key)}]`, value || '')

      return acc
    },
    new FormData(),
  )
}

export function getProgress<T>(admissionRecordsId: string, form: string) {
  return request
    .get<any, AxiosResponse<T>>(`/admission_records/${admissionRecordsId}/${form}`)
    .then(res => res.data)
}

export function submit<Reply, Form extends { [key: string]: any }>(
  admissionRecordsId: string,
  form: string,
  subForm: string,
  data: Form,
  sendInJson?: boolean,
): Promise<any> {
  const key: string = Object.keys(data)[0]
  const requestType = data[key].id ? 'patch' : 'post'
  const body = sendInJson ? data : formToFormData<Form>(key, data[key])

  return request[requestType]<any, AxiosResponse<Reply>>(
    `/admission/${admissionRecordsId}/${form}/${decamelize(
      get(SPECIAL_CASE, subForm, `${subForm}s`),
    )}${requestType === 'patch' ? `/${data[key].id}` : ''}`,
    body,
    sendInJson
      ? {}
      : {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
  ).then(res => res.data)
}

export function deleteFile(admissionRecordsId: string, fileId: string) {
  return request.post(`/admission/${admissionRecordsId}/document/frees/delete_file`, {
    filesDestroy: {
      id: fileId,
    },
  })
}

export function deleteFamilyMember(admissionRecordsId: string, familyMembersId: string) {
  return request.delete(
    `/admission/${admissionRecordsId}/administrative/family_members/${familyMembersId}`,
  )
}

export function submitVaccination(
  admissionRecordsId: string,
  data: TMedical['vaccination'],
): Promise<any> {
  const requestType = data.id ? 'patch' : 'post'
  const body = reduce(
    data,
    (acc, data: any, key) => {
      if (key === 'vaccinesAttributes') {
        forEach(data, (vaccine, index) =>
          forOwn(vaccine, (vaccineValue, vaccineKey) => {
            acc.append(
              `vaccination[${decamelize('vaccinesAttributes')}][${index}][${decamelize(
                vaccineKey,
              )}]`,
              vaccineValue || '',
            )
          }),
        )
      } else {
        const value = data instanceof FileList ? data[0] : data
        acc.append(`vaccination[${decamelize(key)}]`, value || '')
      }

      return acc
    },
    new FormData(),
  )

  return request[requestType]<any, AxiosResponse<TMedical['vaccination']>>(
    `/admission/${admissionRecordsId}/medical/vaccinations${
      requestType === 'patch' ? `/${data.id}` : ''
    }`,
    body,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  ).then(res => res.data)
}
