import React, { useState } from 'react'

import Modal from 'ui/modal'
import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'
import { deleteKitList } from 'api/kit'
import { useToasts } from 'hooks/useToaster'

export default function DeleteKit({
  kitInfos,
  opened,
  onClose,
  onSuccess,
  selectedInstitutionId,
}: {
  kitInfos: {
    id: string
    name: string
    type: string
  }
  opened: boolean
  onClose: () => void
  onSuccess: () => void
  selectedInstitutionId: string | undefined
}) {
  const { addToast, addGenericErrorToast } = useToasts()
  const [loading, setLoader] = useState(false)

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      title={`Suppression du trousseau ${kitInfos.name} - ${kitInfos.type}`}
    >
      <Box fd="c">
        <Box br={4} bg="redLight2" padding="32px 24px">
          <Icon name="message" color="pink" size={22} containerStyle={{ marginRight: '24px' }} />
          <Text fs={16}>
            Êtes-vous sûr de votre choix ? La suppresion du trousseau est définitive.
          </Text>
        </Box>
        <Box jc="fe" padding="16px 0 0 0">
          <Button variant="outline" onClick={onClose} style={{ marginRight: '16px' }}>
            Annuler
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setLoader(true)

              deleteKitList(kitInfos.id, selectedInstitutionId)
                .then(() => {
                  setLoader(false)
                  addToast({
                    title: 'Succès !',
                    type: 'success',
                    message: 'Trousseau supprimé avec succès !',
                  })
                  onSuccess()
                  onClose()
                })
                .catch(() => {
                  addGenericErrorToast()
                  setLoader(false)
                })
            }}
          >
            {loading ? (
              <Loader size={12} />
            ) : (
              <>
                Supprmer le trousseau
                <Icon name="arrow" color="white" size={20} containerStyle={{ marginLeft: '8px' }} />
              </>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
