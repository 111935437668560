// Fetch Cities List fron API
export const getCitiesByZipCode = (zipCode: string) =>
  fetch(
    `https://datanova.laposte.fr/data-fair/api/v1/datasets/laposte-hexasmal/values/nom_de_la_commune?qs=${zipCode}`,
  )
    .then(res => res.json())
    .then(
      result => result,
      error => console.error(error),
    )
