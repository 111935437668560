import React from 'react'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'

const StepLayout: React.FC<{
  title: string
  subText: string
}> = ({ title, subText, children }) => (
  <Box padding="8% 12% 64px 12%" fd="c">
    <Box fd="c">
      <Title fs={28} bold spacingBottom="8px">
        {title}
      </Title>
      <Text opacity={0.6} spacingBottom="8%">
        {subText}
      </Text>
      <Box fd="c">{children}</Box>
    </Box>
  </Box>
)

export default StepLayout
