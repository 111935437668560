export const countries = [
  { value: 'France', label: 'France' },
  { value: 'Belgique', label: 'Belgique' },
  { value: 'Afghanistan', label: 'Afghanistan' },
  { value: 'Afrique du Sud', label: 'Afrique du Sud' },
  { value: 'Albanie', label: 'Albanie' },
  { value: 'Algérie', label: 'Algérie' },
  { value: 'Allemagne', label: 'Allemagne' },
  { value: 'Andorre', label: 'Andorre' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Anguilla', label: 'Anguilla' },
  { value: 'Antarctique', label: 'Antarctique' },
  { value: 'Antigua-et-Barbuda', label: 'Antigua-et-Barbuda' },
  { value: 'Antilles néerlandaises', label: 'Antilles néerlandaises' },
  { value: 'Arabie saoudite', label: 'Arabie saoudite' },
  { value: 'Argentine', label: 'Argentine' },
  { value: 'Arménie', label: 'Arménie' },
  { value: 'Aruba', label: 'Aruba' },
  { value: 'Australie', label: 'Australie' },
  { value: 'Autriche', label: 'Autriche' },
  { value: 'Azerbaïdjan', label: 'Azerbaïdjan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Bahreïn', label: 'Bahreïn' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbade', label: 'Barbade' },
  { value: 'Bélarus', label: 'Bélarus' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Bénin', label: 'Bénin' },
  { value: 'Bermudes', label: 'Bermudes' },
  { value: 'Bhoutan', label: 'Bhoutan' },
  { value: 'Bolivie', label: 'Bolivie' },
  { value: 'Bosnie-Herzégovine', label: 'Bosnie-Herzégovine' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Brésil', label: 'Brésil' },
  { value: 'Brunéi Darussalam', label: 'Brunéi Darussalam' },
  { value: 'Bulgarie', label: 'Bulgarie' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Cambodge', label: 'Cambodge' },
  { value: 'Cameroun', label: 'Cameroun' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Cap-Vert', label: 'Cap-Vert' },
  { value: 'Ceuta et Melilla', label: 'Ceuta et Melilla' },
  { value: 'Chili', label: 'Chili' },
  { value: 'Chine', label: 'Chine' },
  { value: 'Chypre', label: 'Chypre' },
  { value: 'Colombie', label: 'Colombie' },
  { value: 'Comores', label: 'Comores' },
  { value: 'Congo-Brazzaville', label: 'Congo-Brazzaville' },
  { value: 'Corée du Nord', label: 'Corée du Nord' },
  { value: 'Corée du Sud', label: 'Corée du Sud' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Côte d’Ivoire', label: 'Côte d’Ivoire' },
  { value: 'Croatie', label: 'Croatie' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Danemark', label: 'Danemark' },
  { value: 'Diego Garcia', label: 'Diego Garcia' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominique', label: 'Dominique' },
  { value: 'Égypte', label: 'Égypte' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Émirats arabes unis', label: 'Émirats arabes unis' },
  { value: 'Équateur', label: 'Équateur' },
  { value: 'Érythrée', label: 'Érythrée' },
  { value: 'Espagne', label: 'Espagne' },
  { value: 'Estonie', label: 'Estonie' },
  { value: 'État de la Cité du Vatican', label: 'État de la Cité du Vatican' },
  { value: 'États fédérés de Micronésie', label: 'États fédérés de Micronésie' },
  { value: 'États-Unis', label: 'États-Unis' },
  { value: 'Éthiopie', label: 'Éthiopie' },
  { value: 'Fidji', label: 'Fidji' },
  { value: 'Finlande', label: 'Finlande' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambie', label: 'Gambie' },
  { value: 'Géorgie', label: 'Géorgie' },
  {
    value: 'Géorgie du Sud et les îles Sandwich du Sud',
    label: 'Géorgie du Sud et les îles Sandwich du Sud',
  },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Gibraltar', label: 'Gibraltar' },
  { value: 'Grèce', label: 'Grèce' },
  { value: 'Grenade', label: 'Grenade' },
  { value: 'Groenland', label: 'Groenland' },
  { value: 'Guadeloupe', label: 'Guadeloupe' },
  { value: 'Guam', label: 'Guam' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guernesey', label: 'Guernesey' },
  { value: 'Guinée', label: 'Guinée' },
  { value: 'Guinée équatoriale', label: 'Guinée équatoriale' },
  { value: 'Guinée-Bissau', label: 'Guinée-Bissau' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Guyane française', label: 'Guyane française' },
  { value: 'Haïti', label: 'Haïti' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hongrie', label: 'Hongrie' },
  { value: 'Île Bouvet', label: 'Île Bouvet' },
  { value: 'Île Christmas', label: 'Île Christmas' },
  { value: 'Île Clipperton', label: 'Île Clipperton' },
  { value: "Île de l'Ascension", label: "Île de l'Ascension" },
  { value: 'Île de Man', label: 'Île de Man' },
  { value: 'Île Norfolk', label: 'Île Norfolk' },
  { value: 'Îles Åland', label: 'Îles Åland' },
  { value: 'Îles Caïmans', label: 'Îles Caïmans' },
  { value: 'Îles Canaries', label: 'Îles Canaries' },
  { value: 'Îles Cocos - Keeling', label: 'Îles Cocos - Keeling' },
  { value: 'Îles Cook', label: 'Îles Cook' },
  { value: 'Îles Féroé', label: 'Îles Féroé' },
  { value: 'Îles Heard et MacDonald', label: 'Îles Heard et MacDonald' },
  { value: 'Îles Malouines', label: 'Îles Malouines' },
  { value: 'Îles Mariannes du Nord', label: 'Îles Mariannes du Nord' },
  { value: 'Îles Marshall', label: 'Îles Marshall' },
  {
    value: 'Îles Mineures Éloignées des États-Unis',
    label: 'Îles Mineures Éloignées des États-Unis',
  },
  { value: 'Îles Salomon', label: 'Îles Salomon' },
  { value: 'Îles Turks et Caïques', label: 'Îles Turks et Caïques' },
  { value: 'Îles Vierges britanniques', label: 'Îles Vierges britanniques' },
  { value: 'Îles Vierges des États-Unis', label: 'Îles Vierges des États-Unis' },
  { value: 'Inde', label: 'Inde' },
  { value: 'Indonésie', label: 'Indonésie' },
  { value: 'Irak', label: 'Irak' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Irlande', label: 'Irlande' },
  { value: 'Islande', label: 'Islande' },
  { value: 'Israël', label: 'Israël' },
  { value: 'Italie', label: 'Italie' },
  { value: 'Jamaïque', label: 'Jamaïque' },
  { value: 'Japon', label: 'Japon' },
  { value: 'Jersey', label: 'Jersey' },
  { value: 'Jordanie', label: 'Jordanie' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kirghizistan', label: 'Kirghizistan' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Koweït', label: 'Koweït' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Lettonie', label: 'Lettonie' },
  { value: 'Liban', label: 'Liban' },
  { value: 'Libéria', label: 'Libéria' },
  { value: 'Libye', label: 'Libye' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lituanie', label: 'Lituanie' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Macédoine', label: 'Macédoine' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malaisie', label: 'Malaisie' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malte', label: 'Malte' },
  { value: 'Maroc', label: 'Maroc' },
  { value: 'Martinique', label: 'Martinique' },
  { value: 'Maurice', label: 'Maurice' },
  { value: 'Mauritanie', label: 'Mauritanie' },
  { value: 'Mayotte', label: 'Mayotte' },
  { value: 'Mexique', label: 'Mexique' },
  { value: 'Moldavie', label: 'Moldavie' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolie', label: 'Mongolie' },
  { value: 'Monténégro', label: 'Monténégro' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Myanmar', label: 'Myanmar' },
  { value: 'Namibie', label: 'Namibie' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Népal', label: 'Népal' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigéria', label: 'Nigéria' },
  { value: 'Niue', label: 'Niue' },
  { value: 'Norvège', label: 'Norvège' },
  { value: 'Nouvelle-Calédonie', label: 'Nouvelle-Calédonie' },
  { value: 'Nouvelle-Zélande', label: 'Nouvelle-Zélande' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Ouganda', label: 'Ouganda' },
  { value: 'Ouzbékistan', label: 'Ouzbékistan' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palaos', label: 'Palaos' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papouasie-Nouvelle-Guinée', label: 'Papouasie-Nouvelle-Guinée' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Pays-Bas', label: 'Pays-Bas' },
  { value: 'Pérou', label: 'Pérou' },
  { value: 'Philippines', label: 'Philippines' },
  { value: 'Pitcairn', label: 'Pitcairn' },
  { value: 'Pologne', label: 'Pologne' },
  { value: 'Polynésie française', label: 'Polynésie française' },
  { value: 'Porto Rico', label: 'Porto Rico' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'R.A.S. chinoise de Hong Kong', label: 'R.A.S. chinoise de Hong Kong' },
  { value: 'R.A.S. chinoise de Macao', label: 'R.A.S. chinoise de Macao' },
  { value: 'régions éloignées de l’Océanie', label: 'régions éloignées de l’Océanie' },
  { value: 'République centrafricaine', label: 'République centrafricaine' },
  { value: 'République démocratique du Congo', label: 'République démocratique du Congo' },
  { value: 'République dominicaine', label: 'République dominicaine' },
  { value: 'République tchèque', label: 'République tchèque' },
  { value: 'Réunion', label: 'Réunion' },
  { value: 'Roumanie', label: 'Roumanie' },
  { value: 'Royaume-Uni', label: 'Royaume-Uni' },
  { value: 'Russie', label: 'Russie' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'Sahara occidental', label: 'Sahara occidental' },
  { value: 'Saint-Barthélémy', label: 'Saint-Barthélémy' },
  { value: 'Saint-Kitts-et-Nevis', label: 'Saint-Kitts-et-Nevis' },
  { value: 'Saint-Marin', label: 'Saint-Marin' },
  { value: 'Saint-Martin', label: 'Saint-Martin' },
  { value: 'Saint-Pierre-et-Miquelon', label: 'Saint-Pierre-et-Miquelon' },
  { value: 'Saint-Vincent-et-les Grenadines', label: 'Saint-Vincent-et-les Grenadines' },
  { value: 'Sainte-Hélène', label: 'Sainte-Hélène' },
  { value: 'Sainte-Lucie', label: 'Sainte-Lucie' },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'Samoa américaines', label: 'Samoa américaines' },
  { value: 'Sao Tomé-et-Principe', label: 'Sao Tomé-et-Principe' },
  { value: 'Sénégal', label: 'Sénégal' },
  { value: 'Serbie', label: 'Serbie' },
  { value: 'Serbie-et-Monténégro', label: 'Serbie-et-Monténégro' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Singapour', label: 'Singapour' },
  { value: 'Slovaquie', label: 'Slovaquie' },
  { value: 'Slovénie', label: 'Slovénie' },
  { value: 'Somalie', label: 'Somalie' },
  { value: 'Soudan', label: 'Soudan' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Suède', label: 'Suède' },
  { value: 'Suisse', label: 'Suisse' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Svalbard et Île Jan Mayen', label: 'Svalbard et Île Jan Mayen' },
  { value: 'Swaziland', label: 'Swaziland' },
  { value: 'Syrie', label: 'Syrie' },
  { value: 'Tadjikistan', label: 'Tadjikistan' },
  { value: 'Taïwan', label: 'Taïwan' },
  { value: 'Tanzanie', label: 'Tanzanie' },
  { value: 'Tchad', label: 'Tchad' },
  { value: 'Terres australes françaises', label: 'Terres australes françaises' },
  {
    value: "Territoire britannique de l'océan Indien",
    label: "Territoire britannique de l'océan Indien",
  },
  { value: 'Territoire palestinien', label: 'Territoire palestinien' },
  { value: 'Thaïlande', label: 'Thaïlande' },
  { value: 'Timor oriental', label: 'Timor oriental' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tokelau', label: 'Tokelau' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinité-et-Tobago', label: 'Trinité-et-Tobago' },
  { value: 'Tristan da Cunha', label: 'Tristan da Cunha' },
  { value: 'Tunisie', label: 'Tunisie' },
  { value: 'Turkménistan', label: 'Turkménistan' },
  { value: 'Turquie', label: 'Turquie' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'Union européenne', label: 'Union européenne' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Viêt Nam', label: 'Viêt Nam' },
  { value: 'Wallis-et-Futuna', label: 'Wallis-et-Futuna' },
  { value: 'Yémen', label: 'Yémen' },
  { value: 'Zambie', label: 'Zambie' },
  { value: 'Zimbabwe', label: 'Zimbabwe' },
]
