import styled from 'styled-components'

export default styled.label<{
  required?: boolean
  fs?: number
  bold?: boolean
  hasError?: boolean
}>`
  font-family: Nunito;
  font-weight: 600;
  line-height: ${props => (props.fs ? props.fs + 10 : 26)}px;
  font-size: ${props => props.fs || 16}px;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};

  &::before {
    content: '${props => (props.required ? '* ' : '')}';
    color: ${props => props.theme.pink};
  }
`
