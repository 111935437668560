import React from 'react'

import { ReactComponent as LightLogo } from 'assets/logo-light.svg'
import { ReactComponent as DarkLogo } from 'assets/logo-dark.svg'

export default function Logo({
  paddingBottom = '0',
  darkLogo,
  url = '/',
}: {
  paddingBottom?: string
  darkLogo?: boolean
  url?: string
}) {
  const Logo = darkLogo ? DarkLogo : LightLogo

  return (
    <a href={url}>
      <Logo style={{ paddingBottom, width: '123px' }} />
    </a>
  )
}
