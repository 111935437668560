import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { map, find, capitalize, isEmpty } from 'lodash'

import Box, { BoxWithHeader } from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Button, { BackButton } from 'ui/button'
import Icon from 'ui/icon'
import Loader from 'ui/loader'
import DeleteKit from 'components/modals/kit'

import { TYPE_OPTIONS } from './form/step1'
import { SECTIONS } from './form/step2'

import { getKitLists } from 'api/kit'
import { KitPayload } from 'types/kit'

import useResource from 'hooks/useResource'
import { usePageName } from 'hooks/usePageName'

import { AuthContext } from 'App'

export default function KitList() {
  const history = useHistory()
  const { setPageName } = usePageName()
  const { auth } = useContext(AuthContext)

  const [{ resource, loading }, dispatch] = useResource<KitPayload[], {}>(
    undefined,
    () => getKitLists(auth.selectedInstitutionId),
    [auth.selectedInstitutionId],
  )
  const [openPanelId, setOpenPanelId] = useState<string>()
  const [kitToDelete, setKitToDelete] = useState<{ name: string; type: string; id: string }>()

  useEffect(() => {
    setPageName('Mon Établissement')
  }, [])

  return (
    <Box gutter="negative" style={{ maxHeight: '100%' }}>
      <BoxWithHeader
        br={4}
        style={{ margin: '0 15px', alignSelf: 'flex-start', maxHeight: '100%' }}
      >
        <Box>
          <BackButton onClick={history.goBack} />
          <Title fs={24} bold>
            Liste des trousseaux
          </Title>
          <Box jc="fe">
            <Button
              variant="outline"
              onClick={() => history.push('/mon-etablissement/trousseaux/edition')}
            >
              <Icon name="add" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
              Créer un nouveau trousseau
            </Button>
          </Box>
        </Box>

        <Box fd="c" padding="16px 32px 0 32px" style={{ overflowY: 'scroll' }}>
          {loading ? (
            <Box fd="c" ai="c">
              <Loader />
            </Box>
          ) : (
            map(resource, ({ id, name, typeList, createdAt, ...kitItems }) => {
              const displayed = openPanelId === id

              return (
                <Box
                  key={`kit-${id}`}
                  br={4}
                  withBorder
                  padding="26px 32px"
                  style={{ marginTop: '16px' }}
                  fd="c"
                >
                  <Box>
                    <Box fd="c">
                      <Title>{`${name} - ${
                        find(TYPE_OPTIONS, o => o.value === typeList)?.label
                      }`}</Title>
                      <Text opacity={0.5}>{`Ajouté le ${new Date(
                        createdAt,
                      ).toLocaleDateString()}`}</Text>
                    </Box>
                    <Box jc="fe" ai="c">
                      <Button
                        variant="outline"
                        onClick={() => setOpenPanelId(displayed ? undefined : id)}
                        style={{ marginRight: '8px' }}
                        size="small"
                      >
                        {displayed ? 'Masquer' : 'Déplier'}
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => history.push(`/mon-etablissement/trousseaux/edition/${id}`)}
                        style={{ marginRight: '8px' }}
                        size="small"
                      >
                        <Icon name="pencil" size={20} />
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() =>
                          setKitToDelete({
                            name,
                            type: find(TYPE_OPTIONS, o => o.value === typeList)?.label || '',
                            id,
                          })
                        }
                        size="small"
                      >
                        <Icon name="trash" size={16} color="pink" />
                      </Button>
                    </Box>
                  </Box>
                  <Box fd="c" style={{ display: displayed ? 'flex' : 'none', marginTop: '32px' }}>
                    {SECTIONS.filter(({ name }) => !isEmpty(kitItems[name])).map(
                      ({ name, panelLabel }) => (
                        <Box fd="c" key={name}>
                          <Text color="black" bold style={{ margin: '24px 0 8px 0' }}>
                            {panelLabel}
                          </Text>
                          <Box br={4} bg="greyLightN" fd="c" padding="24px">
                            {map(kitItems[name], (value, key) => (
                              <Box
                                jc="sb"
                                style={{ marginTop: '8px' }}
                                key={`${name}-item-${key}-${id}`}
                              >
                                <Text color="black">{capitalize(key)}</Text>
                                <Text color="black">{value}</Text>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      ),
                    )}
                  </Box>
                </Box>
              )
            })
          )}
        </Box>
      </BoxWithHeader>

      <Box
        fd="c"
        bg="white"
        width="380px"
        br={4}
        withBorder
        padding="32px"
        style={{ margin: '0 15px', alignSelf: 'flex-start' }}
      >
        <Icon name="stacks" color="green" size={32} containerStyle={{ marginBottom: '16px' }} />
        <Box ai="c">
          <Title fs={18} bold spacingBottom="16px">
            Créez un trousseau pour chaque type d’accueil
          </Title>
        </Box>
        <Text>
          Cela vous ferra gagner un temps précieux lorsque vous accepterez les dossiers des usagers
          et conviendrez du rendez-vous d’accueil.
        </Text>
      </Box>
      <DeleteKit
        kitInfos={kitToDelete || { name: '', type: '', id: '' }}
        onClose={() => setKitToDelete(undefined)}
        onSuccess={() => dispatch({ name: 'REFRESH_DATA' })}
        opened={!!kitToDelete}
        selectedInstitutionId={auth.selectedInstitutionId}
      />
    </Box>
  )
}
