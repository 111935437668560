import { nationalities } from 'constants/nationality'
import { Form } from 'types/form'
import { ACTUAL_SITUATION, GENDER, HABITATION_SITUATION } from 'types/enums'
import { countries } from 'constants/country'

const CONCERNED_FORM: Form = [
  [
    {
      name: 'idCard',
      type: 'file',
      label: 'Photographie d’identité',
      placeholder: '',
      required: false,
    },
    null,
  ],
  [
    {
      name: 'gender',
      type: 'select',
      label: 'Sexe',
      placeholder: 'Homme, Femme',
      required: true,
      options: [
        { value: GENDER.MALE, label: 'Homme' },
        { value: GENDER.FEMALE, label: 'Femme' },
      ],
    },
    null,
  ],
  [
    {
      name: 'firstName',
      type: 'text',
      label: 'Prénom',
      placeholder: 'Saisissez le prénom',
      required: true,
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Nom',
      placeholder: 'Saisissez le nom',
      required: true,
    },
  ],
  [
    {
      name: 'dateOfBirth',
      type: 'date',
      label: 'Date de naissance',
      placeholder: 'JJ/MM/AAAA',
      required: true,
    },
    {
      name: 'placeOfBirth',
      type: 'text',
      label: 'Lieu de naissance',
      placeholder: 'Saisissez le lieu de naissance',
      required: true,
    },
  ],
  [
    {
      name: 'street',
      type: 'text',
      label: 'Adresse',
      placeholder: "Saisissez l'adresse",
      required: true,
    },
    {
      name: 'streetComplement',
      type: 'text',
      label: "Complément d'adresse",
      placeholder: "Saisissez le complément d'adresse",
      required: false,
    },
  ],
  [
    {
      name: 'zipcode',
      type: 'text',
      label: 'Code postal',
      placeholder: 'Saisissez le code postal',
      required: true,
    },
    {
      name: 'city',
      type: 'text',
      label: 'Ville',
      placeholder: 'Saisissez la ville',
      required: true,
    },
  ],
  [
    {
      name: 'country',
      type: 'select',
      label: 'Pays',
      placeholder: 'Choisissez le pays',
      options: countries,
      required: true,
    },
    null,
  ],
  [
    {
      name: 'phoneNumber',
      type: 'phone',
      label: 'Téléphone',
      placeholder: 'Saisissez le numéro de téléphone',
      required: false,
    },
    {
      name: 'email',
      type: 'text',
      label: 'E-mail',
      placeholder: "Saisissez l'e-mail",
      required: false,
    },
  ],
  [
    {
      name: 'nationality',
      type: 'select',
      label: 'Nationalité',
      placeholder: 'Choisissez la nationalité',
      required: true,
      options: nationalities,
    },
    {
      name: 'secondNationality',
      type: 'select',
      label: 'Deuxième nationalité',
      placeholder: 'Choisissez la seconde nationalité',
      required: true,
      options: [{ value: 'NONE', label: 'Aucune' }, ...nationalities],
    },
  ],
  [
    {
      name: 'actualSituation',
      type: 'select',
      label: 'Situation actuelle',
      placeholder: 'Choisissez la situation actuelle',
      required: true,
      options: [
        { value: ACTUAL_SITUATION.SINGLE, label: 'Célibataire' },
        { value: ACTUAL_SITUATION.MARRIED, label: 'Marié(e)' },
        { value: ACTUAL_SITUATION.IN_COHABITATION, label: 'En concubinage' },
        { value: ACTUAL_SITUATION.PACS, label: 'PACS' },
        { value: ACTUAL_SITUATION.WIDOWER, label: 'Veuf(ve)' },
        { value: ACTUAL_SITUATION.DIVORCED, label: 'Divorcé(e)' },
        { value: ACTUAL_SITUATION.SEPARATE, label: 'Séparé(e)' },
        { value: ACTUAL_SITUATION.OTHER, label: 'Autre' },
      ],
    },
    {
      name: 'habitationSituation',
      type: 'select',
      label: 'Prise en charge',
      placeholder: 'Choisissez la prise en charge',
      required: true,
      options: [
        {
          value: HABITATION_SITUATION.EHPAD,
          label:
            'La personne est en EHPAD (Etablissement d’Hébergement pour Personnes Agées Dépendantes)',
        },
        {
          value: HABITATION_SITUATION.ESMS,
          label: 'La personne est en ESMS (Etablissement social et médico-social)',
        },
        {
          value: HABITATION_SITUATION.HOSPITAL,
          label: ' La personne est en Etablissement sanitaire (hôpital ou clinique)',
        },

        {
          value: HABITATION_SITUATION.ALONE_AT_HOME,
          label: 'La personne vit à domicile - aucune prise en charge',
        },
        {
          value: HABITATION_SITUATION.FAMILY_HOME,
          label: "La personne vit à domicile d'un membre de sa famille",
        },
        {
          value: HABITATION_SITUATION.HELP_AT_HOME,
          label: "La personne vit à domicile d'un accueillant professionnel",
        },
        {
          value: HABITATION_SITUATION.RESIDENCY,
          label: 'La personne vit dans un appartement ou logement associatif',
        },
        {
          value: HABITATION_SITUATION.ESTABLISHMENT,
          label: 'La personne vit dans un appartement thérapeutique',
        },
      ],
    },
  ],
]

export default CONCERNED_FORM
