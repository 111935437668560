import React, { createContext, useContext, useMemo, useState } from 'react'

type ContextState = {
  setPageName: (name: string) => void
  pageName: string
}

const PageNameContext = createContext({} as ContextState)

function PageNameProvider({ children }: { children: any }) {
  const [pageName, setPageName] = useState('Accueil')

  const value = useMemo(() => ({ pageName, setPageName }), [pageName, setPageName])

  return <PageNameContext.Provider value={value}>{children}</PageNameContext.Provider>
}

function usePageName() {
  const context = useContext(PageNameContext)

  if (context === undefined) {
    throw new Error(`usePageName must be used within a PageName Provider`)
  }

  return context
}

export { PageNameProvider, usePageName }
