import React, { useEffect } from 'react'

import { getProgress, submit } from 'api/form'
import {
  GenericStep,
  FormLayout,
  GenericForm,
  GetItemsAdmissionRecord,
} from 'components/pages/form'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { usePageName } from 'hooks/usePageName'

import TAdministrative, {
  Concerned as TConcerned,
  LegalGuardian as TLegalGuardian,
  MedicalTeam as TMedicalTeam,
  Family as TFamily,
} from 'types/administrative'
import CONCERNED_FORM from 'components/pages/patient/administrative/forms/concerned'
import LEGAL_GUARDIAN_FORM from 'components/pages/patient/administrative/forms/legalGuardian'
import FormFamily from 'components/pages/patient/administrative/forms/family'
import MEDICAL_TEAM_FORM from 'components/pages/patient/administrative/forms/medicalTeam'

import TSocial, { Coverage, OtherCoverage } from 'types/social'
import COVERAGE_FORM from 'components/pages/patient/social/forms/socialCoverage'
import COVERAGE_FORM2 from 'components/pages/patient/social/forms/socialCoverageOther'

import TEducative from 'types/educative'
import SituationForm from 'components/pages/patient/educative/forms/situation'
import AbilityForm from 'components/pages/patient/educative/forms/ability'
import AGGRESSIVENESS_FORM from 'components/pages/patient/educative/forms/aggressiveness'
import SEXUALITY_BEHAVIOR_FORM from 'components/pages/patient/educative/forms/sexualBehavior'
import FOOD_FORM from 'components/pages/patient/educative/forms/food'
import RUNAWAY_FORM from 'components/pages/patient/educative/forms/runaway'
import ADDICTION_FORM from 'components/pages/patient/educative/forms/addiction'

import TFinancial, { Benefit, PocketMoney } from 'types/financial'
import POCKET_MONEY_FORM from 'components/pages/patient/financial/forms/pocketMoney'
import BENEFIT_FORM from 'components/pages/patient/financial/forms/benefit'

import TDocument from 'types/document'
import FormDoc from 'components/pages/patient/document/form'
import FreeDocumentForm from 'components/pages/patient/document/form/free'
import { EDUCATIVE_FORM, MEDICAL_FORM, OWNER_FORM } from 'components/pages/patient/document'

import TMedical from 'types/medical'
import PATIENT_FORM from 'components/pages/doctor/medical/forms/patient'
import ALD_FORM from 'components/pages/doctor/medical/forms/ald'
import DIAGNOSTIC_FORM from 'components/pages/doctor/medical/forms/diagnostic'
import HANDICAP_FORM from 'components/pages/doctor/medical/forms/handicap'
import TreatmentForm from 'components/pages/doctor/medical/forms/treatment'
import DEFICIENCY_FORM from 'components/pages/doctor/medical/forms/deficiency'
import LIFE_STYLE_FORM from 'components/pages/doctor/medical/forms/lifeStyle'
import VaccinationForm from 'components/pages/doctor/medical/forms/vaccination'
import HISTORY_FORM from 'components/pages/doctor/medical/forms/history'
import AUTHOR_FORM from 'components/pages/doctor/medical/forms/author'

import useResource from 'hooks/useResource'
import { AdmissionRecordProgress } from 'types/admissionRecord'
import { getAdmissionFormProgress } from 'api/admissionRecord'

export default function AdmissionRecordForm() {
  const { admissionRecordId, formName } = useParams<{
    admissionRecordId: string
    formName: string
  }>()
  const history = useHistory()
  const { pathname } = useLocation()
  const { setPageName } = usePageName()
  const [resourceState, dispatch] = useResource<AdmissionRecordProgress, {}>(
    undefined,
    () => getAdmissionFormProgress(admissionRecordId),
    [admissionRecordId],
  )

  useEffect(() => {
    setPageName('Mon Établissement')
  }, [])

  const renderContent = () => {
    switch (formName) {
      case 'administrative':
        return (
          <GenericForm<TAdministrative>
            key="administrative-form"
            title="Administratifs et familiaux"
            url={`/mon-etablissement/resident/${admissionRecordId}`}
            path="administrative"
            admissionRecordId={admissionRecordId}
            resourceLoader={() => getProgress(admissionRecordId, 'administrative')}
            globalStep={1}
            afterSave={() => dispatch({ name: 'REFRESH_DATA' })}
            steps={() => [
              {
                title: 'Personne concernée',
                Component: ({ resource }) => (
                  <GenericStep<TAdministrative>
                    resource={resource}
                    subForm="concerned"
                    fields={CONCERNED_FORM}
                  />
                ),
                save: (data: { concerned: TConcerned }) => {
                  return submit<TConcerned, { concerned: TConcerned }>(
                    admissionRecordId,
                    'administrative',
                    'concerned',
                    data,
                  )
                },
              },
              {
                title: 'Responsable légal',
                Component: ({ resource }) => (
                  <GenericStep<TAdministrative>
                    resource={resource}
                    subForm="legalGuardian"
                    fields={LEGAL_GUARDIAN_FORM}
                  />
                ),
                save: (data: { legalGuardian: TLegalGuardian }) => {
                  return submit<TLegalGuardian, { legalGuardian: TLegalGuardian }>(
                    admissionRecordId,
                    'administrative',
                    'legal_guardian',
                    data,
                  )
                },
              },
              {
                title: 'Famille',
                Component: ({ resource }) => (
                  <FormFamily resource={resource} admissionRecordId={admissionRecordId} />
                ),
                save: (data: { family: TFamily }) => {
                  return submit<TFamily, { family: TFamily }>(
                    admissionRecordId,
                    'administrative',
                    'family',
                    data,
                    true,
                  )
                },
              },
              {
                title: 'Équipe médicale',
                Component: ({ resource }) => (
                  <GenericStep<TAdministrative>
                    resource={resource}
                    subForm="medicalTeam"
                    fields={MEDICAL_TEAM_FORM}
                  />
                ),
                save: (data: { medicalTeam: TMedicalTeam }) => {
                  return submit<TMedicalTeam, { medicalTeam: TMedicalTeam }>(
                    admissionRecordId,
                    'administrative',
                    'medical_team',
                    data,
                  )
                },
              },
            ]}
          />
        )
      case 'social':
        return (
          <GenericForm<TSocial>
            key="social-form"
            title="Renseignements sociaux"
            url={`/mon-etablissement/resident/${admissionRecordId}`}
            path="social"
            admissionRecordId={admissionRecordId}
            resourceLoader={() => getProgress(admissionRecordId, 'social')}
            globalStep={2}
            steps={() => [
              {
                title: 'Couverture sociale',
                Component: ({ resource }) => (
                  <GenericStep<TSocial>
                    resource={resource}
                    subForm="coverage"
                    fields={COVERAGE_FORM}
                    subTitle="Informations relatives à la couverture sociale"
                  />
                ),
                save: (data: any) => {
                  return submit<Coverage, { coverage: Coverage }>(
                    admissionRecordId,
                    'social',
                    'coverage',
                    data,
                  )
                },
              },
              {
                title: 'Autre couverture',
                Component: ({ resource }) => (
                  <GenericStep<TSocial>
                    resource={resource}
                    subForm="otherCoverage"
                    fields={COVERAGE_FORM2}
                    subTitle="Informations relatives à la couverture sociale"
                  />
                ),
                save: (data: any) => {
                  return submit<OtherCoverage, { otherCoverage: OtherCoverage }>(
                    admissionRecordId,
                    'social',
                    'other_coverage',
                    data,
                  )
                },
              },
            ]}
          />
        )
      case 'educative':
        return (
          <GenericForm<TEducative>
            key="educative-form"
            title="Renseignements éducatifs"
            url={`/mon-etablissement/resident/${admissionRecordId}`}
            path="educative"
            admissionRecordId={admissionRecordId}
            resourceLoader={() => getProgress(admissionRecordId, 'educative')}
            globalStep={3}
            steps={() => [
              {
                title: 'Situation actuelle',
                Component: ({ resource }) => <SituationForm resource={resource} />,
                save: (data: { situation: TEducative['situation'] }) => {
                  return submit<TEducative['situation'], { situation: TEducative['situation'] }>(
                    admissionRecordId,
                    'educative',
                    'situation',
                    data,
                    true,
                  )
                },
              },
              {
                title: 'Évaluation des capacités',
                Component: ({ resource }) => <AbilityForm resource={resource} />,
                save: (data: any) => {
                  return submit<TEducative['ability'], { ability: TEducative['ability'] }>(
                    admissionRecordId,
                    'educative',
                    'ability',
                    data,
                  )
                },
              },
              {
                title: 'Évaluation des capacités',
                Component: ({ resource }) => (
                  <GenericStep<TEducative>
                    subTitle="Aggressivité"
                    resource={resource}
                    subForm="aggressiveness"
                    fields={AGGRESSIVENESS_FORM}
                  />
                ),
                save: (data: any) => {
                  return submit<
                    TEducative['aggressiveness'],
                    { aggressiveness: TEducative['aggressiveness'] }
                  >(admissionRecordId, 'educative', 'aggressiveness', data)
                },
              },
              {
                title: 'Évaluation des capacités',
                Component: ({ resource }) => (
                  <GenericStep<TEducative>
                    resource={resource}
                    subTitle="Sexualité"
                    subForm="sexualityBehavior"
                    fields={SEXUALITY_BEHAVIOR_FORM}
                  />
                ),
                save: (data: any) => {
                  return submit<
                    TEducative['sexualityBehavior'],
                    { sexualityBehavior: TEducative['sexualityBehavior'] }
                  >(admissionRecordId, 'educative', 'sexualityBehavior', data)
                },
              },
              {
                title: 'Évaluation des capacités',
                Component: ({ resource }) => (
                  <GenericStep<TEducative>
                    subTitle="Conduites alimentaires"
                    resource={resource}
                    subForm="foodLine"
                    fields={FOOD_FORM}
                  />
                ),
                save: (data: any) => {
                  return submit<
                    TEducative['foodLine'],
                    { sexualityBehavior: TEducative['foodLine'] }
                  >(admissionRecordId, 'educative', 'foodLine', data)
                },
              },
              {
                title: 'Évaluation des capacités',
                Component: ({ resource }) => (
                  <GenericStep<TEducative>
                    resource={resource}
                    subForm="runaway"
                    subTitle="Fugues"
                    fields={RUNAWAY_FORM}
                  />
                ),
                save: (data: any) => {
                  return submit<TEducative['runaway'], { runaway: TEducative['runaway'] }>(
                    admissionRecordId,
                    'educative',
                    'runaway',
                    data,
                  )
                },
              },
              {
                title: 'Évaluation des capacités',
                Component: ({ resource }) => (
                  <GenericStep<TEducative>
                    resource={resource}
                    subForm="addiction"
                    subTitle="Conduites addictives"
                    fields={ADDICTION_FORM}
                  />
                ),
                save: (data: any) => {
                  return submit<TEducative['addiction'], { addiction: TEducative['addiction'] }>(
                    admissionRecordId,
                    'educative',
                    'addiction',
                    data,
                  )
                },
              },
            ]}
          />
        )
      case 'financial':
        return (
          <GenericForm<TFinancial>
            key="financial-form"
            title="Renseignements financiers"
            url={`/mon-etablissement/resident/${admissionRecordId}`}
            path="financial"
            admissionRecordId={admissionRecordId}
            resourceLoader={() => getProgress(admissionRecordId, 'financial')}
            globalStep={5}
            steps={() => [
              {
                title: 'Prestations sociales perçues',
                Component: ({ resource }) => (
                  <GenericStep<TFinancial>
                    resource={resource}
                    subForm="benefit"
                    fields={BENEFIT_FORM}
                  />
                ),
                save: (data: { benefit: Benefit }) => {
                  return submit<Benefit, { benefit: Benefit }>(
                    admissionRecordId,
                    'financial',
                    'benefit',
                    data,
                  )
                },
              },
              {
                title: 'Argent personnel',
                Component: ({ resource }) => (
                  <GenericStep<TFinancial>
                    resource={resource}
                    subForm="pocketMoney"
                    fields={POCKET_MONEY_FORM}
                  />
                ),
                save: (data: { pocketMoney: PocketMoney }) => {
                  return submit<PocketMoney, { pocketMoney: PocketMoney }>(
                    admissionRecordId,
                    'financial',
                    'pocketMoney',
                    data,
                  )
                },
              },
            ]}
          />
        )
      case 'document':
        return (
          <GenericForm<TDocument>
            key="document-form"
            title="Documents à joindre"
            url={`/mon-etablissement/resident/${admissionRecordId}`}
            path="document"
            admissionRecordId={admissionRecordId}
            resourceLoader={() => getProgress(admissionRecordId, 'document')}
            globalStep={6}
            steps={() => [
              {
                title: 'Liste des documents',
                Component: ({ resource }) => (
                  <FormDoc
                    resource={resource['owner']}
                    formStatus={resource.ownerStatus}
                    formPath="owner"
                    title="Documents relatifs aux données administratives"
                    form={OWNER_FORM}
                  />
                ),
                save: (data: any) => {
                  return submit<TDocument['owner'], { owner: TDocument['owner'] }>(
                    admissionRecordId,
                    'document',
                    'owner',
                    data,
                  )
                },
              },
              {
                title: 'Liste des documents',
                Component: ({ resource }) => (
                  <FormDoc
                    resource={resource['medical']}
                    formStatus={resource.medicalStatus}
                    formPath="medical"
                    title="Documents relatifs aux données médicales"
                    form={MEDICAL_FORM}
                  />
                ),
                save: (data: any) => {
                  return submit<TDocument['medical'], { medical: TDocument['medical'] }>(
                    admissionRecordId,
                    'document',
                    'medical',
                    data,
                  )
                },
              },
              {
                title: 'Liste des documents',
                Component: ({ resource }) => (
                  <FormDoc
                    resource={resource['educative']}
                    formStatus={resource.educativeStatus}
                    formPath="educative"
                    title="Documents relatifs aux données socio-éducatives"
                    form={EDUCATIVE_FORM}
                  />
                ),
                save: (data: any) => {
                  return submit<TDocument['educative'], { educative: TDocument['educative'] }>(
                    admissionRecordId,
                    'document',
                    'educative',
                    data,
                  )
                },
              },
              {
                title: 'Documents libres',
                Component: ({ resource, refreshResource }) => (
                  <FreeDocumentForm
                    resource={resource}
                    refreshResource={refreshResource}
                    admissionRecordId={admissionRecordId}
                  />
                ),
                save: (data: any) => {
                  return submit<TDocument['free'], { frees: TDocument['free'] }>(
                    admissionRecordId,
                    'document',
                    'free',
                    data,
                  )
                },
              },
            ]}
          />
        )
      case 'medical':
        return (
          <GenericForm<TMedical>
            key="medical-form"
            title="Renseignements médicaux"
            url={`/mon-etablissement/resident/${admissionRecordId}`}
            path="medical"
            admissionRecordId={admissionRecordId}
            resourceLoader={() => getProgress(admissionRecordId, 'medical')}
            globalStep={1}
            steps={() => [
              {
                title: 'Patient',
                Component: ({ resource }) => (
                  <GenericStep<TMedical>
                    resource={resource}
                    subForm="patient"
                    fields={PATIENT_FORM}
                    subTitle="Informations relatives au patient"
                  />
                ),
                save: (data: { patient: TMedical['patient'] }) => {
                  return submit<TMedical['patient'], { patient: TMedical['patient'] }>(
                    admissionRecordId,
                    'medical',
                    'patient',
                    data,
                  )
                },
              },
              {
                title: 'Informations ALD',
                Component: ({ resource }) => (
                  <GenericStep<TMedical>
                    resource={resource}
                    subForm="ald"
                    fields={ALD_FORM}
                    subTitle="Informations ALD"
                  />
                ),
                save: (data: { ald: TMedical['ald'] }) => {
                  return submit<TMedical['ald'], { ald: TMedical['ald'] }>(
                    admissionRecordId,
                    'medical',
                    'ald',
                    data,
                  )
                },
              },
              {
                title: 'Diagnostic principal',
                Component: ({ resource }) => (
                  <GenericStep<TMedical>
                    resource={resource}
                    subForm="diagnostic"
                    fields={DIAGNOSTIC_FORM}
                    subTitle="Informations relatives au diagnostic principal"
                  />
                ),
                save: (data: { diagnostic: TMedical['diagnostic'] }) => {
                  return submit<TMedical['diagnostic'], { diagnostic: TMedical['diagnostic'] }>(
                    admissionRecordId,
                    'medical',
                    'diagnostic',
                    data,
                  )
                },
              },
              {
                title: 'Origine du handicap',
                Component: ({ resource }) => (
                  <GenericStep<TMedical>
                    resource={resource}
                    subForm="handicap"
                    fields={HANDICAP_FORM}
                    subTitle="Informations relatives à l'origine de l'handicap"
                  />
                ),
                save: (data: { handicap: TMedical['handicap'] }) => {
                  return submit<TMedical['handicap'], { handicap: TMedical['handicap'] }>(
                    admissionRecordId,
                    'medical',
                    'handicap',
                    data,
                  )
                },
              },
              {
                title: 'Traitement actuel',
                Component: ({ resource }) => <TreatmentForm resource={resource} />,
                save: (data: { treatment: TMedical['treatment'] }) => {
                  return submit<TMedical['treatment'], { treatment: TMedical['treatment'] }>(
                    admissionRecordId,
                    'medical',
                    'treatment',
                    data,
                    true,
                  )
                },
              },
              {
                title: 'Déficiences',
                Component: ({ resource }) => (
                  <GenericStep<TMedical>
                    resource={resource}
                    subForm="deficiency"
                    fields={DEFICIENCY_FORM}
                    subTitle="Informations relatives aux déficiences"
                  />
                ),
                save: (data: { deficiency: TMedical['deficiency'] }) => {
                  return submit<TMedical['deficiency'], { deficiency: TMedical['deficiency'] }>(
                    admissionRecordId,
                    'medical',
                    'deficiency',
                    data,
                  )
                },
              },
              {
                title: 'Mode de vie',
                Component: ({ resource }) => (
                  <GenericStep<TMedical>
                    resource={resource}
                    subForm="lifeStyle"
                    fields={LIFE_STYLE_FORM}
                    subTitle="Informations relatives au mode de vie"
                  />
                ),
                save: (data: { lifeStyle: TMedical['lifeStyle'] }) => {
                  return submit<TMedical['lifeStyle'], { lifeStyle: TMedical['lifeStyle'] }>(
                    admissionRecordId,
                    'medical',
                    'lifeStyle',
                    data,
                  )
                },
              },
              {
                title: 'Vaccinations',
                Component: ({ resource }) => <VaccinationForm resource={resource} />,
                save: (data: { vaccination: TMedical['vaccination'] }) => {
                  return submit<TMedical['vaccination'], { vaccination: TMedical['vaccination'] }>(
                    admissionRecordId,
                    'medical',
                    'vaccination',
                    data,
                    true,
                  )
                },
              },
              {
                title: 'Antécédents',
                Component: ({ resource }) => (
                  <GenericStep<TMedical>
                    resource={resource}
                    subForm="history"
                    fields={HISTORY_FORM}
                    subTitle="Informations relatives aux antécédents"
                  />
                ),
                save: (data: { history: TMedical['history'] }) => {
                  return submit<TMedical['history'], { history: TMedical['history'] }>(
                    admissionRecordId,
                    'medical',
                    'history',
                    data,
                  )
                },
              },
              {
                title: 'Auteur des données',
                Component: ({ resource }) => (
                  <GenericStep<TMedical>
                    resource={resource}
                    subForm="author"
                    fields={AUTHOR_FORM}
                    subTitle="Informations relatives à l'auteur des données"
                  />
                ),
                save: (data: { author: TMedical['author'] }) => {
                  return submit<TMedical['author'], { author: TMedical['author'] }>(
                    admissionRecordId,
                    'medical',
                    'author',
                    data,
                  )
                },
              },
            ]}
          />
        )
      default:
        return (
          <Redirect
            from="*"
            to={`/mon-etablissement/resident/${admissionRecordId}/administrative/etape/1`}
          />
        )
    }
  }

  return (
    <FormLayout
      path={formName}
      resourceState={resourceState}
      progressBar={{
        title: 'Dossier',
        items: GetItemsAdmissionRecord(
          resourceState,
          history,
          pathname,
          'user_institution',
          `/mon-etablissement/resident/${admissionRecordId}`,
        ),
      }}
    >
      <>{renderContent()}</>
    </FormLayout>
  )
}
