import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Box, { BoxWithHeader } from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'

export type Step = {
  title: string
  Component: () => JSX.Element
  onSave?: (data: any) => Promise<any>
}

const Layout: React.FC<{
  title: string
  globalStep?: number
  steps: () => Step[]
}> = ({ title, globalStep, steps }) => {
  const { step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)
  const wrappedSteps = useMemo(() => steps(), [])

  const { title: subTitle, Component } = wrappedSteps[parsedStep - 1] || wrappedSteps[0] // In case of unknown step/removed go to beginning

  return (
    <BoxWithHeader f="2" br={4} style={{ margin: '0 15px', padding: '0' }}>
      <Box jc="sb">
        <Box ai="c">
          {globalStep && (
            <Box
              bg="black"
              br={4}
              width="24px"
              height="24px"
              jc="c"
              ai="c"
              style={{ marginRight: '16px' }}
            >
              <Text color="white" bold>
                {globalStep}
              </Text>
            </Box>
          )}
          <Box fd="c">
            <Title fs={24} bold>
              {title}
            </Title>
            {globalStep && <Text>{`${globalStep}.${step} ${subTitle}`}</Text>}
          </Box>
        </Box>
      </Box>

      <Box fd="c" style={{ overflowY: 'scroll' }}>
        <Component />
      </Box>
    </BoxWithHeader>
  )
}

export default Layout
