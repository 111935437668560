import React from 'react'

import Layout from './layout'
import { Form } from 'types/form'

const NOTIFICATION_FORM: Form = [
  [
    {
      name: 'admissionFollowup',
      type: 'toggle',
      label: 'Suivi d’admission',
      placeholder:
        'Recevez des alertes par e-mails lorsqu’un usager vous transmet un fichier manquant.',

      required: false,
    },
  ],
  [
    {
      name: 'admissionUpdate',
      type: 'toggle',
      label: 'Mise à jour d’un dossier',
      placeholder:
        'Recevez des alertes par e-mails lorsqu’un usager vous transmet un fichier manquant.',

      required: false,
    },
  ],
]

export default function Notification() {
  return (
    <Layout
      title="Notifications"
      subText=""
      help={{
        icon: 'chat',
        title: 'À quelle(s) adresse(s) e-mail(s) souhaitez vous recevoir les alertes ?',
        text: 'En transmettant votre e-mail vous acceptez de recevoir automatiquement ces alertes.',
      }}
      fields={NOTIFICATION_FORM}
      subForm="notification"
    />
  )
}
