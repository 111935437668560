import { Form } from 'types/form'

const ALD_FORM: Form = [
  [
    {
      name: 'isPresent',
      type: 'radio',
      label: 'ALD',
      options: [
        {
          label: 'Oui',
          value: 'true',
        },
        { label: 'Non', value: 'false' },
      ],
      required: true,
    },
    null,
  ],
  [
    {
      name: 'diagnostic',
      type: 'textarea',
      label: "Les diagnostics de l'ALD",
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'pharmacist',
      type: 'textarea',
      label: 'Spécialités pharmaceutiques ou classes thérapeutiques ou dispositifs médicaux',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'biologic',
      type: 'textarea',
      label: 'Suivi biologique prévu',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'specialist',
      type: 'textarea',
      label: 'Recours à des spécialistes (précision de la spécialité et des actes spécialisés)',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'paraMedical',
      type: 'textarea',
      label: 'Recours à des professionnels de santé para-médicaux',
      placeholder: 'Précisez',
      required: false,
    },
  ],
  [
    {
      name: 'firstExtension',
      type: 'date',
      label: 'Date du dernier renouvellement',
      placeholder: 'JJ/MM/AAAA',
      required: false,
    },
    {
      name: 'treatmentProtocol',
      type: 'file',
      label: 'Protocole de soins (cerfa 11626*03)',
      placeholder: '',
      required: false,
    },
  ],
]

export default ALD_FORM
