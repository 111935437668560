import React, { useState } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'

import Box from 'ui/box'
import Text from 'ui/typography/text'

const Container = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`

const InnerTooltip = styled(Box).attrs({
  f: '0',
  br: 4,
  bg: 'white',
  padding: '4px 8px',
  withBorder: true,
})<{
  visibility: boolean
}>`
  position: absolute;
  width: max-content;
  max-width: 220px;
  z-index: 10;
  border-color: ${props => transparentize(0.7, props.theme.black)};
  top: -5px;
  left: calc(100% + 5px);
  transition: opacity 3s ease;
  opacity: ${props => (props.visibility ? 1 : 0)};

  ${Text} {
    line-height: 150%;
  }
`

const Tooltip: React.FC<{ text: string }> = ({ text, children }) => {
  const [visibility, setVisibility] = useState(false)

  return (
    <Container onMouseEnter={() => setVisibility(true)} onMouseLeave={() => setVisibility(false)}>
      {children}
      {visibility && (
        <InnerTooltip visibility={visibility}>
          <Text fs={12}>{text}</Text>
        </InnerTooltip>
      )}
    </Container>
  )
}

export default Tooltip
