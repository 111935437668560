import React from 'react'
import { get } from 'lodash'
import { useToasts } from 'hooks/useToaster'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Button from 'ui/button'
import Icon from 'ui/icon'
import { ReactComponent as Success } from 'assets/illustrations/success.svg'

import { resendEmailConfirmation } from 'api/user'
import { Auth } from 'types/user'

interface Props {
  auth: Partial<Auth & { selectedId: string }>
  email?: string
}

const ResendEmail = ({ auth, email }: Props) => {
  const { addToast } = useToasts()

  return (
    <Box padding="15% 10% 12% 10%" fd="c" ai="c">
      <Success style={{ marginBottom: '8%' }} />
      <Title fs={28} bold spacingBottom="8px" align="center">
        {get(auth.user, 'firstName')} Votre compte est presque prêt !
      </Title>
      <Text opacity={0.6} spacingBottom="4%" align="center">
        Confirmez votre adresse e-mail pour accéder à votre espace.
      </Text>
      <Button
        onClick={() =>
          resendEmailConfirmation(email).then(() =>
            addToast({
              title: 'Succès !',
              type: 'success',
              message: `L'email vient d'être renvoyé !`,
            }),
          )
        }
      >
        Envoyer à nouveau
        <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
      </Button>
    </Box>
  )
}

export default ResendEmail
