import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'

import { Colors } from '../../theme'
import Icon, { IconProps } from '../../icon'

interface LinkProps {
  fs?: number
  color?: Colors
  bold?: boolean
  underline?: boolean
  spacingBottom?: string
  target?: string
}

export const StyledLink = styled.a<LinkProps>`
  font-family: Nunito;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  line-height: ${props => (props.fs ? props.fs + 10 : 26)}px;
  font-size: ${props => props.fs || 16}px;
  color: ${props => (props.color ? props.theme[props.color] : props.theme.black)};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  cursor: pointer;

  display: flex;
  align-items: center;

  margin-bottom: ${props => props.spacingBottom || 0};

  .linkIcon {
    padding-right: 9px;
  }

  &:hover {
    opacity: 0.8;
  }
`

export default function Link({
  children,
  icon,
  onClick,
  ...linksProps
}: LinkProps & {
  icon?: IconProps['name']
  children: React.ReactNode
  onClick?: (e: SyntheticEvent) => void
  style?: React.CSSProperties
  href?: string
}) {
  return (
    <StyledLink onClick={onClick} {...linksProps}>
      {icon && (
        <Icon
          className="linkIcon"
          name={icon}
          color={linksProps.color || 'black'}
          size={linksProps.fs ? linksProps.fs * 1.6 : 18}
        />
      )}
      {children}
    </StyledLink>
  )
}
