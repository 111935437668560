import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { map, get } from 'lodash'
import styled from 'styled-components'

import Box from 'ui/box'
import Button from 'ui/button'
import CardInfo from 'ui/card/info'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Input from 'ui/form/input'

import { FormBody } from 'components/pages/form'

import { Form } from 'types/form'
import Educative from 'types/educative'

const ESTABLISHMENT_FORM: Form = [
  [
    {
      name: 'name',
      type: 'text',
      label: "Nom de l'établissement",
      placeholder: "Saisissez le nom de l'établissement",
      required: true,
    },
  ],
  [
    {
      name: 'entrance',
      type: 'date',
      label: 'Entrée dans l’établissement',
      placeholder: "Saisissez la date d'entrée",
      required: true,
    },
    {
      name: 'exit',
      type: 'date',
      label: 'Départ de l’établissement',
      placeholder: 'Saisissez la date de  départ',
      required: false,
    },
  ],
  [
    {
      name: '_destroy',
      type: 'hidden',
      label: '',
      placeholder: '',
      required: false,
    },
  ],
]

const EstablishmentWrapper = styled(Box).attrs({ f: 'initial', withBorder: true, br: 4 })`
  border-color: ${props => props.theme.greyLightN2};
  padding: 24px;
  margin-bottom: 32px;
`

export default function SituationForm({ resource }: { resource: Educative }) {
  const defaultValues = resource['situation'] || {}
  const { register, setValue, getValues, triggerValidation } = useFormContext<
    Educative['situation']
  >()
  const [state, setState] = useState<{
    openCreateForm: boolean
    openEditForms: { [key: number]: boolean }
    listOfEstablishments: Educative['situation']['establishmentsAttributes']
  }>({
    openCreateForm: false,
    openEditForms: {},
    listOfEstablishments: get(defaultValues, 'establishmentsAttributes'),
  })

  useEffect(() => {
    setValue('situation', { ...defaultValues })
  }, [defaultValues])

  return (
    <>
      <Input id="id" name="situation.id" type="hidden" ref={register()} />
      <Title bold fs={22} spacingBottom="24px">
        Dites-nous en un peu plus sur la situation actuelle.
      </Title>

      <CardInfo
        name="Quels établissements l'usager a-t-il fréquenté ?"
        description="Nous aimerions connaître le nom de l'établissement ainsi que la durée du séjour dans ce dernier."
        style={{ marginBottom: '32px' }}
      />

      <Box f="initial" jc="sb" style={{ marginBottom: '32px' }}>
        <Text fs={16} bold>
          Établissements fréquentés
        </Text>
        <Button
          variant="outline"
          size="small"
          onClick={() => setState({ ...state, openCreateForm: true })}
        >
          Ajoutez un établissement
        </Button>
      </Box>

      {state.openCreateForm && (
        <EstablishmentWrapper>
          <Box fd="c">
            <FormBody<Educative>
              fields={ESTABLISHMENT_FORM}
              subForm="newEstablishment"
              resource={resource}
              buttons={{
                onCancel: () => setState({ ...state, openCreateForm: false }),
                onValidating: async () => {
                  if (
                    await triggerValidation(
                      ESTABLISHMENT_FORM.flat()
                        .filter(f => !!f)
                        .map(f => `newEstablishment.${f?.name}`),
                    )
                  ) {
                    setState({
                      ...state,
                      openCreateForm: false,
                      listOfEstablishments: [
                        ...state.listOfEstablishments,
                        get(getValues({ nest: true }), 'newEstablishment'),
                      ],
                    })
                    setValue('situation.establishmentsAttributes', [
                      ...state.listOfEstablishments,
                      get(getValues({ nest: true }), 'newEstablishment'),
                    ])

                    return
                  }
                },
              }}
            />
          </Box>
        </EstablishmentWrapper>
      )}

      {map(state.listOfEstablishments, (establishment, index: number) => {
        const editable = state.openEditForms[index] || false

        return (
          <EstablishmentWrapper
            key={`establishment-${establishment.id || index}`}
            style={{ display: establishment._destroy ? 'none' : 'flex' }}
          >
            <Box fd="c" style={{ display: editable ? 'flex' : 'none' }}>
              <FormBody<Educative>
                fields={ESTABLISHMENT_FORM}
                subForm={`situation.establishmentsAttributes[${index}]`}
                resource={resource}
                buttons={{
                  onCancel: () =>
                    setState({
                      ...state,
                      openEditForms: { ...state.openEditForms, [index]: false },
                    }),
                  onValidating: async () => {
                    if (
                      await triggerValidation(
                        ESTABLISHMENT_FORM.flat().map(
                          f => `situation.establishmentsAttributes[${index}].${f?.name}`,
                        ),
                      )
                    ) {
                      setState({
                        ...state,
                        openEditForms: { ...state.openEditForms, [index]: false },
                        listOfEstablishments: get(
                          getValues({ nest: true }),
                          'situation.establishmentsAttributes',
                        ),
                      })

                      return
                    }
                  },
                }}
              />
            </Box>
            <Box style={{ display: !editable ? 'flex' : 'none' }}>
              <Box fd="c">
                <Text fs={18} bold>
                  {establishment.name}
                </Text>
                <Text fs={16} color="greyLightN2">
                  Du {new Date(establishment.entrance).toLocaleDateString()}{' '}
                  {establishment.exit
                    ? `au ${new Date(establishment.exit).toLocaleDateString()}`
                    : "à aujourd'hui"}
                </Text>
              </Box>
              <Box f="initial" ai="c">
                <Button
                  variant="danger"
                  size="small"
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    setValue(`situation.establishmentsAttributes[${index}]._destroy`, true)
                    setState({
                      ...state,
                      listOfEstablishments: get(
                        getValues({ nest: true }),
                        'situation.establishmentsAttributes',
                      ),
                    })
                  }}
                >
                  Supprimer
                </Button>
                <Button
                  variant="outline"
                  size="small"
                  onClick={() =>
                    setState({
                      ...state,
                      openEditForms: { ...state.openEditForms, [index]: true },
                    })
                  }
                >
                  Éditer
                </Button>
              </Box>
            </Box>
          </EstablishmentWrapper>
        )
      })}
    </>
  )
}
