import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import Icon, { IconProps } from 'ui/icon'
import { Colors } from 'ui/theme'

import { ProgressVariant } from 'types/enums'

interface Props {
  items: {
    label: string
    variant: ProgressVariant
  }[]
  lockedFrom?: number
  style?: React.CSSProperties
}

const ProgressBarCircle = styled(Box).attrs({ ai: 'c', jc: 'c', height: '40px', width: '40px' })`
  border-radius: 120px;
`

const CircleContent = styled(Box)`
  border-radius: 120px;
`

const Item = styled(Box).attrs({ fd: 'c' })`
  ${Text} {
    margin-top: 16px;
    max-width: 100px;
  }

  &:first-child {
    .bar {
      border-radius: 120px 0 0 120px;
    }
  }

  &:last-child {
    flex: 0;
    align-self: start;
    width: 40px;
    direction: rtl;

    .bar {
      width: 40px;
      border-radius: 0 120px 120px 0;
    }

    ${Text} {
      width: 100px;
      flex-flow: row nowrap;
      direction: rtl;
      margin-top: 16px;
      text-align: right;
    }
  }
`

export const variants = {
  todo: {
    circleColor: 'transparent',
  },
  disabled: {
    circleColor: 'transparent',
  },
  pending: {
    circleColor: 'black',
    textColor: 'black',
  },
  validating: {
    icon: 'check',
    circleColor: 'green',
  },
  waitChange: {
    icon: 'loading',
    iconColor: 'white',
    circleColor: 'pink',
    textColor: 'black',
  },
  supportValidating: {
    icon: 'loading',
    iconColor: 'black',
    circleColor: 'yellow',
    textColor: 'black',
  },
  completed: {
    icon: 'check',
    circleColor: 'green',
  },
} as {
  [index in ProgressVariant]: {
    icon?: IconProps['name']
    circleColor?: Colors | 'transparent'
    iconColor?: Colors
    textColor?: Colors
  }
}

const ProgressBarHorizontal: React.FC<Props> = ({ items, lockedFrom, style }) => {
  return (
    <Box ai="s" jc="sa" style={style}>
      {map(items, (item, index) => {
        const { variant, label } = item
        const isLocked = lockedFrom || lockedFrom === 0 ? index >= lockedFrom : false
        const isFirstItemLocked = index === lockedFrom
        const variantStyle =
          isLocked && variant !== 'supportValidating' && variant !== 'waitChange'
            ? ({ circleColor: 'transparent', textColor: 'black' } as typeof variants['todo'])
            : variants[variant]

        return (
          <Item key={`PBHItem-${index}`}>
            <Box className="bar" height="40px" bg={isLocked ? 'greyLightN3' : 'greyLightN'}>
              <Box
                bg={isFirstItemLocked ? 'greyLightN' : 'transparent'}
                width="40px"
                style={{ borderRadius: '0 120px 120px 0' }}
              >
                <ProgressBarCircle
                  bg={
                    isFirstItemLocked && variant !== 'supportValidating' && variant !== 'waitChange'
                      ? 'greyLightN2'
                      : variantStyle.circleColor
                  }
                >
                  {isFirstItemLocked || variantStyle.icon ? (
                    <Icon
                      name={
                        isFirstItemLocked &&
                        variant !== 'supportValidating' &&
                        variant !== 'waitChange'
                          ? 'lock'
                          : (variantStyle.icon as IconProps['name'])
                      }
                      color={variantStyle.iconColor || 'white'}
                      size={16}
                    />
                  ) : (
                    <CircleContent
                      height="8px"
                      width="8px"
                      bg={variant === 'todo' || isLocked ? 'black' : 'white'}
                    />
                  )}
                </ProgressBarCircle>
              </Box>
            </Box>

            <Text color={variantStyle.textColor || 'green'} fs={12}>
              {label}
            </Text>
          </Item>
        )
      })}
    </Box>
  )
}

export default ProgressBarHorizontal
