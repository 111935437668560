import { Form } from 'types/form'

const MEDICAL_TEAM_FORM: Form = [
  [
    {
      name: 'doctorEmail',
      type: 'text',
      label: 'E-mail du médecin traitant',
      placeholder: "Saisissez l'e-mail",
      required: true,
    },
    {
      name: 'establishmentEmail',
      type: 'text',
      label: "E-mail du service médical de l'établissement actuel ou de l'assitante sociale",
      placeholder: "Saisissez l'e-mail",
      required: true,
    },
  ],
]

export default MEDICAL_TEAM_FORM
