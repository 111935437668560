import React, { useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { get } from 'lodash'

import Loader from 'ui/loader'
import { BoxWithHeader } from 'ui/box'
import { GenericForm } from '../../form'
import Phone from './phone'
import Confirm from './confirm'
import Validate from './validate'

import { getProgress } from 'api/form'
import TMedical from 'types/medical'
import { AuthContext } from 'App'
import useResource from 'hooks/useResource'

export default function ValidateMedicalInfo() {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const { step } = useParams<{ step: string }>()
  const path = 'medical-validation'
  const [{ resource, loading }] = useResource<TMedical, {}>(
    undefined,
    () => getProgress(get(auth, 'selectedId', '0'), 'medical'),
    [step],
  )

  useEffect(() => {
    if (!resource) return

    switch (step) {
      case '1':
        resource.signature && history.push(`/${path}/etape/3`)

        return
      case '2':
        resource.signature && history.push(`/${path}/etape/3`)
        !resource.phoneDoctor && history.push(`/${path}/etape/1`)

        return
      case '3':
        !resource.signature && history.push(`/${path}/etape/1`)

        return
      default:
        history.push(`/${path}/etape/1`)

        return
    }
  }, [resource])

  return loading ? (
    <BoxWithHeader f="2" ai="c" jc="c" br={4}>
      <Loader />
    </BoxWithHeader>
  ) : (
    <GenericForm<{}>
      key="medical-validation-form"
      title="Validation"
      path={path}
      resourceLoader={() => Promise.resolve({})}
      globalStep={2}
      steps={() => [
        {
          title: 'Validez les renseignements médicaux',
          Component: ({ onNext }) => <Phone onNext={onNext} />,
        },
        {
          title: 'Validez les renseignements médicaux',
          Component: ({ onNext }) => (
            <Confirm onNext={onNext} phone={get(resource, 'phoneDoctor', '')} />
          ),
        },
        {
          title: 'Renseignements médicaux complétés',
          Component: () => (
            <Validate
              patientName={`${get(resource, 'patient.firstName')} ${get(
                resource,
                'patient.lastName',
              )}`}
            />
          ),
        },
      ]}
    />
  )
}
