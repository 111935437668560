import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'

import Box from 'ui/box'
import Loader from 'ui/loader'
import Intro from 'components/pages/intro'

import Layout from '../layout'
import StepCCAS from './stepCCAS'
import StepCPAM from './stepCPAM'

import { AuthContext } from 'App'
import useResource from 'hooks/useResource'
import { getAdmission } from 'api/admission'
import { ESTABLISHMENT_TYPES } from 'types/enums'
import Conclusion from '../conclusion'

export default function FinancialStep() {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const [resourceState] = useResource<ESTABLISHMENT_TYPES, {}>(
    undefined,
    () =>
      getAdmission(get(auth, 'selectedId', '0'), { current: true }).then(admission =>
        get(admission.candidacies, '[0].institutionType', []).join(', '),
      ),
    [],
  )

  if (resourceState.loading) {
    return (
      <Box f="2" bg="white" ai="c" jc="c" style={{ margin: '0 15px' }} withBorder>
        <Loader />
      </Box>
    )
  }

  return (
    <Layout
      title="Effectuer la demande de financement"
      globalStep={5}
      steps={() => {
        const establishmentType = resourceState.resource
        const commonSteps = {
          intro: {
            title: 'Introduction',
            Component: () => (
              <Box padding="32px">
                <Intro
                  onNext={() => history.push('/admission/financement/etape/2')}
                  sections={[
                    {
                      title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                      text:
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas nemo natus debitis harum expedita repellendus accusamus dolorum. Recusandae, sequi numquam. Illum minima provident accusantium blanditiis voluptas numquam nam ipsum nihil!',
                    },
                    {
                      title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                      text:
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quas nemo natus debitis harum expedita repellendus accusamus dolorum. Recusandae, sequi numquam. Illum minima provident accusantium blanditiis voluptas numquam nam ipsum nihil!',
                    },
                  ]}
                />
              </Box>
            ),
          },
          conclusion: {
            title: 'Complet',
            Component: () => (
              <Conclusion
                title="Vous avez renseigné votre financement"
                text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet unde earum ratione, sapiente pariatur, totam quam neque velit."
                buttonTitle="Aller à l'étape Trousseau & Accueil"
                onNext={() => history.push('/admission/trousseau/etape/1')}
              />
            ),
          },
        }

        const steps =
          establishmentType === 'fam'
            ? [
                {
                  title: 'Effectuer la demande de financement CCAS',
                  Component: () => <StepCCAS />,
                },
                {
                  title: 'Effectuer la demande de financement CPAM',
                  Component: () => <StepCPAM />,
                },
              ]
            : establishmentType === 'mas'
            ? [
                {
                  title: 'Effectuer la demande de financement CPAM',
                  Component: () => <StepCPAM />,
                },
              ]
            : [
                {
                  title: 'Effectuer la demande de financement CCAS',
                  Component: () => <StepCCAS />,
                },
              ]

        return [commonSteps.intro, ...steps, commonSteps.conclusion]
      }}
    />
  )
}
