import React, { ChangeEvent, useMemo } from 'react'
import styled from 'styled-components'

import Box from 'ui/box'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Switch = styled.div<{ checked?: boolean }>`
  position: relative;
  width: 58px;
  height: 30px;
  background: ${({ theme }) => theme.greyLightN2};
  border-radius: 32px;
  padding: 2px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 35px;
    top: 50%;
    left: 2px;
    background: white;
    transform: translate(0, -50%);
  }
`

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: ${props => props.theme.green};

    &:before {
      transform: translate(28px, -50%);
    }
  }
`

const IconContainer = styled.div<{ checked?: boolean }>`
  position: relative;
  top: -25px;
  left: ${({ checked }) => (checked ? '14px' : '7px')};
  width: 20px;
  transform: ${({ checked }) => (checked ? 'translate(100%, 0%)' : 'translate(0%, 0%)')};
`

interface ToogleType {
  placeholder?: string
  id: string
  name: string
  forwardRef?: (instance: HTMLInputElement | null) => void
  value?: string | boolean
  onChange?: any
}

const Toggle = ({ placeholder, id, name, value, forwardRef, onChange }: ToogleType) => {
  const checked = useMemo(() => (value === 'false' ? false : true), [value])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked)
  }

  return (
    <Label>
      <Box width="100%" ai="c" jc="sb">
        <Text color="grey" style={{ width: '50%' }}>
          {placeholder}
        </Text>
        <div>
          <Input
            checked={checked}
            type="checkbox"
            onChange={handleChange}
            id={id}
            name={name}
            ref={forwardRef}
          />
          <Switch checked={checked} />

          <IconContainer checked={checked}>
            {checked ? (
              <Icon name={'check'} size={18} color="green" />
            ) : (
              <Icon name={'close'} size={18} color="greyLightN2" />
            )}
          </IconContainer>
        </div>
      </Box>
    </Label>
  )
}

export default Toggle
