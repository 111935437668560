import React, { useEffect, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { get } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Group from 'ui/form/group'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'

import StepLayout from './stepLayout'
import { State } from '..'
import InputPhone from 'ui/form/inputPhone'
import Checkbox from 'ui/form/checkbox'
import Link from 'ui/typography/link'
import { InstitutionInfoFromWebflow } from 'types/institution'
import { AuthContext } from 'App'

const StepUserAndContactInfos: React.FC<{
  onNext: (data: State['data']) => void
  defaultValues?: State['data']
  dataFromWebFlow: InstitutionInfoFromWebflow | undefined
}> = ({ onNext, defaultValues, dataFromWebFlow }) => {
  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValues,
  })

  const { addEstablishment, updateAddEstablishment } = useContext(AuthContext)

  useEffect(() => {
    if (dataFromWebFlow) {
      setValue('contact.phoneNumber', dataFromWebFlow.contactPhoneNumber)
      setValue('contact.email', dataFromWebFlow.contactEmail)
    }
  }, [dataFromWebFlow])

  return (
    <StepLayout
      title="Contact de l’établissement"
      subText="Ces informations nous permettrons de vous contacter pour optimiser votre fiche établissement"
      spacingBottom="32px"
    >
      <Box as="form" onSubmit={handleSubmit(onNext)} autoComplete="off" fd="c">
        <Box ai="s" f="0 1 auto">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="firstName" required style={{ paddingBottom: '16px' }}>
              Prénom
            </Label>
            <Input
              id="firstName"
              name="contact.firstName"
              placeholder="Saisissez le prénom"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.contact?.firstName ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="lastName" required style={{ paddingBottom: '16px' }}>
              Nom
            </Label>
            <Input
              id="lastName"
              name="contact.lastName"
              placeholder="Saisissez le nom"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.contact?.lastName ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <Box ai="s" f="0 1 auto" padding="0 0 32px 0">
          <Group style={{ paddingBottom: '2%' }}>
            <Label htmlFor="contactEmail" required style={{ paddingBottom: '16px' }}>
              Adresse e-mail
            </Label>
            <Input
              id="contactEmail"
              name="contact.email"
              type="email"
              placeholder="Saisissez l'e-mail"
              ref={register({ required: true })}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.contact?.email ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
          <Group style={{ padding: '0 0 2% 5%' }}>
            <Label htmlFor="phoneNumber" required style={{ paddingBottom: '16px' }}>
              Numéro de téléphone
            </Label>
            <Controller
              as={InputPhone}
              id="phoneNumber"
              name="contact.phoneNumber"
              control={control}
              placeholder="Saisissez le numéro de téléphone"
              rules={{ required: true }}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: errors.contact?.phoneNumber ? 'visible' : 'hidden' }}
            >
              Ce champ est requis
            </Text>
          </Group>
        </Box>
        <>
          {!addEstablishment && (
            <>
              <Box fd="c" f="0 1 auto">
                <Title fs={28} bold spacingBottom="8px">
                  Informations de connexion
                </Title>
                <Text opacity={0.6} spacingBottom="32px">
                  Ce sera votre moyen de connexion à la plateforme où que vous soyez !
                </Text>
              </Box>
              <Box ai="s" f="0 1 auto">
                <Group style={{ paddingBottom: '2%' }}>
                  <Label htmlFor="email" required style={{ paddingBottom: '16px' }}>
                    E-mail
                  </Label>
                  <Input
                    id="email"
                    name="user.email"
                    type="email"
                    placeholder="Saisissez l’adresse e-mail"
                    ref={register({ required: true })}
                  />
                  <Text
                    fs={16}
                    color="pink"
                    style={{ visibility: errors.user?.email ? 'visible' : 'hidden' }}
                  >
                    Ce champ est requis
                  </Text>
                </Group>
                <Group style={{ padding: '0 0 2% 5%' }}>
                  <Label htmlFor="password" required style={{ paddingBottom: '16px' }}>
                    Mot de passe
                  </Label>
                  <Input
                    id="password"
                    name="user.password"
                    type="password"
                    placeholder="Saisissez un mot de passe"
                    ref={register({
                      required: 'Ce champ est requis',
                      minLength: {
                        value: 8,
                        message: 'Le mot de passe doit contenir au moins 8 caractères',
                      },
                    })}
                  />
                  <Text
                    fs={16}
                    color="pink"
                    style={{ visibility: errors.user?.password ? 'visible' : 'hidden' }}
                  >
                    {get(errors, 'user.password.message')}
                  </Text>
                </Group>
              </Box>
              <Box ai="c" jc="fs">
                <Checkbox
                  id="cgu"
                  name="cgu"
                  value="true"
                  containerStyle={{ marginBottom: '18px' }}
                  forwardRef={register({ required: 'Ce champ est requis' })}
                >
                  <div>
                    Nous acceptons les{' '}
                    <Link
                      color="green"
                      underline
                      href="https://sahanest.fr/cgu"
                      target="_blank"
                      style={{ display: 'inline' }}
                    >
                      conditions générales d’utilisation
                    </Link>{' '}
                    et déclare avoir pris connaissance de la{' '}
                    <Link
                      color="green"
                      underline
                      href="https://sahanest.fr/politique-donnees"
                      target="_blank"
                      style={{ display: 'inline' }}
                    >
                      politique de protection des données
                    </Link>
                  </div>
                </Checkbox>
              </Box>
            </>
          )}
        </>
        <Box jc="sb" f="0 1 auto">
          {addEstablishment && (
            <Box jc="fs">
              <Button onClick={() => updateAddEstablishment(false)}>
                <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
                Etape précédente
              </Button>
            </Box>
          )}
          <Box jc="fe">
            <Button type="submit" disabled={!addEstablishment && !watch('cgu')}>
              Étape suivante
              <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
            </Button>
          </Box>
        </Box>
      </Box>
    </StepLayout>
  )
}

export default StepUserAndContactInfos
