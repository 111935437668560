import React, { useEffect, useMemo, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Box, { BoxWithHeader } from 'ui/box'
import Button from 'ui/button'
import Table from 'ui/table'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'

import { usePageName } from 'hooks/usePageName'
import useResource from 'hooks/useResource'
import { getCandidacies } from 'api/candidacy'
import { Candidacies } from 'types/candidacy'
import { AuthContext } from 'App'

export default function Admission() {
  const history = useHistory()
  const { setPageName } = usePageName()
  const { auth } = useContext(AuthContext)

  const institutionId = useMemo(() => auth.selectedInstitutionId, [auth.selectedInstitutionId])

  const [{ resource, loading }] = useResource<Candidacies, {}>(
    undefined,
    () => getCandidacies('admission', institutionId),
    [institutionId],
  )
  const columns = useMemo(
    () => [
      {
        Header: 'résidents',
        accessor: 'patientName',
      },
      {
        Header: 'Handicap',
        accessor: 'handicap',
      },
      {
        Header: 'MODE ACCUEIL',
        accessor: 'mode',
      },
      {
        Header: '',
        accessor: 'id',
        width: 70,
        Cell: ({ value }: { value: string }) => (
          <Button
            variant="outline"
            size="small"
            onClick={() => history.push(`/admission/${value}`)}
          >
            Voir le suivi
          </Button>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  useEffect(() => {
    setPageName('Suivi de l’admission')
  }, [])

  return (
    <Box fd="c">
      <BoxWithHeader br={4} f="0 1 auto" style={{ display: 'block' }}>
        <Box>
          <Box ai="b">
            <Title fs={24} bold>
              Liste des usagers
            </Title>

            {resource && (
              <Text opacity={0.5} style={{ marginLeft: '8px' }}>
                ({resource.candidacies.length})
              </Text>
            )}
          </Box>
        </Box>

        {loading ? (
          <Box padding="32px 32px 0 32px" ai="c" jc="c">
            <Loader />
          </Box>
        ) : (
          <Box fd="c" padding="32px 32px 0 32px">
            <Table
              columns={columns}
              data={resource ? resource.candidacies : []}
              noDataText="Aucun dossier en cours de suivi"
              noDataInfo="Ils apparaîtront ici lorsque la candidature aura été accepté."
            />
          </Box>
        )}
      </BoxWithHeader>
    </Box>
  )
}
