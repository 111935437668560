import React from 'react'
import { get } from 'lodash'

import { GenericForm, GenericStep } from '../../form'
import Intro from 'components/pages/intro'
import { Auth } from 'types/user'
import TSocial, { Coverage, OtherCoverage } from 'types/social'
import { getProgress, submit } from 'api/form'
import COVERAGE_FORM from './forms/socialCoverage'
import COVERAGE_FORM2 from './forms/socialCoverageOther'
import Conclusion from '../conclusion'

export default function Social({ refreshProgress }: { refreshProgress: () => void }) {
  return (
    <GenericForm<TSocial>
      key="social-form"
      title="Renseignements sociaux"
      path="social"
      resourceLoader={(auth: Partial<Auth>) => getProgress(get(auth, 'selectedId', '0'), 'social')}
      globalStep={2}
      afterSave={refreshProgress}
      steps={(auth: Partial<Auth>) => [
        {
          title: 'Introduction',
          Component: ({ onNext }) => (
            <Intro
              onNext={onNext}
              sections={[
                {
                  title: 'Couverture sociale',
                  text:
                    "Vous allez renseigner des informations comme le nom de la caisse, le prénom et le nom de l'assuré, le numéro de matricule...",
                },
                {
                  title: 'Autres informations relatives à la couverture sociale',
                  text:
                    "Vous allez envoyer des pièces jointes comme la copie de la notification MDPH, la copie de la notification de l'aide sociale...",
                },
              ]}
            />
          ),
        },
        {
          title: 'Couverture sociale',
          Component: ({ resource }) => (
            <GenericStep<TSocial>
              resource={resource}
              subForm="coverage"
              fields={COVERAGE_FORM}
              subTitle="Informations relatives à la couverture sociale"
            />
          ),
          save: (data: any) => {
            return submit<Coverage, { coverage: Coverage }>(
              get(auth, 'selectedId', '0'),
              'social',
              'coverage',
              data,
            )
          },
        },
        {
          title: 'Autre couverture',
          Component: ({ resource }) => (
            <GenericStep<TSocial>
              resource={resource}
              subForm="otherCoverage"
              fields={COVERAGE_FORM2}
              subTitle="Informations relatives à la couverture sociale"
            />
          ),
          save: (data: any) => {
            return submit<OtherCoverage, { otherCoverage: OtherCoverage }>(
              get(auth, 'selectedId', '0'),
              'social',
              'other_coverage',
              data,
            )
          },
        },
        {
          title: 'Complet',
          Component: ({ resource }) => (
            <Conclusion
              title="renseignements sociaux"
              text="Vous pouvez poursuivre le formulaire d'admission dès maintenant en complétant la
              section renseignements éducatifs !"
              nextStepCta="Complétez les renseignements éducatifs"
              nextStep="/educative/etape/1"
              firstStep="/social/etape/2"
              isCompleted={['validating', 'completed'].includes(resource.statusProgress)}
            />
          ),
        },
      ]}
    />
  )
}
