import { Form } from 'types/form'
import { DOCTOR_KIND } from 'types/medical'

const AUTHOR_FORM: Form = [
  [
    {
      name: 'doctor',
      type: 'text',
      label: 'Docteur',
      placeholder: 'Saisissez le nom du docteur',
      required: true,
    },
    {
      name: 'kind',
      type: 'select',
      label: 'Qualité de',
      placeholder: 'Choisissez le type de docteur',
      options: [
        { label: 'Médecin traitant', value: DOCTOR_KIND.REGULAR },
        { label: 'Médecin coordonateur', value: DOCTOR_KIND.COORDINATOR },
      ],
      required: true,
    },
  ],
  [
    {
      name: 'signedOn',
      type: 'date',
      label: 'Date',
      placeholder: 'JJ/MM/AAAA',
      required: true,
    },
    null,
  ],
  [
    {
      name: 'certifyDoctorTrueInfos',
      type: 'checkbox',
      options: [
        {
          label:
            'Je certifie que les informations fournies sur le patient sont bien complètes et exactes',
          value: 'true',
        },
      ],
      required: true,
    },
  ],
]
export default AUTHOR_FORM
