import React from 'react'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import Icon from 'ui/icon'
import CodeSnippet from 'ui/codeSnippet'

import iphone from 'assets/images/iphone.png'

interface Props {
  establishmentId?: string
}

const CodeSnippetBlock = ({ establishmentId }: Props) => {
  return (
    <Box bg="yellow" fd="c" padding="34px" style={{ marginTop: '24px' }}>
      <Icon name="embedLink" size={20} />
      <Text bold style={{ margin: '20px 0' }} fs={20}>
        Facilitez les admissions depuis votre site en intégrant Sahanest
      </Text>
      <Text style={{ margin: '0 0 24px 0' }}>
        Vous pouvez intégrer directement ce code à votre site internet.
      </Text>
      <Text fs={14}>Preview:</Text>
      <Box bg="white" jc="c" br={4} padding="0 0 8px 0" style={{ margin: '0 0 16px 0' }}>
        <img src={iphone} style={{ width: '150px' }} />
      </Box>
      <Text fs={14}>Code:</Text>
      <CodeSnippet establishmentId={establishmentId} />
    </Box>
  )
}

export default CodeSnippetBlock
