import React from 'react'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import EstablishmentCard from 'ui/card/establishment'
import StepLayout from './stepLayout'
import { InstitutionInfoFromWebflow } from 'types/institution'
import { formatedAddress } from 'components/pages/helper'
import { xor } from 'lodash'

interface Props {
  selectedEstablishment: InstitutionInfoFromWebflow
  suggestedEstablishments: InstitutionInfoFromWebflow[]
  onChange: (value: string[]) => void
  otherSuggestedEstablishmentId: string[]
  onPrevious: () => void
  onNext: () => void
}

const StepSuggestedEstablishment = ({
  selectedEstablishment,
  suggestedEstablishments,
  onChange,
  otherSuggestedEstablishmentId,
  onPrevious,
  onNext,
}: Props) => {
  return (
    <>
      <StepLayout
        title="Établissement sélectionné"
        subText="Nous avons besoin de ces informations pour vous proposez des lieux adaptés."
      >
        <EstablishmentCard
          institutionType={selectedEstablishment?.infoInstitutionType}
          address={formatedAddress(
            selectedEstablishment?.infoStreet,
            selectedEstablishment?.infoZipcode,
            selectedEstablishment?.infoCountry,
            selectedEstablishment?.infoCity,
          )}
          institutionName={selectedEstablishment?.infoInstitutionName}
          webSite={selectedEstablishment?.infoWebsite}
          phoneNumber={selectedEstablishment?.infoPhoneNumber}
          siretNumber={selectedEstablishment?.infoSiretNumber}
          onClick={() => null}
          isSelected={true}
        />
      </StepLayout>
      <StepLayout
        title="Établissements similaires"
        subText="Nous avons trouvés des établissements similaires à celui que vous avez sélectionné"
      >
        {suggestedEstablishments.map((institution, index) => {
          return (
            <EstablishmentCard
              key={index}
              institutionType={institution.infoInstitutionType}
              address={formatedAddress(
                institution.infoStreet,
                institution.infoZipcode,
                institution.infoCountry,
                institution.infoCity,
              )}
              institutionName={institution.infoInstitutionName}
              webSite={institution.infoWebsite}
              phoneNumber={institution.infoPhoneNumber}
              siretNumber={institution.infoSiretNumber}
              onClick={() =>
                onChange(xor(otherSuggestedEstablishmentId, [institution.webflowItemId]))
              }
              isSelected={otherSuggestedEstablishmentId?.includes(institution.webflowItemId)}
            />
          )
        })}

        <Box jc="sb" f="0 1 auto" style={{ marginTop: '20px' }}>
          <Button variant="outline" style={{ backgroundColor: 'white' }} onClick={onPrevious}>
            <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
            Étape précédente
          </Button>
          <Button onClick={onNext}>
            Étape suivante
            <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
          </Button>
        </Box>
      </StepLayout>
    </>
  )
}

export default StepSuggestedEstablishment
