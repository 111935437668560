import styled from 'styled-components'
import { Colors } from 'ui/theme'
import { get } from 'lodash'

export default styled.div<{
  color?: string | Colors
  opacity?: number
  spacingTop?: number
  spacingBottom?: number
}>`
  height: 1px;
  width: 100%;
  background-color: ${props =>
    props.color ? get(props.theme, props.color, props.color) : props.theme.greyLightN2};
  margin-top: ${props => `${props.spacingTop}px` || 0};
  margin-bottom: ${props => `${props.spacingBottom}px` || 0};
  opacity: ${props => props.opacity || 1};
`
