import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { map } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'

import Box, { BoxWithHeader } from 'ui/box'
import Button, { BackButton, LinkButton } from 'ui/button'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Icon, { IconNames } from 'ui/icon'

import { usePageName } from 'hooks/usePageName'
import { AuthContext } from 'App'
import { getInstitutionWebflowInfos } from 'api/institution'
import InclusiveInfos from './blocks/inclusiveInfos'
import InclusiveCaracteristics from './blocks/inclusiveCaracteristics'
import InclusiveSocialProject from './blocks/inclusiveSocialProject'
import InclusiveAnimation from './blocks/inclusiveAnimation'
import InclusiveCandidate from './blocks/inclusiveCandidateProcess'
import { Institution } from 'types/user'

const HeadBlock = styled(Box).attrs({ br: 4, fd: 'c' })`
  background-color: ${props => props.theme.greyLightN};
  margin: 0 15px 32px 15px;
  padding: 24px;
  flex: 0 1 346px;
  flex-shrink: 0;
  cursor: pointer;
`

const listOfItems = [
  {
    title: 'Informations du lieu de vie',
    text: 'Éditez les informationsconcernant votre lieu de vie',
    icon: 'building',
    path: 'informations',
  },
  {
    title: 'Caractéristiques',
    text: 'Renseignez les caractéristiques du lieu de vie, ses infrastructures environnantes...',
    icon: 'specifications',
    path: 'specifications',
  },
  {
    title: 'Projet de vie sociale',
    text: 'Détaillez le projet de vie et les objectifs de votre lieu de vie',
    icon: 'target',
    path: 'projet',
  },
  {
    title: 'Animations et organisation',
    text: 'Renseignez les animations et l’organisation de votre lieu de vie',
    icon: 'animation',
    path: 'animations',
  },
  {
    title: 'Parcours de candidature',
    text: 'Expliquez le parcours de candidature afin d’intégrer votre lieu de vie',
    icon: 'subscription',
    path: 'candidature',
  },
] as {
  title: string
  text: string
  icon: keyof typeof IconNames
  path: string
  completed: boolean
}[]

export default function InclusiveInstitutionEdit() {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const { subStep } = useParams<{ subStep: string }>()
  const { setPageName } = usePageName()
  const [institutionSlug, setInstitutionSlug] = useState<string>('')

  const selectedInstitution = useMemo(
    () => auth?.institution?.filter(({ id }) => id === auth.selectedInstitutionId)[0],
    [auth],
  )

  const webflowId = useMemo(() => selectedInstitution?.webflowItemId, [selectedInstitution])

  useEffect(() => {
    const getItem = async () => {
      const response = await getInstitutionWebflowInfos(webflowId)
      setInstitutionSlug(response?.webflowSlug)
    }

    setPageName('Mon Lieu')

    webflowId && getItem()
  }, [])

  let block

  const checkIfCompleted = (path: string, institution?: Institution) => {
    switch (path) {
      case 'animations':
        return !!institution?.inclusiveOrganization
      case 'informations':
        return !!institution?.info?.projectHolder //the only non mandatory field in signin that is mandatory after
      case 'specifications':
        return !!institution?.inclusiveCaracteristic
      case 'projet':
        return !!institution?.inclusiveSocialProject
      case 'candidature':
        return !!institution?.inclusiveCandidateProcess
      default:
        return false
    }
  }

  const listOfItemsWithStatus = useMemo(
    () =>
      listOfItems.map(item => {
        return { ...item, completed: checkIfCompleted(item.path, selectedInstitution) }
      }),
    [listOfItems, selectedInstitution],
  )

  const isEverythingComplete = useMemo(() => !listOfItemsWithStatus.find(item => !item.completed), [
    listOfItemsWithStatus,
  ])

  switch (subStep) {
    case 'informations':
      block = <InclusiveInfos />
      break
    case 'specifications':
      block = <InclusiveCaracteristics />
      break
    case 'projet':
      block = <InclusiveSocialProject />
      break
    case 'animations':
      block = <InclusiveAnimation />
      break
    case 'candidature':
      block = <InclusiveCandidate />
      break
  }

  return block ? (
    block
  ) : (
    <Box fd="c">
      <BoxWithHeader br={4} f="0 1 auto" style={{ display: 'block' }}>
        <Box fd="r" jc="sb">
          <Box>
            <Title fs={24} bold>
              Édition de la fiche établissement
            </Title>
          </Box>
          <Box style={{ flex: 0 }}>
            {selectedInstitution?.webflowState === 'published' ? (
              <>
                <LinkButton
                  className="outline"
                  href={
                    institutionSlug
                      ? `https://www.sahanest.fr/annuaire/${institutionSlug}`
                      : `https://www.sahanest.fr/annuaire/`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ whiteSpace: 'nowrap', marginRight: '32px' }}
                >
                  <Icon
                    name="eye"
                    size={18}
                    containerStyle={{ marginRight: '8px' }}
                    color="black"
                  />
                  Voir la fiche établissement
                </LinkButton>
                <Button variant="outline" style={{ borderColor: 'green', color: 'green' }}>
                  <Icon
                    name="checkGreen"
                    size={18}
                    containerStyle={{ marginRight: '8px' }}
                    color="green"
                  />
                  Publiée
                </Button>
              </>
            ) : (
              <Button variant={isEverythingComplete ? 'primary' : 'disabled'}>
                <Icon
                  name="publish"
                  size={18}
                  containerStyle={{ marginRight: '8px' }}
                  color={isEverythingComplete ? 'white' : 'black'}
                />
                <Text
                  fs={14}
                  color={isEverythingComplete ? 'white' : 'black'}
                  style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}
                >
                  {isEverythingComplete ? (
                    'En attente de validation'
                  ) : (
                    <Text fs={14} italic style={{ whiteSpace: 'nowrap', marginLeft: '4px' }}>
                      Informations manquantes
                    </Text>
                  )}
                </Text>
              </Button>
            )}
          </Box>
        </Box>

        <Box fd="r" padding="32px 32px 0 32px" gutter="negative" style={{ flexWrap: 'wrap' }}>
          {map(listOfItemsWithStatus, ({ title, text, icon, path, completed }, i) => {
            const color = completed ? 'green' : 'yellowDarker'

            return (
              <HeadBlock
                key={`section-${path}-${i}`}
                onClick={() => history.push(`/mon-lieu/edition/${path}`)}
              >
                <Box fd="r" jc="sb" ai="c" style={{ marginBottom: '16px' }}>
                  <Icon name={icon} color={color} size={32} />
                  <Box fd="r" ai="c" style={{ flex: 0, marginRight: '53px' }}>
                    <Icon
                      name={completed ? 'checkGreen' : 'tocomplete'}
                      size={18}
                      color={color}
                      containerStyle={{ marginRight: '8px' }}
                    />
                    <Text color={color} style={{ whiteSpace: 'nowrap' }}>
                      {completed ? 'Complet' : 'À compléter'}
                    </Text>
                  </Box>
                </Box>
                <Box ai="c" fd="r" jc="sb">
                  <Title fs={18} color={color} bold spacingBottom="4px">
                    {title}
                  </Title>
                  <Icon
                    name="arrow"
                    color={color}
                    size={20}
                    containerStyle={{ margin: '0 0 4px 8px' }}
                  />
                </Box>
                <Text>{text}</Text>
              </HeadBlock>
            )
          })}
        </Box>
      </BoxWithHeader>
    </Box>
  )
}
