import React, { useState } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { times } from 'lodash'

import Box from '../box'
import Button from '../button'
import Link, { StyledLink } from '../typography/link'
import Icon from '../icon'
import Text from '../typography/text'
import { Colors } from 'ui/theme'

const Overlay = styled(Box).attrs({ jc: 'c', ai: 'c', fd: 'r' })`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
`

const Control = styled.a<{ disabled?: boolean }>`
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 80px;
  width: 64px;
  height: 64px;
  box-sizing: border-box;
  cursor: pointer;
  visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
  opacity: ${props => (props.disabled ? 0 : 1)};
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled(Box).attrs({ fd: 'c', ai: 'c', jc: 'sb' })`
  max-width: 850px;
  max-height: 632px;
  height: 632px;
  background-color: white;
  border-radius: 4px;
  margin: 0 32px;

  ${Text} {
    color: ${props => transparentize(0.3, props.theme.black)};
  }

  ${StyledLink} {
    color: ${props => transparentize(0.5, props.theme.black)};
    line-height: 52px;
  }
`

const Bullet = styled.a<{ active?: boolean }>`
  background-color: ${props =>
    props.active ? props.theme.black : transparentize(0.8, props.theme.black)};
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 3px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.black};
  }
`

const CarouselImage = styled.img<{ bg?: Colors }>`
  background-color: ${props => (props.bg ? props.theme[props.bg] : 'transparent')};
  width: 850px;
`

export function CarouselItem({ src, children, bg }: { src: string; children: any; bg?: Colors }) {
  return (
    <Box fd="c" ai="c" jc="c" br={4}>
      <CarouselImage src={src} bg={bg} />
      <Box style={{ padding: '32px 80px 0 80px' }}>{children}</Box>
    </Box>
  )
}

export default function Carousel({
  children = [],
  onFinish,
  withButton,
}: {
  children: JSX.Element[]
  withButton?: boolean
  onFinish: () => void
}) {
  const [index, setIndex] = useState<number>(0)
  const last = index === children.length - 1
  const first = index === 0

  return (
    <Overlay>
      <Control
        disabled={first}
        onClick={() => {
          first ? void 0 : setIndex(index - 1)

          return false
        }}
      >
        <Icon name="caret" size={24} rotate={180} color="white" />
      </Control>
      <Content>
        {children.map((element, i) => (
          <div key={`content-children-${i}`} style={{ display: index === i ? 'block' : 'none' }}>
            {element}
          </div>
        ))}
        <Box fd="c" ai="c" jc="c" style={{ paddingBottom: '32px' }}>
          <Box fd="r" ai="c" jc="c" style={{ paddingBottom: '10px' }}>
            {times(children.length, i => (
              <Bullet key={`bullet-${i}`} onClick={() => setIndex(i)} active={i === index} />
            ))}
          </Box>
          {withButton && last ? (
            <Button onClick={onFinish}>Accéder à mon espace</Button>
          ) : (
            <Link underline onClick={onFinish}>
              {last ? 'Accéder à mon espace' : 'Passer l’introduction'}
            </Link>
          )}
        </Box>
      </Content>
      <Control
        disabled={last}
        onClick={() => {
          last ? void 0 : setIndex(index + 1)

          return false
        }}
      >
        <Icon name="caret" size={24} color="white" />
      </Control>
    </Overlay>
  )
}
