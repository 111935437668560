import React from 'react'
import styled from 'styled-components'
import Icon from 'ui/icon'

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  size?: 'normal' | 'small'
  variant?:
    | 'danger'
    | 'outline'
    | 'outline-transparent'
    | 'outline-danger'
    | 'primary'
    | 'success'
    | 'card'
    | 'secondary'
    | 'primary'
    | 'transparent'
    | 'disabled'
  type?: 'button' | 'submit'
  style?: React.CSSProperties
  disabled?: boolean
}

const StyledButton = styled.button`
  align-items: center;
  border-radius: 4px;
  border: 1px solid;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.4' : '1')};
  display: flex;
  font-family: Nunito;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  outline: 0;
  padding: 15px 24px;
  transition: opacity 0.3s ease;
  box-sizing: border-box;

  &.primary {
    background-color: ${props => props.theme.yellow};
    border-color: ${props => props.theme.yellow};
    color: ${props => props.theme.black};
  }

  &.secondary {
    background-color: ${props => props.theme.white};
    border-color: ${props => props.theme.white};
    color: ${props => props.theme.black};
  }

  &.disabled {
    border-color: ${props => props.theme.greyDark3};
    background-color: ${props => props.theme.greyDark3};
    color: ${props => props.theme.black};
  }

  &.outline {
    border-color: ${props => props.theme.greyLightN2};
    background-color: white;
    color: ${props => props.theme.black};
  }

  &.outline-transparent {
    border-color: ${props => props.theme.greyLightN2};
    background-color: transparent;
    color: ${props => props.theme.black};
  }

  &.outline-danger {
    border-color: ${props => props.theme.pink};
    background-color: transparent;
    color: ${props => props.theme.pink};
  }

  &.success {
    background-color: ${props => props.theme.green};
    border-color: ${props => props.theme.green};
    color: ${props => props.theme.white};
  }

  &.danger {
    border-color: ${props => props.theme.pink};
    background-color: ${props => props.theme.pink};
    color: ${props => props.theme.white};
  }

  &.card {
    border-color: ${props => props.theme.white};
    background-color: ${props => props.theme.white};
    box-shadow: 0px 2px 0px 0px #d5e2e1;
  }

  &.transparent {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  &.small {
    font-size: 12px;
    padding: 10px 24px;
    height: 38px;
  }

  &:hover {
    opacity: ${props => (props.disabled ? '0.4' : '0.8')};
  }
`

// Ok so it's a simple copy paste to avoid typescript issue
// not the best...
const LinkButton = styled.a`
  align-items: center;
  border-radius: 3px;
  border: 1px solid;
  cursor: pointer;
  display: flex;
  font-family: Nunito;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  outline: 0;
  padding: 15px 24px;
  transition: opacity 0.3s ease;
  box-sizing: border-box;
  text-decoration: none;

  &.primary {
    background-color: ${props => props.theme.yellow};
    border-color: ${props => props.theme.yellow};
    color: ${props => props.theme.black};
  }

  &.secondary {
    background-color: ${props => props.theme.white};
    border-color: ${props => props.theme.white};
    color: ${props => props.theme.black};
  }

  &.outline {
    border-color: ${props => props.theme.greyLightN2};
    background-color: white;
    color: ${props => props.theme.black};
  }

  &.outline-transparent {
    border-color: ${props => props.theme.greyLightN2};
    background-color: transparent;
    color: ${props => props.theme.black};
  }

  &.outline-danger {
    border-color: ${props => props.theme.pink};
    background-color: transparent;
    color: ${props => props.theme.pink};
  }

  &.success {
    background-color: ${props => props.theme.green};
    border-color: ${props => props.theme.green};
    color: ${props => props.theme.white};
  }

  &.danger {
    border-color: ${props => props.theme.pink};
    background-color: ${props => props.theme.pink};
    color: ${props => props.theme.white};
  }

  &.card {
    border-color: ${props => props.theme.white};
    background-color: ${props => props.theme.white};
    box-shadow: 0px 2px 0px 0px #d5e2e1;
  }

  &.transparent {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  &.small {
    font-size: 12px;
    padding: 10px 24px;
    height: 38px;
  }

  &:hover {
    opacity: 0.8;
  }
`

const Button: React.FC<Props> = ({
  children,
  onClick,
  size = 'normal',
  variant = 'primary',
  type = 'button',
  disabled,
  style,
}) => (
  <StyledButton
    disabled={disabled}
    onClick={onClick}
    className={`${size} ${variant}`}
    type={type}
    style={style}
  >
    {children}
  </StyledButton>
)

const BackButton: React.FC<{
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}> = ({ onClick }) => (
  <Button variant="transparent" onClick={onClick} style={{ marginRight: '8px' }}>
    <Icon
      name="caret"
      color="white"
      bg="greyLightN2"
      cp="6px"
      size={10}
      rotate={180}
      containerStyle={{ borderRadius: '50%' }}
    />
  </Button>
)

export { LinkButton, BackButton }

export default Button
