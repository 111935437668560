import React from 'react'

import Layout from './layout'
import { Form } from 'types/form'

const CONTACT_FORM: Form = [
  [
    {
      name: 'facebook',
      type: 'text',
      label: 'Facebook',
      placeholder: 'Facebook',
      required: false,
    },
    {
      name: 'twitter',
      type: 'text',
      label: 'twitter',
      placeholder: 'twitter',
      required: false,
    },
  ],
  [
    {
      name: 'whatsapp',
      type: 'text',
      label: 'Whatsapp',
      placeholder: 'Whatsapp',
      required: false,
    },
    {
      name: 'youtube',
      type: 'text',
      label: 'Youtube',
      placeholder: 'Youtube',
      required: false,
    },
  ],
]

export default function SocialNetworks() {
  return (
    <Layout
      title="Réseaux Sociaux"
      subText="Si votre établissement à évolué il est important de garder ces données à jour."
      help={{
        icon: 'chat',
        title: 'En quoi ces informations sont importantes ?',
        text:
          'Sahanest souhaite proposer aux usagers des établissements adaptés à leurs besoins. Et c’est justement ces informations qui peremttent aux usagers de choisir le bon lieu de vie.',
      }}
      fields={CONTACT_FORM}
      subForm="contact"
    />
  )
}
