import styled from 'styled-components'

import { Colors } from '../../theme'

export default styled.p<{
  align?: 'center' | 'justify' | 'left' | 'right'
  color?: Colors
  fs?: number
  spacingBottom?: string
  bold?: boolean
  italic?: boolean
  opacity?: number
}>`
  color: ${props => (props.color ? props.theme[props.color] : props.theme.black)};
  font-family: Nunito;
  font-size: ${props => props.fs || 16}px;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
  line-height: ${props => (props.fs ? props.fs + 10 : 26)}px;
  margin-bottom: ${props => props.spacingBottom || 0};
  margin-top: 0;
  opacity: ${props => props.opacity || 1};
  text-align: ${props => props.align || 'left'};
`
