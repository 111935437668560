import React from 'react'
import styled from 'styled-components'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import EstablishmentCard from 'ui/card/establishment'

import StepLayout from './stepLayout'
import { InstitutionInfoFromWebflow } from 'types/institution'
import { formatedAddress } from 'components/pages/helper'
interface Props {
  suggestedEstablishments: InstitutionInfoFromWebflow[]
  onPrevious?: () => void
  onChange: (value: InstitutionInfoFromWebflow | null) => void
  onNext?: () => void
  selectedInstitution?: InstitutionInfoFromWebflow
}

const StepSuggestedEstablishments = ({
  onPrevious,
  onChange,
  onNext,
  selectedInstitution,
  suggestedEstablishments,
}: Props) => {
  return (
    <StepLayout
      spacingBottom="8px"
      title="Sélection de l’établissement"
      subText="Si votre établissement est dans la liste sélectionnez-le"
    >
      <StyledLink onClick={onNext}>
        <Icon name="help" size={20} color="green" />
        <Text color="green" bold style={{ marginLeft: '5px' }}>
          Je ne trouve pas mon établissement
        </Text>
      </StyledLink>

      {suggestedEstablishments.map(institution => {
        return (
          <EstablishmentCard
            key={institution.id}
            institutionType={institution.infoInstitutionType}
            address={formatedAddress(
              institution.infoStreet,
              institution.infoZipcode,
              institution.infoCountry,
              institution.infoCity,
            )}
            institutionName={institution.infoInstitutionName}
            webSite={institution.infoWebsite}
            phoneNumber={institution.infoPhoneNumber}
            siretNumber={institution.infoSiretNumber}
            onClick={() =>
              onChange(institution.id === selectedInstitution?.id ? null : institution)
            }
            isSelected={institution.id === selectedInstitution?.id}
          />
        )
      })}

      <Box jc="sb" f="0 1 auto" style={{ marginTop: '40px' }}>
        <Button variant="outline" style={{ backgroundColor: 'white' }} onClick={onPrevious}>
          <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
          Étape précédente
        </Button>
        <Button onClick={onNext} disabled={!selectedInstitution?.id}>
          Étape suivante
          <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
        </Button>
      </Box>
    </StepLayout>
  )
}

export default StepSuggestedEstablishments

export const StyledLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 40px;
`
