import React from 'react'
import styled from 'styled-components'

import Icon, { IconNames } from '../../icon'

const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1 0 30px;

  .checkedIcon {
    display: none;
    position: absolute;
    top: 4px;
    left: 2px;
  }
`

const StyledLabel = styled.label<{ hasError?: boolean }>`
  font-family: Nunito;
  font-size: 16px;
  line-height: 26px;
  color: ${props => (props.hasError ? props.theme.pink : props.theme.greyDark2)};
  cursor: pointer;
  transition: color 0.4s;
  display: flex;
  padding-top: 6px;

  & svg path {
    transition: color 0.4s;
  }
`
const Square = styled.label<{ hasError?: boolean }>`
  margin-top: 4px;

  &:before {
    content: '';
    margin-right: 8px;
    padding: 11px;
    border: 1px solid ${props => (props.hasError ? props.theme.pink : props.theme.greyDark2)};
    box-sizing: border-box;
    line-height: 0;
    font-size: 0;
    vertical-align: middle;
    transition: border 0.4s;
  }
`

const StyledInput = styled.input<{ hasError?: boolean }>`
  position: absolute;
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin: 1px 0 0 0;
  z-index: 1;
  opacity: 0;

  &:checked {
    ~ .checkedIcon {
      display: block;
    }

    ~ ${StyledLabel} {
      color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};

      & svg path {
        fill: ${props => props.theme.black};
      }
    }

    ~ .checkbox-icon svg path {
      fill: ${props => props.theme.black};
    }

    ~ ${Square} {
      &:before {
        border: 1px solid ${props => (props.hasError ? props.theme.pink : props.theme.black)};
        background-color: ${props => (props.hasError ? props.theme.pink : props.theme.black)};
      }
    }
  }
`

export default function Checkbox({
  style,
  containerStyle,
  children,
  forwardRef,
  hasError,
  icon,
  ...radioProps
}: {
  id: string
  style?: any
  containerStyle?: any
  name: string
  value: any
  children: any
  forwardRef?: any
  disabled?: boolean
  hasError?: boolean
  icon?: keyof typeof IconNames
}) {
  return (
    <Container style={containerStyle}>
      <StyledInput type="checkbox" {...radioProps} ref={forwardRef} hasError={hasError} />
      <Icon name="check" color="white" className="checkedIcon" size={20} />
      <Square htmlFor={radioProps.id} hasError={hasError} />
      <StyledLabel htmlFor={radioProps.id} style={style} hasError={hasError}>
        {!!icon && (
          <Icon
            name={icon}
            size={18}
            containerStyle={{ marginRight: '5px' }}
            className="checkbox-icon"
            color="greyDark2"
          />
        )}
        {children}
      </StyledLabel>
    </Container>
  )
}
