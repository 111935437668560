import React from 'react'
import styled from 'styled-components'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'

const administrativesElements = [
  'Une copie de l’orientation MDPH',
  "Une copie de la notification de l'aide sociale",
  'Copie de la carte Européenne délivrée par la sécurité sociale',
  'Documents de mutuelle',
  'Attestation de sécurité sociale',
  'Accord du département pour la prise en charge des frais de séjour',
  'Joindre l’allocation Adulte handicapé (notification de l’AAH)',
]

const medicalElements = [
  'Un bilan sanguin récent',
  'Bilan dentaire datant de moins de 12 mois ',
  'Bilan médical de moins de 12 mois',
  'Une prescription médicale',
  'Sections du carnet de santé',
]

const socioElements = [
  'Rapport de comportement récent fait par les professionels engagés dans le suivi ',
]

const BlueBox = styled(Box)`
  margin-bottom: 16px;
  padding: 16px;
  background-color: ${props => props.theme.greenLight2};
`

export default function List() {
  return (
    <Box fd="c">
      <Title bold spacingBottom="32px" fs={18}>
        Documents relatifs aux données administratives
      </Title>
      {administrativesElements.map(s => (
        <BlueBox key={s}>
          <Text fs={16}>{s}</Text>
        </BlueBox>
      ))}
      <Title bold spacingBottom="32px" fs={18}>
        Une copie de l’orientation MDPH
      </Title>
      {medicalElements.map(s => (
        <BlueBox key={s}>
          <Text fs={16}>{s}</Text>
        </BlueBox>
      ))}
      <Title bold spacingBottom="32px" fs={16}>
        Documents relatifs aux socio-éducatives
      </Title>
      {socioElements.map(s => (
        <BlueBox key={s}>
          <Text fs={16}>{s}</Text>
        </BlueBox>
      ))}
    </Box>
  )
}
