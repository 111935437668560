import { Form } from 'types/form'
import { ADDICTION_KIND_OF } from 'types/educative'

const ADDICTION_FORM: Form = [
  [
    {
      name: 'kindOf',
      type: 'checkbox',
      label: "Types d'addiction",
      placeholder: 'Sélectionner les conduites addictives',
      options: [
        {
          label: 'Aucune',
          value: ADDICTION_KIND_OF.NONE,
        },
        { label: 'Tabac', value: ADDICTION_KIND_OF.TABACCO },
        { label: 'Alcool', value: ADDICTION_KIND_OF.ALCOHOL },
        { label: 'Stupéfiants/Psychotropes', value: ADDICTION_KIND_OF.DRUGS },
      ],
      required: true,
      vertical: true,
    },
  ],
  [
    {
      name: 'precision',
      type: 'textarea',
      label: 'Précisions',
      placeholder: '',
      required: false,
    },
  ],
]

export default ADDICTION_FORM
