import { ProgressState } from './enums'

export enum ALLOWANCES {
  NONE = 'none',
  AAH = 'aah',
  PCH = 'pch',
  NAS = 'nas',
  OTHER = 'other',
}

export type Benefit = {
  id?: string
  allowances: number[]
  additionalAllowances: string
  progress: number
  createdAt: string
  updatedAt: string
}

export type PocketMoney = {
  id?: string
  monthlyFreeMoney: number
  monthlyClothingCosts: number
  progress: number
  createdAt: string
  updatedAt: string
}

type Financial = {
  statusProgress: ProgressState
  percentProgress: number
  pocketMoney: PocketMoney
  benefit: Benefit
}

export default Financial
