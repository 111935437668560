import React, { useContext, useEffect } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { find } from 'lodash'

import { FormLayout, GetItemsAdmissionRecord } from 'components/pages/form'
import Medical from '../medical'
import ValidateMedicalInfo from '../validate'

import { usePageName } from 'hooks/usePageName'
import { AuthContext } from 'App'
import useResource from 'hooks/useResource'
import { AdmissionRecordProgress } from 'types/admissionRecord'
import { getAdmissionFormProgress } from 'api/admissionRecord'

export default function DoctorForm() {
  const { formName } = useParams<{
    formName: string
  }>()
  const { setPageName } = usePageName()
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const { pathname } = useLocation()
  const admissionRecord = find(auth.admissionRecords, ar => ar.id === auth.selectedId)
  const [resourceState, dispatch] = useResource<AdmissionRecordProgress, {}>(
    undefined,
    () =>
      auth.selectedId
        ? getAdmissionFormProgress(auth.selectedId)
        : Promise.resolve({} as AdmissionRecordProgress),
    [auth?.selectedId],
  )

  useEffect(() => {
    setPageName(`Dossier de ${admissionRecord?.firstName} ${admissionRecord?.lastName}`)
  }, [])

  const renderContent = () => {
    switch (formName) {
      case 'medical':
        return <Medical refreshProgress={() => dispatch({ name: 'REFRESH_DATA' })} />
      case 'medical-validation':
        return <ValidateMedicalInfo />
      default:
        return <Redirect from="*" to="/medical/etape/1" />
    }
  }

  return (
    <FormLayout
      path={formName}
      resourceState={resourceState}
      progressBar={{
        title: 'Informations médicales',
        items: GetItemsAdmissionRecord(resourceState, history, pathname, 'doctor'),
      }}
    >
      {renderContent()}
    </FormLayout>
  )
}
