import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { get } from 'lodash'

import Box from 'ui/box'
import Title from 'ui/typography/title'
import Text from 'ui/typography/text'
import { LinkButton } from 'ui/button'
import Icon from 'ui/icon'
import File from 'ui/form/file'
import Loader from 'ui/loader'
import { Controls } from 'components/pages/form'

import { AuthContext } from 'App'
import useResource from 'hooks/useResource'
import { useToasts } from 'hooks/useToaster'
import { Admission } from 'types/admission'
import { addFileMDPH, getAdmission } from 'api/admission'

export default function Step1() {
  const { auth } = useContext(AuthContext)
  const history = useHistory()
  const { addToast, addGenericErrorToast } = useToasts()
  const [resourceState] = useResource<Admission, {}>(
    undefined,
    () => getAdmission(get(auth, 'selectedId', '0'), { current: true }),
    [],
  )
  const { register, control, handleSubmit, errors } = useForm<{ files?: FileList }>()
  const { section, step } = useParams<{ section: string; step: string }>()
  const parsedStep = Number(step)

  const onSubmit = (data: { files?: FileList }) => {
    addFileMDPH(get(auth, 'selectedId', '0'), data.files as FileList)
      .then(() =>
        addToast({
          type: 'success',
          title: 'Succès !',
          message: 'Votre notification MPDH a bien été envoyé !',
        }),
      )
      .then(() => history.push(`/admission/${section}/etape/${parsedStep + 1}`))
      .catch(addGenericErrorToast)
  }

  return (
    <Box
      fd="c"
      style={{
        minHeight: '100%',
      }}
    >
      <Box
        fd="c"
        padding="32px"
        style={{
          overflowY: 'scroll',
        }}
      >
        <Box
          f="initial"
          ai="c"
          jc="sb"
          bg="greyLightN"
          br={4}
          padding="24px"
          style={{ marginBottom: '32px' }}
        >
          <Text fs={16}>
            Comment envoyer mon dossier à la MDPH ? Toutes les informations sont disponibles sur
            notre blog.
          </Text>

          <LinkButton
            style={{ marginLeft: '32px', flexShrink: 0 }}
            className="primary"
            href={'https://www.sahanest.fr/mdph'}
            target="_blank"
          >
            Consulter le blog
            <Icon name="arrow" size={14} rotate={-45} containerStyle={{ marginLeft: '8px' }} />
          </LinkButton>
        </Box>
        <Title fs={22} bold spacingBottom="8px">
          Envoyer la notification MPDH
        </Title>
        <Text fs={16} opacity={0.6} spacingBottom="32px">
          Vous avez envoyé votre dossier à la MDPH et reçu votre notification ? Ajoutez ci-dessous
          la notification MDPH reçue par courrier. Même si aucun établissement est dans la
          possibilité de vous accueillir nous avons besoin de votre notification MDPH.
        </Text>
        {resourceState.loading ? (
          <Box ai="c" jc="c">
            <Loader />
          </Box>
        ) : (
          <form>
            <File
              name="files"
              id="files"
              forwardRef={register({
                required: 'Ce champ est requis',
              })}
              getValue={() => control.getValues('files') as FileList}
              resetFile={() => control.setValue('files', undefined)}
              uploadedFile={resourceState.resource?.mdph}
              disabled={!!resourceState.resource?.mdph}
              hasError={!!errors?.files}
            />
            <Text
              fs={16}
              color="pink"
              style={{ visibility: !!errors?.files ? 'visible' : 'hidden', marginTop: '8px' }}
            >
              Ce champ est requis
            </Text>
          </form>
        )}
      </Box>
      <Controls
        prevButton={{
          callback: () => history.push(`/admission/${section}/etape/${parsedStep - 1}`),
          label: 'Retour',
        }}
        nextButton={{
          callback: !!resourceState.resource?.mdph
            ? () => history.push(`/admission/${section}/etape/${parsedStep + 1}`)
            : handleSubmit(onSubmit),
          label: 'Continuer',
        }}
      />
    </Box>
  )
}
