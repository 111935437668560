import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import styled from 'styled-components'

import Box from 'ui/box'
import Text from 'ui/typography/text'
import Group from 'ui/form/group'
import Dropdown from 'ui/form/dropdown'
import Input from 'ui/form/input'
import Label from 'ui/form/label'
import Icon from 'ui/icon'

import { PENSION_TYPE } from 'types/kit'

const CustomText = styled(Text)`
  span {
    color: ${props => props.theme.green};
  }
`

export const TYPE_OPTIONS = [
  { value: PENSION_TYPE.FULL_PENSION, label: 'Pension complète' },
  { value: PENSION_TYPE.HALF_PENSION, label: 'Demi-pension' },
  { value: PENSION_TYPE.OTHER, label: 'Autre' },
]

const Step1: React.FC = () => {
  const { register, control, errors } = useFormContext()

  return (
    <Box fd="c" padding="32px" style={{ overflowY: 'scroll' }}>
      <Group style={{ width: '66%' }}>
        <Label htmlFor="type" required style={{ paddingBottom: '16px' }}>
          Sélectionnez le mode de pension
        </Label>
        <Controller
          as={Dropdown}
          id="type"
          name="type"
          placeholder="Selectionnez un mode de pension"
          options={TYPE_OPTIONS}
          control={control}
          rules={{ required: true }}
        />
        <Text fs={16} color="pink" style={{ visibility: errors.type ? 'visible' : 'hidden' }}>
          Ce champ est requis
        </Text>
      </Group>
      <Box padding="32px 0" width="420px">
        <Icon name="message" size={20} color="green" containerStyle={{ marginRight: '8px' }} />

        <CustomText>
          <span>Conseil :</span> Par la suite vous pourrez créer un trousseau pour chaque mode de
          pension.
        </CustomText>
      </Box>
      <Group style={{ width: '66%' }}>
        <Label htmlFor="name" required style={{ paddingBottom: '16px' }}>
          Nom
        </Label>
        <Input
          id="name"
          name="name"
          placeholder="Nommez votre trousseau"
          ref={register({ required: true })}
        />
        <Text fs={16} color="pink" style={{ visibility: errors.name ? 'visible' : 'hidden' }}>
          Ce champ est requis
        </Text>
      </Group>
    </Box>
  )
}

export default Step1
