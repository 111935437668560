import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { map } from 'lodash'

import Box, { BoxWithHeader } from 'ui/box'
import { BackButton } from 'ui/button'
import Text from 'ui/typography/text'
import Title from 'ui/typography/title'
import Loader from 'ui/loader'
import Button from 'ui/button'

import { usePageName } from 'hooks/usePageName'
import useResource, { Fetched } from 'hooks/useResource'
import { getCandidacy } from 'api/candidacy'
import { Candidacy } from 'types/candidacy'
import Icon from 'ui/icon'
import { getAdmissionTimeline } from 'constants/admission'
import InstitutionChooseEngagement from 'components/modals/admission/institutionChooseEngagement'
import InstitutionRefusedEngagement from 'components/modals/admission/institutionRefusedEngagement'

import { AuthContext } from 'App'

export default function AdmissionDetails() {
  const history = useHistory()
  const { auth } = useContext(AuthContext)
  const { candidacyId } = useParams<{ candidacyId: string }>()
  const { setPageName } = usePageName()
  const [resourceState, dispatch] = useResource<Candidacy, {}>(
    undefined,
    () => getCandidacy(candidacyId),
    [],
  )
  const [openModal, setOpenModal] = useState<'accept' | 'reject'>()

  useEffect(() => {
    setPageName('Suivi de l’admission')
  }, [])

  function onCloseModal() {
    setOpenModal(undefined)
  }

  function onSuccessModal() {
    setOpenModal(undefined)
    dispatch({ name: 'REFRESH_DATA' })
  }

  if (resourceState.loading || !!resourceState.error) {
    return (
      <Box ai="c" jc="c">
        <Loader />
      </Box>
    )
  }

  const { resource: candidacy } = resourceState as Fetched<Candidacy>
  const timelineItem = getAdmissionTimeline(candidacy)

  return (
    <Box gutter="negative" style={{ maxHeight: '100%' }}>
      <BoxWithHeader
        br={4}
        style={{
          margin: '0 15px',
          alignSelf: 'flex-start',
        }}
      >
        <Box>
          <BackButton onClick={history.goBack} />
          <Title fs={24} bold>
            {`Suivi de l’admission de ${candidacy.patientName}`}
          </Title>
        </Box>

        <Box fd="c" padding="32px 32px 0 32px">
          {map(timelineItem, (item, i) => (
            <Box
              ai="c"
              br={4}
              withBorder
              padding="24px 32px"
              key={`timeline-item-${i}`}
              style={{ marginBottom: '16px' }}
            >
              {i === 0 && !item.icon ? (
                <Icon
                  name="loading"
                  size={20}
                  color="orange"
                  containerStyle={{ marginRight: '40px' }}
                  isSpinner
                />
              ) : (
                <Icon
                  name={item.icon || 'check'}
                  size={20}
                  color={item.icon ? 'pink' : 'green'}
                  containerStyle={{ marginRight: '40px' }}
                />
              )}
              <Box fd="c">
                <Box style={{ marginBottom: '8px' }}>
                  <Text fs={16} opacity={0.6}>{`Le ${new Date(
                    item.date,
                  ).toLocaleDateString()} `}</Text>
                  <Text fs={16} bold>
                    &nbsp;{`- ${item.title}`}
                  </Text>
                </Box>
                <Text fs={16}>{item.text}</Text>
                {item.hasButtons && (
                  <Box style={{ marginTop: '16px' }}>
                    <Button
                      variant="outline"
                      onClick={() => setOpenModal('reject')}
                      style={{ marginRight: '16px' }}
                    >
                      Ne pas accueillir l’usager
                    </Button>
                    <Button variant="primary" onClick={() => setOpenModal('accept')}>
                      Générer l’accord de prise en charge
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </BoxWithHeader>
      <BoxWithHeader
        br={4}
        width="380px"
        style={{
          margin: '0 15px',
          alignSelf: 'flex-start',
        }}
      >
        <Box>
          <Title fs={20} bold>
            Aperçu du dossier
          </Title>
        </Box>

        <Box fd="c" padding="32px 32px 0 32px">
          <Box fd="c" padding="24px" bg="greyLightN" style={{ marginBottom: '24px' }}>
            <Box fd="c">
              <Text fs={14} opacity={0.7} style={{ textTransform: 'uppercase' }}>
                HANDICAP
              </Text>
              {/* TODO : get handicap when it will be available */}
              {/* <Text fs={16}>{candidacy.handicap}</Text> */}
            </Box>
            <Box fd="c" padding="32px 0 0 0">
              <Text fs={14} opacity={0.7} style={{ textTransform: 'uppercase' }}>
                STATUT
              </Text>
              {/* TODO : get status when it will be available */}
              {/* <Text fs={16}></Text> */}
            </Box>
          </Box>
          <Button onClick={() => history.push(`/admission/${candidacyId}/details`)}>
            Consulter le dossier
          </Button>
        </Box>
      </BoxWithHeader>

      <InstitutionChooseEngagement
        data={{
          candidacyId: candidacyId,
          patientName: candidacy.patientName,
          kitListId: candidacy.institutionKitListId,
        }}
        onClose={onCloseModal}
        onSuccess={onSuccessModal}
        opened={openModal === 'accept'}
        selectedInstitutionId={auth.selectedInstitutionId}
      />
      <InstitutionRefusedEngagement
        data={{
          candidacyId: candidacyId,
          patientName: candidacy.patientName,
        }}
        onClose={onCloseModal}
        onSuccess={onSuccessModal}
        opened={openModal === 'reject'}
      />
    </Box>
  )
}
