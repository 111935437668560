import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { capitalize, find, isEmpty, map } from 'lodash'

import Modal from 'ui/modal'
import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import Loader from 'ui/loader'

import { success } from 'assets/illustrations'

import { acceptCandidacy } from 'api/candidacy'
import { getKitLists } from 'api/kit'
import { KitPayload } from 'types/kit'

import { useToasts } from 'hooks/useToaster'
import useResource from 'hooks/useResource'
import { TYPE_OPTIONS } from 'components/pages/institution/myInstitution/kit/form/step1'
import { SECTIONS } from 'components/pages/institution/myInstitution/kit/form/step2'

export default function Accept({
  candidacy,
  opened,
  onClose,
  onSuccess,
  selectedInstitutionId,
}: {
  candidacy: {
    id: string
    patientName: string
  }
  opened: boolean
  onClose: () => void
  onSuccess: () => void
  selectedInstitutionId: string | undefined
}) {
  const { addGenericErrorToast } = useToasts()
  const history = useHistory()
  const [state, setState] = useState<{
    loading: boolean
    showSuccess: boolean
    openPanelId?: string
    selectedKitId?: string
  }>({ loading: false, showSuccess: false })

  const [{ resource: listOfKits }] = useResource<KitPayload[], {}>(
    undefined,
    () => getKitLists(selectedInstitutionId),
    [],
  )

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      title={`Programmation de l’accueil de ${candidacy.patientName}`}
    >
      {state.showSuccess ? (
        <Box fd="c">
          <Box ai="c" br={4} bg="greyLightN" padding="32px 24px" style={{ marginBottom: '32px' }}>
            <img src={success} style={{ width: '92px', marginRight: '24px' }} alt="succés" />
            <Box fd="c">
              <Text fs={18} bold>
                Vous venez d’accepter le dossier de {candidacy.patientName}
              </Text>
              <Text fs={16} opacity={0.6}>
                Une confirmation email a été envoyée à son aidant afin de la prévenir des détails
                concernant son arrivée.
              </Text>
            </Box>
          </Box>

          <Box jc="fe" padding="16px 0 0 0">
            <Button onClick={onSuccess}>
              Terminer <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
            </Button>
          </Box>
        </Box>
      ) : (
        <Box fd="c">
          <Box ai="c" br={4} bg="greyLightN" padding="32px 24px" style={{ marginBottom: '32px' }}>
            <img src={success} style={{ width: '92px', marginRight: '24px' }} alt="succés" />
            <Box fd="c">
              <Text fs={18} bold>
                Pour accepter le dossier de {candidacy.patientName}
              </Text>
              <Text fs={16} opacity={0.6}>
                Il ne vous reste plus qu’à sélectionner un trousseau pour préparer son accueil
              </Text>
            </Box>
          </Box>

          {listOfKits && listOfKits.length > 0 ? (
            <Box fd="c">
              <Box jc="sb" ai="c">
                <Text fs={18} bold>
                  Sélectionnez un trousseau
                </Text>
                <Button onClick={() => history.push('/mon-etablissement/trousseaux/edition')}>
                  Créer un trousseau d’accueil
                  <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
                </Button>
              </Box>
              {map(listOfKits, ({ id, name, typeList, createdAt, ...kitItems }) => {
                const displayed = state.openPanelId === id

                return (
                  <Box
                    key={`kit-${id}`}
                    fd="c"
                    padding="24px"
                    br={2}
                    withBorder
                    style={{ marginTop: '16px' }}
                  >
                    <Box>
                      <Box fd="c">
                        <Text fs={16} bold>
                          {`${name} - ${find(TYPE_OPTIONS, o => o.value === typeList)?.label}`}
                        </Text>
                        <Text fs={16} opacity={0.5}>
                          {`Ajouté le ${new Date(createdAt).toLocaleDateString()}`}
                        </Text>
                      </Box>
                      <Box jc="fe">
                        <Button
                          variant="outline"
                          size="small"
                          onClick={() =>
                            setState({ ...state, openPanelId: displayed ? undefined : id })
                          }
                          style={{ marginRight: '16px' }}
                        >
                          {displayed ? 'Masquer' : 'Aperçu'}
                        </Button>
                        <Button
                          size="small"
                          onClick={() => setState({ ...state, selectedKitId: id })}
                        >
                          {state.selectedKitId === id ? (
                            <>
                              Sélectionné
                              <Icon
                                name="check"
                                size={15}
                                containerStyle={{ paddingLeft: '6px' }}
                              />
                            </>
                          ) : (
                            'Choisir ce trousseau'
                          )}
                        </Button>
                      </Box>
                    </Box>
                    <Box fd="c" style={{ display: displayed ? 'flex' : 'none', marginTop: '8px' }}>
                      {SECTIONS.filter(({ name }) => !isEmpty(kitItems[name])).map(
                        ({ name, panelLabel }) => (
                          <Box fd="c" key={name}>
                            <Text color="black" bold style={{ margin: '8px 0' }}>
                              {panelLabel}
                            </Text>
                            <Box br={4} bg="greyLightN" fd="c" padding="8px 24px 16px 24px">
                              {map(kitItems[name], (value, key) => (
                                <Box
                                  jc="sb"
                                  style={{ marginTop: '8px' }}
                                  key={`${name}-item-${key}-${id}`}
                                >
                                  <Text fs={14} color="black">
                                    {capitalize(key)}
                                  </Text>
                                  <Text fs={14} color="black">
                                    {value}
                                  </Text>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        ),
                      )}
                    </Box>
                  </Box>
                )
              })}
              <Box jc="fe" padding="16px 0 0 0">
                <Button
                  disabled={!state.selectedKitId}
                  onClick={() => {
                    setState({ ...state, loading: true })
                    acceptCandidacy(candidacy.id, state.selectedKitId as string)
                      .then(() => setState({ ...state, loading: false, showSuccess: true }))
                      .catch(() => {
                        addGenericErrorToast()
                        setState({ ...state, loading: false })
                      })
                  }}
                >
                  {state.loading ? (
                    <Loader size={12} />
                  ) : (
                    <>
                      Accepter le dossier
                      <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
                    </>
                  )}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box fd="c" ai="c" br={4} withBorder padding="32px">
              <Icon name="book" size={46} containerStyle={{ marginBottom: '16px' }} />
              <Text fs={18} bold align="center" spacingBottom="8px">
                Vous n’avez pas encore de trousseau d’accueil
              </Text>
              <Text fs={16} opacity={0.6} align="center" spacingBottom="16px">
                Une confirmation email a été envoyée à son aidant afin de la prévenir des détails
                concernant son arrivée.
              </Text>
              <Button onClick={() => history.push('/mon-etablissement/trousseaux/edition')}>
                Créer un trousseau d’accueil
                <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Modal>
  )
}
