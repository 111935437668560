import React, { useState } from 'react'
import { includes, isEmpty, xor } from 'lodash'

import Box from 'ui/box'
import Button from 'ui/button'
import Icon from 'ui/icon'
import Text from 'ui/typography/text'
import StepLayout from './stepLayout'

import { ESTABLISHMENT_TYPES, EstablishmentType } from 'types/enums'

const StepEstablishment: React.FC<{
  onNext: (data: EstablishmentType[]) => void
  onPrevious: () => void
  defaultValue?: EstablishmentType[]
}> = ({ onNext, onPrevious, defaultValue }) => {
  const [establishmentSearch, setEstablishmentSearch] = useState<EstablishmentType[]>(
    defaultValue || [],
  )
  const [error, setError] = useState(false)

  const onSubmit = () => {
    if (!isEmpty(establishmentSearch)) {
      return onNext(establishmentSearch)
    }

    setError(true)
  }

  return (
    <StepLayout
      title="Quel type d'établissement cherchez-vous ?"
      subText="Nous avons besoin de ces informations pour vous proposez des lieux adaptés."
    >
      <Box fd="c">
        <Box
          bg="white"
          f="0 1 auto"
          ai="c"
          jc="sb"
          padding="24px 36px"
          br={4}
          withBorder
          style={{ marginBottom: '24px' }}
        >
          <Text>
            <strong>MAS</strong> - Maison d&apos;accueil spécialisée
          </Text>
          <Button
            variant={includes(establishmentSearch, 'mas') ? 'success' : 'outline'}
            size="small"
            onClick={() =>
              setEstablishmentSearch(xor(establishmentSearch, [ESTABLISHMENT_TYPES.MAS]))
            }
          >
            {includes(establishmentSearch, 'mas') ? (
              <>
                Sélectionné
                <Icon
                  name="check"
                  size={15}
                  color="white"
                  containerStyle={{ paddingLeft: '6px' }}
                />
              </>
            ) : (
              'Sélectionnez'
            )}
          </Button>
        </Box>
        <Box
          bg="white"
          f="0 1 auto"
          ai="c"
          jc="sb"
          padding="24px 36px"
          br={4}
          withBorder
          style={{ marginBottom: '24px' }}
        >
          <Text>
            <strong>FAM</strong> - Foyer d&apos;Accueil Médicalisé
          </Text>
          <Button
            variant={includes(establishmentSearch, 'fam') ? 'success' : 'outline'}
            size="small"
            onClick={() =>
              setEstablishmentSearch(xor(establishmentSearch, [ESTABLISHMENT_TYPES.FAM]))
            }
          >
            {includes(establishmentSearch, 'fam') ? (
              <>
                Sélectionné
                <Icon
                  name="check"
                  size={15}
                  color="white"
                  containerStyle={{ paddingLeft: '6px' }}
                />
              </>
            ) : (
              'Sélectionnez'
            )}
          </Button>
        </Box>
        <Box bg="white" f="0 1 auto" ai="c" jc="sb" padding="24px 36px" br={4} withBorder>
          <Text>
            <strong>FO / FV</strong> - Foyer Occupationnel / Foyer de Vie
          </Text>
          <Button
            variant={includes(establishmentSearch, 'fo_fv') ? 'success' : 'outline'}
            size="small"
            onClick={() =>
              setEstablishmentSearch(xor(establishmentSearch, [ESTABLISHMENT_TYPES.FO_FV]))
            }
          >
            {includes(establishmentSearch, 'fo_fv') ? (
              <>
                Sélectionné
                <Icon
                  name="check"
                  size={15}
                  color="white"
                  containerStyle={{ paddingLeft: '6px' }}
                />
              </>
            ) : (
              'Sélectionnez'
            )}
          </Button>
        </Box>
        <Text fs={16} color="pink" style={{ visibility: error ? 'visible' : 'hidden' }}>
          Vous devez sélectionner un établissement
        </Text>
      </Box>
      <Box jc="sb" f="0 1 auto">
        <Button variant="outline" style={{ backgroundColor: 'white' }} onClick={onPrevious}>
          <Icon name="arrow" size={20} rotate={180} containerStyle={{ marginRight: '8px' }} />
          Étape précédente
        </Button>
        <Button onClick={onSubmit}>
          Étape suivante
          <Icon name="arrow" size={20} containerStyle={{ marginLeft: '8px' }} />
        </Button>
      </Box>
    </StepLayout>
  )
}

export default StepEstablishment
